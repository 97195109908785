import { BehaviorSubject, map, Subject } from 'rxjs';
import { Organisations } from '../../data/report/organisations';
import {
    changeOrganizationPlanAsAdmin,
    getOrganizationPlanLimits,
    OrganisationDetails,
    OrganisationPlanLimits,
    OrganisationPlanLimitsRequest,
    ChangeOrganisationPlanRequest,
    ChangeOrganisationPlanRequestV2,
    getOrganisationDetails,
    changeOrganizationPlanAsAdminV2,
} from '../../data/report/organisationDetails';
import { monitor } from '../monitor';
import { InformativeError, PermissionError } from '../../utils/repository';

export const organisations$ = monitor('organisations$', new BehaviorSubject<Organisations>([]));

export const selectedOrganisationDetails$ = monitor(
    'selectedOrganisationDetails$',
    new BehaviorSubject<OrganisationDetails | undefined>(undefined)
);

export const selectedOrganisationPlanLimits$ = monitor(
    'selectedOrganisationPlanLimits$',
    new BehaviorSubject<OrganisationPlanLimits | undefined>(undefined)
);

export const organisationPlanLimitsLoading$ = monitor(
    'organisationPlanLimitsLoading$',
    new BehaviorSubject<boolean>(false)
);

export const organisationPlanLimitsError$ = monitor(
    'organisationPlanLimitsError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const getOrganisationPlanLimitsAction$ = monitor(
    'getOrganisationPlanLimitsAction$',
    new Subject<OrganisationPlanLimitsRequest>()
);

export const changeOrganisationPlanLimitsAction$ = monitor(
    'changeOrganisationPlanLimitsAction$',
    new Subject<ChangeOrganisationPlanRequest>()
);

export const changeOrganisationPlanLimitsActionV2$ = monitor(
    'changeOrganisationPlanLimitsActionV2$',
    new Subject<ChangeOrganisationPlanRequestV2>()
);

const organisationPlanLimitsCache = new Map<string, OrganisationPlanLimits>();

getOrganisationPlanLimitsAction$
    .pipe(
        map(async (event) => {
            organisationPlanLimitsLoading$.next(true);
            organisationPlanLimitsError$.next(undefined);
            selectedOrganisationPlanLimits$.next(undefined);

            try {
                let limits = organisationPlanLimitsCache.get(event.plan);
                if (!limits || event.plan === 'custom') {
                    limits = await getOrganizationPlanLimits(event);
                    organisationPlanLimitsCache.set(event.plan, limits);
                }

                selectedOrganisationPlanLimits$.next(limits);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    organisationPlanLimitsError$.next(e.message);
                } else {
                    organisationPlanLimitsError$.next(
                        'Error occurred while getting plan limits. Please try again, if the issue persists please contact support.'
                    );
                }
            }

            organisationPlanLimitsLoading$.next(false);
        })
    )
    .subscribe();

changeOrganisationPlanLimitsAction$
    .pipe(
        map(async (event) => {
            organisationPlanLimitsLoading$.next(true);
            organisationPlanLimitsError$.next(undefined);
            selectedOrganisationPlanLimits$.next(undefined);

            try {
                await changeOrganizationPlanAsAdmin(event);

                const details = selectedOrganisationDetails$.value;

                // eslint-disable-next-line no-unused-vars
                const { organizationUid, period, ...limits } = event;

                if (details) {
                    selectedOrganisationDetails$.next({
                        ...details,
                        ...limits,
                        planPeriod: period,
                    });
                }
            } catch (e) {
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    organisationPlanLimitsError$.next(e.message);
                } else {
                    organisationPlanLimitsError$.next(
                        'Error occurred while changing plan limits. Please try again, if the issue persists please contact support.'
                    );
                }
            }

            organisationPlanLimitsLoading$.next(false);
        })
    )
    .subscribe();

changeOrganisationPlanLimitsActionV2$
    .pipe(
        map(async (event) => {
            organisationPlanLimitsLoading$.next(true);
            organisationPlanLimitsError$.next(undefined);
            selectedOrganisationPlanLimits$.next(undefined);

            try {
                await changeOrganizationPlanAsAdminV2(event);

                const [details, limits] = await Promise.all([
                    getOrganisationDetails({
                        organisationUid: event.organizationUid,
                    }),
                    getOrganizationPlanLimits({
                        organisationUid: event.organizationUid,
                        plan: 'custom',
                    }),
                ]);

                selectedOrganisationDetails$.next(details);
                selectedOrganisationPlanLimits$.next(limits);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    organisationPlanLimitsError$.next(e.message);
                } else {
                    organisationPlanLimitsError$.next(
                        'Error occurred while changing plan limits. Please try again, if the issue persists please contact support.'
                    );
                }
            }

            organisationPlanLimitsLoading$.next(false);
        })
    )
    .subscribe();
