import { styled } from '@mui/material';
import { BillingDetailsList } from '../billing-details/BillingDetailsList';
import { useState } from 'react';
import { Button } from '../../buttons/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { DialogAlert } from '../../dialog';
import { OrganizationPlan } from '../../../data/organization';

interface BillingDetailsScreenProps {
    billingEntities: {
        uid: string;
        contactName: string;
        contactEmail: string;
    }[];
    plan?: OrganizationPlan;
    selectedBillingEntity: string;
    saving: boolean;
    error?: string;
    onEdit(uid: string): void;
    onSave(uid: string): void;
    onCancel(): void;
}

const StyledContainer = styled('div')(() => ({
    minHeight: '100%',
}));

const StyledContentContainer = styled('div')(() => ({
    maxWidth: 518,
}));

const StyledCancelButton = styled(Button)(({ theme }) => ({
    ':disabled': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.disabled,
    },
}));

export const BillingDetailsScreen: React.FC<BillingDetailsScreenProps> = ({
    billingEntities,
    plan,
    selectedBillingEntity,
    saving,
    error,
    onEdit,
    onSave,
    onCancel,
}) => {
    const [selectedEntity, setSelectedEntity] = useState(selectedBillingEntity);
    const isChanged = selectedBillingEntity !== selectedEntity;
    const isDisabled = plan?.paymentType === 'CARD' && plan?.tier !== 'free';

    return (
        <StyledContainer>
            <Typography pb={2}>
                View available billing contacts for your team. To create a new one, head to the top right menu and find
                the 'Billing details' option.
            </Typography>
            {error && <DialogAlert severity="error" alertTitle="Error" text={error} />}
            {isDisabled && (
                <DialogAlert
                    severity="info"
                    alertTitle="Information"
                    text={'Changing your billing details is not availale at this moment.'}
                />
            )}
            <StyledContentContainer>
                <BillingDetailsList
                    billingEntities={billingEntities}
                    selectedBillingEntity={selectedEntity}
                    onSelect={setSelectedEntity}
                    onEdit={onEdit}
                />
                {!!billingEntities.length && (
                    <DialogActions>
                        <StyledCancelButton onClick={onCancel} variant="outlined" disabled={!isChanged}>
                            Cancel
                        </StyledCancelButton>
                        <Button
                            onClick={() => onSave(selectedEntity)}
                            disabled={!isChanged || isDisabled}
                            busy={saving}
                        >
                            Save
                        </Button>
                    </DialogActions>
                )}
            </StyledContentContainer>
        </StyledContainer>
    );
};
