import React from 'react';
import { ConnectionDialog } from '../ConnectionDialog';
import { ProductIcon } from '../../icons/ProductIcon';

export interface ManageConnectionDialog {
    readonly onSave: (name: string) => void;
    readonly saving: boolean;
    readonly onAuthorize: (name: string) => void;
    readonly authorizeLoading: boolean;
    readonly open: boolean;
    readonly onCancel: () => void;
    readonly name: string;
    readonly authorized: boolean;
    readonly errors?: string;
    readonly connectionType?: string;
}

export const TrelloManageConnectionDialog: React.FC<ManageConnectionDialog> = ({
    name,
    saving,
    onSave,
    onAuthorize,
    authorizeLoading,
    open,
    onCancel,
    authorized,
    errors,
    connectionType,
}) => {
    return (
        <ConnectionDialog
            authorized={authorized}
            name={name}
            onSave={onSave}
            onAuthorize={onAuthorize}
            authorizeLoading={!!authorizeLoading}
            saving={saving}
            icon={<ProductIcon name={connectionType ?? ''} />}
            open={open}
            onCancel={onCancel}
            errors={errors}
            customAuthorizeButtonLabel={'Configure'}
            connectionType={connectionType}
        />
    );
};
