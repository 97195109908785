import React from 'react';
import { styled, Table, TableRow } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import ListAltIcon from '@mui/icons-material/ListAlt';
import GridViewIcon from '@mui/icons-material/GridView';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface SelectorViewButtonProps {
    readonly onClick?: () => void;
    readonly icon?: 'grid' | 'list';
    readonly sx?: SxProps<Theme>;
}

interface SelectorTableRowProps {
    readonly onClick?: () => void;
}

interface SelectorCardTitleProps {
    block?: boolean;
    title?: string;
    readonly sx?: SxProps<Theme>;
}

interface SelectorCardDescriptionProps {
    readonly description: string | undefined;
}

// selector page title area

export const viewSelectedStyle = (theme: Theme) =>
    ({
        backgroundColor: theme.palette.action.selected,
    } as const);

const StyledViewButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    color: theme.palette.text.secondary,
    '&.MuiIconButton-root': {
        height: 40,
        width: 40,
    },
    '&:not(:last-of-type)': {
        marginRight: theme.spacing(1),
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        '& .MuiSvgIcon-root': {
            color: theme.palette.secondary.dark,
        },
    },
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

export const SelectorViewButton: React.FC<SelectorViewButtonProps> = ({ onClick, sx, icon }) => {
    const tooltip = icon === 'list' ? 'List view' : 'Grid view';
    const viewIcon = icon === 'list' ? <ListAltIcon /> : <GridViewIcon />;

    return (
        <Tooltip title={tooltip} placement="top-end" role="button">
            <StyledViewButton onClick={onClick} sx={sx}>
                {viewIcon}
            </StyledViewButton>
        </Tooltip>
    );
};

// selector common page content

export const selectorChipStyle = (theme: Theme) =>
    ({
        borderRadius: 2,
        boxShadow: theme.constants.boxShadow,
        cursor: 'inherit',
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        height: theme.spacing(2.25),
        padding: theme.spacing(0, 1),
        '& .MuiChip-label': {
            padding: 0,
        },
    } as const);

export const StyledStatus = styled(Chip)(({ theme }) => ({
    ...selectorChipStyle(theme),
    '&:not(:last-of-type)': {
        marginBottom: theme.spacing(1),
    },
}));

export const TemplateStyledChip = styled(Chip)(({ theme }) => ({
    ...selectorChipStyle(theme),
    borderRadius: 2,
    color: theme.palette.connections.contrastText,
    flexDirection: 'row-reverse',
    height: theme.spacing(2.75),
    marginRight: theme.spacing(1.5),
    '& .MuiChip-label': {
        padding: theme.spacing(0, 0.5, 0, 0.5),
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.connections.contrastText,
        height: 14,
        margin: theme.spacing(0, 0.25, 0, 0),
        width: 14,
    },
}));

const StyledImageBox = styled(Box)(({ theme }) => ({
    ...theme.typography.radiusCircle,
    height: 30,
    position: 'relative',
    width: 30,
    '& img': {
        ...theme.typography.radiusCircle,
        border: `1px solid ${theme.palette.background.default}`,
    },
}));

const StyledCheckCircleRoundedIcon = styled(CheckCircleRoundedIcon)(({ theme }) => ({
    color: theme.palette.success.light,
}));

const StyledAuthorized = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiTypography-root': {
        marginLeft: theme.spacing(0.5),
    },
}));

const StyledLineTitle = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowLine,
    display: 'block',
    marginBottom: theme.spacing(0.5),
}));

const StyledBlockTitle = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowBlock,
    maxHeight: '100%',
    WebkitLineClamp: 2,
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowLine,
    color: theme.palette.text.secondary,
    width: '100%',
}));

export const SelectorImageBox: React.FC = ({ children }) => {
    return <StyledImageBox>{children}</StyledImageBox>;
};

export const SelectorAuthorized: React.FC = () => {
    return (
        <StyledAuthorized>
            <StyledCheckCircleRoundedIcon />
            <Typography color="text.secondary" variant="body2">
                Authorized
            </Typography>
        </StyledAuthorized>
    );
};

export const SelectorItemTitle: React.FC<SelectorCardTitleProps> = ({ title = 'Card Title', block, sx }) => {
    const titleStyle = block ? (
        <StyledBlockTitle sx={sx} variant="subtitle2">
            {title}
        </StyledBlockTitle>
    ) : (
        <StyledLineTitle sx={sx} variant="subtitle2">
            {title}
        </StyledLineTitle>
    );
    return <Tooltip title={title}>{titleStyle}</Tooltip>;
};

export const SelectorItemDescription: React.FC<SelectorCardDescriptionProps> = ({ description }) => {
    return description ? (
        <Tooltip title={description}>
            <StyledDescription variant="body1">{description}</StyledDescription>
        </Tooltip>
    ) : null;
};

// selector list as table

const StyledTable = styled(Table)(({ theme }) => ({
    borderCollapse: 'separate',
    borderSpacing: theme.spacing(0, 1),
    marginTop: theme.spacing(2),
    '& th, td': {
        borderBottom: theme.constants.borderHidden,
        maxWidth: 200,
        padding: theme.spacing(1, 1.5),
    },
    '& th': {
        ...theme.typography.body1,
    },
    // extra padding for shorter height rows
    '& .padme td': {
        padding: theme.spacing(2, 1.5),
    },
    '& subtitle2, p': {
        ...theme.typography.overflowBlock,
        WebkitLineClamp: '2',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    alignItems: 'center',
    bottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.constants.boxShadow,
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
        outline: theme.constants.borderSelected,
    },
}));

const StyledAppIconOuter = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: theme.spacing(0.5),
    overflow: 'visible',
}));

const StyledAppIconInner = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.constants.boxShadow,
    height: theme.spacing(4),
    justifyContent: 'center',
    width: theme.spacing(4),
    '&:not(:last-of-type)': {
        marginRight: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

const StyledIcon = styled('span')(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        height: 18,
        marginRight: theme.spacing(0.5),
        position: 'relative',
        top: theme.spacing(0.75),
        width: 18,
    },
}));

export const SelectorTitleIcon: React.FC = ({ children }) => {
    return <StyledIcon>{children}</StyledIcon>;
};

export const SelectorTable: React.FC = ({ children }) => {
    return <StyledTable>{children}</StyledTable>;
};

export const SelectorTableRow: React.FC<SelectorTableRowProps> = ({ children, onClick }) => {
    return <StyledTableRow onClick={onClick}>{children}</StyledTableRow>;
};

export const SelectorAppIconsOuter: React.FC = ({ children }) => {
    return <StyledAppIconOuter>{children}</StyledAppIconOuter>;
};

export const SelectorAppIconsInner: React.FC = ({ children }) => {
    return <StyledAppIconInner>{children}</StyledAppIconInner>;
};

// selector cards

export const selectorCardStyle = (theme: Theme) =>
    ({
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            outline: theme.constants.borderSelected,
        },
    } as const);

export const selectorUserAvatarsStyle = () =>
    ({
        alignItems: 'flex-end',
        display: 'flex',
    } as const);

export const selectorCardUserAvatarsStyle = (theme: Theme) =>
    ({
        ...selectorUserAvatarsStyle(),
        '& > div:not(:first-of-type)': {
            marginLeft: `-${theme.spacing(1.5)}`,
        },
    } as const);

export const selectorCardIconWrapStyle = (theme: Theme) =>
    ({
        ...theme.typography.flexAlignCenter,
        justifyContent: 'center',
        width: 22,
        '&:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    } as const);
