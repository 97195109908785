import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../buttons/Button';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface OpsgenieEventListenerSetupDialogProps {
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const OpsgenieEventListenerSetupDialog: React.FC<OpsgenieEventListenerSetupDialogProps> = ({
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const connectionTypeName = 'Opsgenie';
    const normalizedUrl = setupBaseUrl ? (setupBaseUrl.endsWith('/') ? setupBaseUrl.slice(0, -1) : setupBaseUrl) : '';
    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}
            >
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Event Listener setup instructions for {connectionTypeName}
                </DialogTitle>
                {loading ? (
                    <AbsoluteCenteredLoadingSpinner />
                ) : (
                    <>
                        <DialogContent>
                            <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                            <DialogContentText variant="subtitle2">
                                You need to setup a webhook in your {connectionTypeName} instance in order to listen to
                                events in ScriptRunner Connect.
                            </DialogContentText>
                            <DialogContentText component="ol">
                                <li>Copy the URL above.</li>
                                {setupBaseUrl && (
                                    <li>
                                        Visit your instance's{' '}
                                        <Link
                                            target="_blank"
                                            href={normalizedUrl + '/settings/integration/add/Webhook/'}
                                        >
                                            Create a Webhook
                                        </Link>{' '}
                                        page.
                                    </li>
                                )}
                                {!setupBaseUrl && (
                                    <li>
                                        Open https://[YOUR_INSTANCE]/settings/integration/add/Webhook/ URL in your
                                        browser.
                                    </li>
                                )}

                                <li>Give your webhook a meaningful name.</li>
                                <li>
                                    Make sure that <strong>Enabled</strong> setting is checked.
                                </li>
                                <li>
                                    In <strong>For Opsgenie alerts</strong> section select ONLY the following event to
                                    listen: <strong>{eventTypeName}</strong>.
                                </li>
                                <li>
                                    Delete the rest predefined events using <strong>X</strong> icon on the right.
                                </li>
                                <li>
                                    Paste the copied URL into the <strong> Webhook URL</strong> field.
                                </li>
                                <li>
                                    Then click <strong>Save Integration</strong>.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Done</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};
