import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../buttons/Button';
import { NetSuiteIcon } from '../../icons/NetSuiteIcon';
import { OpenInNewLink } from '../../link/OpenInNewLink';
import { ConnectionModalTextField } from '../../textfield/ConnectionModalTextField';
import { NetSuiteWizardStage, NetSuiteWizardSteps, StageType } from './NetSuiteWizardSteps';
import { DialogAlert, DialogTitleMain } from '../../dialog';
import { Link } from '@mui/material';

export interface NetSuiteClientInfo {
    netSuiteAccountId: string;
    clientId: string;
    publicCertificate: string;
    certificateId: string;
}

export interface NetSuiteConnection {
    clientInfo: NetSuiteClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: NetSuiteWizardStage;
    setStage: (stage: NetSuiteWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
}

export interface onSaveProps {
    netSuiteAccountId: string;
    clientId: string;
    certificateId: string;
}

const StageContent: StageType = (props) => {
    const [accountId, setAccountId] = useState(props.clientInfo.netSuiteAccountId);
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [certificateId, setCertificateId] = useState(props.clientInfo.certificateId);

    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);

    const NETSUITE_PATH = '/app/common/integration/integrapp.nl';
    const NETSUITE_PATH_MAPPING = '/app/oauth2/clientcredentials/setup.nl';

    const generateSettingsUrl = (path: string): string => `https://${accountId}.app.netsuite.com${path}`;

    switch (props.stage) {
        case NetSuiteWizardStage.ADDURL:
            return (
                <>
                    <DialogContentText>
                        {`This connector requires NetSuite account ID which is a part of the instance URL: https://<ACCOUNT ID>.app.netsuite.com`}
                    </DialogContentText>
                    <ConnectionModalTextField
                        label="Enter NetSuite Account ID"
                        value={accountId}
                        onUpdate={(e) => {
                            if (badUrlAttempted) {
                                props.clearErrors();
                                setBadUrlAttempted(false);
                            }
                            setAccountId(e.target.value);
                        }}
                    />
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                if (accountId) {
                                    setAccountId(accountId);
                                    props.setStage(NetSuiteWizardStage.CREATE);
                                    setBadUrlAttempted(false);
                                } else {
                                    props.setError('Please enter NetSuite account ID.');
                                    setBadUrlAttempted(true);
                                }
                            }}
                            disabled={!accountId}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case NetSuiteWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text="If you already have an application, skip the steps below, paste Client ID and click next"
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={generateSettingsUrl(NETSUITE_PATH)}>
                                    NetSuite settings
                                </OpenInNewLink>{' '}
                                page and click <strong>New Application</strong>.
                            </li>
                            <li>Add a meaningful application name.</li>
                            <li>
                                Make sure <strong>STATE</strong> option is set to Enabled.
                            </li>
                            <li>
                                Under <strong>Token-based Authentication</strong> section uncheck all the options.
                            </li>
                            <li>
                                Under <strong>OAuth 2.0</strong> section check only{' '}
                                <strong>CLIENT CREDENTIALS (MACHINE TO MACHINE) GRANT</strong> option.
                            </li>
                            <li>Select RESTLETS and REST WEB SERVICES scopes.</li>
                            <li>
                                Click <strong>Save</strong>.
                            </li>
                            <li>From the bottom section of the page copy the Client ID into the form below.</li>
                            <strong>NOTE:</strong> These credentials will be stored securely in our platform.
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Client ID"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(NetSuiteWizardStage.ADDURL)}>
                            Back
                        </Button>
                        <Button disabled={!clientId} onClick={() => props.setStage(NetSuiteWizardStage.DETAILS)}>
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case NetSuiteWizardStage.DETAILS:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={generateSettingsUrl(NETSUITE_PATH_MAPPING)}>
                                    NetSuite client credentials settings
                                </OpenInNewLink>{' '}
                                page and click <strong>Create New</strong>.
                            </li>
                            <li>
                                Pick your own account as <strong>Entity</strong>.
                            </li>
                            <li>
                                For the <strong>Role</strong> choose Administrator or Developer depending on
                                availability.
                            </li>
                            <li>
                                Under <strong>Application</strong> select your OAuth application from the previous step.
                            </li>
                            <li>
                                <Link
                                    href={URL.createObjectURL(new Blob([props.clientInfo.publicCertificate]))}
                                    download={`${clientId}-SRC-public-certificate.pem`}
                                >
                                    Download public certificate
                                </Link>{' '}
                                and upload to the mapping.
                            </li>
                            <strong>NOTE:</strong> Please remember that the certificate will be{' '}
                            <strong>valid for 2 years</strong>. After that time, you will need to re-authorize your
                            connector.
                            <li>
                                Click <strong>Save</strong>.
                            </li>
                            <li>
                                Copy <strong>CERTIFICATE ID</strong> of newly created mapping from the first column.
                            </li>
                            <strong>NOTE:</strong> These credentials will be stored securely in our platform.
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Certificate ID"
                            value={certificateId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setCertificateId(e.target.value.trim());
                            }}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(NetSuiteWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !certificateId}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(NetSuiteWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case NetSuiteWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in NetSuite you need to authorize ScriptRunner Connect to be able to
                            make requests on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(NetSuiteWizardStage.DETAILS);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !certificateId}
                            onClick={() =>
                                props.onSave({
                                    clientId,
                                    certificateId,
                                    netSuiteAccountId: accountId,
                                })
                            }
                        >
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case NetSuiteWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const NetSuiteConnectionAppConfigureDialog: React.FC<NetSuiteConnection> = (props) => {
    const error = <DialogAlert severity="error" alertTitle="Error" text={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<NetSuiteIcon />} />
            {props.errors && error}
            <NetSuiteWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
