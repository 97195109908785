// import { Link } from '@mui/material';
import { MakeGenerics, Outlet, ReactLocation, Route } from 'react-location';
import { CenteredLoadingSpinner } from './components/loading/CenteredLoadingSpinner';
import { ApiHandlerDetailsContainer } from './containers/workspace/ApiHandlerDetails';
import { AppMainContainer } from './containers/AppMain';
import { AppSelectorContainer } from './containers/AppSelector';
import { EventListenerDetailsContainer } from './containers/workspace/EventListenerTabs';
import { ScriptDetailsContainer } from './containers/workspace/ScriptDetails';
import { WorkspaceContainer } from './containers/Workspace';
import { WorkspacesContainer } from './containers/Workspaces';
// import { useAuth } from './hooks/auth';
import { apiHandlerDetailsLoader, readOnlyApiHandlerDetailsLoader } from './loaders/workspace/api-handler';
import { appLoader } from './loaders/app';
import { eventListenerLoader, readOnlyEventListenerLoader } from './loaders/workspace/event-listener';
import { scriptDetailsLoader } from './loaders/workspace/script';
import { workspaceLoader, workspaceUnloader } from './loaders/workspace/workspace';
import { workspacesLoader } from './loaders/workspaces';
// import { wrapAsync } from './utils/react';
import { CreateNewWorkspaceEnvironmentDialogContainer } from './containers/workspace/CreateNewEnvironmentDialog';
import { ManageEnvironmentDeploymentTargetsDialogContainer } from './containers/workspace/ManageEnvironmentDeploymentTargetsDialog';
import { SlackManageConnectionDialogContainer } from './containers/connection/slack/ManageConnectionDialog';
import { SlackAppConfigureDialog } from './containers/connection/slack/SlackAppConfigureDialog';
import { SlackEventListenerSetupDialogContainer } from './containers/connection/slack/EventListenerSetupDialog';
import { TemplatesContainer } from './containers/template/Templates';
import { templatesLoader } from './loaders/templates';
import { ConfirmDialogContainer } from './containers/ConfirmDialogContainer';
import { UsersReportPageContainer } from './containers/report/UsersReportPage';
import { usersReportLoader } from './loaders/report/users';
import { OrganisationsReportPageContainer } from './containers/report/OrganisationsReportPage';
import { organisationsReportLoader } from './loaders/report/organisations';
import { OrganisationDetailsReportPageContainer } from './containers/report/OrganisationDetailsReportPage';
import { organisationDetailsReportLoader } from './loaders/report/organisationDetails';
import { InvocationsReportPageContainer } from './containers/report/InvocationsReportPage';
import { invocationsReportLoader } from './loaders/report/invocations';
import { Reports } from './components/report/Reports';
import { BitbucketCloudManageConnectionDialogContainer } from './containers/connection/bitbucket-cloud/ManageConnectionDialog';
import { BitbucketCloudConfigureDialog } from './containers/connection/bitbucket-cloud/BitbucketCloudConfigureDialog';
import { GenericEventListenerSetupDialogContainer } from './containers/connection/generic/EventListenerSetupDialog';
import { ServiceNowManageConnectionDialogContainer } from './containers/connection/servicenow/ManageConnectionDialog';
import { ServiceNowConfigureDialog } from './containers/connection/servicenow/ServiceNowConfigureDialog';
import { ZoomManageConnectionDialogContainer } from './containers/connection/zoom/ManageConnectionDialog';
import { ZoomConfigureDialog } from './containers/connection/zoom/ZoomConfigureDialog';
import { BitbucketOnPremiseManageConnectionDialogContainer } from './containers/connection/bitbucket-on-premise/ManageConnectionDialog';
import { BitbucketOnPremiseConfigureDialog } from './containers/connection/bitbucket-on-premise/BitbucketOnPremiseConfigureDialog';
import { SalesforceManageConnectionDialogContainer } from './containers/connection/salesforce/ManageConnectionDialog';
import { SalesforceConfigureDialog } from './containers/connection/salesforce/SalesforceConfigureDialog';
import { AtlassianCloudEventListenerSetupDialogContainer } from './containers/connection/atlassian-cloud/EventListenerSetupDialog';
import { AtlassianCloudManageConnectionDialogContainer } from './containers/connection/atlassian-cloud/ManageConnectionDialog';
import { AtlassianCloudInstanceSelectionContainer } from './containers/connection/atlassian-cloud/AtlassianCloudInstanceSelection';
import { ApiHandlerImportDialogContainer } from './containers/workspace/ApiHandlerImportDialog';
import { DashboardContainer } from './containers/Dashboard';
import { dashboardLoader } from './loaders/dashboard';
import { CreateNewWorkspaceDialogContainer } from './containers/workspace/CreateNewWorkspaceDialog';
import { EditWorkspaceDialogContainer } from './containers/workspace/EditWorkspaceDialog';
import { AtlassianOnPremiseManageConnectionDialogContainer } from './containers/connection/atlassian-on-premise/ManageConnectionDialog';
import { AtlassianOnPremiseConfigureDialog } from './containers/connection/atlassian-on-premise/AtlassianOnPremiseConfigureDialog';
import { AtlassianOnPremiseEventListenerSetupDialogContainer } from './containers/connection/atlassian-on-premise/EventListenerSetupDialog';
import { UsersWithDeployedWorkspacesReportPageContainer } from './containers/report/UsersWithDeployedWorkspacesReportPage';
import { usersWithDeployedWorkspacesReportLoader } from './loaders/report/usersWithDeployedWorkspaces';
import { UserFeedbackReportPageContainer } from './containers/report/UserFeedbackReportPage';
import { userFeedbackReportLoader } from './loaders/report/userFeedback';
import { DefaultWorkspaceViewContainer } from './containers/workspace/DefaultWorkspaceView';
import { GoogleManageConnectionDialogContainer } from './containers/connection/google/ManageConnectionDialog';
import { GithubManageConnectionDialogContainer } from './containers/connection/github/ManageConnectionDialog';
import { UserFeedbackContainer } from './containers/UserFeedback';
import { BitbucketCloudEventListenerSetupDialogContainer } from './containers/connection/bitbucket-cloud/EventListenerSetupDialog';
import { GitlabManageConnectionDialogContainer } from './containers/connection/gitlab/ManageConnectionDialog';
import { GitlabConfigureDialog } from './containers/connection/gitlab/GitlabConfigureDialog';
import { GitlabEventListenerSetupDialogContainer } from './containers/connection/gitlab/EventListenerSetupDialog';
import { GithubEventListenerSetupDialogContainer } from './containers/connection/github/EventListenerSetupDialog';
import { ConnectionTypesReportPageContainer } from './containers/report/ConnectionTypesReportPage';
import { connectionTypesReportLoader } from './loaders/report/connectionTypes';
import { ServiceNowEventListenerSetupDialogContainer } from './containers/connection/servicenow/EventListenerSetupDialog';
import { NetSuiteEventListenerSetupDialogContainer } from './containers/connection/netsuite/EventListenerSetupDialog';
import { ZoomEventListenerSetupDialogContainer } from './containers/connection/zoom/EventListenerSetupDialog';
import { BitbucketOnPremiseEventListenerSetupDialogContainer } from './containers/connection/bitbucket-on-premise/EventListenerSetupDialog';
import { ConnectorsContainer } from './containers/Connectors';
import { connectorsLoader } from './loaders/connectors';
import { LanguageSelectorContainer } from './containers/workspace/LanguageSelector';
import { GenericManageConnectionDialogContainer } from './containers/connection/generic/ManageConnectionDialog';
import { GenericAppConfigureDialog } from './containers/connection/generic/GenericAppConfigureDialog';
import { MondayManageConnectionDialogContainer } from './containers/connection/monday/ManageConnectionDialog';
import { MondayConfigureDialog } from './containers/connection/monday/MondayConfigureDialog';
import { MondayEventListenerSetupDialogContainer } from './containers/connection/monday/EventListenerSetupDialog';
import { readmeFileDetailsLoader } from './loaders/workspace/readme';
import { ReadmeFileDetailsContainer } from './containers/workspace/ReadmeFileDetails';
import { PackageManagerContainer } from './containers/workspace/PackageManager';
import { TempoCloudManageConnectionDialogContainer } from './containers/connection/tempo-cloud/ManageConnectionDialog';
import { TempoCloudConfigureDialog } from './containers/connection/tempo-cloud/TempoCloudConfigureDialog';
import { NetSuiteManageConnectionDialogContainer } from './containers/connection/netsuite/ManageConnectionDialog';
import { NetSuiteConfigureDialog } from './containers/connection/netsuite/NetSuiteConfigureDialog';
import { ScheduledTriggerDetailsContainer } from './containers/workspace/ScheduledTriggerDetails';
import { scheduledTriggerLoader } from './loaders/workspace/scheduled-trigger';
import { readOnlyTemplateLoader, readOnlyTemplateUnloader } from './loaders/template';
import { ReadOnlyTemplateContainer } from './containers/ReadOnlyTemplate';
import { DefaultReadOnlyTemplateViewContainer } from './containers/workspace/DefaultReadOnlyTemplateView';
import { ReadOnlyApiHandlerDetailsContainer } from './containers/workspace/ReadOnlyApiHandlerDetails';
import { ReadOnlyEventListenerDetailsContainer } from './containers/workspace/ReadOnlyEventListenerTabs';
import { ReadOnlyScheduledTriggerDetailsContainer } from './containers/workspace/ReadOnlyScheduledTriggerDetails';
import { JsmCloudAssetsManageConnectionDialogContainer } from './containers/connection/jsmCloudAssets/ManageConnectionDialog';
import { JsmCloudAssetsConfigureDialog } from './containers/connection/jsmCloudAssets/JsmCloudAssetsConfigureDialog';
import { RegisteredVsActiveUsersReportPageContainer } from './containers/report/RegisteredVsActiveUsersReportPage';
import { UserActivationReportPageContainer } from './containers/report/UserActivationReportPage';
import { TemplatesUsedReportPageContainer } from './containers/report/TemplatesUsedReportPage';
import { registeredVsActiveUsersReportLoader } from './loaders/report/registeredVsActiveUsers';
import { EnvironmentManagerContainer } from './containers/workspace/EnvironmentManager';
import { StartWizardDialogContainer } from './containers/wizard/StartWizardSetupDialog';
import { WizardMinimizedIconContainer } from './containers/wizard/WizardMinimizedIcon';
import { ConfirmMinimizeWizardContainer } from './containers/wizard/ConfirmMinimizeWizard';
import { UsersUtilizationPageContainer } from './containers/report/UsersUtilizationPage';
import { usersUtilizationReportLoader } from './loaders/report/usersUtilization';
import { OrganisationsUtilizationPageContainer } from './containers/report/OrganisationsUtilizationPage';
import { organisationsUtilizationReportLoader } from './loaders/report/organisationsUtilization';
import { MicrosoftManageConnectionDialogContainer } from './containers/connection/microsoft/ManageConnectionDialog';
import { MicrosoftEventListenerSetupDialogContainer } from './containers/connection/microsoft/EventListenerSetupDialog';
import { MicrosoftConfigureDialog } from './containers/connection/microsoft/MicrosoftConfigureDialog';
import { AzureDevopsManageConnectionDialogContainer } from './containers/connection/azure-devops/ManageConnectionDialog';
import { AzureDevopsConfigureDialog } from './containers/connection/azure-devops/AzureDevopsConfigureDialog';
import { reportingLoader } from './loaders/reporting';
import { ReportingContainer } from './containers/Reporting';
import { ProfileContainer } from './containers/profile/ProfileContainer';
import { profileLoader } from './loaders/profile';
import { LoadingWizard } from './components/loading/LoadingWizard';
import { invocationLogsLoader } from './loaders/workspace/invocation-logs';
import { InvocationLogsContainer } from './containers/InvocationLogs';
import { largeInvocationLogPayloadLoader } from './loaders/workspace/large-invocation-log-payload';
import { LargeInvocationLogPayloadContainer } from './containers/LargeInvocationLogPayload';
import { httpLogsLoader } from './loaders/workspace/http-logs';
import { HttpLogsContainer } from './containers/HttpLogs';
import { BillingDetailsContainer } from './containers/organization/BillingDetailsContainer';
import { billingDetailsLoader } from './loaders/billing-details';
import { OrganizationsContainer } from './containers/organization/OrganizationsContainer';
import { organizationsLoader } from './loaders/organizations';
import { CreateOrganizationWizardContainer } from './containers/organization/CreateOrganizationWizardContainer';
import { OrganizationContainer } from './containers/organization/OrganizationContainer';
import { templateUsedReportLoader } from './loaders/report/templatesUsed';
import { organizationBillingDetailsLoader } from './loaders/organization/billing-details';
import { ShareWorkspaceDialogContainer } from './containers/workspace/ShareWorkspaceDialog';
import { organizationLoader } from './loaders/organization/organization';
import { OrganizationUsageContainer } from './containers/organization/OrganizationUsageContainer';
import { organizationUsageLoader } from './loaders/organization/usage';
import { organizationDetailsLoader } from './loaders/organization/details';
import { OrganizationDetailsContainer } from './containers/organization/OrganizationDetailsContainer';
import { OrganizationPlanContainer } from './containers/organization/OrganizationPlanContainer';
import { organizationPlanLoader } from './loaders/organization/plan';
import { AbsoluteCenteredLoadingSpinner } from './components/loading/AbsoluteCenteredLoadingSpinner';
import { OrganizationBillingDetailsContainer } from './containers/organization/OrganizationBillingDetailsContainer';
import { organizationMembersLoader } from './loaders/organization/members';
import { OrganizationMembersContainer } from './containers/organization/OrganizationMembersContainer';
import { ConnectAndSupportContainer } from './containers/ConnectAndSupport';
import { BillingEntityContainer } from './containers/organization/BillingEntityContainer';
import { SalesforceEventListenerSetupDialogContainer } from './containers/connection/salesforce/EventListenerSetupDialog';
import { billingEntityLoader } from './loaders/billing-entity';
import { StatuspageConfigureDialog } from './containers/connection/statuspage/StatuspageConfigureDialog';
import { StatuspageManageConnectionDialogContainer } from './containers/connection/statuspage/ManageConnectionDialog';
import { AddWorkspaceToOrgDialogContainer } from './containers/workspace/AddWorkspaceToOrgDialog';
import { TrelloConfigureDialog } from './containers/connection/trello/TrelloConfigureDialog';
import { TrelloManageConnectionDialogContainer } from './containers/connection/trello/ManageConnectionDialog';
import { OpsgenieConfigureDialog } from './containers/connection/opsgenie/OpsgenieConfigureDialog';
import { OpsgenieManageConnectionDialogContainer } from './containers/connection/opsgenie/ManageConnectionDialog';
import { OpsgenieEventListenerSetupDialogContainer } from './containers/connection/opsgenie/EventListenerSetupDialog';
import { anonymousUsersReportLoader } from './loaders/report/anonymousUsers';
import { AnonymousUsersReportPageContainer } from './containers/report/AnonymousUsersReportPage';
import { userActivationReportLoader } from './loaders/report/userActivation';
import { StatuspageEventListenerSetupDialogContainer } from './containers/connection/statuspage/EventListenerSetupDialog';
import { OnboardingWizardContainer } from './containers/OnboardingWizardContainer';
import { SelectBillingDetailsDialogContainer } from './containers/organization/SelectBillingDetailsDialogContainer';
import { OrganizationPlanSuccessStateContainer } from './containers/organization/OrganizationPlanSuccessStateContainer';
import { ErrorPageContainer } from './containers/ErrorPageContainer';
import { ImpersonationPasswordDialogContainer } from './containers/report/ImpersonationPasswordDialog';
import { UserOnboardingDataReportPageContainer } from './containers/report/UserOnboardingDataReportPage';
import { userOnboardingDataReportLoader } from './loaders/report/userOnboardingData';
import { BrandChangeModalContainer } from './containers/BrandChangeContainer';
import { ChangeOrganizationPlanWizardContainer } from './containers/organization/ChangeOrganizationPlanWizardContainer';
import { CancelOrganizationPlanContainer } from './containers/organization/CancelOrganizationPlanContainer';
import { CancelOrganizationPlanSuccessStateContainer } from './containers/organization/CancelOrganizationPlanSuccessStateContainer';
import { CancelOrganizationPlanFeedbackContainer } from './containers/organization/CancelOrganizationPlanFeedbackContainer';
import { ChangeOrganizationPlanConfirmationContainer } from './containers/organization/ChangeOrganizationPlanConfirmationContainer';
import { OrganizationInvoicePlanInformationDialogContainer } from './containers/organization/OrganizationInvoicePlanInformationDialogContainer';
import { ChangeOrganizationPlanConfirmPurchaseContainer } from './containers/organization/ChangeOrganizationPlanConfirmPurchaseContainer';
import { MondayUserOnboardingDialogContainer } from './containers/monday/MondayUserOnboardingDialog';
import { MondayUserFailureDialogContainer } from './containers/monday/MondayUserFailureDialog';
import { MondayUserSuccessDialogContainer } from './containers/monday/MondayUserSuccessDialog';
import { PackageUpgradesDialogContainer } from './containers/workspace/PackageUpgradesDialog';
import { EnvironmentVariableDetailsContainer } from './containers/workspace/EnvironmentVariableDetails';
import { AtlassianCloudConfigureDialog } from './containers/connection/atlassian-cloud/AtlassianCloudConfigureDialog';
import { environmentVariablesLoader } from './loaders/workspace/environment-variable';
import { ProfileUpdateDialogContainer } from './containers/ProfileUpdateDialogContainer';
import { ProfileUpdateSuccessDialogContainer } from './containers/ProfileUpdateSuccessDialogContainer';
import { DiscountCodesReportPageContainer } from './containers/report/DiscountCodesReport';
import { discountCodesLoader } from './loaders/report/discountCodes';
import { DiscountCodeDetailsReportPageContainer } from './containers/report/DiscountCodeDetailsReport';
import { discountCodeDetailsLoader } from './loaders/report/discountCodeDetails';
import { OnboardingTemplateSelectorContainer } from './containers/OnboardingTemplateSelectorContainer';
import { AzureDevopsEventListenerSetupDialogContainer } from './containers/connection/azure-devops/EventListenerSetupDialog';

export const location = new ReactLocation<LocationGenerics>();

// eslint-disable-next-line @typescript-eslint/ban-types
export type LocationGenerics = MakeGenerics<{}>;

export const getRoutes = (): Route<LocationGenerics>[] => {
    /**
     * PUBLIC_URL could be . or /<git commit hash> so need to adjust the root path accordingly
     */
    const rootPath = process.env.PUBLIC_URL.substring(1).length ? process.env.PUBLIC_URL : '/';
    const errorElement = <ErrorPageContainer />;

    return [
        {
            path: rootPath,
            element: (
                <>
                    <AppMainContainer rootPath={rootPath} />
                    <OnboardingWizardContainer />
                    <AppSelectorContainer />
                    <AtlassianCloudManageConnectionDialogContainer />
                    <AtlassianCloudInstanceSelectionContainer />
                    <AtlassianCloudConfigureDialog />
                    <BitbucketCloudManageConnectionDialogContainer />
                    <BitbucketCloudConfigureDialog />
                    <AtlassianOnPremiseManageConnectionDialogContainer />
                    <AtlassianOnPremiseConfigureDialog />
                    <ServiceNowManageConnectionDialogContainer />
                    <BitbucketOnPremiseManageConnectionDialogContainer />
                    <ServiceNowConfigureDialog />
                    <BitbucketOnPremiseConfigureDialog />
                    <SlackManageConnectionDialogContainer />
                    <SlackAppConfigureDialog />
                    <GoogleManageConnectionDialogContainer />
                    <GenericManageConnectionDialogContainer />
                    <GenericAppConfigureDialog />
                    <GithubManageConnectionDialogContainer />
                    <GitlabManageConnectionDialogContainer />
                    <GitlabConfigureDialog />
                    <MondayManageConnectionDialogContainer />
                    <MondayConfigureDialog />
                    <ConfirmDialogContainer />
                    <CreateNewWorkspaceDialogContainer />
                    <EditWorkspaceDialogContainer />
                    <ShareWorkspaceDialogContainer />
                    <UserFeedbackContainer />
                    <SelectBillingDetailsDialogContainer />
                    <ImpersonationPasswordDialogContainer />
                    <TempoCloudManageConnectionDialogContainer />
                    <TempoCloudConfigureDialog />
                    <NetSuiteManageConnectionDialogContainer />
                    <NetSuiteConfigureDialog />
                    <JsmCloudAssetsManageConnectionDialogContainer />
                    <JsmCloudAssetsConfigureDialog />
                    <StartWizardDialogContainer />
                    <WizardMinimizedIconContainer />
                    <ConfirmMinimizeWizardContainer />
                    <MicrosoftManageConnectionDialogContainer />
                    <MicrosoftEventListenerSetupDialogContainer />
                    <MicrosoftConfigureDialog />
                    <AzureDevopsManageConnectionDialogContainer />
                    <AzureDevopsEventListenerSetupDialogContainer />
                    <AzureDevopsConfigureDialog />
                    <ZoomManageConnectionDialogContainer />
                    <ZoomEventListenerSetupDialogContainer />
                    <ZoomConfigureDialog />
                    <CreateOrganizationWizardContainer />
                    <SalesforceManageConnectionDialogContainer />
                    <SalesforceConfigureDialog />
                    <StatuspageManageConnectionDialogContainer />
                    <StatuspageConfigureDialog />
                    <StatuspageEventListenerSetupDialogContainer />
                    <ConnectAndSupportContainer />
                    <TrelloManageConnectionDialogContainer />
                    <TrelloConfigureDialog />
                    <OpsgenieManageConnectionDialogContainer />
                    <OpsgenieConfigureDialog />
                    <BrandChangeModalContainer />
                    <MondayUserOnboardingDialogContainer />
                    <MondayUserFailureDialogContainer />
                    <MondayUserSuccessDialogContainer />
                    <OrganizationPlanSuccessStateContainer />
                    <ProfileUpdateDialogContainer />
                    <ProfileUpdateSuccessDialogContainer />
                    <OnboardingTemplateSelectorContainer />
                </>
            ),
            loader: appLoader,
            pendingElement: async () => <LoadingWizard />,
            errorElement,
            pendingMs: 0,
            children: [
                {
                    path: '/workspaces',
                    element: <WorkspacesContainer />,
                    loader: workspacesLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/workspace/:workspaceUid/environment/:environmentUid',
                    element: (
                        <>
                            <WorkspaceContainer />
                            <GenericEventListenerSetupDialogContainer />
                            <AtlassianCloudEventListenerSetupDialogContainer />
                            <SlackEventListenerSetupDialogContainer />
                            <AtlassianOnPremiseEventListenerSetupDialogContainer />
                            <BitbucketCloudEventListenerSetupDialogContainer />
                            <ServiceNowEventListenerSetupDialogContainer />
                            <NetSuiteEventListenerSetupDialogContainer />
                            <SalesforceEventListenerSetupDialogContainer />
                            <BitbucketOnPremiseEventListenerSetupDialogContainer />
                            <MondayEventListenerSetupDialogContainer />
                            <GitlabEventListenerSetupDialogContainer />
                            <GithubEventListenerSetupDialogContainer />
                            <ZoomEventListenerSetupDialogContainer />
                            <CreateNewWorkspaceEnvironmentDialogContainer />
                            <ManageEnvironmentDeploymentTargetsDialogContainer />
                            <LanguageSelectorContainer />
                            <PackageManagerContainer />
                            <EnvironmentManagerContainer />
                            <AddWorkspaceToOrgDialogContainer />
                            <OpsgenieEventListenerSetupDialogContainer />
                            <PackageUpgradesDialogContainer />
                        </>
                    ),
                    loader: workspaceLoader,
                    unloader: workspaceUnloader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                    children: [
                        {
                            path: '/',
                            element: (
                                <>
                                    <DefaultWorkspaceViewContainer />
                                </>
                            ),
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/script/:scriptUid',
                            element: (
                                <>
                                    <ScriptDetailsContainer />
                                    <ApiHandlerImportDialogContainer />
                                </>
                            ),
                            loader: scriptDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/readme/:readmeFileUid',
                            element: <ReadmeFileDetailsContainer />,
                            loader: readmeFileDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/variables',
                            element: <EnvironmentVariableDetailsContainer />,
                            loader: environmentVariablesLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/api/:apiHandlerUid',
                            element: <ApiHandlerDetailsContainer />,
                            loader: apiHandlerDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/event/:eventListenerUid',
                            element: <EventListenerDetailsContainer />,
                            loader: eventListenerLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/scheduled/:scheduledTriggerUid',
                            element: <ScheduledTriggerDetailsContainer />,
                            loader: scheduledTriggerLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/template/:templateUid',
                    element: (
                        <>
                            <ReadOnlyTemplateContainer />
                        </>
                    ),
                    loader: readOnlyTemplateLoader,
                    unloader: readOnlyTemplateUnloader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                    children: [
                        {
                            path: '/',
                            element: (
                                <>
                                    <DefaultReadOnlyTemplateViewContainer />
                                </>
                            ),
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/script/:scriptUid',
                            element: (
                                <>
                                    <ScriptDetailsContainer />
                                </>
                            ),
                            loader: scriptDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/readme/:readmeFileUid',
                            element: (
                                <>
                                    <ReadmeFileDetailsContainer />
                                </>
                            ),
                            loader: readmeFileDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/variables',
                            element: <EnvironmentVariableDetailsContainer />,
                            loader: environmentVariablesLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/api/:apiHandlerUid',
                            element: <ReadOnlyApiHandlerDetailsContainer />,
                            loader: readOnlyApiHandlerDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/event/:eventListenerUid',
                            element: <ReadOnlyEventListenerDetailsContainer />,
                            loader: readOnlyEventListenerLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/scheduled/:scheduledTriggerUid',
                            element: <ReadOnlyScheduledTriggerDetailsContainer />,
                            loader: scheduledTriggerLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/team/:teamUid',
                    element: (
                        <>
                            <OrganizationContainer />
                        </>
                    ),
                    loader: organizationLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                    children: [
                        {
                            path: '/',
                            element: <OrganizationContainer />,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },

                        {
                            path: '/usage',
                            element: <OrganizationUsageContainer />,
                            loader: organizationUsageLoader,
                            pendingElement: async () => <AbsoluteCenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/details',
                            element: <OrganizationDetailsContainer />,
                            loader: organizationDetailsLoader,
                            pendingElement: async () => <AbsoluteCenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/members',
                            element: <OrganizationMembersContainer />,
                            loader: organizationMembersLoader,
                            pendingElement: async () => <AbsoluteCenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/plan',
                            element: (
                                <>
                                    <OrganizationPlanContainer />
                                    <ChangeOrganizationPlanWizardContainer />
                                    <ChangeOrganizationPlanConfirmationContainer />
                                    <ChangeOrganizationPlanConfirmPurchaseContainer />
                                    <CancelOrganizationPlanContainer />
                                    <CancelOrganizationPlanSuccessStateContainer />
                                    <CancelOrganizationPlanFeedbackContainer />
                                    <OrganizationInvoicePlanInformationDialogContainer />
                                </>
                            ),
                            loader: organizationPlanLoader,
                            pendingElement: async () => <AbsoluteCenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/billing',
                            element: <OrganizationBillingDetailsContainer />,
                            loader: organizationBillingDetailsLoader,
                            pendingElement: async () => <AbsoluteCenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/connectors',
                    element: <ConnectorsContainer />,
                    loader: connectorsLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/teams',
                    element: <OrganizationsContainer />,
                    loader: organizationsLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/templates',
                    element: <TemplatesContainer />,
                    loader: templatesLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/dashboard',
                    element: <DashboardContainer />,
                    loader: dashboardLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/reporting',
                    element: <ReportingContainer />,
                    loader: reportingLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/invocationlogs/workspace/:workspaceUid/invocation/:invocationId/log/:logId',
                    element: <LargeInvocationLogPayloadContainer />,
                    loader: largeInvocationLogPayloadLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/invocationlogs/workspace/:workspaceUid/invocation/:invocationId',
                    element: <InvocationLogsContainer />,
                    loader: invocationLogsLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/httplogs/workspace/:workspaceUid/invocation/:invocationId',
                    element: <HttpLogsContainer />,
                    loader: httpLogsLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/reports',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/',
                            element: <Reports />,
                        },
                        {
                            path: '/users',
                            element: <UsersReportPageContainer />,
                            loader: usersReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/anonymous',
                            element: <AnonymousUsersReportPageContainer />,
                            loader: anonymousUsersReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/team/:teamUid',
                            element: <OrganisationDetailsReportPageContainer />,
                            loader: organisationDetailsReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/teams',
                            element: <OrganisationsReportPageContainer />,
                            loader: organisationsReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/invocations',
                            element: <InvocationsReportPageContainer />,
                            loader: invocationsReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/usersWithDeployedWorkspaces',
                            element: <UsersWithDeployedWorkspacesReportPageContainer />,
                            loader: usersWithDeployedWorkspacesReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/userFeedback',
                            element: <UserFeedbackReportPageContainer />,
                            loader: userFeedbackReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/connectionTypes',
                            element: <ConnectionTypesReportPageContainer />,
                            loader: connectionTypesReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/registeredVsActiveUsers',
                            element: <RegisteredVsActiveUsersReportPageContainer />,
                            loader: registeredVsActiveUsersReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/usersUtilization',
                            element: <UsersUtilizationPageContainer />,
                            loader: usersUtilizationReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/teamsUtilization',
                            element: <OrganisationsUtilizationPageContainer />,
                            loader: organisationsUtilizationReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/templatesUsed',
                            element: <TemplatesUsedReportPageContainer />,
                            loader: templateUsedReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/userActivation',
                            element: <UserActivationReportPageContainer />,
                            loader: userActivationReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/userOnboardingData',
                            element: <UserOnboardingDataReportPageContainer />,
                            loader: userOnboardingDataReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/discountCodes',
                            element: <DiscountCodesReportPageContainer />,
                            loader: discountCodesLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },

                        {
                            path: '/discountCode/:uid',
                            element: <DiscountCodeDetailsReportPageContainer />,
                            loader: discountCodeDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/profile',
                    element: <ProfileContainer />,
                    loader: profileLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/billing',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/',
                            element: <BillingDetailsContainer />,
                            loader: billingDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/:billingEntityUid',
                            element: <BillingEntityContainer />,
                            loader: billingEntityLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
            ],
        },
    ];
};
