import { useObservableState } from 'observable-hooks';
import { ServiceNowConnectionAppConfigureDialog } from '../../../components/connections/servicenow/ServiceNowAppConfigureDialog';
import { configTopic$ } from '../../../store/config';
import {
    serviceNowConnectionSaving$,
    serviceNowManageConnectionDetails$,
    serviceNowAppSetupDialogErrors$,
    serviceNowAppSetupDialogOpen$,
    serviceNowAppSetupCloseDialogAction$,
    serviceNowAppSetupAuthorizeAction$,
    serviceNowAppSetupDialogStage$,
} from '../../../store/connection/servicenow/setup-connection';

export const ServiceNowConfigureDialog: React.FC = () => {
    const open = useObservableState(serviceNowAppSetupDialogOpen$);
    const saving = useObservableState(serviceNowConnectionSaving$);
    const details = useObservableState(serviceNowManageConnectionDetails$);
    const errors = useObservableState(serviceNowAppSetupDialogErrors$);
    const currentStage = useObservableState(serviceNowAppSetupDialogStage$);
    const baseUrl = configTopic$.value.connection?.serviceNow?.baseUrl;

    return (
        <ServiceNowConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                serviceNowAppSetupCloseDialogAction$.next();
                serviceNowAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => serviceNowAppSetupDialogStage$.next(stage)}
            clientInfo={{
                instanceUrl: details?.instanceUrl ?? '',
                clientId: details?.clientId ?? '',
                clientSecret: details?.clientSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => serviceNowAppSetupDialogErrors$.next(error)}
            clearErrors={() => serviceNowAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => serviceNowAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${baseUrl}/callback`}
        />
    );
};
