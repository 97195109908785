import { createScript as createScriptRpc } from '@avst-stitch/repository-lib/lib';
import { getScript as getScriptRpc } from '@avst-stitch/repository-lib/lib';
import { getScripts as getScriptsRpc } from '@avst-stitch/repository-lib/lib';
import { saveScript as saveScriptRpc } from '@avst-stitch/repository-lib/lib';
import { renameScript as renameScriptRpc } from '@avst-stitch/repository-lib/lib';
import { saveBundledScript as saveBundledScriptRpc } from '@avst-stitch/repository-lib/lib';
import { saveBundledScriptSourceMap as saveBundledScriptSourceMapRpc } from '@avst-stitch/repository-lib/lib';
import { deleteScript as deleteScriptRpc } from '@avst-stitch/repository-lib/lib';
import { Response as CreatedScript } from '@avst-stitch/repository-lib/lib/rpcs/createScript';
import { Response as Script } from '@avst-stitch/repository-lib/lib/rpcs/getScript';
import { Response as ScriptsWithUrl, ScriptWithUrl } from '@avst-stitch/repository-lib/lib/rpcs/getScripts';
import { Response as ScriptDeletion } from '@avst-stitch/repository-lib/lib/rpcs/deleteScript';
import { repositoryInvoker } from '../utils/repository';
export type { CreatedScript, Script, ScriptWithUrl, ScriptsWithUrl, ScriptDeletion };

export const createScript = async (workspaceUid: string, name: string): Promise<CreatedScript> => {
    return await createScriptRpc(repositoryInvoker, {
        workspaceUid,
        name,
    });
};

export const getScript = async (uid: string, environmentUid?: string): Promise<Script> => {
    return await getScriptRpc(repositoryInvoker, {
        uid,
        environmentUid,
    });
};

export const getScripts = async (
    scriptUids: string[],
    workspaceUid: string,
    environmentUid?: string
): Promise<ScriptsWithUrl> => {
    return await getScriptsRpc(repositoryInvoker, {
        scriptUids,
        workspaceUid,
        environmentUid,
    });
};

export const saveScript = async (uid: string, content: string): Promise<void> => {
    return await saveScriptRpc(repositoryInvoker, {
        uid,
        content,
    });
};

export const renameScript = async (uid: string, name: string): Promise<void> => {
    return await renameScriptRpc(repositoryInvoker, {
        uid,
        name,
    });
};

export const saveBundledScript = async (uid: string, content: string, manual?: boolean): Promise<void> => {
    return await saveBundledScriptRpc(repositoryInvoker, {
        uid,
        content,
        manual,
    });
};

export const saveBundledScriptSourceMap = async (uid: string, content: string, manual?: boolean): Promise<void> => {
    return await saveBundledScriptSourceMapRpc(repositoryInvoker, {
        uid,
        content,
        manual,
    });
};

export const deleteScript = async (uid: string, ignoreWarnings?: boolean): Promise<ScriptDeletion> => {
    return await deleteScriptRpc(repositoryInvoker, {
        uid,
        ignoreWarnings,
    });
};
