import { LoadResult, Plugin } from 'rollup';

// Adapted from: https://github.com/rollup/plugins/tree/master/packages/virtual#readme
//
// Differences:
// * Original implementation does not allow specifying other than strings as files,
//   but to use original source maps we need to specify an object,
//   so in this implementation string is switched with LoadResult.
// * Don't need to resolve module ids passed in
// * We resolve only relative imports atm (if we want to support absolute, then monaco needs fixing to support it too)
// * Use URL to resolve rather than node path.resolve
// * Use an alternative prefix

const PREFIX = 'local:/';

export default function virtual(modules: Record<string, LoadResult>): Plugin {
    return {
        name: 'virtual',

        resolveId(id, importer) {
            if (id in modules) {
                return PREFIX + id;
            } else if (importer && importer.startsWith(PREFIX) && id[0] === '.') {
                try {
                    return new URL(id, importer).href;
                } catch {
                    return null;
                }
            }

            return null;
        },

        load(id) {
            if (id.startsWith(PREFIX)) {
                return modules[id.slice(PREFIX.length)];
            }

            return null;
        },
    };
}
