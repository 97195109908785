import { useObservableState } from 'observable-hooks';
import { ManageEnvironments } from '../../components/workspace/manage-environments';
import { selectedWorkspaceEnvironments$ } from '../../store/workspace';
import {
    closeEnvironmentManagerAction$,
    deleteEnvironmentAction$,
    environmentManagerErrors$,
    environmentManagerLoading$,
    environmentManagerOpen$,
    renameEnvironmentAction$,
} from '../../store/workspace/environment-manager';

export const EnvironmentManagerContainer: React.FC = () => {
    const environments = useObservableState(selectedWorkspaceEnvironments$);
    const loading = useObservableState(environmentManagerLoading$);
    const open = useObservableState(environmentManagerOpen$);
    const errors = useObservableState(environmentManagerErrors$);

    return (
        <ManageEnvironments
            environments={environments.map((e) => ({ uid: e.uid, name: e.name, default: e.default }))}
            loading={loading}
            open={open}
            errors={errors}
            onClose={() => closeEnvironmentManagerAction$.next()}
            onDelete={(uid) => deleteEnvironmentAction$.next(uid)}
            onRename={(uid, name) => renameEnvironmentAction$.next({ uid, name })}
        />
    );
};
