import { ReactNode } from 'react';
import { styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { ProductIcon } from '../../icons/ProductIcon';
import { ThreeDotsButton } from '../../buttons/ThreeDotsButton';
import {
    SelectorAppIconsInner,
    SelectorAppIconsOuter,
    SelectorItemDescription,
    SelectorItemTitle,
    SelectorTableRow,
    StyledStatus,
    selectorCardIconWrapStyle,
} from '../../selector/SelectorComponents';
import {
    StyledIconCell,
    StyledShortCell,
    StyledTextCell,
} from '../../workspace/workspace-selector/WorkspaceSelectorTableItem';

interface TemplateSelectorTableItemProps {
    description?: string;
    draft?: boolean;
    isNew?: boolean;
    incoming?: string[];
    onSelect(uid: string): void;
    onEdit(uid: string): void;
    outgoing?: string[];
    title?: string;
    uid: string;
    userCanWorkWithTemplates?: boolean;
}

const StyledIconContainer = styled('div')(({ theme }) => ({
    ...selectorCardIconWrapStyle(theme),
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
    },
}));

export const TemplateSelectorTableItem: React.FC<TemplateSelectorTableItemProps> = ({
    description,
    draft = false,
    isNew = false,
    incoming = [],
    onSelect,
    outgoing = [],
    title,
    uid,
    onEdit,
    userCanWorkWithTemplates,
}) => {
    const iconList = (connections: string[]): ReactNode[] => {
        return connections.map((connection, index) => {
            return (
                <SelectorAppIconsInner key={index}>
                    <Tooltip key={index} title={connection} describeChild>
                        <StyledIconContainer>
                            <ProductIcon name={connection} />
                        </StyledIconContainer>
                    </Tooltip>
                </SelectorAppIconsInner>
            );
        });
    };

    return (
        <SelectorTableRow onClick={() => onSelect(uid)}>
            <StyledTextCell>
                <SelectorItemTitle title={title} block={true} />
            </StyledTextCell>
            <StyledTextCell>
                <SelectorItemDescription description={description} />
            </StyledTextCell>
            <StyledIconCell>
                <SelectorAppIconsOuter>{iconList(incoming)}</SelectorAppIconsOuter>
            </StyledIconCell>
            <StyledIconCell>
                <SelectorAppIconsOuter>{iconList(outgoing)}</SelectorAppIconsOuter>
            </StyledIconCell>
            <StyledShortCell>
                <SelectorAppIconsOuter>
                    {isNew && (
                        <StyledStatus
                            label="New"
                            sx={(theme) => ({
                                backgroundColor: 'setup.main',
                                color: 'setup.contrastText',
                                margin: theme.spacing(1, 0, 0, 2),
                            })}
                        />
                    )}
                    {draft && (
                        <StyledStatus
                            label="Draft"
                            sx={(theme) => ({
                                backgroundColor: 'action.disabled',
                                color: 'text.primary',
                                margin: theme.spacing(1, 0, 0, 2),
                            })}
                        />
                    )}
                </SelectorAppIconsOuter>
            </StyledShortCell>
            <StyledShortCell>
                {userCanWorkWithTemplates && (
                    <ThreeDotsButton
                        uid={uid}
                        actions={[{ name: 'Edit', onSelect: onEdit, icon: <ModeEditOutlineOutlinedIcon /> }]}
                    />
                )}
            </StyledShortCell>
        </SelectorTableRow>
    );
};
