import { useObservableState } from 'observable-hooks';
import {
    cancelDeleteAccountAction$,
    customRoleValidationError$,
    deleteAccountAction$,
    loggedInUserDetails$,
    resetUserPasswordAction$,
    updateUserDetailsAction$,
    userDetailsUpdating$,
} from '../../store/user';
import { Profile } from '../../components/profile';
import { useNavigate } from 'react-location';
import { useAuth0 } from '@auth0/auth0-react';
import { industryRoles$ } from '../../store/roles';
import { promptQuestion } from '../../store/confirm';
import { MONDAY_ORIGIN } from '../../utils/constants';
import { mondayServiceManagementUrl, supportPortalUrl } from '../../utils/documentation';
import { scriptingFamiliarityOptions$ } from '../../store/onboarding';
import { apps$ } from '../../store/apps';
import { getBasePath } from '../../utils/path';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ProfileContainer: React.FC = () => {
    const isUpdating = useObservableState(userDetailsUpdating$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const industryRoles = useObservableState(industryRoles$);
    const navigate = useNavigate();
    const validationError = useObservableState(customRoleValidationError$);
    const { userOrigin } = useObservableState(loggedInUserDetails$) ?? {};
    const scriptingFamiliarities = useObservableState(scriptingFamiliarityOptions$);
    const apps = useObservableState(apps$);
    const isMondayUser = userOrigin === MONDAY_ORIGIN;

    const handleReset = (): void => {
        if (loggedInUserDetails?.email) {
            resetUserPasswordAction$.next(loggedInUserDetails.email);
        }
    };

    const isManualReg = useAuth0().user?.sub?.startsWith('auth0') ?? false;

    const remappedIndustryRoles = industryRoles?.map((r) => {
        return {
            value: r.uid,
            name: r.label,
        };
    });
    const remappedScriptingFamiliarity = scriptingFamiliarities?.map((sf) => {
        return {
            value: sf.uid,
            name: sf.label,
        };
    });
    const handleDeleteAccount = (): void => {
        if (loggedInUserDetails?.deleteDate) {
            promptQuestion({
                title: 'Are you sure you want to cancel the account deletion?',
                onProceed: () => cancelDeleteAccountAction$.next(),
            });
        } else {
            promptQuestion({
                title: 'Are you sure you want to delete the account?',
                onProceed: () => deleteAccountAction$.next(),
            });
        }
    };
    const goals = [];
    if (loggedInUserDetails?.integrationsPreference) {
        goals.push('Automation');
    }
    if (loggedInUserDetails?.migrationPreference) {
        goals.push('Migration');
    }
    if (loggedInUserDetails?.syncPreference) {
        goals.push('Synchronisation');
    }

    const handleDiscard = (): void => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}/profile` }), 100);
    };

    return (
        <Profile
            apps={apps.map(({ uid, name }) => ({
                uid,
                name,
            }))}
            email={loggedInUserDetails?.email ?? ''}
            firstName={loggedInUserDetails?.firstName ?? ''}
            lastName={loggedInUserDetails?.lastName ?? ''}
            company={loggedInUserDetails?.company ?? ''}
            roleUid={loggedInUserDetails?.roleUid ?? 'Other'}
            roles={remappedIndustryRoles}
            customRole={loggedInUserDetails?.customRole ?? ''}
            emailNotificationsEnabled={!!loggedInUserDetails?.emailNotificationsEnabled}
            mfaEnabled={!!loggedInUserDetails?.mfaEnabled}
            showPasswordReset={isManualReg}
            // onInitiateAvatarUpload={() => alert('Functionality not yet implemented')}
            supportPortalUrl={isMondayUser ? mondayServiceManagementUrl : supportPortalUrl}
            onPasswordReset={handleReset}
            onUpdate={({
                firstName,
                lastName,
                company,
                roleUid,
                customRole,
                enableEmailNotifications,
                enableMfa,
                scriptingFamiliarityUid,
                personalization,
                userAppPreference,
            }) =>
                updateUserDetailsAction$.next({
                    enableMfa,
                    firstName,
                    lastName,
                    company,
                    roleUid,
                    customIndustryRole: customRole,
                    enableEmailNotifications,
                    scriptingFamiliarityUid,
                    personalization,
                    userAppPreference,
                })
            }
            onDiscard={handleDiscard}
            deleteDate={loggedInUserDetails?.deleteDate}
            onDelete={handleDeleteAccount}
            saving={isUpdating}
            errors={validationError}
            scriptingFamiliarityUid={loggedInUserDetails?.scriptingFamiliarityUid ?? ''}
            scriptingFamiliarities={remappedScriptingFamiliarity ?? []}
            selectedApps={loggedInUserDetails?.userAppPreference ?? []}
            selectedGoals={goals ?? []}
            isMondayUser={isMondayUser}
        />
    );
};
