import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '../../buttons/Button';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface GenericEventListenerSetupDialogProps {
    open?: boolean;
    url: string;
    onClose: () => void;
}

export const GenericEventListenerSetupDialog: React.FC<GenericEventListenerSetupDialogProps> = ({
    open = false,
    url,
    onClose,
}) => {
    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}
            >
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Event Listener setup instructions for Generic App
                </DialogTitle>
                <>
                    <DialogContent>
                        <ReadOnlyTextField label="URL" value={url} />
                        <DialogContentText variant="subtitle2">
                            Copy the URL and use it however you need in order to connect to ScriptRunner Connect, common
                            use cases include:
                        </DialogContentText>
                        <DialogContentText component="ol">
                            <li>Registering the URL as a webhook in external app to listen events from that app.</li>
                            <li>Build your own API by sending back JSON in the response.</li>
                            <li>Build your own user interface by sending back HTML in the response.</li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Done</Button>
                    </DialogActions>
                </>
            </Dialog>
        </>
    );
};
