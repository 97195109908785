import { useObservableState } from 'observable-hooks';
import { SalesforceManageConnectionDialog } from '../../../components/connections/salesforce/ManageConnectionDialog';
import {
    salesforceConnectionSaving$,
    salesforceManageConnectionAuthorizeLoading$,
    salesforceManageConnectionCloseDialogAction$,
    salesforceManageConnectionDetails$,
    salesforceManageConnectionDialogErrors$,
    salesforceManageConnectionDialogOpen$,
    salesforceManageConnectionInitiateSetupAction$,
    salesforceSaveConnectionAction$,
} from '../../../store/connection/salesforce/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const SalesforceManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(salesforceManageConnectionDialogOpen$);
    const saving = useObservableState(salesforceConnectionSaving$);
    const authorizeLoading = useObservableState(salesforceManageConnectionAuthorizeLoading$);
    const details = useObservableState(salesforceManageConnectionDetails$);
    const errors = useObservableState(salesforceManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <SalesforceManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => salesforceManageConnectionCloseDialogAction$.next()}
            onSave={(name) => salesforceSaveConnectionAction$.next(name)}
            onAuthorize={(name) => salesforceManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
