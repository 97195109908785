import { useObservableState } from 'observable-hooks';
import { TempoCloudManageConnectionDialog } from '../../../components/connections/tempo-cloud/ManageConnectionDialog';
import {
    tempoCloudConnectionSaving$,
    tempoCloudManageConnectionAuthorizeLoading$,
    tempoCloudManageConnectionCloseDialogAction$,
    tempoCloudManageConnectionDetails$,
    tempoCloudManageConnectionDialogErrors$,
    tempoCloudManageConnectionDialogOpen$,
    tempoCloudManageConnectionInitiateSetupAction$,
    tempoCloudSaveConnectionAction$,
} from '../../../store/connection/tempo-cloud/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const TempoCloudManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(tempoCloudManageConnectionDialogOpen$);
    const saving = useObservableState(tempoCloudConnectionSaving$);
    const authorizeLoading = useObservableState(tempoCloudManageConnectionAuthorizeLoading$);
    const details = useObservableState(tempoCloudManageConnectionDetails$);
    const errors = useObservableState(tempoCloudManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <TempoCloudManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => tempoCloudManageConnectionCloseDialogAction$.next()}
            onSave={(name) => tempoCloudSaveConnectionAction$.next(name)}
            onAuthorize={(name) => tempoCloudManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
