import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const AzureDevopsIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            className="productIcon"
            aria-label="Azure Devops"
        >
            <defs>
                <linearGradient id="a" x1="9" y1="16.97" x2="9" y2="1.03" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#0078d4" />
                    <stop offset=".16" stop-color="#1380da" />
                    <stop offset=".53" stop-color="#3c91e5" />
                    <stop offset=".82" stop-color="#559cec" />
                    <stop offset="1" stop-color="#5ea0ef" />
                </linearGradient>
            </defs>
            <path
                d="M17 4v9.74l-4 3.28-6.2-2.26V17l-3.51-4.59 10.23.8V4.44zm-3.41.49L7.85 1v2.29L2.58 4.84 1 6.87v4.61l2.26 1V6.57z"
                fill="url(#a)"
            />
        </SvgIcon>
    );
};
