import { useEffect, useState } from 'react';
import { EnvironmentDeploymentMapping } from './index';
import { Dropdown } from '../../dropdown/Dropdown';

interface EnvironmentProps {
    envItem: {
        environment: {
            uid: string;
            name: string;
        };
        deploymentUid?: string;
    };
    optionList: {
        uid: string;
        name: string;
    }[];
    handleUpdate(data: EnvironmentDeploymentMapping): void;
}

export const Environment: React.FC<EnvironmentProps> = ({ optionList, envItem, handleUpdate }) => {
    const [currentOption, setCurrentOption] = useState(envItem.deploymentUid ?? 'head');

    const handleSelect = (value: string): void => {
        setCurrentOption(value);
    };

    useEffect(() => {
        handleUpdate({
            environmentUid: envItem.environment.uid,
            deploymentUid: currentOption,
        });
    }, [currentOption]);

    const environments = optionList.map((option) => {
        return { value: option.uid, name: option.name };
    });

    return (
        <Dropdown
            label={envItem.environment.name}
            selectedItem={currentOption}
            items={[{ value: 'head', name: 'HEAD' }, ...environments]}
            onSelect={handleSelect}
        />
    );
};
