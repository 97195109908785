import { BehaviorSubject, map, Subject } from 'rxjs';
import { FilteringOptions } from '../components/template/template-selector';
import { QuickFilters } from '../components/template/template-selector/QuickFilter';
import { Apps, getApps } from '../data/apps';
import { getTemplate, Template } from '../data/template';
import { getTemplates, Templates } from '../data/templates';
import { apps$ } from './apps';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';

export const templates$ = monitor('templates$', new BehaviorSubject<Templates>([]));
export const selectedReadOnlyTemplate$ = monitor(
    'selectedReadOnlyTemplate$',
    new BehaviorSubject<Template | undefined>(undefined)
);

export const editTemplateAction$ = monitor('editTemplateAction$', new Subject<string>());
export const navigateToWorkspaceFromTemplateEditAction$ = monitor(
    'navigateToWorkspaceFromTemplateEditAction$',
    new Subject<Template>()
);
export const viewTemplateAction$ = monitor('viewTemplateAction$', new Subject<string>());
export const navigateToReadOnlyTemplateFromViewTemplateAction$ = monitor(
    'navigateToReadOnlyTemplateFromViewTemplateAction$',
    new Subject<string>()
);

editTemplateAction$
    .pipe(
        map(async (templateUid) => {
            try {
                const template = await getTemplate(templateUid);
                navigateToWorkspaceFromTemplateEditAction$.next(template);
            } catch (error) {
                console.error('Error while loading template', error);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Could not load template. Please try again, if the issue persists please contact support',
                    noToast: true,
                });
            }
        })
    )
    .subscribe();

viewTemplateAction$
    .pipe(
        map(async (templateUid) => {
            try {
                const template = await getTemplate(templateUid);
                selectedReadOnlyTemplate$.next(template);
                navigateToReadOnlyTemplateFromViewTemplateAction$.next(templateUid);
            } catch (error) {
                console.error('Error while loading template', error);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Could not load template. Please try again, if the issue persists please contact support',
                    noToast: true,
                });
            }
        })
    )
    .subscribe();

export const loadTemplates = async (bypassCache = true): Promise<void> => {
    if (templates$.value.length === 0 || bypassCache) {
        const templates = await getTemplates();
        templates$.next(templates);
        if (packagesFilterItems$.value.length === 0) {
            packagesFilterItems$.next(
                Array.from(
                    templates.reduce((acc, { packages }) => {
                        packages.forEach((p) => acc.add(p));
                        return acc;
                    }, new Set<string>())
                )
            );
        }
        if (apps$.value.length === 0) {
            const apps = await getApps();
            apps$.next(apps);
        }
        if (quickFilters$.value.filters.length === 0) {
            setupQuickFiltersAction$.next(apps$.value);
        }
    }
    refreshTemplatesAndReapplyFiltersAction$.next();
};

export const quickFilters$ = monitor(
    'quickFilters$',
    new BehaviorSubject<QuickFilters>({ filters: [], activeFiltersCount: 0 })
);
export const setupQuickFiltersAction$ = monitor('setupQuickFiltersAction$', new Subject<Apps>());
export const refreshTemplatesAndReapplyFiltersAction$ = monitor(
    'refreshTemplatesAndReapplyFiltersAction$',
    new Subject<void>()
);
export const updateQuickFilterAction$ = monitor('updateQuickFilterAction$', new Subject<string>());
export const resetQuickFilterAction$ = monitor('resetQuickFilterAction$', new Subject<void>());

resetQuickFilterAction$.subscribe(() => {
    setupQuickFiltersAction$.next(apps$.value);
    updateTemplatesWithFilters();
});

updateQuickFilterAction$.subscribe((app) => {
    let updatedCount = quickFilters$.value.activeFiltersCount;
    const updatedFilters = quickFilters$.value.filters.map((filter) => {
        if (filter.name === app) {
            filter.checked = !filter.checked;
            filter.checked ? (updatedCount += 1) : (updatedCount -= 1);
        }
        return filter;
    });
    quickFilters$.next({ filters: updatedFilters, activeFiltersCount: updatedCount });
    updateTemplatesWithFilters();
});

const updateTemplatesWithFilters = (): void => {
    if (quickFilters$.value.activeFiltersCount) {
        const quickFilteredTemplates = performTemplateQuickFiltering();
        filteredTemplates$.next(performMainFiltering(quickFilteredTemplates));
    } else {
        filteredTemplates$.next(performMainFiltering(templates$.value));
    }
};

const performTemplateQuickFiltering = (): Templates => {
    const filteredTemplates = new Set<{
        uid: string;
        name: string;
        draft: boolean;
        isNew: boolean;
        description?: string;
        incomingApps: string[];
        outgoingApps: string[];
        packages: string[];
    }>();
    quickFilters$.value.filters.forEach((filter) => {
        if (filter.checked) {
            templates$.value.forEach((t) => {
                if (filter.templateUids.some((uid) => uid === t.uid)) {
                    filteredTemplates.add(t);
                }
            });
        }
    });
    return Array.from(filteredTemplates);
};

setupQuickFiltersAction$.subscribe((apps) => {
    const filters = apps.reduce<QuickFilters['filters']>((acc, app) => {
        acc.push({
            name: app.name,
            templateUids: collectUidsForApp(app.name),
            checked: false,
        });

        return acc;
    }, []);

    quickFilters$.next({ filters, activeFiltersCount: 0 });
});

refreshTemplatesAndReapplyFiltersAction$.subscribe(() => {
    const filters = quickFilters$.value.filters.map((f) => {
        return {
            ...f,
            templateUids: collectUidsForApp(f.name),
        };
    });
    quickFilters$.next({ ...quickFilters$.value, filters });
    updateTemplatesWithFilters();
});

const collectUidsForApp = (app: string): string[] => {
    const uids = templates$.value.reduce<Set<string>>((acc, t) => {
        if (t.incomingApps.some((a) => a === app)) {
            acc.add(t.uid);
        }
        if (t.outgoingApps.some((a) => a === app)) {
            acc.add(t.uid);
        }
        return acc;
    }, new Set());

    return Array.from(uids);
};

export const activeFilters$ = monitor(
    'activeFilters$',
    new BehaviorSubject<FilteringOptions>({
        incoming: [],
        outgoing: [],
        packages: [],
        count: 0,
    })
);
export const updateIncomingFilterAction$ = monitor('updateIncomingFilterAction$', new Subject<string>());
export const updateOutgoingFilterAction$ = monitor('updateOutgoingFilterAction$', new Subject<string>());
export const updatePackagesFilterAction$ = monitor('updatePackagesFilterAction$', new Subject<string>());
export const resetFilterAction$ = monitor('resetFilterAction$', new Subject<void>());
export const filteredTemplates$ = monitor('filteredTemplates$', new BehaviorSubject<Templates>([]));
export const packagesFilterItems$ = monitor('packagesFilterItems$', new BehaviorSubject<string[]>([]));

resetFilterAction$.subscribe(() => {
    activeFilters$.next({
        incoming: [],
        outgoing: [],
        packages: [],
        count: 0,
    });
    updateTemplatesWithFilters();
});

updateIncomingFilterAction$.subscribe((app) => {
    updateMainFilters(activeFilters$.value.incoming, app, 'incoming');
    updateTemplatesWithFilters();
});

updateOutgoingFilterAction$.subscribe((app) => {
    updateMainFilters(activeFilters$.value.outgoing, app, 'outgoing');
    updateTemplatesWithFilters();
});

updatePackagesFilterAction$.subscribe((packageName) => {
    updateMainFilters(activeFilters$.value.packages, packageName, 'packages');
    updateTemplatesWithFilters();
});

const updateMainFilters = (
    activeItems: string[],
    updatedItem: string,
    field: 'incoming' | 'outgoing' | 'packages'
): void => {
    const foundIndex = activeItems.findIndex((a) => a === updatedItem);
    if (foundIndex >= 0) {
        activeItems.splice(foundIndex, 1);
        activeFilters$.next({
            ...activeFilters$.value,
            [field]: activeItems,
            count: activeFilters$.value.count - 1,
        });
    } else {
        activeFilters$.next({
            ...activeFilters$.value,
            [field]: [...activeItems, updatedItem],
            count: activeFilters$.value.count + 1,
        });
    }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const performMainFiltering = (templates: Templates): Templates => {
    const checkTemplate = (isSelected: boolean, checked: boolean, items: string[], options: string[]): boolean => {
        const diff = (arr1: string[], arr2: string[]): boolean => arr1.some((a) => arr2.includes(a));
        const selected = diff(items, options);
        if (checked) {
            return isSelected ? selected : isSelected;
        } else {
            return selected;
        }
    };

    const { incoming, outgoing, packages } = activeFilters$.value;
    if (!incoming.length && !outgoing.length && !packages.length) {
        return templates;
    } else {
        return templates.filter((t) => {
            let isSelected = false;
            let isPreviouslyFiltered = false;
            if (incoming.length) {
                isSelected = checkTemplate(isSelected, isPreviouslyFiltered, t.incomingApps, incoming);
                isPreviouslyFiltered = true;
            }
            if (outgoing.length) {
                isSelected = checkTemplate(isSelected, isPreviouslyFiltered, t.outgoingApps, outgoing);
                isPreviouslyFiltered = true;
            }
            if (packages.length) {
                isSelected = checkTemplate(isSelected, isPreviouslyFiltered, t.packages, packages);
            }
            if (isSelected) {
                return true;
            }
            return false;
        });
    }
};
