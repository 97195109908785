import { useObservableState } from 'observable-hooks';
import { ManageEnvironmentDeploymentTargetsDialog } from '../../components/workspace/manage-environment-deployment-targets-dialog';
import { selectedWorkspaceEnvironments$ } from '../../store/workspace';
import { selectedWorkspaceDeployments$ } from '../../store/workspace/deployment';
import {
    closeManageEnvironmentDeploymentTargetsDialogAction$,
    manageEnvironmentDeploymentTargetsDialogErrors$,
    manageEnvironmentDeploymentTargetsDialogLoading$,
    manageEnvironmentDeploymentTargetsDialogOpen$,
    saveEnvironmentDeploymentTargetsAction$,
    savingEnvironmentDeploymentTargets$,
} from '../../store/workspace/environment';

export const ManageEnvironmentDeploymentTargetsDialogContainer: React.FC = () => {
    const open = useObservableState(manageEnvironmentDeploymentTargetsDialogOpen$);
    const loading = useObservableState(manageEnvironmentDeploymentTargetsDialogLoading$);
    const saving = useObservableState(savingEnvironmentDeploymentTargets$);
    const errors = useObservableState(manageEnvironmentDeploymentTargetsDialogErrors$);
    const deployments = useObservableState(selectedWorkspaceDeployments$);
    const environments = useObservableState(selectedWorkspaceEnvironments$);

    return (
        <ManageEnvironmentDeploymentTargetsDialog
            open={open}
            loading={loading}
            errors={errors}
            saving={saving}
            deployments={deployments.map(({ uid, version, label }) => ({
                uid,
                name: label ? `${version} (${label})` : version,
            }))}
            mappings={environments.map(({ uid, name, deployment }) => ({
                environment: {
                    uid,
                    name,
                },
                deploymentUid: deployment?.uid,
            }))}
            onSave={(event) => saveEnvironmentDeploymentTargetsAction$.next(event)}
            onCancel={() => closeManageEnvironmentDeploymentTargetsDialogAction$.next()}
        />
    );
};
