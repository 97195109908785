import { useObservableState, useSubscription } from 'observable-hooks';
import { Dashboard } from '../components/dashboard';
import {
    editTemplateAction$,
    navigateToWorkspaceFromTemplateEditAction$,
    navigateToReadOnlyTemplateFromViewTemplateAction$,
    templates$,
    viewTemplateAction$,
} from '../store/templates';
import { loggedInUserDetails$ } from '../store/user';
import { newDuplicatedWorkspace$, newWorkspaceFromTemplate$, openNewBlankWorkspaceAction$ } from '../store/workspaces';
import { getBasePath } from '../utils/path';
import { useNavigate } from 'react-location';
import { updateWizardStepAction$, WizardStep } from '../store/wizard';
import { segmentAnalyticsTrack } from '../data/segment-analytics';
import { onboardingWizardOpen$, openProfileUpdateDialogAction$ } from '../store/onboarding';
import { readLocalStorage } from '../utils/localStorage';
import { useEffect } from 'react';
import { featureFlagsTopic$ } from '../store/config';
import {
    apiConnectionDocumentationUrl,
    connectorDocumentationUrl,
    deploymentsAndEnvironmentsDocumentationUrl,
    editorDocumentationUrl,
    eventListenerDocumenationUrl,
    genericConnectorDocumentationUrl,
    genericEventDocumentationUrl,
    httpLogsDocumentationUrl,
    managedApiDocumentationUrl,
    packageManagerDocumentationUrl,
    recordStorageDocumentationUrl,
    runtimeDocumentationUrl,
    scheduledTriggerDocumentationUrl,
    scriptingDocumentationUrl,
    triggeringScriptsDocumentationUrl,
    workingPrinciplesDocumentationUrl,
} from '../utils/documentation';

export const DashboardContainer: React.FC = () => {
    const templates = useObservableState(templates$);
    const navigate = useNavigate();

    const filteredTemplates = templates.length > 3 ? templates.slice(0, 3) : templates;
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const isOnboardingWizardOpen = useObservableState(onboardingWizardOpen$);
    const featureFlags = useObservableState(featureFlagsTopic$);
    const userHasSkippedProfileUpdate: boolean = readLocalStorage('hasSkippedProfileUpdate', false);
    const openProfileUpdate: boolean = readLocalStorage('openProfileUpdate', false);
    const userCanWorkWithTemplates = !!(loggedInUserDetails && loggedInUserDetails.canWorkWithTemplates);
    const missingScriptingFamiliarity =
        !loggedInUserDetails?.scriptingFamiliarityUid && loggedInUserDetails?.userOrigin !== 'monday.com';
    const showProfileUpdateBanner =
        !!featureFlags.showReturningUserOnboardingFlow && missingScriptingFamiliarity && userHasSkippedProfileUpdate;

    useSubscription(navigateToWorkspaceFromTemplateEditAction$, (template) =>
        navigate({ to: `${getBasePath()}workspace/${template.workspaceUid}/environment/${template.environmentUid}` })
    );

    useSubscription(navigateToReadOnlyTemplateFromViewTemplateAction$, (templateUid) =>
        navigate({ to: `${getBasePath()}template/${templateUid}` })
    );

    if (featureFlags.showReturningUserOnboardingFlow) {
        useEffect(() => {
            if (
                (!userHasSkippedProfileUpdate && missingScriptingFamiliarity && !isOnboardingWizardOpen) ||
                (openProfileUpdate && missingScriptingFamiliarity)
            ) {
                openProfileUpdateDialogAction$.next();
            }
        }, [loggedInUserDetails?.scriptingFamiliarityUid]);
    }

    return (
        <Dashboard
            embeddedVideo="https://www.youtube.com/embed/Sx1_LImeJzc"
            knowledgeBaseArticles={[
                {
                    title: 'Working principle',
                    description: 'How ScriptRunner Connect works.',
                    url: workingPrinciplesDocumentationUrl,
                },
                {
                    title: 'Runtime',
                    description: 'How your code gets executed.',
                    url: runtimeDocumentationUrl,
                },
                {
                    title: 'Scripting',
                    description: 'What are the benefits of JavaScript/TypeScript.',
                    url: scriptingDocumentationUrl,
                },
                {
                    title: 'Managed APIs',
                    description: 'How Managed APIs can increase developer experience and productivity.',
                    url: managedApiDocumentationUrl,
                },
                {
                    title: 'Editor',
                    description: 'How to enjoy writing code by taking advantage of the Editor features.',
                    url: editorDocumentationUrl,
                },
                {
                    title: 'Connectors',
                    description: 'How Connectors can help you build integrations easier and more securely.',
                    url: connectorDocumentationUrl,
                },
                {
                    title: 'API Connections',
                    description: 'How to talk to external services.',
                    url: apiConnectionDocumentationUrl,
                },
                {
                    title: 'Generic Connector',
                    description: 'Find out how to connect to something ScriptRunner Connect does not yet support',
                    url: genericConnectorDocumentationUrl,
                },
                {
                    title: 'Event Listeners',
                    description: 'How to listen events originating from external services.',
                    url: eventListenerDocumenationUrl,
                },
                {
                    title: 'Generic HTTP Events',
                    description:
                        'How to use Generic Event Listener to listen events from any service, build your own APIs or basic UIs.',
                    url: genericEventDocumentationUrl,
                },
                {
                    title: 'Scheduled Triggers',
                    description: 'How to run scripts periodically.',
                    url: scheduledTriggerDocumentationUrl,
                },
                {
                    title: 'Deployments and Environments',
                    description: 'How Deployments and Environments work.',
                    url: deploymentsAndEnvironmentsDocumentationUrl,
                },
                {
                    title: 'Package Manager',
                    description:
                        'How to manually control your Workspace packages or how to pull in third party NPM packages.',
                    url: packageManagerDocumentationUrl,
                },
                {
                    title: 'HTTP Logs',
                    description: 'How to troubleshoot API calls by inspecting HTTP logs.',
                    url: httpLogsDocumentationUrl,
                },
                {
                    title: 'API for Record Storage',
                    description: 'How to store data using simple key-value storage.',
                    url: recordStorageDocumentationUrl,
                },
                {
                    title: 'API for Triggering Scripts',
                    description: 'How to trigger scripts programmatically',
                    url: triggeringScriptsDocumentationUrl,
                },
            ]}
            showProfileUpdateBanner={showProfileUpdateBanner}
            onUpdateProfile={() => openProfileUpdateDialogAction$.next()}
            templates={filteredTemplates}
            onStartWizard={() => updateWizardStepAction$.next(WizardStep.START)}
            onBlankSetup={() => {
                newWorkspaceFromTemplate$.next(undefined);
                newDuplicatedWorkspace$.next(undefined);
                openNewBlankWorkspaceAction$.next('blank');
            }}
            userCanWorkWithTemplates={userCanWorkWithTemplates}
            onTemplateSelect={(templateUid) => {
                const template = templates.find((temp) => temp.uid === templateUid);
                if (template) {
                    viewTemplateAction$.next(templateUid);
                }
            }}
            onEdit={(selectedTemplateUid) => {
                editTemplateAction$.next(selectedTemplateUid);
            }}
            onMoreTemplates={() =>
                segmentAnalyticsTrack('DashboardViewMoreTemplates Clicked', {
                    userOrigin: loggedInUserDetails?.userOrigin,
                })
            }
            onLearnMore={(url) =>
                segmentAnalyticsTrack('DashboardKB Accessed', {
                    url,
                    userOrigin: loggedInUserDetails?.userOrigin,
                })
            }
        />
    );
};
