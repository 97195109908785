import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '../buttons/Button';
import CheckIcon from '@mui/icons-material/Check';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VerifiedIcon from '@mui/icons-material/Verified';
import { StyledDialogInstructions } from '../dialog/DialogComponents';
import { handleKeyUp } from '../../utils/handleKeyUp';
import { getPlanData } from './create-organization-wizard/BillingPlanCard';

interface OrganizationPlanSuccessStateDialogProps {
    open?: boolean;
    successState: 'create' | 'upgrade' | 'downgrade' | 'onboarding';
    lastTier?: string;
    tier: string;
    onClose(onboarding?: boolean): void;
}

interface OrganizationPlanSuccessState {
    successIcon: JSX.Element;
    successMessage: string;
    title: string;
    text: string;
}

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
}));

const StyledTitleContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    padding: theme.spacing(2, 0),
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    color: theme.palette.success.light,
    paddingTop: theme.spacing(1),
    textTransform: 'uppercase',
}));

const StyledDialogContent = styled(DialogContent)(() => ({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
}));

const StyledInstructions = styled(StyledDialogInstructions)(() => ({
    flexDirection: 'column',
    width: '70%',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    paddingBottom: theme.spacing(1),
}));

const StyledList = styled(List)(({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 1, 2, 2),
    width: '70%',
    border: `1px solid ${theme.palette.divider}`,
}));

const StyledListItem = styled(ListItem)(() => ({
    padding: 0,
}));

const StyledBoldListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
        fontWeight: theme.typography.h2.fontWeight,
    },
}));

const StyledBoldSpan = styled('span')(({ theme }) => ({
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.palette.text.secondary,
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: 'unset',
    paddingRight: theme.spacing(1),
    color: theme.palette.success.main,
}));

const StyledSubtitleListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
        color: theme.palette.text.secondary,
    },
}));

const StyledIconContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.success.light,
    width: theme.spacing(9),
    height: theme.spacing(9),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    '& .MuiSvgIcon-root': {
        color: theme.palette.common.white,
        fontSize: theme.spacing(6),
    },
}));

export const OrganizationPlanSuccessStateDialog: React.FC<OrganizationPlanSuccessStateDialogProps> = ({
    open = false,
    successState,
    tier,
    lastTier = 'last',
    onClose,
}) => {
    const capitalizedTier = tier.charAt(0).toUpperCase() + tier.slice(1).toLowerCase();
    const capitalizedLastTier = lastTier.charAt(0).toUpperCase() + lastTier.slice(1).toLowerCase();

    const getSuccessStateContent = (state: string): OrganizationPlanSuccessState => {
        switch (state) {
            case 'upgrade':
                return {
                    successIcon: <CheckIcon />,
                    successMessage: 'Upgraded Team',
                    title: `${capitalizedTier} Plan Activated`,
                    text: `Your team has been successfully upgraded from the ${capitalizedLastTier} Plan to the ${capitalizedTier} Plan.`,
                };
            case 'downgrade':
                return {
                    successIcon: <NextPlanOutlinedIcon />,
                    successMessage: 'Downgraded Team',
                    title: `${capitalizedTier} Plan Enabled`,
                    text: `Your team has been successfully downgraded from the ${capitalizedLastTier} plan to the ${capitalizedTier} plan.`,
                };
            case 'onboarding':
            case 'create':
            default:
                return {
                    successIcon: <LockOpenIcon />,
                    successMessage: 'Created Team',
                    title: `${capitalizedTier} Plan Unlocked`,
                    text: `Congratulations! Your team has been successfully created and you have unlocked the ${capitalizedTier} plan.`,
                };
        }
    };

    const successStateContent = getSuccessStateContent(successState);
    const planData = getPlanData(tier);

    return (
        <Dialog
            open={open}
            onKeyUp={(event) =>
                handleKeyUp({
                    event,
                    enterFn: () => onClose(successState === 'onboarding'),
                })
            }
        >
            <StyledTitleContainer>
                <StyledIconContainer>{successStateContent.successIcon}</StyledIconContainer>
                <StyledDialogTitle variant="subtitle1">{successStateContent.successMessage}</StyledDialogTitle>
            </StyledTitleContainer>
            <StyledDialogContent>
                <StyledInstructions>
                    <StyledTypography variant="h4" className="extra-bold">
                        {successStateContent.title}
                    </StyledTypography>
                    <Typography>{successStateContent.text}</Typography>
                </StyledInstructions>
                <StyledList>
                    <StyledListItem>
                        <StyledBoldListItemText>{capitalizedTier} Plan Features</StyledBoldListItemText>
                    </StyledListItem>
                    <StyledListItem>
                        <StyledSubtitleListItemText>You now have access to the following:</StyledSubtitleListItemText>
                    </StyledListItem>
                    <StyledListItem>
                        <StyledListItemIcon>
                            <VerifiedIcon />
                        </StyledListItemIcon>
                        <StyledSubtitleListItemText>
                            <StyledBoldSpan>{planData.connectors}</StyledBoldSpan> connectors
                        </StyledSubtitleListItemText>
                    </StyledListItem>
                    <StyledListItem>
                        <StyledListItemIcon>
                            <VerifiedIcon />
                        </StyledListItemIcon>
                        <StyledSubtitleListItemText>
                            <StyledBoldSpan>{planData.functionInvocations}</StyledBoldSpan> script invocations / month
                        </StyledSubtitleListItemText>
                    </StyledListItem>
                </StyledList>
            </StyledDialogContent>
            <StyledDialogActions>
                <Button variant="contained" onClick={() => onClose(successState === 'onboarding')}>
                    {successState === 'onboarding' ? 'Get started' : 'Close'}
                </Button>
            </StyledDialogActions>
        </Dialog>
    );
};
