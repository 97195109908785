import { useObservableState } from 'observable-hooks';
import { selectedWorkspaceUid$ } from '../../store/workspace';
import { AddWorkspaceToOrgDialog } from '../../components/organization/AddWorkspaceToOrgDialog';
import {
    createOrganizationWizardDialogOpen$,
    loggedInUserOrganizations$,
    organizationSelectedForSharing$,
} from '../../store/organizations';
import {
    addWorkspaceToOrganizationAction$,
    addWorkspaceToOrganizationDialogOpen$,
    addWorkspaceToOrganizationError$,
    addingWorkspaceToOrganization$,
    cancelAddWorkspaceToOrganizationAction$,
    workspaceBeingShared$,
} from '../../store/workspace/sharing';

export const AddWorkspaceToOrgDialogContainer: React.FC = () => {
    const addWorkspaceToOrganizationDialogOpen = useObservableState(addWorkspaceToOrganizationDialogOpen$);
    const addingWorkspaceToOrganization = useObservableState(addingWorkspaceToOrganization$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const organizations = useObservableState(loggedInUserOrganizations$);
    const selectedOrganization = useObservableState(organizationSelectedForSharing$);
    const errors = useObservableState(addWorkspaceToOrganizationError$);

    const remappedOrganizations = organizations.map((o) => ({ name: o.name, value: o.uid }));

    const handleCreateNewOrganization = (): void => {
        addWorkspaceToOrganizationDialogOpen$.next(false);
        addWorkspaceToOrganizationError$.next('');
        workspaceBeingShared$.next(selectedWorkspaceUid ?? '');
        createOrganizationWizardDialogOpen$.next(true);
    };

    return (
        <AddWorkspaceToOrgDialog
            errors={errors}
            selectedOrganizationUid={selectedOrganization ?? remappedOrganizations[0]?.value ?? ''}
            onCreateNewOrganization={handleCreateNewOrganization}
            open={addWorkspaceToOrganizationDialogOpen}
            saving={addingWorkspaceToOrganization}
            organizations={remappedOrganizations}
            onCancel={() => cancelAddWorkspaceToOrganizationAction$.next()}
            onSelectOrganization={(uid) => {
                addWorkspaceToOrganizationAction$.next({
                    organizationUid: uid,
                    workspaceUid: selectedWorkspaceUid ?? '',
                });
            }}
        />
    );
};
