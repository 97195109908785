import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import { Button } from '../buttons/Button';
import {
    DialogActionsOverhauled,
    DialogConnectionNameInputOverhauled,
    DialogContentOverhauled,
    DialogContentTextOverhauled,
    DialogOrderedListOverhauled,
    DialogOverhauled,
    DialogSubtitleOverhauled,
    DialogTitleLargeOverhauled,
} from '../dialog/DialogOverhaul';
import { handleKeyUp } from '../../utils/handleKeyUp';

interface MondayUserOnboardingDialogProps {
    name: string;
    open?: boolean;
    saving?: boolean;
    onAuthorize: (name: string) => void;
}

export const MondayUserOnboardingDialog: React.FC<MondayUserOnboardingDialogProps> = ({
    name,
    open = false,
    saving = false,
    onAuthorize,
}) => {
    const [currentName, setCurrentName] = useState(name);

    useEffect(() => {
        setCurrentName(name);
    }, [open]);

    const canAuthorize = !!currentName && !saving;

    return (
        <DialogOverhauled
            open={open}
            onKeyUp={(event) =>
                handleKeyUp({ event, enterCondition: canAuthorize, enterFn: () => onAuthorize(currentName) })
            }
        >
            <DialogTitleLargeOverhauled title="Welcome to Advanced Automations" sx={{ mb: 0 }} />
            <DialogContentOverhauled>
                <DialogSubtitleOverhauled title="Powered by ScriptRunner Connect" />
                <Divider sx={{ mb: 2 }} />
                <DialogContentTextOverhauled sx={{ mb: 3 }}>
                    To set up the connector, follow the steps below:
                </DialogContentTextOverhauled>
                <DialogOrderedListOverhauled>
                    <li>Name your monday.com connector</li>
                    <DialogConnectionNameInputOverhauled
                        mb={3}
                        connectionType="monday.com"
                        value={currentName}
                        onChangeInputValue={(value) => setCurrentName(value)}
                    />
                    <li>Authorize by clicking the button below</li>
                </DialogOrderedListOverhauled>
            </DialogContentOverhauled>
            <DialogActionsOverhauled className="left">
                <Button busy={saving} disabled={!canAuthorize} onClick={() => onAuthorize(currentName)}>
                    Authorize
                </Button>
            </DialogActionsOverhauled>
        </DialogOverhauled>
    );
};
