import Dialog from '@mui/material/Dialog';
import { Button } from '../buttons/Button';
import { useTheme } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FullLengthDivider, ProfileUpdateContent, ProfileUpdateTitle } from './OnboardingComponents';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';

export interface OnboardingSuccessDialogProps {
    open: boolean;
    onClose: () => void;
    onViewProfile: () => void;
}

export const ProfileUpdateSuccessDialog: React.FC<OnboardingSuccessDialogProps> = ({
    open = false,
    onClose,
    onViewProfile,
}) => {
    const theme = useTheme();
    const items = [
        {
            icon: <ManageAccountsOutlinedIcon />,
            title: 'Updated profile account',
            description: 'Your personal account page will be updated with new information',
        },
        {
            icon: <EmailOutlinedIcon />,
            title: 'Manage email notifications',
            description: 'In your profile settings, you can manage basic email notifications',
        },
        {
            icon: <PsychologyOutlinedIcon />,
            title: 'Improving user experience',
            description:
                'By updating your account information, we can help provide you with relevant content and recommendations',
        },
    ];

    return (
        <Dialog open={open}>
            <ProfileUpdateTitle
                title="Congratulations! You are up to date"
                subtitle="Your profile has been successfully updated with the latest information"
                icon={<CheckCircleOutlinedIcon />}
                color={theme.palette.success.light}
            />
            <DialogContent sx={{ margin: theme.spacing(2, 0) }}>
                <ProfileUpdateContent title="What are the benefits?" items={items} />
            </DialogContent>
            <FullLengthDivider />
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
                <Button onClick={onViewProfile}>View Profile</Button>
            </DialogActions>
        </Dialog>
    );
};
