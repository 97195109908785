import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material';
import { tinyDialogStyles } from './dialogStyles';
import { Button } from '../buttons/Button';
import { handleKeyUp } from '../../utils/handleKeyUp';
import DialogContent from '@mui/material/DialogContent';

export interface ConfirmDialogProps {
    open?: boolean;
    details: ConfirmDialogDetails;
}

interface ConfirmDialogDetails {
    title?: string;
    message?: string;
    cancelLabel?: string;
    proceedLabel?: string;
    onCancel?: () => void;
    onClose?: () => void;
    onProceed?: () => void;
}

const StyledDialog = styled(Dialog)(() => tinyDialogStyles);

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open = false, details }) => {
    // use message if there is no title
    const confirmTitle = !details.title ? details.message : details.title;
    // if message is same as title use only title
    const confirmMsg = confirmTitle === details.message ? '' : details.message;

    return (
        <StyledDialog
            open={open}
            onClose={details.onClose}
            onKeyUp={(event) =>
                handleKeyUp({
                    event,
                    enterFn: () => details.onProceed?.(),
                    //Escape and Enter actions identical for "OK" dialogs.
                    escFn: () => (details.onClose ? details.onClose() : details.onProceed?.()),
                })
            }
        >
            <DialogTitle sx={{ mb: 2 }} variant="h6">
                {confirmTitle}
            </DialogTitle>
            <DialogContent>{confirmMsg}</DialogContent>
            <DialogActions>
                {details.onCancel && (
                    <Button variant="outlined" onClick={() => details.onCancel?.()}>
                        {details.cancelLabel ?? 'Cancel'}
                    </Button>
                )}
                <Button onClick={() => details.onProceed?.()}>{details.proceedLabel ?? 'OK'}</Button>
            </DialogActions>
        </StyledDialog>
    );
};
