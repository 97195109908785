import React from 'react';
import { ConnectionDialog } from '../ConnectionDialog';
import { ProductIcon } from '../../icons/ProductIcon';

export interface GoogleManageConnectionDialogProps {
    readonly onSave: (name: string) => void;
    readonly saving: boolean;
    readonly onAuthorize: (name: string) => void;
    readonly authorizeLoading: boolean;
    readonly open: boolean;
    readonly onCancel: () => void;
    readonly name: string;
    readonly authorized: boolean;
    readonly errors?: string;
    readonly connectionType?: string;
}

export const GoogleManageConnectionDialog: React.FC<GoogleManageConnectionDialogProps> = ({
    name,
    saving,
    onSave,
    authorizeLoading,
    onAuthorize,
    open,
    onCancel,
    authorized,
    errors,
    connectionType,
}) => {
    return (
        <ConnectionDialog
            authorized={authorized}
            name={name}
            onSave={onSave}
            onAuthorize={onAuthorize}
            saving={!!saving}
            authorizeLoading={!!authorizeLoading}
            icon={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <ProductIcon name={connectionType!} />
            }
            open={open}
            onCancel={onCancel}
            errors={errors}
            connectionType={connectionType}
        />
    );
};
