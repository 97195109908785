import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface GitlabEventListenerSetupDialogProps {
    eventTypeName: string;
    gitlabUrl?: string;
    loading?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const GitlabEventListenerSetupDialog: React.FC<GitlabEventListenerSetupDialogProps> = ({
    eventTypeName,
    gitlabUrl,
    loading = false,
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = APP.GITLAB.NAME;

    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}
            >
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Event Listener setup instructions for {appName}
                </DialogTitle>
                {loading ? (
                    <AbsoluteCenteredLoadingSpinner />
                ) : (
                    <>
                        <DialogContent>
                            <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                            <DialogContentText variant="subtitle2">
                                You need to setup a webhook in your {appName} group or project in order to listen to
                                events in ScriptRunner Connect.
                            </DialogContentText>
                            <DialogContentText component="ol">
                                <li>Copy the URL above.</li>
                                {gitlabUrl ? (
                                    <li>
                                        Visit your {appName}{' '}
                                        <Link target="_blank" href={gitlabUrl}>
                                            account
                                        </Link>{' '}
                                        page.
                                    </li>
                                ) : (
                                    <li>
                                        Open{' '}
                                        <Link target="_blank" href="https://www.gitlab.com">
                                            https://www.gitlab.com
                                        </Link>{' '}
                                        in your browser.
                                    </li>
                                )}
                                <li>Open the group or project you wish to setup the Event Listener for.</li>
                                <li>
                                    On the left hand menu, select <strong>Settings</strong> ={'>'}{' '}
                                    <strong>Webhooks</strong>.
                                </li>
                                <li>
                                    Paste the copied URL into the <strong>URL</strong> field.
                                </li>
                                <li>
                                    Select ONLY the following event to listen to: <strong>{eventTypeName}</strong> and
                                    click <strong>Add webhook</strong>.
                                </li>

                                <li>
                                    If successful, you should see your webhook appear on the{' '}
                                    <strong>Project hooks</strong> card on the bottom of the page.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Done</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};
