/* eslint-disable sonarjs/no-duplicate-string */
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ApiIcon from '@mui/icons-material/Api';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import CodeIcon from '@mui/icons-material/Code';
import { CenteredLoadingSpinner } from '../loading/CenteredLoadingSpinner';
import { StyledReflexContainer, StyledReflexElement } from '../reflex/ReflexComponents';
import { WizardInstructionsCard } from '../wizard/WizardInstructionsCard';
import {
    updateWizardStateAction$,
    updateWizardStepAction$,
    WizardState,
    WizardStep,
    Wizard,
    ResourceManagerOffsets,
} from '../../store/wizard';

export interface DefaultWorkspaceViewProps {
    blank?: boolean;
    loading?: boolean;
    resourceManagerOffsets?: ResourceManagerOffsets;
    wizard?: Wizard;
    workspaceUid?: string;
    onCreateNewApiConnection?: () => void;
    onCreateNewEventListener?: () => void;
    onCreateNewScript?: () => void;
}

const minColWidth = 60;
const addBtnSize = '20px';

const StyledArrow = styled('img')(({ theme }) => ({
    bottom: theme.spacing(1),
    height: 52,
    position: 'absolute',
    left: theme.spacing(-1.5),
}));

const StyledAddButton = styled(IconButton)(({ theme }) => ({
    height: addBtnSize + ' !important',
    width: addBtnSize + ' !important',
    '&:hover': {
        backgroundColor: 'initial',
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.dark,
        height: 16,
        width: 16,
    },
}));

const StyledContent = styled('div')(() => ({
    marginLeft: 228,
    whiteSpace: 'nowrap',
}));

const StyledImg = styled('img')(({ theme }) => ({
    marginBottom: theme.spacing(6),
    marginLeft: 168,
    width: 321,
}));

const StyledList = styled(List)(() => ({
    padding: 0,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: 0,
    '&:not(:last-child)': {
        marginBottom: theme.spacing(0.5),
    },
}));

const StyledListItemIcon = styled(ListItemIcon)(() => ({
    minWidth: 0 + ' !important',
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, 0, 1),
}));

const StyledMedia = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: 397,
    left: '50%',
    padding: theme.spacing(5, 0),
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 808,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    marginLeft: `calc(${addBtnSize} + ${theme.spacing(1)})`,
    marginBottom: theme.spacing(0.75),
}));

const StyledTitleContainer = styled('div')(() => ({
    position: 'relative',
}));

export const DefaultWorkspaceView: React.FC<DefaultWorkspaceViewProps> = ({
    blank,
    loading = false,
    resourceManagerOffsets,
    wizard,
    workspaceUid,
    onCreateNewApiConnection,
    onCreateNewEventListener,
    onCreateNewScript,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const workspaceMatchWizard = wizard?.workspaceDetails?.workspaceUid === workspaceUid;

    const getCardOffset = (resourceOffset?: number): number => {
        const containerOffset = resourceManagerOffsets?.container;
        const difference = resourceOffset && containerOffset && resourceOffset - containerOffset;
        return difference ? difference - 48 : 0;
    };

    let view: 'default' | 'addApiConnection' | 'addEventListener' | 'createScript';

    if (workspaceMatchWizard && (wizard?.state === WizardState.ACTIVE || wizard?.state === WizardState.CONFIRM)) {
        if (wizard?.step === WizardStep.ADD_API_CONNECTION) {
            view = 'addApiConnection';
        } else if (wizard?.step === WizardStep.ADD_EVENT_LISTENER) {
            view = 'addEventListener';
        } else if (wizard?.step === WizardStep.CREATE_SCRIPT) {
            view = 'createScript';
        } else {
            view = 'default';
        }
    } else {
        view = 'default';
    }

    const getComponentView = (): JSX.Element | undefined => {
        const defaultView = (
            <StyledMedia>
                <StyledImg src={process.env.PUBLIC_URL + '/blank-workspace-img.png'} alt="Blank workspace upper" />
                <StyledContent>
                    <StyledTitleContainer>
                        <StyledArrow src={process.env.PUBLIC_URL + '/curved-arrow.png'} alt="Curved arrow" />
                        <StyledTitle variant="subtitle1">
                            {blank ? 'Start' : 'Continue'} building your Workspace
                        </StyledTitle>
                    </StyledTitleContainer>
                    <StyledList>
                        <StyledListItem>
                            <StyledListItemIcon>
                                <StyledAddButton title="New API Connection" onClick={onCreateNewApiConnection}>
                                    <AddCircleIcon sx={{ opacity: '50%' }} />
                                </StyledAddButton>
                            </StyledListItemIcon>
                            <StyledListItemText>
                                Call APIs of your apps using <strong>API Connections</strong>
                            </StyledListItemText>
                        </StyledListItem>
                        <StyledListItem>
                            <StyledListItemIcon>
                                <StyledAddButton title="New Event Listener" onClick={onCreateNewEventListener}>
                                    <AddCircleIcon sx={{ opacity: '75%' }} />
                                </StyledAddButton>
                            </StyledListItemIcon>
                            <StyledListItemText>
                                React to events triggered from your apps using <strong>Event Listeners</strong>
                            </StyledListItemText>
                        </StyledListItem>
                        <StyledListItem>
                            <StyledListItemIcon>
                                <StyledAddButton title="New Script" onClick={onCreateNewScript}>
                                    <AddCircleIcon />
                                </StyledAddButton>
                            </StyledListItemIcon>
                            <StyledListItemText>
                                Write your integration logic using <strong>Scripts</strong>
                            </StyledListItemText>
                        </StyledListItem>
                    </StyledList>
                </StyledContent>
            </StyledMedia>
        );

        switch (view) {
            case 'default':
                return defaultView;
            case 'addApiConnection':
                return (
                    <WizardInstructionsCard
                        offsetTop={getCardOffset(resourceManagerOffsets?.apiConnections)}
                        title="Add API Connections"
                        icon={<ApiIcon />}
                        instructions={
                            <span>
                                API Connections allow you to make <strong>outbound API calls</strong>.
                            </span>
                        }
                        tip={
                            <span>
                                While you don't have to begin with API Connections {'('}for example you could start by
                                adding <strong>Event Listeners</strong>
                                {')'}, it's as good a place to start as any.
                            </span>
                        }
                        onClose={() => updateWizardStateAction$.next(WizardState.CONFIRM)}
                        onNext={() => updateWizardStepAction$.next(WizardStep.ADD_EVENT_LISTENER)}
                    />
                );
            case 'addEventListener':
                return (
                    <WizardInstructionsCard
                        offsetTop={getCardOffset(resourceManagerOffsets?.eventListeners)}
                        title="Setup Event Listeners"
                        icon={<BoltOutlinedIcon />}
                        instructions={
                            <span>
                                Event Listeners allow you to listen to external app events and define{' '}
                                <strong>webhooks</strong> to trigger your scripts.
                            </span>
                        }
                        tip="In the future, feel free to define Event Listeners first if that approach suits you better. ScriptRunner Connect is flexible!"
                        onClose={() => updateWizardStateAction$.next(WizardState.CONFIRM)}
                        onNext={() => updateWizardStepAction$.next(WizardStep.CREATE_SCRIPT)}
                        onPrevious={() => updateWizardStepAction$.next(WizardStep.ADD_API_CONNECTION)}
                    />
                );
            case 'createScript':
                return (
                    <WizardInstructionsCard
                        offsetTop={getCardOffset(resourceManagerOffsets?.scripts)}
                        title="Create bespoke solutions using Scripts"
                        icon={<CodeIcon />}
                        instructions="Write your business logic in a dedicated Script file to automate tasks in your workspace. If you configured an Event Listener, a Script
                        might have already been generated for you, but try creating another one!"
                        tip={
                            <span>
                                Scripts allow you to write your business logic in either <strong>JavaScript</strong> or{' '}
                                <strong>TypeScript</strong> scripting languages.
                            </span>
                        }
                        onClose={() => updateWizardStateAction$.next(WizardState.CONFIRM)}
                        onNext={() => updateWizardStepAction$.next(WizardStep.SUCCESS)}
                        onPrevious={() => updateWizardStepAction$.next(WizardStep.ADD_EVENT_LISTENER)}
                    />
                );
            default:
                defaultView;
        }
    };

    const componentView = getComponentView();

    return (
        <StyledReflexContainer orientation="vertical" data-test-id="blank-workspace-view">
            <StyledReflexElement flex={8} minSize={minColWidth}>
                {loading ? <CenteredLoadingSpinner /> : componentView}
            </StyledReflexElement>
        </StyledReflexContainer>
    );
};
