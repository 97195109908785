import { Auth0UserDetails } from '../hooks/auth';
import { configTopic$, stitchSession$ } from '../store/config';
import { getFetchOptions } from './fetch';

export const getAuth0UserDetails = async (): Promise<Auth0UserDetails> => {
    const getUserUrl = configTopic$.value.trigger?.getUserFromAuth0Url;
    if (!getUserUrl) {
        throw new Error('No get user url configured in meta');
    }
    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' });
    const response = await fetch(getUserUrl, fetchOptions);
    if (!response.ok) {
        throw new Error('Could not get user from Auth0');
    }
    return (await response.json()) as Auth0UserDetails;
};

export const updateUserInAuth0 = async (enableMfa: boolean): Promise<void> => {
    const updateUserUrl = configTopic$.value.trigger?.updateUserInAuth0Url;
    if (!updateUserUrl) {
        throw new Error('No update user url configured in meta');
    }
    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' }, { mfaEnabled: enableMfa });
    const response = await fetch(updateUserUrl, fetchOptions);
    if (!response.ok) {
        throw new Error('Could not update MFA preference');
    }
};
