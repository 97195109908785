import { useObservableState } from 'observable-hooks';
import {
    ApiHandlerImportDialog,
    ApiHandlerImportDialogProps,
} from '../../components/workspace/api-handler-import-dialog';
import { selectedWorkspaceResources$ } from '../../store/workspace';
import {
    apiHandlerImportDialogErrors$,
    apiHandlerImportDialogOpen$,
    closeApiHandlerImportDialogAction$,
    importApiHandlerDialogImportAction$,
} from '../../store/workspace/api-handler';

type ApiHandlers = ApiHandlerImportDialogProps['apiHandlers'];

export const ApiHandlerImportDialogContainer: React.FC = () => {
    const open = useObservableState(apiHandlerImportDialogOpen$);
    const errors = useObservableState(apiHandlerImportDialogErrors$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);

    const apiHandlers = workspaceResources.apiHandlers.filter(
        (ah) => ah.remnantEnvironments.length === 0 && !!ah.path && !!ah.connectionName
    ) as ApiHandlers;

    return (
        <ApiHandlerImportDialog
            open={open}
            errors={errors}
            apiHandlers={apiHandlers}
            loading={false}
            saving={false}
            onCancel={() => closeApiHandlerImportDialogAction$.next()}
            onImport={(uid, importName) => importApiHandlerDialogImportAction$.next({ uid, importName })}
        />
    );
};
