import { useObservableState } from 'observable-hooks';
import { BitbucketOnPremiseEventListenerSetupDialog } from '../../../components/connections/bitbucket-on-premise/EventListenerSetupDialog';
import { configTopic$ } from '../../../store/config';
import {
    bitbucketOnPremiseEventListenerSetupDialogCloseAction$,
    bitbucketOnPremiseEventListenerSetupDialogDetails$,
    bitbucketOnPremiseEventListenerSetupDialogLoading$,
    bitbucketOnPremiseEventListenerSetupDialogOpen$,
} from '../../../store/connection/bitbucket-on-premise/setup-event-listener';

export const BitbucketOnPremiseEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(bitbucketOnPremiseEventListenerSetupDialogLoading$);
    const open = useObservableState(bitbucketOnPremiseEventListenerSetupDialogOpen$);
    const details = useObservableState(bitbucketOnPremiseEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <BitbucketOnPremiseEventListenerSetupDialog
            loading={loading}
            open={open}
            eventTypeName={details?.eventTypeName ?? ''}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            setupBaseUrl={details?.setupBaseUrl}
            onClose={() => bitbucketOnPremiseEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
