import { useObservableState } from 'observable-hooks';
import { BitbucketOnPremiseManageConnectionDialog } from '../../../components/connections/bitbucket-on-premise/ManageConnectionDialog';
import {
    bitbucketOnPremiseConnectionSaving$,
    bitbucketOnPremiseManageConnectionAuthorizeLoading$,
    bitbucketOnPremiseManageConnectionCloseDialogAction$,
    bitbucketOnPremiseManageConnectionDetails$,
    bitbucketOnPremiseManageConnectionDialogErrors$,
    bitbucketOnPremiseManageConnectionDialogOpen$,
    bitbucketOnPremiseManageConnectionInitiateSetupAction$,
    bitbucketOnPremiseSaveConnectionAction$,
} from '../../../store/connection/bitbucket-on-premise/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const BitbucketOnPremiseManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(bitbucketOnPremiseManageConnectionDialogOpen$);
    const saving = useObservableState(bitbucketOnPremiseConnectionSaving$);
    const authorizeLoading = useObservableState(bitbucketOnPremiseManageConnectionAuthorizeLoading$);
    const details = useObservableState(bitbucketOnPremiseManageConnectionDetails$);
    const errors = useObservableState(bitbucketOnPremiseManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <BitbucketOnPremiseManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => bitbucketOnPremiseManageConnectionCloseDialogAction$.next()}
            onSave={(name) => bitbucketOnPremiseSaveConnectionAction$.next(name)}
            onAuthorize={(name) => bitbucketOnPremiseManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
