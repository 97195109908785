import Avatar from '@mui/material/Avatar';
import { Theme, styled } from '@mui/material';
import { AppMainProps } from '../layout';

interface AvatarContainerProps {
    credentials: AppMainProps['credentials'];
    size?: 'regular' | 'large' | 'small';
}

interface OrgAvatarContainerProps {
    name: string;
    style?: 'round' | 'square';
}

interface AvatarColor {
    backgroundColor?: string;
    fontColor?: string;
}

const getColor = (theme: Theme, name: string): AvatarColor => {
    const backgroundColors = [
        theme.palette.primary.main,
        theme.palette.primary.light,
        theme.palette.primary.dark,
        theme.palette.secondary.main,
        theme.palette.secondary.light,
        theme.palette.secondary.dark,
        theme.palette.setup.dark,
        theme.palette.setup.light,
        theme.palette.setup.main,
        theme.palette.info.main,
        theme.palette.info.light,
        theme.palette.info.dark,
    ];

    const fontColors = [
        theme.palette.primary.contrastText,
        theme.palette.primary.contrastText,
        theme.palette.primary.contrastText,
        theme.palette.secondary.contrastText,
        theme.palette.secondary.contrastText,
        theme.palette.secondary.contrastText,
        theme.palette.setup.contrastText,
        theme.palette.setup.contrastText,
        theme.palette.setup.contrastText,
        theme.palette.info.contrastText,
        theme.palette.info.contrastText,
        theme.palette.info.contrastText,
    ];

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    return {
        backgroundColor: backgroundColors[Math.abs(hash) % backgroundColors.length],
        fontColor: fontColors[Math.abs(hash) % backgroundColors.length],
    };
};

const StyledAvatar = styled(Avatar)<{ name: string }>(({ theme, name }) => {
    const color = getColor(theme, name);
    return {
        backgroundClip: 'padding-box',
        backgroundColor: color.backgroundColor,
        border: `1px solid ${theme.palette.background.default}`,
        fontSize: theme.typography.subtitle2.fontSize,
        color: color.fontColor,
    };
});

export const AvatarContainer: React.FC<AvatarContainerProps> = ({ credentials, size = 'regular' }) => {
    const { firstName, lastName, email } = credentials;

    const handleInitials = (): string => {
        if (firstName && lastName) {
            return `${firstName.substring(0, 1).toUpperCase()}${lastName.substring(0, 1).toUpperCase()}`;
        } else {
            return email?.substring(0, 1).toUpperCase();
        }
    };
    const initials = handleInitials();

    return (
        <StyledAvatar
            sx={
                size === 'large'
                    ? { width: 80, height: 80, fontSize: '1.875rem' }
                    : size === 'small'
                    ? { width: 30, height: 30 }
                    : {}
            }
            name={initials}
        >
            {initials}
        </StyledAvatar>
    );
};

const StyledOrgAvatar = styled(StyledAvatar)(({ theme }) => ({
    backgroundClip: 'padding-box',
    height: 52,
    marginRight: theme.spacing(1),
    width: 52,
}));

export const OrgAvatarContainer: React.FC<OrgAvatarContainerProps> = ({ name, style = 'round' }) => {
    return (
        <StyledOrgAvatar sx={style === 'square' ? { borderRadius: '12px' } : {}} name={name}>
            {name[0]?.toUpperCase() ?? ''}
        </StyledOrgAvatar>
    );
};
