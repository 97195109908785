import { BehaviorSubject, map, Subject } from 'rxjs';
import { ignoreNextWorkspaceExit$, selectedEnvironmentUid$, selectedWorkspaceUid$ } from '.';
import { EnvironmentDeploymentMapping } from '../../components/workspace/manage-environment-deployment-targets-dialog';
import { getWorkspaceDeployments, saveEnvironmentDeploymentPointers } from '../../data/deployment';
import { createWorkspaceEnvironment, switchWorkspaceEnvironment } from '../../data/environment';
import { InformativeError } from '../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { selectedWorkspaceDeployments$ } from './deployment';
import {
    loadEnvironmentVariables,
    loadWorkspaceEnvironments,
    loadWorkspaceEnvironmentsWhenEventIsEmitted,
    loadWorkspaceResourcesWhenEventIsEmitted,
} from './utils';
import { savedScriptDetails$, unsavedScriptDetails$ } from './script';
import { savedReadmeFileDetails$, unsavedReadmeFileDetails$ } from './readme';
import { environmentVariablesHaveUnsavedChanges$, unsavedEnvironmentVariables$ } from './environment-variable';
import { promptQuestion } from '../confirm';

export const openCreateNewWorkspaceEnvironmentDialogAction$ = monitor(
    'openCreateNewWorkspaceEnvironmentDialogAction$',
    new Subject<void>()
);
export const closeCreateNewWorkspaceEnvironmentDialogAction$ = monitor(
    'closeCreateNewWorkspaceEnvironmentDialogAction$',
    new Subject<void>()
);
export const createNewWorkspaceEnvironmentAction$ = monitor('createNewEnvironmentAction$', new Subject<string>());
export const newWorkspaceEnvironmentCreatedAction$ = monitor(
    'newWorkspaceEnvironmentCreatedAction$',
    new Subject<string>()
);
export const switchWorkspaceEnvironmentAction$ = monitor('switchWorkspaceEnvironmentAction$', new Subject<string>());
export const navigateToDifferentWorkspaceEnvironmentAction$ = monitor(
    'navigateToDifferentWorkspaceEnvironmentAction$',
    new Subject<string>()
);
export const openManageEnvironmentDeploymentTargetsDialogAction$ = monitor(
    'openManageEnvironmentDeploymentTargetsDialogAction$',
    new Subject<void>()
);
export const closeManageEnvironmentDeploymentTargetsDialogAction$ = monitor(
    'closeManageEnvironmentDeploymentTargetsDialogAction$',
    new Subject<void>()
);
export const saveEnvironmentDeploymentTargetsAction$ = monitor(
    'saveEnvironmentDeploymentTargetsAction$',
    new Subject<EnvironmentDeploymentMapping[]>()
);
export const environmentDeploymentTargetsSavedAction$ = monitor(
    'environmentDeploymentTargetsSavedAction$',
    new Subject<void>()
);

export const createNewWorkspaceEnvironmentDialogOpen$ = monitor(
    'createNewWorkspaceEnvironmentDialogOpen$',
    new BehaviorSubject(false)
);
export const createNewWorkspaceEnvironmentDialogErrors$ = monitor(
    'createNewWorkspaceEnvironmentDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const creatingNewWorkspaceEnvironment$ = monitor('creatingNewWorkspaceEnvironment$', new BehaviorSubject(false));
export const switchingWorkspaceEnvironment$ = monitor('switchingWorkspaceEnvironment$', new BehaviorSubject(false));
export const manageEnvironmentDeploymentTargetsDialogOpen$ = monitor(
    'manageEnvironmentDeploymentTargetsDialogOpen$',
    new BehaviorSubject(false)
);
export const manageEnvironmentDeploymentTargetsDialogLoading$ = monitor(
    'manageEnvironmentDeploymentTargetsDialogLoading$',
    new BehaviorSubject(false)
);
export const manageEnvironmentDeploymentTargetsDialogErrors$ = monitor(
    'manageEnvironmentDeploymentTargetsDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const savingEnvironmentDeploymentTargets$ = monitor(
    'savingEnvironmentDeploymentTargets$',
    new BehaviorSubject(false)
);

openCreateNewWorkspaceEnvironmentDialogAction$.subscribe(() => createNewWorkspaceEnvironmentDialogOpen$.next(true));
closeCreateNewWorkspaceEnvironmentDialogAction$.subscribe(() => createNewWorkspaceEnvironmentDialogOpen$.next(false));

openManageEnvironmentDeploymentTargetsDialogAction$
    .pipe(
        map(async () => {
            manageEnvironmentDeploymentTargetsDialogOpen$.next(true);
            manageEnvironmentDeploymentTargetsDialogLoading$.next(true);
            manageEnvironmentDeploymentTargetsDialogErrors$.next(undefined);

            try {
                const workspaceDeployments = await getWorkspaceDeployments(selectedWorkspaceUid$.value ?? '');
                selectedWorkspaceDeployments$.next(workspaceDeployments);
            } catch (e) {
                console.error('Failed to load deployments', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message:
                        'Failed to load Deployments, please try again, if the issue persists please contact support',
                });
                manageEnvironmentDeploymentTargetsDialogOpen$.next(false);
            }

            manageEnvironmentDeploymentTargetsDialogLoading$.next(false);
        })
    )
    .subscribe();

closeManageEnvironmentDeploymentTargetsDialogAction$.subscribe(() =>
    manageEnvironmentDeploymentTargetsDialogOpen$.next(false)
);

createNewWorkspaceEnvironmentAction$
    .pipe(
        map(async (name) => {
            creatingNewWorkspaceEnvironment$.next(true);
            createNewWorkspaceEnvironmentDialogErrors$.next(undefined);

            try {
                const { uid } = await createWorkspaceEnvironment(selectedWorkspaceUid$.value ?? '', name);

                closeCreateNewWorkspaceEnvironmentDialogAction$.next();
                newWorkspaceEnvironmentCreatedAction$.next(uid);

                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Environment created.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    createNewWorkspaceEnvironmentDialogErrors$.next(e.message);
                } else {
                    console.error('Error while creating new Environment', e);
                    createNewWorkspaceEnvironmentDialogErrors$.next(
                        'Failed to create new Environment, please try again, if the issue persists please contact support.'
                    );
                }
            }

            creatingNewWorkspaceEnvironment$.next(false);
        })
    )
    .subscribe();

newWorkspaceEnvironmentCreatedAction$.subscribe((uid) => switchWorkspaceEnvironmentAction$.next(uid));

switchWorkspaceEnvironmentAction$
    .pipe(
        map(async (uid) => {
            const currentEnvironmentUid = selectedEnvironmentUid$.value;

            const initiateEnvironmentSwitch = async (
                ignoreEditModeWarning?: boolean,
                ignoreUnsavedChangesWarning?: boolean
            ): Promise<void> => {
                switchingWorkspaceEnvironment$.next(true);
                if (!ignoreEditModeWarning && (unsavedEnvironmentVariables$.value ?? []).some((v) => v.editMode)) {
                    promptQuestion({
                        message:
                            'One or more environment variables are still being edited. Are you sure you want to switch environment and potentially lose your changes?',
                        onProceed: async () => await initiateEnvironmentSwitch(true),
                        onCancel: () => {
                            selectedEnvironmentUid$.next(undefined);
                            setTimeout(() => selectedEnvironmentUid$.next(currentEnvironmentUid), 100);
                            loadWorkspaceEnvironments(selectedWorkspaceUid$.value ?? '');
                        },
                    });
                } else if (!ignoreUnsavedChangesWarning && environmentVariablesHaveUnsavedChanges$.value) {
                    promptQuestion({
                        message:
                            'Environment variables have unsaved changes. Are you sure you want to switch environment and lose your changes?',
                        onProceed: async () => await initiateEnvironmentSwitch(true, true),
                        // eslint-disable-next-line sonarjs/no-identical-functions
                        onCancel: () => {
                            selectedEnvironmentUid$.next(undefined);
                            setTimeout(() => selectedEnvironmentUid$.next(currentEnvironmentUid), 100);
                            loadWorkspaceEnvironments(selectedWorkspaceUid$.value ?? '');
                        },
                    });
                } else {
                    try {
                        await switchWorkspaceEnvironment(selectedWorkspaceUid$.value ?? '', uid);

                        ignoreNextWorkspaceExit$.next(true);
                        navigateToDifferentWorkspaceEnvironmentAction$.next(uid);

                        loadEnvironmentVariables(selectedWorkspaceUid$.value ?? '', uid);

                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Environment switched.',
                        });
                    } catch (e) {
                        console.error('Failed to switch environment', e);
                        publishLocalFeedbackEventAction$.next({
                            level: 'ERROR',
                            message:
                                'Failed to switch Environment, please try again, if the issue persists please contact support',
                        });

                        selectedEnvironmentUid$.next(undefined);
                        setTimeout(() => selectedEnvironmentUid$.next(currentEnvironmentUid), 100);
                        loadWorkspaceEnvironments(selectedWorkspaceUid$.value ?? '');
                    }
                }

                switchingWorkspaceEnvironment$.next(false);
            };

            await initiateEnvironmentSwitch();
        })
    )
    .subscribe();

saveEnvironmentDeploymentTargetsAction$
    .pipe(
        map(async (event) => {
            savingEnvironmentDeploymentTargets$.next(true);
            manageEnvironmentDeploymentTargetsDialogErrors$.next(undefined);

            try {
                await saveEnvironmentDeploymentPointers(selectedWorkspaceUid$.value ?? '', event);

                environmentDeploymentTargetsSavedAction$.next();

                let currentSavedScriptDetails = Object.entries(savedScriptDetails$.value ?? {});
                let currentUnsavedScriptDetails = Object.entries(unsavedScriptDetails$.value ?? {});
                let currentSavedReadmeFileDetails = Object.entries(savedReadmeFileDetails$.value ?? {});
                let currentUnsavedReadmeFileDetails = Object.entries(unsavedReadmeFileDetails$.value ?? {});

                for (const mapping of event) {
                    currentSavedScriptDetails = currentSavedScriptDetails.filter(
                        ([scriptKey]) => !scriptKey.endsWith(`_${mapping.environmentUid}`)
                    );
                    currentUnsavedScriptDetails = currentUnsavedScriptDetails.filter(
                        ([scriptKey]) => !scriptKey.endsWith(`_${mapping.environmentUid}`)
                    );
                    currentSavedReadmeFileDetails = currentSavedReadmeFileDetails.filter(
                        ([readmeKey]) => !readmeKey.endsWith(`_${mapping.environmentUid}`)
                    );
                    currentUnsavedReadmeFileDetails = currentUnsavedReadmeFileDetails.filter(
                        ([readmeKey]) => !readmeKey.endsWith(`_${mapping.environmentUid}`)
                    );
                }

                savedScriptDetails$.next(Object.fromEntries(currentSavedScriptDetails));
                unsavedScriptDetails$.next(Object.fromEntries(currentUnsavedScriptDetails));
                savedReadmeFileDetails$.next(Object.fromEntries(currentSavedReadmeFileDetails));
                unsavedReadmeFileDetails$.next(Object.fromEntries(currentUnsavedReadmeFileDetails));

                closeManageEnvironmentDeploymentTargetsDialogAction$.next();
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Environment Deployment targets saved.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    manageEnvironmentDeploymentTargetsDialogErrors$.next(e.message);
                } else {
                    console.error('Error while saving Environment Deployment targets', e);
                    manageEnvironmentDeploymentTargetsDialogErrors$.next(
                        'Failed to create saving Environment Deployment targets, please try again, if the issue persists please contact support.'
                    );
                }
            }

            savingEnvironmentDeploymentTargets$.next(false);
        })
    )
    .subscribe();

loadWorkspaceEnvironmentsWhenEventIsEmitted(environmentDeploymentTargetsSavedAction$);
loadWorkspaceResourcesWhenEventIsEmitted(environmentDeploymentTargetsSavedAction$);
