import { styled } from '@mui/material';

interface IconCircleProps {
    background?: string;
    color?: string;
    icon: JSX.Element;
}

const StyledCircle = styled('div')<{ background?: string; color?: string }>(({ theme, background, color }) => ({
    ...theme.typography.radiusCircle,
    ...theme.typography.flexAlignCenter,
    backgroundColor: background || theme.palette.background.default,
    border: '1px solid ' + theme.palette.action.selected,
    flexShrink: 0,
    height: 38,
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    width: 38,
    '& .MuiSvgIcon-root': {
        color: color || theme.palette.secondary.main,
        height: 22,
        placeSelf: 'center',
        width: 22,
    },
}));

export const IconCircle: React.FC<IconCircleProps> = ({ background, color, icon }) => {
    return (
        <StyledCircle background={background} color={color}>
            {icon}
        </StyledCircle>
    );
};
