import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { Alert } from '../../alert/Alert';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface AzureDevopsEventListenerSetupDialogProps {
    errors?: string;
    loading?: boolean;
    saving?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    workspaceLocked?: boolean;
    onClose: () => void;
}

export const AzureDevopsEventListenerSetupDialog: React.FC<AzureDevopsEventListenerSetupDialogProps> = ({
    errors,
    loading = false,
    open = false,
    saving = false,
    webhookBaseUrl,
    webhookUrlId,
    workspaceLocked = false,
    onClose,
}) => {
    const appName = APP.AZURE_DEVOPS.NAME;

    const canSave = !loading && !saving;

    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyUp={(event) => handleKeyUp({ event, enterCondition: canSave, escFn: onClose })}
            >
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Event Listener setup instructions for {appName}
                </DialogTitle>
                {loading ? (
                    <AbsoluteCenteredLoadingSpinner />
                ) : (
                    <>
                        {workspaceLocked && (
                            <Alert
                                severity="warning"
                                alertTitle="Warning"
                                text="The workspace is currently locked and the token field cannot be edited"
                                sx={{ marginBottom: 2 }}
                            />
                        )}
                        {errors && <Alert severity="error" alertTitle="Error" text={errors} sx={{ marginBottom: 2 }} />}
                        <DialogContent>
                            <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                            <DialogContentText variant="subtitle2">
                                You need to setup an outgoing webhook in your {appName} project in order to listen to
                                events in ScriptRunner Connect.
                            </DialogContentText>
                            <DialogContentText component="ol">
                                <li>Copy the URL above.</li>
                                <li>
                                    Open <strong>Azure Devops</strong> and navigate to the organisation you want to
                                    setup the event listener for.
                                </li>
                                <li>
                                    On the bottom left, click on <strong>Organisation Settings</strong>.
                                </li>
                                <li>
                                    Click on <strong>projects</strong> and click on the project you wish to setup an
                                    event listener for.
                                </li>
                                <li>
                                    Under General, click on the <strong>service hooks</strong> menu item.
                                </li>
                                <li>
                                    Click the <strong>+</strong> icon in the top bar.
                                </li>
                                <li>
                                    From the services menu, select <strong>Web Hooks</strong> and then next.
                                </li>
                                <li>Select the conditions on which the webhook will trigger, and then next.</li>
                                <li>
                                    Paste the URL from this page into the <strong>URL</strong> field, and then finish.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Done</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};
