import { useObservableState } from 'observable-hooks';
import { MondayUserOnboardingDialog } from '../../components/monday/MondayUserOnboardingDialog';
import {
    mondayUserAuthorizeConnectionAction$,
    mondayUserOnboardingDetails$,
    mondayUserOnboardingDialogOpen$,
    mondayUserOnboardingDialogSaving$,
} from '../../store/monday';

export const MondayUserOnboardingDialogContainer: React.FC = () => {
    const open = useObservableState(mondayUserOnboardingDialogOpen$);
    const saving = useObservableState(mondayUserOnboardingDialogSaving$);
    const details = useObservableState(mondayUserOnboardingDetails$);

    return (
        <MondayUserOnboardingDialog
            name={details?.name ?? ''}
            open={open}
            saving={saving}
            onAuthorize={(name) => mondayUserAuthorizeConnectionAction$.next(name)}
        />
    );
};
