import { useObservableState } from 'observable-hooks';
import { OnboardingWizard } from '../components/onboarding/OnboardingWizard';
import { apps$ } from '../store/apps';
import {
    onboardingWizardOpen$,
    onboardingWizardLoading$,
    scriptingFamiliarityOptions$,
    onboardingWizardSaving$,
    saveOnboardingResponsesAction$,
} from '../store/onboarding';
import { industryRoles$ } from '../store/roles';
import { loggedInUserDetails$ } from '../store/user';

export const OnboardingWizardContainer: React.FC = () => {
    const onboardingWizardOpen = useObservableState(onboardingWizardOpen$);
    const loading = useObservableState(onboardingWizardLoading$);
    const saving = useObservableState(onboardingWizardSaving$);
    const apps = useObservableState(apps$);
    const roles = useObservableState(industryRoles$);
    const scriptingFamiliarityOptions = useObservableState(scriptingFamiliarityOptions$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const goals = [];

    if (loggedInUserDetails?.integrationsPreference) {
        goals.push('Automation');
    }
    if (loggedInUserDetails?.migrationPreference) {
        goals.push('Migration');
    }
    if (loggedInUserDetails?.syncPreference) {
        goals.push('Synchronisation');
    }

    return (
        <OnboardingWizard
            apps={apps.map(({ uid, name }) => ({
                uid,
                name,
            }))}
            loading={loading}
            open={onboardingWizardOpen}
            roles={roles}
            saving={saving}
            scriptingFamiliarityOptions={scriptingFamiliarityOptions ?? []}
            onFinish={(event) => saveOnboardingResponsesAction$.next(event)}
            userRoleUid={!loggedInUserDetails?.customRole ? loggedInUserDetails?.roleUid ?? '' : ''}
            userCustomRole={loggedInUserDetails?.customRole ?? ''}
            userSelectedApps={loggedInUserDetails?.userAppPreference ?? []}
            userSelectedGoals={goals}
        />
    );
};
