import { LoaderFn } from 'react-location';
import { acceptOrganizationMemberInvite } from '../data/user';
import { LocationGenerics } from '../router';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { loggedInUserDetails$ } from '../store/user';
import { InformativeError, PermissionError } from '../utils/repository';
import { loadErrorPage } from '../store/error';
import { promptMessage } from '../store/confirm';
import { createConnection, getLoggedInUserConnections } from '../data/connection';
import { getApps } from '../data/apps';
import { APP } from '@avst-stitch/repository-lib/constants';
import { mondayUserOnboardingDetails$ } from '../store/monday';
import { MONDAY_ORIGIN } from '../utils/constants';

export const appLoader: LoaderFn<LocationGenerics> = async () => {
    const mondayInstance = new URLSearchParams(window.location.search).get('mondayInstanceUrl');
    if (loggedInUserDetails$.value?.userOrigin === MONDAY_ORIGIN && mondayInstance) {
        try {
            await ensureMondayUserHasConnection(mondayInstance);
        } catch (e) {
            loadErrorPage({
                error: e,
                genericMessage: 'Failed to load monday.com connectors.',
            });
        }
    }

    try {
        const inviteId = new URLSearchParams(window.location.search).get('inviteId');
        if (inviteId && loggedInUserDetails$.value?.termsAccepted) {
            const { organizationName } = await acceptOrganizationMemberInvite(inviteId);
            promptMessage(`Successfully joined team: ${organizationName}`);
        }
    } catch (e) {
        const errorMessage = e instanceof InformativeError ? `. ${e.message}` : '';

        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message:
                `Could not join team ${errorMessage}. Click on the invite link again ` +
                'and if the issue persists please contact support.',
            toastOptions: {
                autoClose: false,
            },
        });

        throw e;
    }

    return {};
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const ensureMondayUserHasConnection = async (instance: string): Promise<void> => {
    const instanceUrl = `https://${instance}.monday.com`;
    const [apps, connections] = await Promise.all([getApps(), getLoggedInUserConnections()]);

    const mondayApp = apps.find((a) => a.name === APP.MONDAY.NAME);
    const mondayInstanceConnection = connections.find(
        (con) => con.connectionType.name === mondayApp?.name && con.instanceUrl === instanceUrl
    );

    if (mondayApp) {
        if (!mondayInstanceConnection) {
            const newConnectionName = `${instance} Monday Connector`;
            try {
                const createdConnection = await createConnection(
                    mondayApp.connectionType.uid,
                    newConnectionName,
                    mondayApp.connectionType.apiHandlerTypes[0]?.uid ?? '',
                    mondayApp.connectionType.eventListenerTypes[0]?.uid ?? ''
                );
                mondayUserOnboardingDetails$.next({
                    connectionType: mondayApp.connectionType,
                    instanceUrl,
                    name: newConnectionName,
                    uid: createdConnection.uid,
                });
            } catch (e) {
                let message: string;
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    message = 'Failed to create monday.com connector. ' + e.message;
                } else {
                    message = 'Failed to create monday.com connector. Please contact support.';
                }

                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message,
                });
                console.error('Failed to create monday.com connector', e);
            }
        }
    } else {
        throw new Error('monday.com app type not found.');
    }
};
