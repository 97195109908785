import { useObservableState } from 'observable-hooks';
import { MondayManageConnectionDialog } from '../../../components/connections/monday/ManageConnectionDialog';
import {
    mondayConnectionSaving$,
    mondayManageConnectionAuthorizeLoading$,
    mondayManageConnectionCloseDialogAction$,
    mondayManageConnectionDetails$,
    mondayManageConnectionDialogErrors$,
    mondayManageConnectionDialogOpen$,
    mondayManageConnectionInitiateSetupAction$,
    mondaySaveConnectionAction$,
} from '../../../store/connection/monday/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const MondayManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(mondayManageConnectionDialogOpen$);
    const saving = useObservableState(mondayConnectionSaving$);
    const authorizeLoading = useObservableState(mondayManageConnectionAuthorizeLoading$);
    const details = useObservableState(mondayManageConnectionDetails$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );
    const errors = useObservableState(mondayManageConnectionDialogErrors$);

    return (
        <MondayManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => mondayManageConnectionCloseDialogAction$.next()}
            onSave={(name) => mondaySaveConnectionAction$.next(name)}
            onAuthorize={(name) => mondayManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
