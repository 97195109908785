import { useObservableState } from 'observable-hooks';
import { MicrosoftManageConnectionDialog } from '../../../components/connections/microsoft/ManageConnectionDialog';
import {
    microsoftConnectionSaving$,
    microsoftManageConnectionAuthorizeLoading$,
    microsoftManageConnectionCloseDialogAction$,
    microsoftManageConnectionDetails$,
    microsoftManageConnectionDialogErrors$,
    microsoftManageConnectionDialogOpen$,
    microsoftManageConnectionInitiateSetupAction$,
    microsoftSaveConnectionAction$,
} from '../../../store/connection/microsoft/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const MicrosoftManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(microsoftManageConnectionDialogOpen$);
    const saving = useObservableState(microsoftConnectionSaving$);
    const authorizeLoading = useObservableState(microsoftManageConnectionAuthorizeLoading$);
    const details = useObservableState(microsoftManageConnectionDetails$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );
    const errors = useObservableState(microsoftManageConnectionDialogErrors$);

    return (
        <MicrosoftManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            tenantId={details?.tenantId}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            onCancel={() => microsoftManageConnectionCloseDialogAction$.next()}
            onSave={(name) => microsoftSaveConnectionAction$.next(name)}
            onAuthorize={(name) => microsoftManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
