import { useObservableState } from 'observable-hooks';
import { SlackManageConnectionDialog } from '../../../components/connections/slack/ManageConnectionDialog';
import {
    slackConnectionSaving$,
    slackManageConnectionAuthorizeLoading$,
    slackManageConnectionCloseDialogAction$,
    slackManageConnectionDetails$,
    slackManageConnectionDialogErrors$,
    slackManageConnectionDialogOpen$,
    slackManageConnectionInitiateSetupAction$,
    slackSaveConnectionAction$,
} from '../../../store/connection/slack/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const SlackManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(slackManageConnectionDialogOpen$);
    const saving = useObservableState(slackConnectionSaving$);
    const authorizeLoading = useObservableState(slackManageConnectionAuthorizeLoading$);
    const details = useObservableState(slackManageConnectionDetails$);
    const errors = useObservableState(slackManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <SlackManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => slackManageConnectionCloseDialogAction$.next()}
            onSave={(name) => slackSaveConnectionAction$.next(name)}
            onAuthorize={(name) => slackManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
