import {
    Box,
    FormControl,
    FormGroup,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { getBasePath } from '../../utils/path';
import { PageContainer } from '../layout/PageComponents';
import { DiscountCodes } from '../../data/report/discountCodes';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Button } from '../buttons/Button';

export interface DiscountCodesReportPageProps {
    discountCodes: DiscountCodes;
    createDiscountCodeError?: string;
    createDiscountCodeInProgress: boolean;
    onCreate: (code: string, expiryDate: string) => void;
}

export const DiscountCodesReportPage: React.FC<DiscountCodesReportPageProps> = ({
    discountCodes,
    createDiscountCodeError,
    onCreate,
    createDiscountCodeInProgress = false,
}) => {
    const [discountCode, setDiscountCode] = useState('');
    const [discountCodeExpiry, setDiscountCodeExpiry] = useState('');
    const [discountCodeExpiryError, setDiscountCodeExpiryError] = useState('');

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">Discount Codes</Typography>
            </Box>
            <FormGroup>
                <FormControl>
                    <TextField
                        variant="outlined"
                        label="Discount Code"
                        placeholder="Enter a new discount code"
                        value={discountCode}
                        error={!!createDiscountCodeError}
                        helperText={createDiscountCodeError ?? ''}
                        onChange={(e) => setDiscountCode(e.target.value)}
                    />
                </FormControl>
                <FormControl size="small">
                    <DatePicker
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                error={!!discountCodeExpiryError}
                                helperText={discountCodeExpiryError ?? 'Select an expiry date greater than today'}
                            />
                        )}
                        label="Expiry"
                        value={discountCodeExpiry ? dayjs(discountCodeExpiry) : null}
                        onChange={(value) => {
                            setDiscountCodeExpiryError('');
                            if (!value?.isValid() || value?.isBefore(dayjs()) || value?.isSame(dayjs())) {
                                setDiscountCodeExpiryError('Invalid date. Select a date greater than today');
                            } else {
                                setDiscountCodeExpiry(value?.toISOString() ?? '');
                            }
                        }}
                    />
                </FormControl>
                <Button
                    sx={{ maxWidth: 'fit-content' }}
                    disabled={
                        createDiscountCodeInProgress ||
                        !discountCode ||
                        !discountCodeExpiry ||
                        Boolean(discountCodeExpiryError)
                    }
                    onClick={() => {
                        onCreate(discountCode, discountCodeExpiry);
                    }}
                >
                    ADD
                </Button>
            </FormGroup>
            {discountCodes.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Discount Code ID</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Number of Teams</TableCell>
                            <TableCell>Expiry Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {discountCodes.map(({ uid, code, numberOfOrganizations, expiryDate }) => (
                            <TableRow id={uid} key={uid}>
                                <TableCell>
                                    <Link href={`${getBasePath()}reports/discountCode/${uid}`} target="_blank">
                                        {uid}
                                    </Link>
                                </TableCell>
                                <TableCell>{code}</TableCell>
                                <TableCell>{numberOfOrganizations}</TableCell>
                                <TableCell>{expiryDate}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <div>No discount codes found</div>
            )}
        </PageContainer>
    );
};
