import { useObservableState } from 'observable-hooks';
import { GoogleManageConnectionDialog } from '../../../components/connections/google-sheets/ManageConnectionDialog';
import {
    googleConnectionSaving$,
    googleManageConnectionAuthorizeLoading$,
    googleManageConnectionCloseDialogAction$,
    googleManageConnectionDetails$,
    googleManageConnectionDialogErrors$,
    googleManageConnectionDialogOpen$,
    googleManageConnectionInitiateAuthorizeAction$,
    googleSaveConnectionAction$,
} from '../../../store/connection/google/authorize-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const GoogleManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(googleManageConnectionDialogOpen$);
    const saving = useObservableState(googleConnectionSaving$);
    const authorizeLoading = useObservableState(googleManageConnectionAuthorizeLoading$);
    const details = useObservableState(googleManageConnectionDetails$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );
    const errors = useObservableState(googleManageConnectionDialogErrors$);

    return (
        <GoogleManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            onCancel={() => googleManageConnectionCloseDialogAction$.next()}
            onSave={(name) => googleSaveConnectionAction$.next(name)}
            onAuthorize={(name) => googleManageConnectionInitiateAuthorizeAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
