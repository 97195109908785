import { useEffect, useRef, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '../../../buttons/Button';
import { Dropdown } from '../../../dropdown/Dropdown';
import {
    DragButton,
    StyledDropArea,
    StyledEditModeActions,
    StyledEditModeContent,
    StyledReadOnlyExpandButton,
    StyledHeaderActions,
    StyledEditModeRequiredButton,
    StyledVariable,
    StyledReadOnlyVariableHeader,
    StyledVariableWrapper,
    emptyWarning,
    StyledReadOnlyValueField,
    StyledReadOnlyKeyField,
    StyledReadOnlyRow,
    StyledReadOnlyOptionalChip,
    StyledReadOnlyRequiredChip,
    StyledReadOnlyMissingInformationChip,
    StyledEditModeDivider,
    StyledEditModeVariableHeader,
} from '../EnvironmentVariableComponents';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
} from '../types';
import { EnvironmentVariableType } from '@avst-stitch/repository-lib/lib/types';
import { autoFocus, isFocused } from '../../../../utils/autoFocus';
import {
    DESCRIPTION_MAX_LENGTH,
    duplicateKeyNameHelperText,
    environmentVariableTypes,
    getVariableHeight,
    invalidKeyNameHelperText,
    isDescriptionTooLong,
} from '../utils';
import { handleKeyUp } from '../../../../utils/handleKeyUp';
import { isScriptOrEnvVariableNameValid } from '../../../../utils/validation';

interface UpdateNumberVariableEvent {
    defaultValue?: number;
    description?: string;
    id: string;
    keyName: string;
    parentId?: string;
    required?: boolean;
    type: EnvironmentVariableType;
    value?: number;
}

interface EnvironmentVariableNumberVariantProps {
    defaultValue?: string;
    description?: string;
    dragOverlay?: boolean;
    editMode?: boolean;
    expanded?: boolean;
    hasBeenEdited?: boolean;
    id: string;
    keyName?: string;
    parentId?: string;
    required?: boolean;
    sameLevelKeyNames?: string[];
    templateMode?: boolean;
    value?: string;
    workspaceLocked?: boolean;
    onChangeType?(event: ChangeVariableTypeEvent): void;
    onDelete?(event: DeleteVariableEvent): void;
    onToggleEditMode?(event: ToggleVariableEditModeEvent): void;
    onToggleExpand?(event: ToggleVariableExpandEvent): void;
    onUpdate?(event: UpdateNumberVariableEvent): void;
}

export const EnvironmentVariableNumberVariant: React.FC<EnvironmentVariableNumberVariantProps> = ({
    defaultValue = '',
    description = '',
    dragOverlay = false,
    editMode = false,
    expanded = false,
    hasBeenEdited = false,
    id,
    keyName = '',
    parentId,
    required = false,
    sameLevelKeyNames = [],
    templateMode = false,
    value = '',
    workspaceLocked = false,
    onChangeType,
    onDelete,
    onToggleEditMode,
    onToggleExpand,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentKeyName, setCurrentKeyName] = useState(keyName);
    const [currentValue, setCurrentValue] = useState(value);
    const [currentDefaultValue, setCurrentDefaultValue] = useState(defaultValue);
    const [currentDescription, setCurrentDescription] = useState(description);
    const [currentRequired, setCurrentRequired] = useState(required);

    const variableRef = useRef<HTMLDivElement>(null);
    const typeInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    const draggable = useDraggable({
        id,
        data: { height: getVariableHeight(variableRef), keyName, parentId, type: 'variable' },
    });

    const droppable = useDroppable({ id });

    useEffect(() => {
        if (editMode) {
            autoFocus(typeInputRef);
        }
    }, [editMode]);

    const handleCancel = (): void => {
        setCurrentKeyName(keyName);
        setCurrentValue(value);
        setCurrentDefaultValue(defaultValue);
        setCurrentDescription(description);
        setCurrentRequired(required);
        onToggleEditMode?.({ editMode: false, id, parentId });
    };

    const handleUpdate = (): void => {
        onUpdate?.({
            defaultValue: currentDefaultValue ? +currentDefaultValue : undefined,
            description: currentDescription,
            id,
            keyName: currentKeyName,
            parentId,
            required: currentRequired,
            type: 'NUMBER',
            value: currentValue ? +currentValue : undefined,
        });
    };

    const hasChanged =
        currentValue !== value ||
        currentDefaultValue !== defaultValue ||
        currentDescription !== description ||
        currentKeyName !== keyName ||
        currentRequired !== required;

    const isKeyNameDuplicate =
        !!currentKeyName && sameLevelKeyNames.filter((kn) => kn !== keyName).includes(currentKeyName);

    const isCurrentRequiredValueMissing = currentRequired && !currentValue;
    const canUpdate =
        !isKeyNameDuplicate &&
        isScriptOrEnvVariableNameValid(currentKeyName) &&
        !isDescriptionTooLong(currentDescription) &&
        (!hasBeenEdited || hasChanged);

    const isSavedRequiredValueMissing = required && !value;
    const missingInformation = !keyName || isSavedRequiredValueMissing;

    const draggedVariable = droppable.active?.data.current;
    const dropAreaHeight = (draggedVariable?.height ?? 0) as number;

    const isDraggedVariableDuplicate =
        !!draggedVariable?.keyName &&
        draggedVariable.parentId !== parentId &&
        sameLevelKeyNames.includes(draggedVariable.keyName);

    const forbiddenFolderSort = parentId ? draggedVariable && draggedVariable.type === 'folder' : false;
    const sortForbidden = draggable.isDragging || isDraggedVariableDuplicate || forbiddenFolderSort;

    const displayedValue = templateMode ? defaultValue : value;

    return (
        <StyledVariableWrapper
            ref={(node) => {
                draggable.setNodeRef(node);
                droppable.setNodeRef(sortForbidden ? null : node);
            }}
            isDragging={draggable.isDragging}
            nested={!!parentId}
        >
            {!sortForbidden && (
                <StyledDropArea height={dropAreaHeight} visible={droppable.isOver} nested={!!parentId} />
            )}
            <StyledVariable
                className={dragOverlay ? 'dragOverlay' : ''}
                ref={variableRef}
                onKeyUp={(event) =>
                    handleKeyUp({
                        event,
                        enterCondition: canUpdate && editMode && !isFocused(descriptionInputRef),
                        enterFn: handleUpdate,
                        escFn: handleCancel,
                    })
                }
            >
                {editMode ? (
                    <>
                        <StyledEditModeVariableHeader>
                            <Typography>{hasBeenEdited ? 'Edit variable' : 'New variable'}</Typography>
                            <StyledHeaderActions>
                                <ToggleButtonGroup
                                    exclusive
                                    value={currentRequired}
                                    onChange={() => setCurrentRequired(!currentRequired)}
                                    aria-label={required ? 'Required' : 'Optional'}
                                >
                                    <StyledEditModeRequiredButton selected={!currentRequired} value={!currentRequired}>
                                        Optional
                                    </StyledEditModeRequiredButton>
                                    <StyledEditModeRequiredButton selected={currentRequired} value={currentRequired}>
                                        Required
                                    </StyledEditModeRequiredButton>
                                </ToggleButtonGroup>
                                <IconButton onClick={() => handleCancel()}>
                                    <CloseIcon />
                                </IconButton>
                            </StyledHeaderActions>
                        </StyledEditModeVariableHeader>
                        <StyledEditModeContent>
                            <Dropdown
                                disabled={hasBeenEdited}
                                inputRef={typeInputRef}
                                label="Type"
                                selectedItem={'NUMBER'}
                                required
                                size="small"
                                items={environmentVariableTypes}
                                onSelect={(v) => onChangeType?.({ id, parentId, type: v as EnvironmentVariableType })}
                            />
                            <TextField
                                label="Key"
                                required
                                size="small"
                                error={!isScriptOrEnvVariableNameValid(currentKeyName) || isKeyNameDuplicate}
                                helperText={
                                    !isScriptOrEnvVariableNameValid(currentKeyName)
                                        ? invalidKeyNameHelperText
                                        : isKeyNameDuplicate
                                        ? duplicateKeyNameHelperText(!!parentId)
                                        : !currentKeyName
                                        ? 'Please enter key name'
                                        : undefined
                                }
                                placeholder="Enter key name"
                                value={currentKeyName}
                                onChange={(e) => setCurrentKeyName(e.target.value)}
                            />
                            <TextField
                                label="Value"
                                required={currentRequired}
                                type="number"
                                size="small"
                                helperText={isCurrentRequiredValueMissing && 'Please enter key value'}
                                placeholder="Enter value"
                                value={currentValue}
                                onChange={(e) => {
                                    setCurrentValue(e.target.value);
                                }}
                            />
                            <TextField
                                label="Notes"
                                inputRef={descriptionInputRef}
                                size="small"
                                error={isDescriptionTooLong(currentDescription)}
                                helperText={
                                    isDescriptionTooLong(currentDescription)
                                        ? `Notes length exceeds ${DESCRIPTION_MAX_LENGTH} characters`
                                        : `${DESCRIPTION_MAX_LENGTH - currentDescription.length} characters remaining`
                                }
                                multiline
                                rows={2}
                                placeholder="Enter notes"
                                value={currentDescription}
                                onChange={(e) => setCurrentDescription(e.target.value)}
                            />
                            <StyledEditModeDivider>
                                <Typography>Developer Options</Typography>
                            </StyledEditModeDivider>
                            <TextField
                                label="Default value"
                                size="small"
                                type="number"
                                placeholder="Enter default value"
                                value={currentDefaultValue}
                                onChange={(e) => setCurrentDefaultValue(e.target.value)}
                            />
                        </StyledEditModeContent>
                        <StyledEditModeActions>
                            <Button size="small" variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button size="small" disabled={!canUpdate} onClick={handleUpdate}>
                                {hasBeenEdited ? 'Update' : 'Create'}
                            </Button>
                        </StyledEditModeActions>
                    </>
                ) : (
                    <>
                        <StyledReadOnlyVariableHeader>
                            <StyledHeaderActions>
                                <DragButton
                                    disabled={workspaceLocked || templateMode}
                                    {...draggable.attributes}
                                    {...draggable.listeners}
                                />
                                <IconButton
                                    disabled={workspaceLocked || templateMode}
                                    onClick={() => {
                                        onToggleEditMode?.({ editMode: true, id, parentId });
                                    }}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                                <IconButton
                                    disabled={workspaceLocked || templateMode}
                                    onClick={() => onDelete?.({ id, parentId })}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </StyledHeaderActions>
                            <StyledHeaderActions>
                                {missingInformation && (
                                    <StyledReadOnlyMissingInformationChip label="Missing information" />
                                )}
                                {!templateMode ? (
                                    required ? (
                                        <StyledReadOnlyRequiredChip label="Required" />
                                    ) : (
                                        <StyledReadOnlyOptionalChip label="Optional" />
                                    )
                                ) : null}
                                <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
                                    <StyledReadOnlyExpandButton
                                        onClick={() => onToggleExpand?.({ expanded: !expanded, id, parentId })}
                                    >
                                        <Typography>Show all</Typography>
                                        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </StyledReadOnlyExpandButton>
                                </Tooltip>
                            </StyledHeaderActions>
                        </StyledReadOnlyVariableHeader>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Key</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {keyName ? <Typography>{keyName}</Typography> : emptyWarning(true)}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>{'Value' + (required ? ' *' : '')}</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {displayedValue ? (
                                    <Typography>{displayedValue}</Typography>
                                ) : (
                                    emptyWarning(!templateMode && required)
                                )}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Notes</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {description ? <Typography>{description}</Typography> : emptyWarning()}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        {expanded && (
                            <>
                                <StyledReadOnlyRow>
                                    <StyledReadOnlyKeyField>
                                        <Typography>Type</Typography>
                                    </StyledReadOnlyKeyField>
                                    <StyledReadOnlyValueField>
                                        <Typography>Number</Typography>
                                    </StyledReadOnlyValueField>
                                </StyledReadOnlyRow>
                                {!templateMode && (
                                    <StyledReadOnlyRow>
                                        <StyledReadOnlyKeyField>
                                            <Typography>Default value</Typography>
                                        </StyledReadOnlyKeyField>
                                        <StyledReadOnlyValueField>
                                            {defaultValue ? <Typography>{defaultValue}</Typography> : emptyWarning()}
                                        </StyledReadOnlyValueField>
                                    </StyledReadOnlyRow>
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledVariable>
        </StyledVariableWrapper>
    );
};
