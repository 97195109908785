import { useObservableState } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { WizardMinimizedIcon } from '../../components/wizard/WizardMinimizedIcon';
import {
    updateWizardStateAction$,
    updateWizardStepAction$,
    WizardState,
    wizardState$,
    wizardStep$,
    wizardWorkspaceDetails$,
} from '../../store/wizard';
import { selectedWorkspaceUid$ } from '../../store/workspace';
import { getBasePath } from '../../utils/path';

export const WizardMinimizedIconContainer: React.FC = () => {
    const navigate = useNavigate();

    const wizardState = useObservableState(wizardState$);
    const wizardStep = useObservableState(wizardStep$);
    const wizardWorkspaceDetails = useObservableState(wizardWorkspaceDetails$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const displayed = wizardState === WizardState.MINIMIZED;

    const handleResume = (): void => {
        updateWizardStateAction$.next(WizardState.ACTIVE);
        updateWizardStepAction$.next(wizardStep);
        if (
            wizardWorkspaceDetails?.workspaceUid &&
            wizardWorkspaceDetails?.environmentUid &&
            selectedWorkspaceUid !== wizardWorkspaceDetails.workspaceUid
        ) {
            navigateToWorkspace(wizardWorkspaceDetails.workspaceUid, wizardWorkspaceDetails.environmentUid);
        }
    };

    const navigateToWorkspace = (workspaceUid: string, environmentUid: string): void =>
        navigate({ to: `${getBasePath()}workspace/${workspaceUid}/environment/${environmentUid}` });

    return (
        <WizardMinimizedIcon
            displayed={displayed}
            onClose={() => updateWizardStateAction$.next(WizardState.CLOSED)}
            onResume={handleResume}
        />
    );
};
