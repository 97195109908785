import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { Button } from '../../buttons/Button';
import { DialogAlert, DialogTitleMain } from '../../dialog';
import { Dropdown } from '../../dropdown/Dropdown';
import { PageContainer } from '../../layout';
import {
    StyledCronGenerator,
    StyledDetails,
    StyledExpression,
    StyledScheduledDate,
    StyledScheduledDatesSection,
    StyledScheduledDatesTitle,
    StyledScheduledTriggerDetails,
    StyledSubtitle,
} from './ScheduledTriggerStyles';
import ScheduleIcon from '@mui/icons-material/Schedule';
import cronstrue from 'cronstrue';

interface ReadOnlyScheduledTriggerDetailsProps {
    cronExpression?: string;
    scripts: {
        uid: string;
        name: string;
    }[];
    selectedScriptUid?: string;
    nextScheduledDates: Date[];
}

export const ReadOnlyScheduledTriggerDetails: React.FC<ReadOnlyScheduledTriggerDetailsProps> = ({
    cronExpression = '',
    nextScheduledDates = [],
    selectedScriptUid,
    scripts = [],
}) => {
    const description = cronstrue.toString(cronExpression);
    const modifiedDescription = description.includes('undefined') ? 'Error: Invalid expression' : description;

    const dates = nextScheduledDates
        .map((sd, i) => {
            return (
                <StyledScheduledDate
                    key={i}
                    sx={(theme) => {
                        return i % 2 == 0
                            ? { backgroundColor: theme.palette.background.default }
                            : { backgroundColor: theme.palette.background.paper };
                    }}
                >
                    <ScheduleIcon />
                    {sd.toLocaleString()}
                </StyledScheduledDate>
            );
        })
        .filter((_, i) => i < 10);

    const displayedScripts = scripts.map((s) => {
        return {
            name: s.name,
            value: s.uid,
        };
    });

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <DialogTitleMain title="Scheduled Trigger" />
            <DialogAlert
                severity="info"
                alertTitle="Information"
                text="Schedules are calculated in UTC (GMT+0) time zone, Next Estimated Scheduled Dates and Last Scheduled Trigger Date are displayed in your local time zone."
            />
            <StyledScheduledTriggerDetails>
                <StyledCronGenerator>
                    <StyledSubtitle variant="subtitle2">CRON Expression</StyledSubtitle>
                    <StyledDetails>
                        <TextField
                            required
                            variant="outlined"
                            disabled={true}
                            value={cronExpression}
                            label="CRON Expression"
                        />
                        <StyledSubtitle variant="subtitle2">CRON Schedule</StyledSubtitle>
                        <StyledExpression variant="subtitle1">{modifiedDescription}</StyledExpression>

                        <Dropdown
                            required
                            items={displayedScripts}
                            label="Run Script"
                            disabled={true}
                            selectedItem={selectedScriptUid}
                        ></Dropdown>
                        <DialogActions>
                            <Button variant="outlined" disabled={true}>
                                Cancel
                            </Button>
                            <Button disabled={true}>Save</Button>
                        </DialogActions>
                    </StyledDetails>
                </StyledCronGenerator>
                <div>
                    {!!nextScheduledDates.length && (
                        <StyledScheduledDatesSection>
                            <StyledScheduledDatesTitle variant="h5">
                                Next Estimated Scheduled Dates
                            </StyledScheduledDatesTitle>
                            {dates}
                        </StyledScheduledDatesSection>
                    )}
                </div>
            </StyledScheduledTriggerDetails>
        </PageContainer>
    );
};
