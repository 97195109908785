import { useObservableState } from 'observable-hooks';
import { TempoCloudConnectionAppConfigureDialog } from '../../../components/connections/tempo-cloud/TempoCloudAppConfigureDialog';
import { configTopic$ } from '../../../store/config';
import {
    tempoCloudConnectionSaving$,
    tempoCloudManageConnectionDetails$,
    tempoCloudAppSetupDialogErrors$,
    tempoCloudAppSetupDialogOpen$,
    tempoCloudAppSetupCloseDialogAction$,
    tempoCloudAppSetupAuthorizeAction$,
    tempoCloudAppSetupDialogStage$,
} from '../../../store/connection/tempo-cloud/setup-connection';

export const TempoCloudConfigureDialog: React.FC = () => {
    const open = useObservableState(tempoCloudAppSetupDialogOpen$);
    const saving = useObservableState(tempoCloudConnectionSaving$);
    const details = useObservableState(tempoCloudManageConnectionDetails$);
    const errors = useObservableState(tempoCloudAppSetupDialogErrors$);
    const currentStage = useObservableState(tempoCloudAppSetupDialogStage$);
    const baseUrl = configTopic$.value.connection?.tempoCloud?.baseUrl;

    return (
        <TempoCloudConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                tempoCloudAppSetupCloseDialogAction$.next();
                tempoCloudAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => tempoCloudAppSetupDialogStage$.next(stage)}
            clientInfo={{
                instanceUrl: details?.instanceUrl ?? '',
                clientId: details?.clientId ?? '',
                clientSecret: details?.clientSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => tempoCloudAppSetupDialogErrors$.next(error)}
            clearErrors={() => tempoCloudAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => tempoCloudAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${baseUrl}/callback`}
        />
    );
};
