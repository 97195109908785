import React from 'react';
import { TypeOptions } from 'react-toastify';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { InfoIcon } from '../icons/InfoIcon';

interface ToastProps {
    type: TypeOptions;
    message: string;
}

const StyledTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
        height: 20,
        marginRight: theme.spacing(0.5),
        width: 20,
    },
}));

export const ToastMessage: React.FC<ToastProps> = ({ type, message }) => {
    const getIcon = (): JSX.Element => {
        switch (type) {
            case 'error':
                return <ErrorOutlineIcon sx={{ color: 'error.light' }} />;
                break;
            case 'info':
                return <InfoOutlined sx={{ color: 'info.light' }} />;
                break;
            case 'success':
                return <CheckCircleOutline sx={{ color: 'success.light' }} />;
                break;
            case 'warning':
                return <WarningAmberIcon sx={{ color: 'warning.light' }} />;
                break;

            default:
                return <InfoIcon />;
        }
    };

    const title = type.charAt(0).toUpperCase() + type.slice(1);
    const icon = getIcon();
    return (
        <>
            <StyledTitleContainer>
                {icon}
                <Typography color="text.primary" variant="subtitle1">
                    {title}
                </Typography>
            </StyledTitleContainer>
            <Typography color={'text.secondary'}>{message}</Typography>
        </>
    );
};
