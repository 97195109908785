import { useObservableState } from 'observable-hooks';
import { ConnectAndSupportModal } from '../components/connect-and-support';
import { closeConnectAndSupportModalAction$, connectAndSupportModalOpen$ } from '../store/connectAndSupport';
import {
    documentationUrl,
    feedbackBoardUrl,
    mondayDocumentationUrl,
    mondayFeedbackBoardUrl,
    mondayServiceManagementUrl,
    slackCommunityUrl,
    supportPortalUrl,
    // youtubeChannelUrl,
} from '../utils/documentation';
import { loggedInUserDetails$ } from '../store/user';
import { MONDAY_ORIGIN } from '../utils/constants';
import { segmentAnalyticsTrack } from '../data/segment-analytics';

export const ConnectAndSupportContainer: React.FC = () => {
    const open = useObservableState(connectAndSupportModalOpen$);
    const userDetails = useObservableState(loggedInUserDetails$);

    const isMondayUser = userDetails?.userOrigin === MONDAY_ORIGIN;

    return (
        <ConnectAndSupportModal
            open={open}
            onClose={() => closeConnectAndSupportModalAction$.next()}
            content={[
                ...(!isMondayUser
                    ? [
                          {
                              title: 'Slack Community',
                              description:
                                  'Stay up to date with the latest announcements, and benefit from premium support.',
                              link: slackCommunityUrl,
                              linkText: 'Join Slack',
                          },
                      ]
                    : []),
                {
                    title: 'Feedback Board',
                    description:
                        'Help us identify what we should build next. Vote on existing ideas or suggest new ones!',
                    link: isMondayUser ? mondayFeedbackBoardUrl : feedbackBoardUrl,
                    linkText: isMondayUser ? 'Feedback board' : 'See Nolt',
                    onClick: () =>
                        segmentAnalyticsTrack('Feedback Accessed', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
                {
                    title: 'Support Portal',
                    description: "Report issues, make suggestions, and more. Whatever you need, we're here to help!",
                    link: isMondayUser ? mondayServiceManagementUrl : supportPortalUrl,
                    linkText: 'Contact Us',
                    onClick: () =>
                        segmentAnalyticsTrack('Support Accessed', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
                {
                    title: 'ScriptRunner Connect Product Documentation',
                    description: 'A growing collection of technical and how to docs to help you use the app.',
                    link: isMondayUser ? mondayDocumentationUrl : documentationUrl,
                    linkText: 'Visit Documentation',
                    onClick: () =>
                        segmentAnalyticsTrack('Documentation Accessed', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
                // {
                //     title: 'YouTube Tutorials',
                //     description: 'If you prefer more visual guides, we have great videos on our Youtube library.',
                //     link: youtubeChannelUrl,
                //     linkText: 'Visit Youtube Playlist',
                // },
            ]}
        />
    );
};
