import { useObservableState } from 'observable-hooks';
import { ErrorPage } from '../components/error-page';
import { errorPageDetails$ } from '../store/error';
import { loadTokens$, sessionExpired$, showSessionExpiredWarning$ } from '../store/config';
import { logoutFromAppAction$, loggedInUserDetails$, userLoggedOutFromApp$ } from '../store/user';
import { mondayServiceManagementUrl, supportPortalUrl } from '../utils/documentation';
import { MONDAY_ORIGIN } from '../utils/constants';

export const ErrorPageContainer: React.FC = () => {
    const errorPageDetails = useObservableState(errorPageDetails$);
    const sessionExpired = useObservableState(sessionExpired$);
    const showSessionExpiredWarning = useObservableState(showSessionExpiredWarning$);
    const userLoggedOutFromApp = useObservableState(userLoggedOutFromApp$);
    const { userOrigin } = useObservableState(loggedInUserDetails$) ?? {};

    const isMondayUser = userOrigin === MONDAY_ORIGIN;

    const showRefreshSessionWarning = sessionExpired && showSessionExpiredWarning;

    return (
        <ErrorPage
            error={errorPageDetails?.errorMessage}
            background={errorPageDetails?.background}
            title={errorPageDetails?.pageTitle}
            skipRefreshMessage={errorPageDetails?.skipRefreshMessage}
            showDashboardLink={errorPageDetails?.showDashboardLink}
            skipRefreshMessageOverride={errorPageDetails?.skipRefreshMessageOverride}
            showRefreshSessionWarning={showRefreshSessionWarning}
            supportPortalUrl={isMondayUser ? mondayServiceManagementUrl : supportPortalUrl}
            onSetLoadTokens={(loadTokens) => loadTokens$.next(loadTokens)}
            logoutFromApp={() => logoutFromAppAction$.next()}
            userLoggedOutFromApp={userLoggedOutFromApp}
        />
    );
};
