import { useObservableState } from 'observable-hooks';
import { AzureDevopsManageConnectionDialog } from '../../../components/connections/azure-devops/ManageConnectionDialog';
import {
    azureDevopsConnectionSaving$,
    azureDevopsManageConnectionAuthorizeLoading$,
    azureDevopsManageConnectionCloseDialogAction$,
    azureDevopsManageConnectionDetails$,
    azureDevopsManageConnectionDialogErrors$,
    azureDevopsManageConnectionDialogOpen$,
    azureDevopsManageConnectionInitiateSetupAction$,
    azureDevopsSaveConnectionAction$,
} from '../../../store/connection/azure-devops/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const AzureDevopsManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(azureDevopsManageConnectionDialogOpen$);
    const saving = useObservableState(azureDevopsConnectionSaving$);
    const authorizeLoading = useObservableState(azureDevopsManageConnectionAuthorizeLoading$);
    const details = useObservableState(azureDevopsManageConnectionDetails$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );
    const errors = useObservableState(azureDevopsManageConnectionDialogErrors$);

    return (
        <AzureDevopsManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            tenantId={details?.tenantId}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            onCancel={() => azureDevopsManageConnectionCloseDialogAction$.next()}
            onSave={(name) => azureDevopsSaveConnectionAction$.next(name)}
            onAuthorize={(name) => azureDevopsManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
