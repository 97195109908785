import { BehaviorSubject, map, Observable } from 'rxjs';
import { getLoggedInUserConnections } from '../../data/connection';
import { loggedInUserConnections$ } from '../connections';
import { configTopic$, stitchSession$ } from '../config';
import { getFetchOptions } from '../../utils/fetch';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { InformativeError } from '../../utils/repository';
import { SESSION_ID } from '../..';
import { mondayUserOnboardingFailedAction$ } from '../monday';

export const loadLoggedInUserConnectionsWhenEventIsEmitted = (
    observable: Observable<{ uid: string; connectionTypeUid?: string }>
): void => {
    observable
        .pipe(
            map(async (event) => {
                await loadLoggedInUserConnections(event.connectionTypeUid);
            })
        )
        .subscribe();
};

export const loadLoggedInUserConnections = async (connectionTypeUid?: string, source?: 'connectors'): Promise<void> => {
    const connections = await getLoggedInUserConnections(connectionTypeUid, source);
    if (!connectionTypeUid) loggedInUserConnections$.next(connections);
    else {
        const otherConnections = loggedInUserConnections$.value.filter(
            (connection) => connection.connectionType.uid !== connectionTypeUid
        );
        loggedInUserConnections$.next([...otherConnections, ...connections]);
    }
};

export const confirmUnsavedConnection = (): boolean =>
    confirm('Are you sure you want to save and exit the connection without having authorized it?');

export const saveConnectionAllowed = (connectionUid: string): boolean => {
    const connection = loggedInUserConnections$.value.find((el) => el.uid === connectionUid);
    return !!(connection && connection.authorized);
};

interface InitiateResponse {
    location: string;
}
export type InitiateRequest = Record<string, string>;

export class TimeoutError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'TimeoutError';
    }
}

export const initiate = async (request: InitiateRequest, initiateUrl: string): Promise<InitiateResponse> => {
    const legacyUrls = ['https://stitchit.app', 'https://stitch.apps.adaptavist.com', 'https://stitch.local:3000'];
    const webAppUrl = window.location.origin;
    const fetchOptions = getFetchOptions(
        { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
        {
            ...request,
            callbackHtml: legacyUrls.includes(webAppUrl) ? `${webAppUrl}` : undefined,
        }
    );
    const response = await fetch(initiateUrl, fetchOptions);
    if (!response.ok) {
        console.error('Error while initiating authorization');

        const message = await response.text();
        const errorMessage =
            message || `Unexpected error while starting oauth flow with Connector service: ${response.status}`;
        if (response.status === 408) {
            const publicIP = configTopic$.value.vpc?.publicIP;
            throw new TimeoutError(
                JSON.parse(errorMessage).error +
                    ` Please ensure the instance is up and running. If it is behind a firewall, please whitelist the ScriptRunner Connect public IP: ${publicIP} and try again.`
            );
        }
        if (response.status === 502) {
            throw new InformativeError(
                'Instance URL is not reachable. Please enter a valid URL and ensure the instance is up and running ' +
                    `and that the instance is accessible in your browser. `
            );
        } else if (response.status === 500 && JSON.parse(errorMessage).code !== 500) {
            throw new InformativeError(
                'Could not obtain the token from the instance. Please ensure the instance is up and running ' +
                    'and that an application link has been created for ScriptRunner Connect.'
            );
        } else {
            throw new Error(errorMessage);
        }
    }

    const respBody = await response.text();
    if (!respBody) {
        throw new Error('Missing response body from Connector initiate request');
    }

    return JSON.parse(respBody);
};

export const openConsentWindow = (location: string, loading$: BehaviorSubject<boolean>, mondayUid?: string): void => {
    const consentWindow = window.open(location);
    // Close consent screen after 100 sec
    const timeoutNr = window.setTimeout(() => {
        consentWindow?.close();
        if (mondayUid) {
            mondayUserOnboardingFailedAction$.next(mondayUid);
        } else {
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: 'Authorization cancelled.',
            });
            loading$.next(false);
        }
    }, 100000);
    // When consent screen has been closed stop authorization loading
    // stops the endless loading indicator when the user closes the screen
    const intervalNr = window.setInterval(() => {
        // !== is required for compatibility with Opera
        if (consentWindow?.closed !== false) {
            window.clearInterval(intervalNr);
            window.clearTimeout(timeoutNr);
            setTimeout(() => loading$.next(false), 2000);
        }
    }, 2000);
};

export const handleManageConnectionError = (
    error: unknown,
    errorSubject$: BehaviorSubject<string | undefined>,
    connectionType: string
): void => {
    if (error instanceof InformativeError) {
        errorSubject$.next(error.message);
    } else {
        errorSubject$.next('Failed to save Connector, please try again, if the issue persists please contact support.');
        console.error(`Error while saving ${connectionType} Connector`, error);
    }
};

interface GenerateResponse {
    publicCertificate: string;
}
export type GenerateRequest = {
    connectionId: string;
};

export const generateNetSuiteConnectionCertificate = async (
    request: GenerateRequest,
    generateUrl: string
): Promise<GenerateResponse> => {
    const fetchOptions = getFetchOptions(
        { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
        request
    );
    const response = await fetch(generateUrl, fetchOptions);
    if (!response.ok) {
        throw new InformativeError('Error while fetching initial certificate data');
    }

    const respBody = await response.text();
    if (!respBody) {
        throw new Error('Missing response body from Connector generate certificate request');
    }

    return JSON.parse(respBody);
};

export type AuthorizeRequest = {
    connectionId: string;
    connectionType: string;
    certificateId: string;
    clientId: string;
    accountId: string;
};

export const authorizeNetSuiteConnectionCertificate = async (
    request: AuthorizeRequest,
    authorizeUrl: string
): Promise<void> => {
    const fetchOptions = getFetchOptions(
        { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
        request
    );
    try {
        const response = await fetch(authorizeUrl, fetchOptions);
        if (!response.ok) {
            throw Error(response.statusText);
        }
    } catch (e) {
        throw new InformativeError('Error while authorizing connector.');
    }
};
