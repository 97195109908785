import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../buttons/Button';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyUp } from '../../../utils/handleKeyUp';
import { useEffect, useState } from 'react';
import { NetSuiteEventListenerWizardStage, NetSuiteEventListenerWizardSteps } from './NetSuiteEventListenerWizardSteps';
import { createNetSuiteEventListenerScriptContent } from '../../../utils/eventListener';

interface NetSuiteEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    accountId?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const NetSuiteEventListenerSetupDialog: React.FC<NetSuiteEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    accountId,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const webhookUrl = `${webhookBaseUrl}/${webhookUrlId}`;
    const uploadScriptUrl = `https://${accountId}.app.netsuite.com/app/common/scripting/uploadScriptFile.nl`;
    const fileName = `${webhookUrlId}-src-user-event.js`;

    const [stage, setStage] = useState(NetSuiteEventListenerWizardStage.UPLOAD_SCRIPT);

    useEffect(() => {
        setStage(NetSuiteEventListenerWizardStage.UPLOAD_SCRIPT);
    }, [open]);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const getStageContent = (): JSX.Element => {
        switch (stage) {
            case NetSuiteEventListenerWizardStage.UPLOAD_SCRIPT:
                return (
                    <Dialog
                        maxWidth="md"
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterFn: () => setStage(NetSuiteEventListenerWizardStage.CREATE_AND_DEPLOY),
                                escFn: onClose,
                            })
                        }
                    >
                        <NetSuiteEventListenerWizardSteps stage={stage} />
                        <DialogTitle sx={{ mb: 2 }} variant="h6">
                            Event Listener setup instructions for NetSuite
                        </DialogTitle>
                        {loading ? (
                            <AbsoluteCenteredLoadingSpinner />
                        ) : (
                            <>
                                <DialogContent>
                                    <DialogContentText component="ol">
                                        <li>
                                            Download the script by clicking on this link:{' '}
                                            <Link
                                                href={URL.createObjectURL(
                                                    new Blob([createNetSuiteEventListenerScriptContent(webhookUrl)])
                                                )}
                                                download={fileName}
                                            >
                                                Download Script
                                            </Link>
                                        </li>
                                        This script will be executed by NetSuite when a chosen record type is created or
                                        updated. <br />
                                        <strong>NOTE:</strong> If you need to customise your script, please see the
                                        docs:{' '}
                                        <Link
                                            target="_blank"
                                            href="https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_1510274245.html"
                                        >
                                            User Event Scripts
                                        </Link>
                                        {accountId && (
                                            <li>
                                                Visit your instance's{' '}
                                                <Link target="_blank" href={uploadScriptUrl}>
                                                    Upload a Script
                                                </Link>{' '}
                                                page.
                                            </li>
                                        )}
                                        {!accountId && (
                                            <li>
                                                Open{' '}
                                                <strong>
                                                    https://[YOUR_ACCOUNT_ID].app.netsuite.com/app/common/scripting/uploadScriptFile.nl
                                                </strong>{' '}
                                                URL in your browser.
                                            </li>
                                        )}
                                        <li>
                                            Click on + to the right of the <strong>SCRIPT FILE</strong> field.
                                        </li>
                                        <li>
                                            On the <strong>File</strong> tab that opens, in the{' '}
                                            <strong>ATTACH FROM</strong> field choose <strong>Computer</strong>.
                                        </li>
                                        <li>
                                            Copy and paste the file name.
                                            <ReadOnlyTextField label="File name" value={fileName} />
                                        </li>
                                        <li>Choose a suitable Folder to upload your script to.</li>
                                        <li>
                                            Click <strong>SELECT FILE</strong> and upload recently downloaded script.
                                            Leave <strong>CHARACTER ENCODING</strong> as UTF-8.
                                        </li>
                                        <li>
                                            Then click <strong>Save</strong>.
                                        </li>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="outlined" onClick={() => onClose()}>
                                        Cancel
                                    </Button>

                                    <Button
                                        onClick={() => setStage(NetSuiteEventListenerWizardStage.CREATE_AND_DEPLOY)}
                                    >
                                        Next
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                );

            case NetSuiteEventListenerWizardStage.CREATE_AND_DEPLOY:
                return (
                    <Dialog open={open} onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}>
                        <NetSuiteEventListenerWizardSteps stage={stage} />
                        <DialogTitle sx={{ mb: 2 }} variant="h6">
                            Event Listener setup instructions for NetSuite
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText component="ol" start={9}>
                                <li>
                                    Click <strong>Create Script Record</strong>.
                                </li>
                                <li>
                                    On the newly opened page make sure that <strong>TYPE</strong> is pre-defined as{' '}
                                    <strong>User Event</strong>.
                                </li>
                                <li>
                                    Add a meaningful name for the script record and leave all other settings as default
                                    unless you know what you're doing.
                                </li>
                                <li>
                                    Click <strong>Save</strong>. Then click <strong>Deploy Script</strong>.
                                </li>
                                <li>
                                    For the <strong>APPLIES TO</strong> field select the Record Type that will trigger
                                    your event listener and leave all other settings as default.
                                </li>
                                <li>
                                    Finally click <strong>Save</strong>.
                                </li>
                                <strong>NOTE:</strong> If you require a more customised event, it's possible to use
                                Webhook URL with NetSuite Workflow Custom Actions.
                                <br />
                                <Link
                                    target="_blank"
                                    href="https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/chapter_N2725813.html"
                                >
                                    More on Workflows
                                </Link>
                                .
                                <ReadOnlyTextField label="Webhook URL" value={webhookUrl} />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => setStage(NetSuiteEventListenerWizardStage.UPLOAD_SCRIPT)}
                            >
                                Back
                            </Button>

                            <Button onClick={() => onClose()}>Done</Button>
                        </DialogActions>
                    </Dialog>
                );
        }
    };
    return getStageContent();
};
