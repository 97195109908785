import { getInvocationsReport as getInvocationsReportRpc } from '@avst-stitch/repository-lib/lib';
import {
    Request as InvocationsReportRequest,
    Response as InvocationsReportResponse,
    InvocationRecord,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getInvocations';
import { repositoryInvoker } from '../../utils/repository';
export type { InvocationsReportRequest, InvocationsReportResponse };

export type Invocations = InvocationRecord[];

export const getInvocationsForReport = async (
    request: InvocationsReportRequest
): Promise<InvocationsReportResponse> => {
    return await getInvocationsReportRpc(repositoryInvoker, request);
};
