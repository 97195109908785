import React from 'react';
import { styled, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import { Button } from '../../buttons/Button';
import { stepByStepSetupDialogStyles } from '../../dialog/dialogStyles';
import { IconCircle } from '../../icon-circle';
import { DialogCloseButton } from '../../dialog/DialogComponents';
import { handleKeyUp } from '../../../utils/handleKeyUp';

export interface WizardSuccessDialogProps {
    open?: boolean;
    scriptCreated?: boolean;
    onClose(): void;
    onStartDeploy(): void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    ...stepByStepSetupDialogStyles(theme),
}));

const StyledTitleContainer = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
}));

export const WizardSuccessDialog: React.FC<WizardSuccessDialogProps> = ({
    open = false,
    scriptCreated = false,
    onClose,
    onStartDeploy,
}) => {
    const theme = useTheme();
    return (
        <StyledDialog
            open={open}
            onKeyUp={(event) =>
                handleKeyUp({ event, enterFn: scriptCreated ? onStartDeploy : onClose, escFn: onClose })
            }
        >
            <DialogCloseButton onClick={onClose} />
            <StyledTitleContainer>
                <IconCircle icon={<ForkRightIcon />} background={theme.palette.background.paper} />
                <DialogTitle variant="h6">What to do next?</DialogTitle>
            </StyledTitleContainer>
            <DialogContent>
                <Typography
                    sx={(theme) => {
                        return { margin: theme.spacing(0, 0, 3) + ' !important' };
                    }}
                >
                    Congratulations! You've completed the basic steps to get a Workspace up and running!
                </Typography>
                <Typography>
                    You can start testing your Workspace by triggering Event Listeners or Scripts, either manually by
                    clicking on the Play Button, or externally. Consider deploying your Workspace when you feel you're
                    ready to do so. Deploying a Workspace prevents any future changes from being applied automatically
                    to the Environment you are working in.
                    {!scriptCreated &&
                        'Note that you will have to create a Script first in order to deploy your Workspace'}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant={scriptCreated ? 'outlined' : 'contained'} onClick={onClose}>
                    Continue Working
                </Button>
                {scriptCreated && <Button onClick={onStartDeploy}>Deployment Options</Button>}
            </DialogActions>
        </StyledDialog>
    );
};
