import { useObservableState } from 'observable-hooks';
import { UserFeedback } from '../components/user-feedback/UserFeedback';
import {
    userFeedbackDialogOpen$,
    closeUserFeedbackDialogAction$,
    userFeedbackDialogErrors$,
    savingNewUserFeedback$,
    saveUserFeedbackAction$,
} from '../store/userFeedback';
import { loggedInUserDetails$ } from '../store/user';

export const UserFeedbackContainer: React.FC = () => {
    const open = useObservableState(userFeedbackDialogOpen$);
    const errors = useObservableState(userFeedbackDialogErrors$);
    const saving = useObservableState(savingNewUserFeedback$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);

    return (
        <UserFeedback
            defaultEmail={loggedInUserDetails?.email}
            open={open}
            saving={saving}
            errors={errors}
            onClose={() => closeUserFeedbackDialogAction$.next()}
            onSend={(userFeedback) => saveUserFeedbackAction$.next(userFeedback)}
        />
    );
};
