import { useState } from 'react';
import { styled, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '../buttons/Button';
import { DialogCloseButton, DialogTitleMain } from '../dialog';
import { PageGridContainer } from '../layout';
import { InfoIcon } from '../icons/InfoIcon';
import { OnboardingAppItem } from '../onboarding/OnboardingComponents';
import { IconCircle } from '../icon-circle';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import { handleKeyUp } from '../../utils/handleKeyUp';

interface ContainerProps {
    title: string;
    isNew?: boolean;
    subtitle?: string;
    tooltip?: string;
}

interface AppDialogProps {
    apps: {
        uid: string;
        name: string;
    }[];
    userSelectedApps: string[];
    open: boolean;
    onClose: () => void;
    onUpdate: (apps: string[]) => void;
}

const StyledContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    marginBottom: theme.spacing(3),
    maxWidth: 712,
    minWidth: 364,
}));

const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
}));

const StyledBoxContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
}));

const StyledAppTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(2),
    '& .MuiDialogTitle-root': {
        margin: 0,
    },
    '& .MuiBox-root > div:first-of-type': {
        marginBottom: 0,
    },
    '& > div:first-of-type': {
        height: 52,
        width: 52,
        '& .MuiSvgIcon-root': {
            height: 32,
            width: 32,
        },
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 0),
    width: '100%',
    minHeight: 100,
}));

const StyledAppContainer = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    maxHeight: 496,
    overflow: 'auto',
    padding: theme.spacing(3),
    width: '100%',
}));

export const FullLengthDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(0, -3),
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        flexBasis: 900,
        maxWidth: 900,
        padding: theme.spacing(2),
    },
}));

export const ProfileCommonContainer: React.FC<ContainerProps> = ({
    title,
    isNew = false,
    tooltip,
    subtitle,
    children,
}) => {
    const theme = useTheme();
    return (
        <StyledContainer>
            <Container>
                <StyledBoxContainer>
                    <Typography component="h3" variant="h6">
                        {title}
                    </Typography>
                    {tooltip && (
                        <Tooltip
                            title={tooltip}
                            placement="top"
                            sx={{
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            <InfoIcon />
                        </Tooltip>
                    )}
                    {isNew && <Chip label="New" size="small" color="success" sx={{ marginLeft: 'auto' }} />}
                </StyledBoxContainer>
                <Typography variant="body1" color="text.secondary">
                    {subtitle}
                </Typography>
            </Container>
            <Divider sx={{ padding: 0 }} />
            <Container>{children}</Container>
        </StyledContainer>
    );
};

export const AppDialog: React.FC<AppDialogProps> = ({ apps, userSelectedApps, open = false, onClose, onUpdate }) => {
    const theme = useTheme();
    const [selectedApps, setSelectedApps] = useState<string[]>(userSelectedApps ?? []);

    const handleSelectApp = (uid: string): void => {
        if (selectedApps.includes(uid)) {
            setSelectedApps(selectedApps.filter((sa) => sa !== uid));
        } else {
            setSelectedApps([...selectedApps, uid]);
        }
    };

    const appItems = apps
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((app) => (
            <OnboardingAppItem
                key={app.uid}
                name={app.name}
                selected={selectedApps.includes(app.uid)}
                uid={app.uid}
                onSelect={handleSelectApp}
            />
        ));

    const updateButtonText = `Update ${selectedApps.length > 0 ? '(' + selectedApps.length + ')' : ''}`;

    return (
        <StyledDialog open={open} onClose={onClose} onKeyUp={(event) => handleKeyUp({ event, escFn: onClose })}>
            <DialogCloseButton onClick={onClose} />
            <StyledAppTitleContainer>
                <IconCircle icon={<AppsOutlinedIcon />} color={theme.palette.text.primary} />
                <Box ml={0.5}>
                    <DialogTitleMain title="What apps do you use?" />
                    <Typography variant="subtitle2" fontWeight="normal">
                        Tell us which apps you use so we can suggest improvements for you
                    </Typography>
                </Box>
            </StyledAppTitleContainer>
            <FullLengthDivider />
            <StyledContent>
                <Typography variant="subtitle1" mb={2}>
                    Select all that apply
                </Typography>
                <StyledAppContainer>
                    <PageGridContainer sx={{ padding: 0, columnGap: 1, rowGap: 2 }}>{appItems}</PageGridContainer>
                </StyledAppContainer>
            </StyledContent>
            <FullLengthDivider />
            <DialogActions sx={{ marginBottom: 0 }}>
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
                <Button
                    onClick={() => {
                        onUpdate(selectedApps);
                        onClose();
                    }}
                >
                    {updateButtonText}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};
