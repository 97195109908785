import { useState } from 'react';
import { styled, useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button } from '../buttons/Button';
import { DialogTitleMain } from '../dialog/DialogComponents';
import { IconCircle } from '../icon-circle';
import { handleKeyUp } from '../../utils/handleKeyUp';

const StyledCheckbox = styled(Checkbox)(() => ({
    padding: 0,
    height: 24,
    width: 24,
}));

const StyledConsent = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    lineHeight: '30px',
    margin: theme.spacing(-0.5, 0, 0, 0.5),
}));

const StyledConsentBox = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(3),
}));

const StyledIconBox = styled('div')(() => ({
    display: 'flex',
    flex: '0 0 32px',
    justifyContent: 'center',
}));

const StyledLink = styled(Link)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
}));

const StyledNote = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
}));

const StyledNoteBox = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    width: 460,
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        width: 24,
    },
}));

interface ConsentDialogProperties {
    onAgree(): void;
    onDisagree(): void;
    isConsentBusy?: boolean;
}

export const ConsentDialog: React.FC<ConsentDialogProperties> = ({ onAgree, onDisagree, isConsentBusy = false }) => {
    const theme = useTheme();
    const [isChecked, setChecked] = useState(false);

    return (
        <Dialog
            open={true}
            onKeyUp={(event) =>
                handleKeyUp({ event, enterCondition: isChecked && !isConsentBusy, enterFn: onAgree, escFn: onDisagree })
            }
        >
            <DialogTitleMain
                title="Consent Required"
                icon={<IconCircle icon={<WarningAmberIcon />} color={theme.palette.warning.main} />}
            />
            <DialogContent>
                <StyledConsentBox>
                    <StyledIconBox>
                        <StyledCheckbox onChange={() => setChecked(!isChecked)} />
                    </StyledIconBox>
                    <StyledConsent>
                        <strong>I agree</strong> to the terms of the{' '}
                        <StyledLink href="https://www.adaptavist.com/adaptavist-eula" target="_blank">
                            Adaptavist EULA
                        </StyledLink>
                        {', '}
                        <StyledLink href="https://www.adaptavist.com/terms-and-conditions" target="_blank">
                            Website Terms
                        </StyledLink>
                        {', '}
                        <StyledLink href="https://www.adaptavist.com/privacy-policy" target="_blank">
                            Privacy Policy
                        </StyledLink>
                        {', and the '}
                        <StyledLink href="https://www.adaptavist.com/dpa" target="_blank">
                            DPA
                        </StyledLink>
                        {'.'}
                    </StyledConsent>
                </StyledConsentBox>
                <StyledNoteBox>
                    <StyledIconBox>
                        <CookieOutlinedIcon />
                    </StyledIconBox>
                    <StyledNote>We use cookies to make your experience better.</StyledNote>
                </StyledNoteBox>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDisagree} disabled={isConsentBusy} variant="outlined">
                    Disagree
                </Button>
                <Button busy={isConsentBusy} disabled={!isChecked || isConsentBusy} onClick={onAgree}>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
};
