import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { Button } from '../../buttons/Button';
import { MondayIcon } from '../../icons/MondayIcon';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { MondayWizardStage, MondayWizardSteps, StageType } from './MondayWizardSteps';
import { DialogAlert, DialogTitleMain } from '../../dialog';

export interface MondayConnection {
    instanceUrl: string;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: MondayWizardStage;
    setStage: (stage: MondayWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    installUrl: string;
}

export interface onSaveProps {
    instanceUrl: string;
}

const StageContent: StageType = (props) => {
    const [instanceUrl, setInstanceUrl] = useState(props.instanceUrl);

    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);

    const INSTALLED_APPS_PATH = '/admin/installedApps/manage';
    const MONDAY_DOMAIN = 'monday.com';

    const generateInstalledAppsUrl = (): string => {
        return instanceUrl.endsWith(INSTALLED_APPS_PATH)
            ? instanceUrl
            : instanceUrl.endsWith('/')
            ? instanceUrl.substring(0, instanceUrl.length - 1) + INSTALLED_APPS_PATH
            : instanceUrl + INSTALLED_APPS_PATH;
    };

    switch (props.stage) {
        case MondayWizardStage.ADDURL:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>This connector requires the monday.com account url.</DialogContentText>
                        <TextField
                            label={`https://<MONDAY_ACCOUNT>.${MONDAY_DOMAIN}`}
                            value={instanceUrl}
                            onChange={(e) => {
                                if (badUrlAttempted) {
                                    props.clearErrors();
                                    setBadUrlAttempted(false);
                                }
                                setInstanceUrl(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={async () => {
                                if (!instanceUrl.includes(MONDAY_DOMAIN)) {
                                    props.setError(`Please enter a valid Workspace URL ending with ${MONDAY_DOMAIN}`);
                                    setBadUrlAttempted(true);
                                } else {
                                    setInstanceUrl(instanceUrl);
                                    props.setStage(MondayWizardStage.INSTALL);
                                    setBadUrlAttempted(false);
                                }
                            }}
                            disabled={!instanceUrl}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case MondayWizardStage.INSTALL:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`To access information in monday.com, ScriptRunner Connect needs to be installed in the monday.com account.`}
                    />

                    <DialogAlert
                        severity="info"
                        text={`To install ScriptRunner Connect in the monday.com account or check if it's already installed, you need administrator privileges.
                            You can give the installation url below to an admin of the account to check and install ScriptRunner Connect for you.`}
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Give an administrator of the account the <strong>Installed Apps Link</strong> below so
                                they can check if ScriptRunner Connect is already installed.
                            </li>
                            <li>
                                If ScriptRunner Connect is already installed, click <strong>Next</strong>.
                            </li>
                            <li>
                                If ScriptRunner Connect is not installed, copy the <strong>Installation Link</strong>{' '}
                                below and give it to an administrator to paste on their browser and install ScriptRunner
                                Connect.
                            </li>
                            <li>
                                Click <strong>Save</strong>.
                            </li>
                            <ReadOnlyTextField label="Installed Apps Link" value={generateInstalledAppsUrl()} />
                            <ReadOnlyTextField label="Installation Link" value={props.installUrl} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(MondayWizardStage.ADDURL)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(MondayWizardStage.AUTHORIZE)}>Next</Button>
                    </DialogActions>
                </>
            );
        case MondayWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in monday.com you need to authorize our app to be able to make
                            requests on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(MondayWizardStage.INSTALL);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            onClick={() =>
                                props.onSave({
                                    instanceUrl,
                                })
                            }
                        >
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case MondayWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const MondayAppConfigureDialog: React.FC<MondayConnection> = (props) => {
    const error = <DialogAlert severity="error" alertTitle="Error" text={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<MondayIcon />} />
            {props.errors && error}

            <MondayWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
