import { ReactNode } from 'react';
import { styled } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { AvatarContainer } from '../../avatar';
import { ProductIcon } from '../../icons/ProductIcon';
import {
    SelectorAppIconsInner,
    SelectorAppIconsOuter,
    SelectorImageBox,
    SelectorTableRow,
    selectorUserAvatarsStyle,
    StyledStatus,
    SelectorItemDescription,
    SelectorItemTitle,
    selectorCardIconWrapStyle,
} from '../../selector/SelectorComponents';
import { ThreeDotsButton } from '../../buttons/ThreeDotsButton';
import { handleDisplayName } from '../../../utils/displayName';

interface WorkspaceSelectorTableItemProps {
    description?: string;
    environments: {
        deployment?: {
            uid: string;
            version: string;
        };
        name: string;
        selected: boolean;
        uid: string;
    }[];
    incoming?: string[];
    orgName?: string; // Don't show in the card if it's not defined
    outgoing?: string[];
    shared?: boolean;
    // status?: 'ACTIVE' | 'INACTIVE';
    template: boolean;
    title?: string;
    uid: string;
    users: {
        avatar?: string;
        email: string;
        firstName?: string;
        lastName?: string;
    }[];
    onDelete(uid: string): void;
    onDuplicate(uid: string): void;
    onSelect(uid: string): void;
}

export const StyledTextCell = styled(TableCell)(() => ({
    minWidth: 200,
    width: '20%',
}));

export const StyledIconCell = styled(TableCell)(() => ({
    minWidth: 190,
    width: '12%',
    '& .MuiTableCell-head': {
        alignItems: 'center',
        display: 'inline-flex',
        flexDirection: 'row',
    },
}));

export const StyledShortCell = styled(TableCell)(() => ({
    textAlign: 'center',
    width: '4%',
}));

const StyledUserAvatars = styled('div')(({ theme }) => ({
    ...selectorUserAvatarsStyle(),
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(1),
    width: '100%',
    '& > div:not(:nth-of-type(5))': {
        marginLeft: `-${theme.spacing(1.5)}`,
    },
}));

const StyledIconContainer = styled('div')(({ theme }) => ({
    ...selectorCardIconWrapStyle(theme),
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
    },
}));

export const WorkspaceSelectorTableItem: React.FC<WorkspaceSelectorTableItemProps> = ({
    description,
    environments,
    incoming = [],
    orgName,
    outgoing = [],
    shared = false,
    // status = 'INACTIVE',
    template,
    title,
    uid,
    users,
    onDelete,
    onDuplicate,
    onSelect,
}) => {
    const ENVIRONMENTS_TOOLTIP = environments
        .map((environment) => {
            return `${environment.name}${environment.deployment ? ' - v' + environment.deployment?.version : ''}`;
        })
        .join(', ');

    const iconList = (connections: string[]): ReactNode[] => {
        return connections.map((connection, index) => {
            return (
                <SelectorAppIconsInner key={index}>
                    <Tooltip title={connection} describeChild>
                        <StyledIconContainer>
                            <ProductIcon name={connection} />
                        </StyledIconContainer>
                    </Tooltip>
                </SelectorAppIconsInner>
            );
        });
    };

    return (
        <SelectorTableRow onClick={() => onSelect(uid)}>
            <StyledTextCell>
                <SelectorItemTitle title={title} block={true} />
            </StyledTextCell>
            <StyledTextCell>
                <SelectorItemDescription description={description} />
            </StyledTextCell>
            <StyledIconCell>
                <SelectorAppIconsOuter>{iconList(incoming)}</SelectorAppIconsOuter>
            </StyledIconCell>
            <StyledIconCell>
                <SelectorAppIconsOuter>{iconList(outgoing)}</SelectorAppIconsOuter>
            </StyledIconCell>
            <StyledShortCell>
                <StyledUserAvatars>
                    {users.map((user, index) => {
                        return user.avatar ? (
                            <SelectorImageBox key={'userAvatar' + index}>
                                <Tooltip
                                    key={'userAvatar' + index}
                                    title={handleDisplayName(user.email, user.firstName, user.lastName)}
                                >
                                    <img
                                        src={`data:image/jpeg;base64,${user.avatar}`}
                                        width={30}
                                        height={30}
                                        alt={user.firstName + ' ' + user.lastName}
                                    />
                                </Tooltip>
                            </SelectorImageBox>
                        ) : (
                            <Tooltip
                                key={'userAvatar' + index}
                                title={handleDisplayName(user.email, user.firstName, user.lastName)}
                            >
                                <div>
                                    <AvatarContainer
                                        key={'userAvatar' + index}
                                        credentials={{
                                            firstName: user.firstName ?? '',
                                            lastName: user.lastName ?? '',
                                            email: user.email ?? '',
                                        }}
                                        size="small"
                                    />
                                </div>
                            </Tooltip>
                        );
                    })}
                </StyledUserAvatars>
            </StyledShortCell>
            <StyledTextCell>
                <Typography variant="subtitle2">{orgName}</Typography>
            </StyledTextCell>
            <StyledShortCell>
                <Tooltip title={ENVIRONMENTS_TOOLTIP} placement="top">
                    <Badge badgeContent={environments.length} color="primary" />
                </Tooltip>
            </StyledShortCell>
            <StyledShortCell>
                <Box display="flex" flexDirection="column" marginBottom={1}>
                    {/* <StyledStatus
                        label={status === 'ACTIVE' ? 'Active' : 'Inactive'}
                        sx={{
                            backgroundColor: status === 'ACTIVE' ? 'success.main' : 'action.disabledBackground',
                            color: status === 'ACTIVE' ? 'primary.contrastText' : 'text.primary',
                        }}
                    /> */}
                    {template && <StyledStatus label="Template" sx={{ backgroundColor: 'warning.light' }} />}
                    {shared && <StyledStatus label="Shared" sx={{ backgroundColor: 'success.light' }} />}
                </Box>
            </StyledShortCell>
            <StyledShortCell>
                <ThreeDotsButton
                    uid={uid}
                    actions={[
                        { name: 'Duplicate', onSelect: onDuplicate, icon: <CopyAllOutlinedIcon /> },
                        { name: 'Delete', onSelect: onDelete, icon: <DeleteOutlineIcon /> },
                    ]}
                />
            </StyledShortCell>
        </SelectorTableRow>
    );
};
