import { LoaderFn, UnloaderFn } from 'react-location';
import { segmentAnalyticsTrack } from '../data/segment-analytics';
import { getTemplate } from '../data/template';
import { getWorkspace } from '../data/workspace';
import { LocationGenerics } from '../router';
import { loadApps } from '../store/apps';
import { Language } from '../store/editor/types';
import { selectedReadOnlyTemplate$ } from '../store/templates';
import {
    exitWorkspaceAction$,
    selectedWorkspace$,
    selectedWorkspaceReadOnlyMode$,
    selectedWorkspaceUid$,
    selectEnvironmentAction$,
    selectWorkspaceAction$,
} from '../store/workspace';
import { loadEnvironmentVariables, loadWorkspaces } from '../store/workspace/utils';
import { initEditor, resetEditor } from './workspace/editor';
import { InformativeError } from '../utils/repository';
import { loadErrorPage } from '../store/error';
import { loggedInUserDetails$ } from '../store/user';

export const readOnlyTemplateLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const templateUid = routeMatch.params.templateUid!;

        if (!selectedReadOnlyTemplate$.value) {
            const template = await getTemplate(templateUid);
            selectedReadOnlyTemplate$.next(template);
        }

        const selectedTemplate = selectedReadOnlyTemplate$.value;
        if (!selectedTemplate) {
            throw new InformativeError('Could not fetch the selected template');
        }
        const [workspace] = await Promise.all([
            getWorkspace(selectedTemplate.workspaceUid, false, true),
            loadWorkspaces(false),
            loadApps(),
            loadEnvironmentVariables(
                selectedReadOnlyTemplate$.value?.workspaceUid ?? '',
                selectedReadOnlyTemplate$.value?.environmentUid ?? ''
            ),
        ]);

        selectedWorkspaceReadOnlyMode$.next(true);
        selectEnvironmentAction$.next(selectedTemplate.environmentUid);
        selectWorkspaceAction$.next(selectedTemplate.workspaceUid);
        selectedWorkspace$.next(workspace);

        initEditor(workspace.language as Language);

        segmentAnalyticsTrack('Template Viewed', {
            template: {
                uid: selectedTemplate.templateUid,
                name: selectedTemplate.name,
            },
            userOrigin: loggedInUserDetails$.value?.userOrigin,
        });
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load template.',
        });

        throw e;
    }

    return {};
};

export const readOnlyTemplateUnloader: UnloaderFn<LocationGenerics> = () => {
    exitWorkspaceAction$.next(selectedWorkspaceUid$.value ?? '');
    selectedReadOnlyTemplate$.next(undefined);
    resetEditor();
};
