import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { JiraServiceManagementIcon } from '../../icons/JiraServiceManagementIcon';
import { OpenInNewLink } from '../../link/OpenInNewLink';
import { Button } from '../../buttons/Button';
import { DialogAlert, DialogTitleMain } from '../../dialog';

interface JsmCloudAssetsAppConfigureDialogProps {
    errors?: string;
    onSave(apiKey: string): void;
    onCancel(): void;
    open: boolean;
    saving?: boolean;
}

export const JsmCloudAssetsAppConfigureDialog: React.FC<JsmCloudAssetsAppConfigureDialogProps> = ({
    errors,
    onCancel,
    onSave,
    open = false,
    saving,
}) => {
    const [email, setEmail] = useState('');
    const [apiToken, setApiToken] = useState('');

    const handleSave = (): void => {
        if (!!email && !!apiToken) {
            onSave(btoa(`${email}:${apiToken}`));
        }
    };

    const handleCancel = (): void => {
        setEmail('');
        setApiToken('');
        onCancel();
    };

    return (
        <Dialog open={open}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<JiraServiceManagementIcon />} />
            {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
            <DialogContent>
                <DialogContentText component="ol" sx={{ paddingLeft: 2.5 }}>
                    <li>Jira Service Management Cloud Assets REST API supports basic authentication.</li>
                    <li>
                        Insert an Atlassian account username to <strong>Email</strong> field.
                    </li>
                    <li>
                        Log in to{' '}
                        <OpenInNewLink url={'https://id.atlassian.com/manage-profile/security/api-tokens'}>
                            API tokens
                        </OpenInNewLink>{' '}
                        page.
                    </li>
                    <li>
                        Click <strong>Create API token</strong>, enter a Label for your token and click{' '}
                        <strong>Create</strong>.
                    </li>
                    <li>
                        Click <strong>Copy</strong> and paste the token to <strong>API token</strong> field.
                    </li>
                </DialogContentText>
                <TextField
                    variant="outlined"
                    label="Email"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                />
                <TextField
                    variant="outlined"
                    label="API token"
                    onChange={(event) => setApiToken(event.target.value)}
                    value={apiToken}
                    type="password"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} variant="outlined">
                    Cancel
                </Button>
                <Button
                    busy={saving}
                    onClick={handleSave}
                    disabled={!email || !apiToken}
                    variant="contained"
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
