import styled from '@mui/system/styled';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import { APP } from '@avst-stitch/repository-lib/constants';

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
}));

const appName = APP.GITHUB.NAME;
const eventTypes = APP.GITHUB.WEBHOOK.EVENT_TYPES;

const REPOSITORIES = 'Repositories';
const ORGANIZATIONS = 'Organizations';
const GITHUB_APPS = 'GitHub apps';
const ENTERPRISES = 'Enterprises';
const MARKETPLACE = 'GitHub Marketplace';
const SPONSORSHIP = 'Sponsored account';

export type Scope =
    | typeof REPOSITORIES
    | typeof ORGANIZATIONS
    | typeof GITHUB_APPS
    | typeof ENTERPRISES
    | typeof MARKETPLACE
    | typeof SPONSORSHIP;

export const getMappedWebhooks = (eventTypeName: string): { scopes: Scope[]; alternativeName?: string } | null => {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (eventTypeName) {
        case eventTypes.BRANCH_OR_TAG_CREATION.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Create' };
        case eventTypes.BRANCH_OR_TAG_DELETION.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Delete' };
        case eventTypes.BRANCH_PROTECTION_CONFIGURATION.NAME:
            return {
                scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS],
                alternativeName: 'Branch protection configuration',
            };
        case eventTypes.BRANCH_PROTECTION_RULE.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Branch protection rule' };
        case eventTypes.CHECK_RUN.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Check run' };
        case eventTypes.CHECK_SUITE.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Check suite' };
        case eventTypes.CODE_SCANNING_ALERT.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Code scanning alert' };
        case eventTypes.COLLABORATOR.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, ENTERPRISES, GITHUB_APPS], alternativeName: 'Member' };
        case eventTypes.COMMIT_COMMENT.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Commit comment' };
        case eventTypes.CUSTOM_PROPERTY.NAME:
            return { scopes: [ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Custom property' };
        case eventTypes.CUSTOM_PROPERTY_VALUE.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS] };
        case eventTypes.DEPENDABOT_ALERT.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Dependabot alert' };
        case eventTypes.DEPLOYMENT.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Deployment' };
        case eventTypes.DEPLOYMENT_PROTECTION_RULE.NAME:
            return { scopes: [GITHUB_APPS] };
        case eventTypes.DEPLOYMENT_REVIEW.NAME:
            return { scopes: [GITHUB_APPS] };
        case eventTypes.DEPLOYMENT_STATUS.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Deployment status' };
        case eventTypes.DEPLOY_KEY.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Deploy key' };
        case eventTypes.DISCUSSION.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Discussion' };
        case eventTypes.DISCUSSION_COMMENT.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Discussion comment' };
        case eventTypes.FORK.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, ENTERPRISES, GITHUB_APPS], alternativeName: 'Fork' };
        case eventTypes.GITHUB_APP_AUTHORIZATION.NAME:
            return { scopes: [GITHUB_APPS] };
        case eventTypes.INSTALLATION.NAME:
            return { scopes: [GITHUB_APPS] };
        case eventTypes.INSTALLATION_REPOSITORIES.NAME:
            return { scopes: [GITHUB_APPS] };
        case eventTypes.INSTALLATION_TARGET.NAME:
            return { scopes: [GITHUB_APPS] };
        case eventTypes.ISSUE.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Issue' };
        case eventTypes.ISSUE_COMMENT.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Issue comment' };
        case eventTypes.LABEL.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Label' };
        case eventTypes.MARKETPLACE_PURCHASE.NAME:
            return { scopes: [MARKETPLACE] };
        case eventTypes.MEMBERSHIP.NAME:
            return { scopes: [ORGANIZATIONS, ENTERPRISES, GITHUB_APPS], alternativeName: 'Membership' };
        case eventTypes.MERGE_GROUP.NAME:
            return { scopes: [ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Merge group' };
        case eventTypes.META.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, ENTERPRISES, GITHUB_APPS, MARKETPLACE] };
        case eventTypes.MILESTONE.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Milestone' };
        case eventTypes.ORGANIZATION.NAME:
            return { scopes: [ORGANIZATIONS, ENTERPRISES, GITHUB_APPS], alternativeName: 'Organization' };
        case eventTypes.ORG_BLOCK.NAME:
            return { scopes: [ORGANIZATIONS, ENTERPRISES, GITHUB_APPS], alternativeName: 'Org block' };
        case eventTypes.PACKAGE.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Package' };
        case eventTypes.PAGE_BUILD.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Page build' };
        case eventTypes.PERSONAL_ACCESS_TOKEN_REQUEST.NAME:
            return { scopes: [GITHUB_APPS] };
        case eventTypes.PING.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, ENTERPRISES, GITHUB_APPS, MARKETPLACE] };
        case eventTypes.PROJECT.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Project' };
        case eventTypes.PROJECT_CARD.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Project card' };
        case eventTypes.PROJECT_COLUMN.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Project column' };
        case eventTypes.PROJECT_V2.NAME:
            return { scopes: [ORGANIZATIONS, GITHUB_APPS] };
        case eventTypes.PROJECT_V2_ITEM.NAME:
            return { scopes: [ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Projects v2 item' };
        case eventTypes.PUBLIC.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Public' };
        case eventTypes.PULL_REQUEST.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Pull request' };
        case eventTypes.PULL_REQUEST_REVIEW.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Pull request review' };
        case eventTypes.PULL_REQUEST_REVIEW_COMMENT.NAME:
            return {
                scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS],
                alternativeName: 'Pull request review comment',
            };
        case eventTypes.PULL_REQUEST_REVIEW_THREAD.NAME:
            return {
                scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS],
                alternativeName: 'Pull request review thread',
            };
        case eventTypes.PUSH.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Push' };
        case eventTypes.REGISTRY_PACKAGE.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Registry package' };
        case eventTypes.RELEASE.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Release' };
        case eventTypes.REPOSITORY.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, ENTERPRISES, GITHUB_APPS], alternativeName: 'Repository' };
        case eventTypes.REPOSITORY_ADVISORY.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Repository advisory' };
        case eventTypes.REPOSITORY_DISPATCH.NAME:
            return { scopes: [GITHUB_APPS] };
        case eventTypes.REPOSITORY_IMPORT.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS], alternativeName: 'Repository import' };
        case eventTypes.REPOSITORY_RULESET.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Repository ruleset' };
        case eventTypes.SECRET_SCANNING_ALERT.NAME:
            return {
                scopes: [REPOSITORIES, ORGANIZATIONS, ENTERPRISES, GITHUB_APPS],
                alternativeName: 'Secret scanning alert',
            };
        case eventTypes.SECRET_SCANNING_ALERT_LOCATION.NAME:
            return {
                scopes: [REPOSITORIES, ORGANIZATIONS, ENTERPRISES, GITHUB_APPS],
                alternativeName: 'Secret scanning alert location',
            };
        case eventTypes.SECURITY_ADVISORY.NAME:
            return { scopes: [GITHUB_APPS] };
        case eventTypes.SECURITY_AND_ANALYSIS.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Security and analysis' };
        case eventTypes.SPONSORSHIP.NAME:
            return { scopes: [SPONSORSHIP] };
        case eventTypes.STAR.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Star' };
        case eventTypes.STATUS.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Status' };
        case eventTypes.TEAM.NAME:
            return { scopes: [ORGANIZATIONS, ENTERPRISES, GITHUB_APPS], alternativeName: 'Team' };
        case eventTypes.TEAM_ADD.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Team add' };
        case eventTypes.WATCH.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, GITHUB_APPS], alternativeName: 'Watch' };
        case eventTypes.WIKI.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, ENTERPRISES, GITHUB_APPS], alternativeName: 'Gollum' };
        case eventTypes.WORKFLOW_DISPATCH.NAME:
            return { scopes: [GITHUB_APPS] };
        case eventTypes.WORKFLOW_JOB.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, ENTERPRISES, GITHUB_APPS], alternativeName: 'Workflow job' };
        case eventTypes.WORKFLOW_RUN.NAME:
            return { scopes: [REPOSITORIES, ORGANIZATIONS, ENTERPRISES, GITHUB_APPS], alternativeName: 'Workflow run' };
        default:
            return null;
    }
};

export const getWebhookOptions = (eventTypeName: string): JSX.Element[] | null => {
    const mappedWebhooks = getMappedWebhooks(eventTypeName);
    return (
        mappedWebhooks &&
        mappedWebhooks.scopes.map((type) => (
            <StyledFormControlLabel key={type} value={type} control={<Radio />} label={type} />
        ))
    );
};

export const getSetupInstructions = (webhookType: Scope, eventTypeName: string): JSX.Element => {
    switch (webhookType) {
        case REPOSITORIES:
            return (
                <DialogContentText component="ol">
                    <li>Copy the URL above.</li>
                    <li>
                        Visit your
                        <Link target="_blank" href="https://www.github.com">
                            {' '}
                            {appName} account
                        </Link>
                        .
                    </li>
                    <li>
                        Navigate to the repository you wish to setup the event listener for and click on{' '}
                        <strong>Settings</strong>.
                    </li>
                    <li>
                        Select the <strong>Webhooks</strong> tab on the left side menu.
                    </li>
                    <li>
                        Click on <strong>Add webhook</strong>.
                    </li>
                    <li>
                        Paste the copied URL into the <strong>Payload URL</strong> field.
                    </li>
                    <li>
                        In the <strong>Content type</strong> dropdown choose <strong>application/json</strong>.
                    </li>
                    <li>
                        Select the option <strong>Let me select individual events</strong>.
                    </li>
                    <li>
                        Check ONLY the following event to listen to: <strong>{eventTypeName}</strong>.
                    </li>
                    <li>
                        Make sure that the checkbox <strong>Active</strong> is checked.
                    </li>
                    <li>
                        Click on <strong>Add webhook</strong>.
                    </li>
                    <li>
                        If successful, you should see your webhook appear in the <strong>Webhooks</strong> list.
                    </li>
                    {eventTypeName !== eventTypes.PING.NAME && (
                        <li>
                            Keep in mind that this webhook will also automatically listen to the{' '}
                            <strong>{eventTypes.PING.NAME}</strong> event as well, so make sure to add an explicit check
                            to your script to stop the logic from running when triggered by a wrong event.
                        </li>
                    )}
                </DialogContentText>
            );
        case ORGANIZATIONS:
            return (
                <DialogContentText component="ol">
                    <li>Copy the URL above.</li>
                    <li>
                        Visit your
                        <Link target="_blank" href="https://www.github.com">
                            {' '}
                            {appName} account
                        </Link>
                        .
                    </li>
                    <li>
                        Navigate to the organization you wish to setup the event listener for and click on{' '}
                        <strong>Settings</strong>.
                    </li>
                    <li>
                        Select the <strong>Webhooks</strong> tab on the left side menu.
                    </li>
                    <li>
                        Click on <strong>Add webhook</strong>.
                    </li>
                    <li>
                        Paste the copied URL into the <strong>Payload URL</strong> field.
                    </li>
                    <li>
                        In the <strong>Content type</strong> dropdown choose <strong>application/json</strong>.
                    </li>
                    <li>
                        Select the option <strong>Let me select individual events</strong>.
                    </li>
                    <li>
                        Check ONLY the following event to listen to: <strong>{eventTypeName}</strong>.
                    </li>
                    <li>
                        Make sure that the checkbox <strong>Active</strong> is checked.
                    </li>
                    <li>
                        Click on <strong>Add webhook</strong>.
                    </li>
                    <li>
                        If successful, you should see your webhook appear in the <strong>Webhooks</strong> list.
                    </li>
                    {eventTypeName !== eventTypes.PING.NAME && (
                        <li>
                            Keep in mind that this webhook will also automatically listen to the{' '}
                            <strong>{eventTypes.PING.NAME}</strong> event as well, so make sure to add an explicit check
                            to your script to stop the logic from running when triggered by a wrong event.
                        </li>
                    )}
                </DialogContentText>
            );
        case GITHUB_APPS:
            return (
                <DialogContentText component="ol">
                    <li>Copy the URL above.</li>
                    <li>
                        Visit your
                        <Link target="_blank" href="https://www.github.com">
                            {' '}
                            {appName} account
                        </Link>
                        .
                    </li>
                    <li>
                        GitHub apps can be installed on a personal account or in an organization. To setup a webhook in
                        an existing GitHub app, either click on your profile photo in the top right corner, and choose{' '}
                        <strong>Settings</strong> or instead, navigate to the organization where the app is installed
                        and find <strong>Settings</strong> there.
                    </li>
                    <li>
                        Scroll down to <strong>Developer settings</strong> and select <strong>Github Apps</strong>{' '}
                    </li>
                    <li>
                        If you have any apps installed you should see them listed now. Find the app where you wish to
                        setup the webhook and click <strong>Edit</strong>. Alternatively you can create a new app by
                        clicking on <strong>New GitHub App</strong>.
                    </li>
                    <li>
                        Scroll down to the <strong>Webhook</strong> section.
                    </li>
                    <li>
                        Paste the copied URL into the <strong>Webhook URL</strong> field.
                    </li>
                    <li>
                        Make sure that the checkbox <strong>Active</strong> is checked.
                    </li>
                    <li>
                        If you created a new app, make sure to fill in the rest of the required fields. Otherwise click
                        on <strong>Save changes</strong>
                    </li>

                    <li>
                        Find the <strong>Permissions & Events</strong> tab on the left menu, or if you are creating a
                        new app, find the section by scrolling down.
                    </li>
                    <li>
                        Select the relevant permissions for your app depending on the event type you've picked. If you
                        are not sure which permissions you need, please find information on your chosen event type from
                        the official{' '}
                        <Link
                            target="_blank"
                            href="https://docs.github.com/en/developers/webhooks-and-events/webhooks/webhook-events-and-payloads"
                        >
                            documentation
                        </Link>
                        .
                    </li>
                    {eventTypeName === eventTypes.GITHUB_APP_AUTHORIZATION.NAME ??
                    eventTypeName === eventTypes.PING.NAME ? (
                        <li>
                            Your app will automatically listen to the <strong>{eventTypeName}</strong> event. Be sure
                            however to have no other events selected under the <strong>Subscribe to events</strong>{' '}
                            section.
                        </li>
                    ) : (
                        <li>
                            Scroll down to the <strong>Subscribe to events</strong> section and check ONLY the following
                            event to listen to:{' '}
                            <strong>{getMappedWebhooks(eventTypeName)?.alternativeName ?? eventTypeName}</strong>. If
                            you don't see this event type listed, you will probably need to add some extra permissions
                            in step 11.
                        </li>
                    )}
                    <li>
                        Click on the button below, which says either <strong>Save changes</strong> or{' '}
                        <strong>Create GitHub app</strong> depending on whether you are editing an existing app or
                        creating a new one. In case of a new app, make sure to also install it either on your personal
                        account or in an organization.
                    </li>
                    {eventTypeName === eventTypes.GITHUB_APP_AUTHORIZATION.NAME ? (
                        <li>
                            Keep in mind that this webhook will also automatically listen to the{' '}
                            <strong>{eventTypes.PING.NAME}</strong> event as well, so make sure to add an explicit check
                            to your script to stop the logic from running when triggered by a wrong event.
                        </li>
                    ) : eventTypeName === eventTypes.PING.NAME ? (
                        <li>
                            Keep in mind that this webhook will also automatically listen to the{' '}
                            <strong>{eventTypes.GITHUB_APP_AUTHORIZATION.NAME}</strong> event as well, so make sure to
                            add an explicit check to your script to stop the logic from running when triggered by a
                            wrong event.
                        </li>
                    ) : (
                        <li>
                            Keep in mind that this webhook will also automatically listen to the{' '}
                            <strong>{eventTypes.GITHUB_APP_AUTHORIZATION.NAME}</strong> and{' '}
                            <strong>{eventTypes.PING.NAME}</strong> events as well, so make sure to add an explicit
                            check to your script to stop the logic from running when triggered by a wrong event.
                        </li>
                    )}
                </DialogContentText>
            );
        case ENTERPRISES:
            return (
                <DialogContentText component="ol">
                    <li>Copy the URL above.</li>
                    <li>
                        Visit your
                        <Link target="_blank" href="https://www.github.com">
                            {' '}
                            {appName} account
                        </Link>
                        .
                    </li>
                    <li>Navigate to the enterprise you wish to setup the event listener for.</li>
                    <li>
                        Select <strong>Settings</strong> from the left hand menu and click on<strong>Hooks</strong>.
                    </li>
                    <li>
                        Click on <strong>Add webhook</strong>.
                    </li>
                    <li>
                        Paste the copied URL into the <strong>Payload URL</strong> field.
                    </li>
                    <li>
                        In the <strong>Content type</strong> dropdown choose <strong>application/json</strong>.
                    </li>
                    <li>
                        Select the option <strong>Let me select individual events</strong>.
                    </li>
                    <li>
                        Check ONLY the following event to listen to: <strong>{eventTypeName}</strong>.
                    </li>
                    <li>
                        Make sure that the checkbox <strong>Active</strong> is checked.
                    </li>
                    <li>
                        Click on <strong>Add webhook</strong>.
                    </li>
                    <li>
                        If successful, you should see your webhook appear in the <strong>Webhooks</strong> list.
                    </li>
                    {eventTypeName !== eventTypes.PING.NAME && (
                        <li>
                            Keep in mind that this webhook will also automatically listen to the{' '}
                            <strong>{eventTypes.PING.NAME}</strong> event as well, so make sure to add an explicit check
                            to your script to stop the logic from running when triggered by a wrong event.
                        </li>
                    )}
                </DialogContentText>
            );
        case MARKETPLACE:
            return (
                <DialogContentText component="ol">
                    <li>Copy the URL above.</li>
                    <li>
                        Webhooks can be setup for apps listed in GitHub Marketplace where you are either the owner or
                        app manager. To see all your apps in the Marketplace, visit your{' '}
                        <Link target="_blank" href="https://github.com/marketplace/manage">
                            listings page
                        </Link>
                    </li>
                    <li>
                        Choose the app which you wish to setup the webhook for and click on{' '}
                        <strong>Manage listing</strong>. Keep in mind that only one webhook is allowed per listing.
                    </li>
                    <li>
                        Click on <strong>Webhook</strong> on the left hand menu.
                    </li>
                    <li>
                        Paste the copied URL into the <strong>Payload URL</strong> field.
                    </li>
                    <li>
                        In the <strong>Content type</strong> dropdown choose <strong>application/json</strong>.
                    </li>
                    <li>
                        Make sure that the checkbox <strong>Active</strong> is checked.
                    </li>
                    <li>
                        This webhook will automatically listen to all Marketplace events, so you will need to add an
                        explicit check to your script to have the logic run only when the{' '}
                        <strong>{eventTypeName}</strong> event fires.
                    </li>
                    <li>
                        Click on <strong>Update webhook</strong>. Please note that the webhook cannot be deleted, but
                        can be deactivated by unchecking the <strong>Active</strong> checkbox.
                    </li>
                </DialogContentText>
            );
        case SPONSORSHIP:
            return (
                <DialogContentText component="ol">
                    <li>Copy the URL above.</li>
                    <li>
                        Visit your
                        <Link target="_blank" href="https://www.github.com">
                            {' '}
                            {appName} account
                        </Link>
                        .
                    </li>
                    <li>
                        Click on your profile photo in top right corner and select
                        <strong>Your sponsors</strong>.
                    </li>
                    <li>
                        If you have sponsorships, you should see them listed now. Next to the account you want to create
                        a webhook for, click <strong>Dashboard</strong>.
                    </li>
                    <li>
                        Select the <strong>Webhooks</strong> tab in the left sidebar.
                    </li>
                    <li>
                        Click on <strong>Add webhook</strong>.
                    </li>
                    <li>
                        Paste the copied URL into the <strong>Payload URL</strong> field.
                    </li>
                    <li>
                        In the <strong>Content type</strong> dropdown choose <strong>application/json</strong>.
                    </li>
                    <li>
                        Make sure that the checkbox <strong>Active</strong> is checked.
                    </li>
                    <li>
                        Click on <strong>Add webhook</strong>.
                    </li>
                </DialogContentText>
            );
    }
};
