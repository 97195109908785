import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { ProductIcon } from '../../components/icons/ProductIcon';
import { ApiHandlerDetails } from '../../components/workspace/api-handler-details';
import { apps$ } from '../../store/apps';
import { connectionCreatedAction$, openConnectionDetailsDialogAction$ } from '../../store/connection';
import { loggedInUserConnections$ } from '../../store/connections';
import {
    updateWizardStateAction$,
    wizardCreatedUnsavedWorkspaceResourceUid$,
    WizardState,
    wizardState$,
    WizardStep,
    wizardStep$,
} from '../../store/wizard';
import {
    selectedWorkspace$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
} from '../../store/workspace';
import {
    saveApiHandlerAction$,
    savingSelectedApiHandler$,
    selectedApiHandlerDetails$,
    selectedApiHandlerErrors$,
} from '../../store/workspace/api-handler';
import { getUserDisplayName } from '../../utils/user';
import { useState } from 'react';

export const ApiHandlerDetailsContainer: React.FC = () => {
    const navigate = useNavigate();

    const [createdConnectionUid, setCreatedConnectionUid] = useState<string | undefined>(undefined);

    const errors = useObservableState(selectedApiHandlerErrors$);
    const details = useObservableState(selectedApiHandlerDetails$);
    const saving = useObservableState(savingSelectedApiHandler$);
    const connections = useObservableState(loggedInUserConnections$);
    const apps = useObservableState(apps$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedWorkspaceEnvironment = useObservableState(selectedWorkspaceEnvironment$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);
    const wizardCreatedUnsavedWorkspaceResourceUid = useObservableState(wizardCreatedUnsavedWorkspaceResourceUid$);
    const wizardState = useObservableState(wizardState$);
    const wizardStep = useObservableState(wizardStep$);

    useSubscription(connectionCreatedAction$, (connectionUid) => {
        setCreatedConnectionUid(connectionUid);
    });

    const vendorApiVersions = workspaceResources.apiHandlers
        .flatMap((handler) =>
            handler.uid === details?.uid
                ? handler.libraries.map((library) => ({
                      value: library.uid,
                      name: library.deprecated
                          ? `${library.label} (deprecated)`
                          : library.recommended
                          ? `${library.label} (recommended)`
                          : library.label || '',
                      deprecated: library.deprecated,
                      recommended: library.recommended,
                  }))
                : []
        )
        .sort((l1, l2) => (l2.recommended ? 1 : 0) - (l1.recommended ? 1 : 0));

    const filteredConnections = connections
        .filter((c) => c.connectionType.uid === details?.connectionTypeUid)
        .map((c) => ({
            value: c.uid,
            name: c.name,
            authorized: c.authorized,
            icon: <ProductIcon name={c.connectionType.name} />,
        }));

    if (details?.selectedSharedConnection) {
        filteredConnections.unshift({
            value: details.selectedSharedConnection.uid,
            name: `${details.selectedSharedConnection.name} (owned by ${getUserDisplayName(
                details.selectedSharedConnection.ownedBy
            )})`,
            authorized: details.selectedSharedConnection.authorized,
            icon: <ProductIcon name={details.selectedSharedConnection.connectionType.name} />,
        });
    }

    const handleCancel = (): void => {
        navigate({ to: '../../' });
        selectedWorkspaceSelectedResource$.next(undefined);
    };

    const handleNewConnection = (): void => {
        const connectionType = (apps ?? [])
            .flatMap((app) => app.connectionType)
            .find((ct) => ct.uid === details?.connectionTypeUid);

        if (connectionType) {
            openConnectionDetailsDialogAction$.next({
                connectionType,
            });
        } else {
            console.error('Connection type not found');
            alert('Connection type not found');
        }
    };

    const handleCloseWizardWarning = (): void => {
        if (wizardState === WizardState.ACTIVE && wizardStep === WizardStep.SAVE_API_CONNECTION) {
            updateWizardStateAction$.next(WizardState.CONFIRM);
        }
    };

    const showWizardWarning =
        (wizardState === WizardState.ACTIVE || wizardState === WizardState.CONFIRM) &&
        wizardStep === WizardStep.SAVE_API_CONNECTION &&
        wizardCreatedUnsavedWorkspaceResourceUid === details?.uid;

    return (
        <ApiHandlerDetails
            uid={details?.uid ?? ''}
            saving={saving}
            errors={errors}
            createdConnectionUid={createdConnectionUid}
            connections={filteredConnections}
            path={details?.path ?? details?.defaultImportPath}
            remnantEnvironments={details?.remnantEnvironments}
            selectedConnectionUid={details?.connectionUid}
            selectedEnvironment={selectedWorkspaceEnvironment}
            onCancel={handleCancel}
            onSave={(event) => saveApiHandlerAction$.next(event)}
            onNewConnection={handleNewConnection}
            onWizardWarningClose={handleCloseWizardWarning}
            wizardWarning={showWizardWarning}
            workspaceLocked={!!selectedWorkspace?.locked}
            hasImplicitlySharedConnectionAttached={!!details?.selectedSharedConnection}
            selectedApiHandlerLibraryUid={details?.apiHandlerLibraryUid}
            vendorApiVersions={vendorApiVersions}
        />
    );
};
