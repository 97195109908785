import { BehaviorSubject, map, Subject } from 'rxjs';
import { saveBundledScript, saveBundledScriptSourceMap } from '../data/script';
import { segmentAnalyticsTrack } from '../data/segment-analytics';
import { assumeWorkspaceEditControl } from '../data/workspace';
import {
    createWorkspace,
    CreateWorkspaceResponse,
    CreateWorkspaceRequest,
    UserWorkspaces,
    deleteWorkspace,
    updateWorkspaceDetails,
    getSaveWorkspaceFormDetails,
    UpdateWorkspaceDetailsResponse,
} from '../data/workspaces';
import { getBundledOutputsFromSelectedWorkspace } from '../utils/bundler';
import { InformativeError, PermissionError } from '../utils/repository';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';
import { selectedReadOnlyTemplate$ } from './templates';
import { wizardWorkspaceDetails$ } from './wizard';
import {
    ignoreWorkspaceExitUnsavedChangesCheck$,
    selectedWorkspace$,
    selectedWorkspaceReadOnlyMode$,
    selectedWorkspaceResources$,
    selectedWorkspaceUid$,
} from './workspace';
import { loggedInUserOrganizations$ } from './organizations';
import { loadWorkspaces, workspaceHasUnsavedChanges } from './workspace/utils';
import { FilteringOptions } from '../components/workspace/workspace-selector';
import { promptQuestion } from './confirm';
import { ITIPPCS } from '../i18n';
import { loggedInUserDetails$ } from './user';
import { scriptsBeingSaved$ } from './workspace/script';
import { saveBlankWorkspaceCorePackagesAction$ } from './workspace/packages';

export interface NewCopiedWorkspaceDetails {
    description?: string;
    name: string;
    sourceUid?: string;
}

interface EditWorkspaceRequest {
    uid: string;
    name: string;
    description?: string;
    template: boolean;
    newTemplate?: boolean;
    createdFromTemplate?: boolean;
    organizationUid: string;
}

interface WorkspaceFormDetails {
    defaultOrganizationUid?: string;
    selectedOrganizationUid?: string;
    organizations: {
        value: string;
        name: string;
    }[];
    organizationDropdownDisabled: boolean;
    isWorkspaceOwner?: boolean;
}

interface AssumeEditControlsDetails {
    userDisplayName?: string;
    isReplayInvocationMode?: boolean;
}

export const newWorkspaceFromTemplate$ = monitor(
    'newWorkspaceFromTemplate$',
    new BehaviorSubject<NewCopiedWorkspaceDetails | undefined>(undefined)
);

export const newDuplicatedWorkspace$ = monitor(
    'newDuplicatedWorkspace$',
    new BehaviorSubject<NewCopiedWorkspaceDetails | undefined>(undefined)
);

export const openNewBlankWorkspaceAction$ = monitor(
    'newBlankWorkspaceAction$',
    new Subject<'blank' | 'guided' | 'home' | 'template' | 'duplicated'>()
);
export const newBlankWorkspaceSource$ = monitor(
    'newBlankWorkspaceSource$',
    new BehaviorSubject<'blank' | 'guided' | 'home' | 'template' | 'duplicated' | undefined>(undefined)
);
export const saveNewBlankWorkspaceAction$ = monitor(
    'saveNewBlankWorkspaceAction$',
    new Subject<Omit<CreateWorkspaceRequest, 'source'>>()
);
export const cancelNewBlankWorkspaceAction$ = monitor('cancelNewBlankWorkspaceAction$', new Subject<void>());
export const cancelEditWorkspaceAction$ = monitor('cancelEditWorkspaceAction$', new Subject<void>());
export const workspaceCreatedAction$ = monitor('workspaceCreatedAction$', new Subject<CreateWorkspaceResponse>());
export const deleteWorkspaceAction$ = monitor('deleteWorkspaceAction$', new Subject<string>());
export const editWorkspaceAction$ = monitor('editWorkspaceAction$', new Subject<EditWorkspaceRequest>());
export const updateWorkspaceDetailsAction$ = monitor(
    'updateWorkspaceDetailsAction$',
    new Subject<EditWorkspaceRequest>()
);
export const workspaceDeletedAction$ = monitor('workspaceDeletedAction$', new Subject<string>());
export const assumeWorkspaceEditControlAction$ = monitor(
    'assumeWorkspaceEditControlAction$',
    new Subject<AssumeEditControlsDetails>()
);
export const workspaceEditControlAssumedAction$ = monitor('workspaceEditControlAssumedAction$', new Subject<void>());
export const workspaceEditControlAssumedForReplayInvocationAction$ = monitor(
    'workspaceEditControlAssumedForReplayInvocationAction$',
    new Subject<void>()
);

export const loggedInUserWorkspaces$ = monitor('loggedInUserWorkspaces$', new BehaviorSubject<UserWorkspaces>([]));
export const newBlankWorkspaceDialogOpen$ = monitor('newBlankWorkspaceOpen$', new BehaviorSubject(false));
export const savingNewBlankWorkspace$ = monitor('savingNewBlankWorkspace$', new BehaviorSubject(false));
export const openWorkspacePreviewDialogErrors$ = monitor(
    'openWorkspacePreviewDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const workspacePreviewDialogOpen$ = monitor('workspacePreviewDialogOpen$', new BehaviorSubject(false));
export const bundleNewWorkspaceScriptsAction$ = monitor('bundleNewWorkspaceScriptsAction$', new Subject<void>());

export const editWorkspaceDialogOpen$ = monitor('editWorkspaceDialogOpen$', new BehaviorSubject(false));
export const workspaceBeingEditted$ = monitor(
    'workspaceBeingEditted$',
    new BehaviorSubject<EditWorkspaceRequest | undefined>(undefined)
);
export const savingExistingWorkspace$ = monitor('savingExistingWorkspace$', new BehaviorSubject(false));
export const workspaceFormDetails$ = monitor(
    'workspaceFormDetails$',
    new BehaviorSubject<WorkspaceFormDetails | undefined>(undefined)
);
export const getWorkspaceFormDetailsAction$ = monitor(
    'getWorkspaceFormDetailsAction$',
    new Subject<string | undefined>()
);
export const workspaceDetailsUpdatedAction$ = monitor('workspaceDetailsUpdatedAction$', new Subject<void>());
export const loadingWorkspaceFormDetails$ = monitor('loadingWorkspaceFormDetails$', new BehaviorSubject(false));

export const activeFilters$ = monitor(
    'activeFilters$',
    new BehaviorSubject<FilteringOptions>({
        organizations: [],
        templates: [],
        count: 0,
    })
);

export const updateOrganizationsFilterAction$ = monitor('updateOrganizationsFilterAction$', new Subject<string>());
export const updateTemplatesFilterAction$ = monitor('updateTemplatesFilterAction$', new Subject<boolean>());
export const resetFilterAction$ = monitor('resetFilterAction$', new Subject<void>());
export const filteredWorkspaces$ = monitor('filteredWorkspaces$', new BehaviorSubject<UserWorkspaces>([]));

export const refreshWorkspacesAndReapplyFiltersAction$ = monitor(
    'refreshWorkspacessAndReapplyFiltersAction$',
    new Subject<void>()
);

export const compileAndBundleWorkspaceScriptsAction$ = monitor(
    'compileAndBundleWorkspaceScriptsAction$',
    new Subject<void>()
);

resetFilterAction$.subscribe(() => {
    activeFilters$.next({
        organizations: [],
        templates: [],
        count: 0,
    });
    updateWorkspacesWithFilters();
});

const updateWorkspacesWithFilters = (): void => {
    checkActiveFilters();
    filteredWorkspaces$.next(updateWorkspaces(loggedInUserWorkspaces$.value));
};

updateOrganizationsFilterAction$.subscribe((workspace) => {
    updateFilters(activeFilters$.value.organizations, workspace, 'organizations');
    updateWorkspacesWithFilters();
});

updateTemplatesFilterAction$.subscribe((templates) => {
    updateFilters(activeFilters$.value.templates, templates, 'templates');
    updateWorkspacesWithFilters();
});

refreshWorkspacesAndReapplyFiltersAction$.subscribe(() => {
    updateWorkspacesWithFilters();
});

const updateFilters = (
    activeItems: string[],
    updatedItem: string | boolean,
    field: 'organizations' | 'templates'
): void => {
    const foundIndex = activeItems.findIndex((a) => a === updatedItem);
    if (foundIndex >= 0) {
        activeItems.splice(foundIndex, 1);
        activeFilters$.next({
            ...activeFilters$.value,
            [field]: activeItems,
            count: activeFilters$.value.count - 1,
        });
    } else {
        activeFilters$.next({
            ...activeFilters$.value,
            [field]: [...activeItems, updatedItem],
            count: activeFilters$.value.count + 1,
        });
    }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const updateWorkspaces = (workspaces: UserWorkspaces): UserWorkspaces => {
    const diff = (str: string, arr: string[]): boolean => arr.some((a) => a.includes(str));
    const { organizations, templates } = activeFilters$.value;

    if (!organizations.length && !templates.length) {
        return workspaces.filter((w) => !w.template);
    }

    return workspaces.filter((w) => {
        const organizationName = w.organization?.name ?? '';
        const isTemplate = w.template;

        if (templates.length) {
            if (isTemplate && !organizations.length) {
                return diff('Display Templates', templates);
            }
            return organizations.includes(organizationName) && isTemplate;
        }

        return organizations.includes(organizationName) && !isTemplate;
    });
};

const checkActiveFilters = (): void => {
    const filteredOrganizations = activeFilters$.value.organizations.filter((org) =>
        loggedInUserOrganizations$.value.some((o) => o.name === org)
    );
    const filteredTemplates = activeFilters$.value.templates;
    const filtersCount = filteredOrganizations.length + filteredTemplates.length;

    if (filtersCount !== activeFilters$.value.count) {
        activeFilters$.next({
            ...activeFilters$.value,
            organizations: filteredOrganizations,
            count: filtersCount,
        });
    }
};

getWorkspaceFormDetailsAction$
    .pipe(
        map(async (uid) => {
            loadingWorkspaceFormDetails$.next(true);
            try {
                const response = await getSaveWorkspaceFormDetails(uid);
                workspaceFormDetails$.next({
                    selectedOrganizationUid: response.selectedOrganizationUid,
                    defaultOrganizationUid: response.defaultOrganizationUid,
                    organizations: response.organizations.map((org) => ({
                        name: org.name,
                        value: org.uid,
                    })),
                    organizationDropdownDisabled: response.organizationDropdownDisabled,
                    isWorkspaceOwner: response.isWorkspaceOwner,
                });
            } catch (err) {
                console.error('Error while retrieving workspace form details', err);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to retrieve Workspace form details, please try again. ${ITIPPCS}`,
                    noToast: true,
                });
            }
            loadingWorkspaceFormDetails$.next(false);
        })
    )
    .subscribe();

bundleNewWorkspaceScriptsAction$
    .pipe(
        map(async () => {
            const bundled = await getBundledOutputsFromSelectedWorkspace({ publishCompileError: false });
            if (!selectedWorkspaceReadOnlyMode$.value) {
                newDuplicatedWorkspace$.next(undefined);
                newWorkspaceFromTemplate$.next(undefined);
            }

            if (bundled) {
                await Promise.all(
                    bundled.map((file) => {
                        if (file.path.endsWith('.js')) {
                            return saveBundledScript(file.uid, file.content);
                        } else if (file.path.endsWith('.js.map')) {
                            return saveBundledScriptSourceMap(file.uid, file.content);
                        }
                    })
                );
            }
        })
    )
    .subscribe();

openNewBlankWorkspaceAction$.subscribe((source) => {
    getWorkspaceFormDetailsAction$.next(undefined);
    newBlankWorkspaceDialogOpen$.next(true);
    savingNewBlankWorkspace$.next(false);
    openWorkspacePreviewDialogErrors$.next(undefined);
    newBlankWorkspaceSource$.next(source);
    if (source === 'blank' || source === 'guided') {
        segmentAnalyticsTrack('Setup Initiated', { type: source, userOrigin: loggedInUserDetails$.value?.userOrigin });
    }
});
cancelNewBlankWorkspaceAction$.subscribe(() => {
    newWorkspaceFromTemplate$.next(undefined);
    newDuplicatedWorkspace$.next(undefined);
    newBlankWorkspaceDialogOpen$.next(false);
    newBlankWorkspaceSource$.next(undefined);
});

saveNewBlankWorkspaceAction$
    .pipe(
        map(async ({ name, description, template, templateUid, sourceWorkspaceUid, organizationUid }) => {
            try {
                savingNewBlankWorkspace$.next(true);
                openWorkspacePreviewDialogErrors$.next(undefined);

                //Extra checks to ensure we don't somehow create a template from a template
                const templateProps = {
                    template: !templateUid && template ? template : undefined,
                    templateUid: templateUid && !template ? templateUid : undefined,
                };

                const source = newBlankWorkspaceSource$.value ?? 'home';

                const workspace = await createWorkspace({
                    name,
                    description,
                    source,
                    organizationUid,
                    sourceWorkspaceUid,
                    ...templateProps,
                });

                if (source === 'blank') {
                    segmentAnalyticsTrack('Setup Finished', {
                        type: source,
                        userOrigin: loggedInUserDetails$.value?.userOrigin,
                    });
                }

                newBlankWorkspaceDialogOpen$.next(false);
                workspaceCreatedAction$.next(workspace);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Workspace created.',
                });
                selectedReadOnlyTemplate$.next(undefined);

                if (!sourceWorkspaceUid && !templateProps.templateUid) {
                    saveBlankWorkspaceCorePackagesAction$.next({ workspaceUid: workspace.workspaceUid });
                }

                await loadWorkspaces();
            } catch (e) {
                savingNewBlankWorkspace$.next(false);
                if (e instanceof InformativeError) {
                    openWorkspacePreviewDialogErrors$.next(e.message);
                } else {
                    openWorkspacePreviewDialogErrors$.next(
                        `Unknown error occurred while creating a new workspace, please try again. ${ITIPPCS}`
                    );
                    console.error('Error while creating new workspace', e);
                }
            }
        })
    )
    .subscribe();

deleteWorkspaceAction$
    .pipe(
        map(async (uid) => {
            const wizardWarning =
                wizardWorkspaceDetails$.value?.workspaceUid === uid
                    ? ' This will also close the Step-by-step setup.'
                    : '';
            promptQuestion({
                title: 'Deleting the Workspace will also delete all of the associated data in the record storage. Are you sure you want to delete the Workspace?',
                message: wizardWarning,
                onProceed: async () => {
                    try {
                        await deleteWorkspace(uid);

                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Workspace deleted.',
                        });

                        workspaceDeletedAction$.next(uid);
                        deleteWorkspaceLocalStorageKeys(uid);
                    } catch (e) {
                        if (e instanceof InformativeError) {
                            publishLocalFeedbackEventAction$.next({
                                level: 'ERROR',
                                message: e.message,
                                toastOptions: {
                                    autoClose: false,
                                },
                            });
                        } else {
                            console.error('Error while deleting workspace', e);

                            publishLocalFeedbackEventAction$.next({
                                level: 'ERROR',
                                message: `Failed to delete Workspace, please try again. ${ITIPPCS}`,
                                noToast: true,
                            });
                        }
                    }
                },
            });
        })
    )
    .subscribe();

editWorkspaceAction$.subscribe((details) => {
    if (details) {
        workspaceBeingEditted$.next(details);
        savingExistingWorkspace$.next(false);
        editWorkspaceDialogOpen$.next(true);
        openWorkspacePreviewDialogErrors$.next(undefined);
        getWorkspaceFormDetailsAction$.next(details.uid);
    }
});
cancelEditWorkspaceAction$.subscribe(() => {
    workspaceBeingEditted$.next(undefined);
    openWorkspacePreviewDialogErrors$.next(undefined);
    savingExistingWorkspace$.next(false);
    editWorkspaceDialogOpen$.next(false);
    workspaceFormDetails$.next(undefined);
});

updateWorkspaceDetailsAction$
    .pipe(
        map(async (details) => {
            try {
                const callUpdateWorkspaceDetails = async (): Promise<UpdateWorkspaceDetailsResponse> => {
                    savingExistingWorkspace$.next(true);
                    const updatedWorkspaceDetails = await updateWorkspaceDetails({
                        ...details,
                        newTemplate: details.newTemplate ?? false,
                    });
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Workspace details updated.',
                    });
                    editWorkspaceDialogOpen$.next(false);

                    return updatedWorkspaceDetails;
                };

                const updatedWorkspaceDetails = await callUpdateWorkspaceDetails();
                savingExistingWorkspace$.next(false);
                selectedWorkspace$.next({
                    ...updatedWorkspaceDetails,
                    locked: selectedWorkspace$.value?.locked ?? undefined,
                    draft: details.template,
                });
                workspaceDetailsUpdatedAction$.next();
                await loadWorkspaces();
            } catch (e) {
                savingExistingWorkspace$.next(false);

                if (e instanceof InformativeError || e instanceof PermissionError) {
                    openWorkspacePreviewDialogErrors$.next(e.message);
                } else {
                    console.error('Error while updating workspace details', e);
                    openWorkspacePreviewDialogErrors$.next(
                        'Unknown error occurred while updating workspace details, please try again. ${ITIPPCS}'
                    );
                }
            }
        })
    )
    .subscribe();

assumeWorkspaceEditControlAction$
    .pipe(
        map(async ({ userDisplayName, isReplayInvocationMode }) => {
            try {
                const message = workspaceHasUnsavedChanges()
                    ? `Assuming workspace edit control will kick out ${
                          userDisplayName ?? 'the other session'
                      }  and reload the workspace with latest state. You have unsaved changes in this workspace, make sure to store these changes externally so you can merge them back into the workspace manually once you have assumed the control. Do you want to proceed?`
                    : `Assuming workspace edit control will kick out ${
                          userDisplayName ?? 'the other session'
                      } and reload the workspace with latest state. Do you want to proceed?`;
                promptQuestion({
                    message,
                    onProceed: async () => {
                        await assumeWorkspaceEditControl(selectedWorkspaceUid$.value ?? '');
                        ignoreWorkspaceExitUnsavedChangesCheck$.next(true);
                        if (isReplayInvocationMode) {
                            workspaceEditControlAssumedForReplayInvocationAction$.next();
                        } else {
                            workspaceEditControlAssumedAction$.next();
                        }
                    },
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    console.error('Error while assuming workspace edit control', e);

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to assume workspace edit control, please try again. ${ITIPPCS}`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }
            }
        })
    )
    .subscribe();

const deleteWorkspaceLocalStorageKeys = (uid: string): void => {
    const storageKeys = Object.keys(localStorage);
    const environmentVariablesReadmeOpenKey = storageKeys.find((k) => k === `isEnvironmentVariablesReadmeOpen-${uid}`);
    const environmentVariablesReadmeFlexGrowKey = storageKeys.find(
        (k) => k === `environmentVariablesReadmeFlexGrow-${uid}`
    );
    const folderExpandKeys = storageKeys.filter((k) => k.includes(`isExpanded-${uid}-`));

    if (environmentVariablesReadmeFlexGrowKey) {
        localStorage.removeItem(environmentVariablesReadmeFlexGrowKey);
    }
    if (environmentVariablesReadmeOpenKey) {
        localStorage.removeItem(environmentVariablesReadmeOpenKey);
    }

    for (const key of folderExpandKeys) {
        localStorage.removeItem(key);
    }
};

compileAndBundleWorkspaceScriptsAction$
    .pipe(
        map(async () => {
            const workspaceScripts = selectedWorkspaceResources$.value.scripts;

            scriptsBeingSaved$.next({
                ...scriptsBeingSaved$.value,
                ...Object.fromEntries(workspaceScripts.map((sc) => [sc.uid, true])),
            });

            const bundled = await getBundledOutputsFromSelectedWorkspace();

            if (bundled) {
                await Promise.all(
                    // eslint-disable-next-line sonarjs/no-identical-functions
                    bundled.map((file) => {
                        if (file.path.endsWith('.js')) {
                            return saveBundledScript(file.uid, file.content);
                        } else if (file.path.endsWith('.js.map')) {
                            return saveBundledScriptSourceMap(file.uid, file.content);
                        }
                    })
                );
            }

            scriptsBeingSaved$.next({
                ...scriptsBeingSaved$.value,
                ...Object.fromEntries(workspaceScripts.map((sc) => [sc.uid, false])),
            });
        })
    )
    .subscribe();
