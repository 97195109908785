import { Box, Typography } from '@mui/material';
import { PageContainer } from '../layout/PageComponents';
import { DiscountCodeDetails } from '../../data/discountCodeDetails';

export interface DiscountCodeDetailsReportPageProps {
    details?: DiscountCodeDetails;
}

export const DiscountCodeDetailsReportPage: React.FC<DiscountCodeDetailsReportPageProps> = ({ details }) => {
    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">Discount Code Details</Typography>
            </Box>
            {details && (
                <Box sx={{ paddingLeft: 2 }}>
                    <Typography>
                        <strong>ID:</strong> {details.uid}
                    </Typography>
                    <Typography>
                        <strong>Code:</strong> {details.code}
                    </Typography>
                    <Typography>
                        <strong>Expiry:</strong> {details.expiryDate}
                    </Typography>
                    <Typography>
                        <strong>Number of Teams:</strong> {details.numberOfOrganizations}
                    </Typography>
                    <Typography>
                        <strong>Teams:</strong>{' '}
                        {details.organizations.length > 0
                            ? details.organizations.map((el) => el.name).join(', ')
                            : 'No teams'}
                    </Typography>
                </Box>
            )}
        </PageContainer>
    );
};
