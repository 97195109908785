import React from 'react';
import { DialogActions, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import { DialogCloseButton } from '../dialog/DialogComponents';
import { handleKeyUp } from '../../utils/handleKeyUp';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import { Button } from '../buttons/Button';
import { SRConnectIcon } from '../icons/SRConnectIcon';

interface AnnouncementProps {
    open?: boolean;
    onClose?(): void;
}

interface FeatureProps {
    text: string;
}

const StyledHeader = styled('div')(({ theme }) => ({
    margin: theme.spacing(2, 0),
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: theme.typography.subtitle1.fontSize,
}));

const FeatureSection = styled('div')(({ theme }) => ({
    color: theme.palette.text.secondary,
    '& .MuiTypography-subtitle1': {
        margin: theme.spacing(2, 0),
    },
}));

const FeatureText = styled(Typography)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    fontSize: theme.typography.subtitle1.fontSize,
    marginBottom: theme.spacing(),
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
    },
}));

export const BrandChangeModal: React.FC<AnnouncementProps> = ({ open = false, onClose }) => {
    const features: FeatureProps[] = [
        {
            text: 'A new revamped website and logo',
        },
        {
            text: 'The product is now out of the Beta stage',
        },
        {
            text: 'Teams have been implemented under the Free plan',
        },
    ];

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}
        >
            <DialogCloseButton onClick={onClose} />

            <DialogContent>
                <SRConnectIcon sx={{ height: 80, width: 80 }} />
                <StyledHeader>
                    <Typography component="h1" variant="subtitle1">
                        We have evolved!
                    </Typography>
                    <Typography component="h2" variant="h4">
                        Introducing <span>ScriptRunner Connect</span>
                    </Typography>
                </StyledHeader>
                <StyledDescription>
                    Stitch It is officially out of Beta and we are now part of the ScriptRunner suite of products as
                    ScriptRunner Connect.
                </StyledDescription>
                <FeatureSection>
                    <Typography variant="subtitle1" component="h3">
                        What does this mean for you?
                    </Typography>
                    {features.map((f, i) => (
                        <FeatureText key={i}>
                            <VerifiedRoundedIcon color="success" />
                            {f.text}
                        </FeatureText>
                    ))}
                    <Typography variant="subtitle1">And there's more!</Typography>
                    <StyledDescription>
                        As we're finalising the checkout process, you can continue using ScriptRunner Connect for free
                        until pricing plans become available on October 12.
                    </StyledDescription>
                </FeatureSection>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Got It</Button>
            </DialogActions>
        </Dialog>
    );
};
