import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    getLoggedInUserOrganizations,
    UserOrganizations,
    CreateOrganizationRequest,
    createOrganization,
    deleteOrganization,
    CreateOrganizationRequestV2,
    createOrganizationV2,
} from '../data/organizations';
import { InformativeError, PermissionError } from '../utils/repository';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';
import { addWorkspaceToOrganizationDialogOpen$, workspaceBeingShared$ } from './workspace/sharing';
import { ITIPPCS } from '../i18n';
import { openSalableCheckoutWindow } from './utils';
import { openOrganizationPlanSuccessStateDialogAction$ } from './organization/changeOrganizationPlan';
import { sendOrganizationMemberInvite } from '../data/organization';
import { checkDiscountCodeIsValid } from '../data/discountCodeDetails';

interface CreateOrganizationEvent extends CreateOrganizationRequest {
    emails: string[];
}

interface CreateOrganizationEventV2 extends CreateOrganizationRequestV2 {
    emails: string[];
}

export const loadLoggedInUserOrganizations = async (): Promise<void> => {
    const organizations = await getLoggedInUserOrganizations();
    loggedInUserOrganizations$.next(organizations);
};

export const loggedInUserOrganizations$ = monitor(
    'loggedInUserOrganizations$',
    new BehaviorSubject<UserOrganizations>([])
);
export const organizationSelectedForSharing$ = monitor(
    'organizationSelectedForSharing$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const closeCreateOrganizationWizardDialogAction$ = monitor(
    'closeCreateOrganizationWizardDialogAction$',
    new Subject<void>()
);
export const openCreateOrganizationWizardDialogAction$ = monitor(
    'openCreateOrganizationWizardDialogAction$',
    new Subject<void>()
);
export const createOrganizationAction$ = monitor('createOrganizationAction$', new Subject<CreateOrganizationEvent>());
export const deleteOrganizationAction$ = monitor('deleteOrganizationAction$', new Subject<string>());
export const sendOrganizationInvitesAction$ = monitor(
    'sendOrganizationInvitesAction$',
    new Subject<{ organizationUid: string; emails: string[] }>()
);

export const createOrganizationWizardDialogOpen$ = monitor(
    'createOrganizationWizardDialogOpen$',
    new BehaviorSubject<boolean>(false)
);
export const createOrganizationValidationError$ = monitor(
    'createOrganizationValidationError$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const createOrganizationWizardSaving$ = monitor(
    'createOrganizationWizardSaving$',
    new BehaviorSubject<boolean>(false)
);
export const createOrganizationWizardInviteEmails$ = monitor(
    'createOrganizationWizardInviteEmails$',
    new BehaviorSubject<string[] | undefined>(undefined)
);
export const organizationCreatedAction$ = monitor('organizationCreatedAction$', new Subject<string>());
export const organizationDeletedAction$ = monitor('organizationDeletedAction$', new Subject<void>());

export const createOrganizationActionV2$ = monitor(
    'createOrganizationActionV2$',
    new Subject<CreateOrganizationEventV2>()
);

export const applyOrganizationDiscountAction$ = monitor('applyOrganizationDiscountAction$', new Subject<string>());

export const applyDiscountInProgress$ = monitor('applyDiscountInProgress$', new BehaviorSubject<boolean>(false));

export const organizationSelectedDiscountCode$ = monitor(
    'organizationSelectedDiscountCode$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const organizationAppliedDiscountCode$ = monitor(
    'organizationAppliedDiscountCode$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const discountCodeValidationError$ = monitor(
    'discountCodeValidationError$',
    new BehaviorSubject<string | undefined>(undefined)
);

openCreateOrganizationWizardDialogAction$.subscribe(() => {
    createOrganizationValidationError$.next(undefined);
    createOrganizationWizardDialogOpen$.next(true);
    applyDiscountInProgress$.next(false);
    organizationAppliedDiscountCode$.next(undefined);
    organizationSelectedDiscountCode$.next(undefined);
    discountCodeValidationError$.next(undefined);
});

closeCreateOrganizationWizardDialogAction$.subscribe(() => {
    createOrganizationWizardDialogOpen$.next(false);
    applyDiscountInProgress$.next(false);
    organizationAppliedDiscountCode$.next(undefined);
    organizationSelectedDiscountCode$.next(undefined);
    discountCodeValidationError$.next(undefined);
    if (workspaceBeingShared$.value) {
        addWorkspaceToOrganizationDialogOpen$.next(true);
    }
});

organizationCreatedAction$.subscribe((uid) => {
    const sharedWorkspace = workspaceBeingShared$.value;
    if (sharedWorkspace) {
        organizationSelectedForSharing$.next(uid);
        addWorkspaceToOrganizationDialogOpen$.next(true);
        workspaceBeingShared$.next(undefined);
    }
    loadLoggedInUserOrganizations();
});

applyOrganizationDiscountAction$
    .pipe(
        map(async (code) => {
            applyDiscountInProgress$.next(true);
            try {
                const isValid = await checkDiscountCodeIsValid({ code });
                if (isValid) {
                    organizationAppliedDiscountCode$.next(code);
                } else {
                    throw new InformativeError('Discount code is invalid');
                }
            } catch (error) {
                if (error instanceof InformativeError) {
                    discountCodeValidationError$.next(error.message);
                } else {
                    discountCodeValidationError$.next(
                        'Could not verify discount code. Please try again, if the issue persists please contact support'
                    );
                }
            }
            applyDiscountInProgress$.next(false);
        })
    )
    .subscribe();

const TEAM_CREATED = 'Team created.';
const TEAM_CREATION_ERROR = 'Error occurred while creating team.';

createOrganizationAction$
    .pipe(
        map(async ({ emails, ...createOrgEvent }) => {
            try {
                createOrganizationWizardSaving$.next(true);
                const { uid } = await createOrganization(createOrgEvent);
                createOrganizationValidationError$.next(undefined);

                const invitesMessage = emails.length ? ' Sending invites.' : '';
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: `${TEAM_CREATED}${invitesMessage}`,
                });

                if (emails.length) {
                    sendOrganizationInvitesAction$.next({ emails, organizationUid: uid });
                }

                closeCreateOrganizationWizardDialogAction$.next();
                organizationCreatedAction$.next(uid);
                createOrganizationWizardSaving$.next(false);
            } catch (e) {
                createOrganizationWizardSaving$.next(false);
                if (e instanceof InformativeError) {
                    createOrganizationValidationError$.next(e.message);
                } else {
                    createOrganizationValidationError$.next(TEAM_CREATION_ERROR);
                }
            }
        })
    )
    .subscribe();

createOrganizationActionV2$
    .pipe(
        map(async ({ emails, ...createOrgEvent }) => {
            try {
                createOrganizationWizardSaving$.next(true);
                createOrganizationWizardInviteEmails$.next(undefined);
                createOrganizationValidationError$.next(undefined);
                const response = await createOrganizationV2({
                    ...createOrgEvent,
                    originalReferrer: window.location.origin,
                });

                if ('checkoutUrl' in response) {
                    createOrganizationWizardInviteEmails$.next(emails);
                    openSalableCheckoutWindow(
                        response.checkoutUrl,
                        [createOrganizationWizardSaving$],
                        [createOrganizationValidationError$]
                    );
                } else {
                    createOrganizationValidationError$.next(undefined);

                    if (emails.length) {
                        sendOrganizationInvitesAction$.next({ emails, organizationUid: response.uid });
                    }

                    closeCreateOrganizationWizardDialogAction$.next();
                    organizationCreatedAction$.next(response.uid);
                    createOrganizationWizardSaving$.next(false);

                    openOrganizationPlanSuccessStateDialogAction$.next({
                        tier: createOrgEvent.plan.tier,
                        state: 'create',
                    });
                }
            } catch (e) {
                createOrganizationWizardSaving$.next(false);
                if (e instanceof InformativeError) {
                    createOrganizationValidationError$.next(e.message);
                } else {
                    createOrganizationValidationError$.next(TEAM_CREATION_ERROR);
                }
            }
        })
    )
    .subscribe();

deleteOrganizationAction$
    .pipe(
        map(async (uid) => {
            try {
                await deleteOrganization(uid);

                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Team deleted',
                });

                organizationDeletedAction$.next();
            } catch (e) {
                let message: string;
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    message = e.message;
                } else {
                    message = 'Error occured while deleting team. ' + ITIPPCS;
                }

                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message,
                });
            }
        })
    )
    .subscribe();

sendOrganizationInvitesAction$
    .pipe(
        map(async (details) => {
            const successfulInvites: string[] = [];
            const failedInvites: string[] = [];

            for (const email of details.emails) {
                try {
                    await sendOrganizationMemberInvite({ email, organizationUid: details.organizationUid });
                    successfulInvites.push(email);
                } catch (e) {
                    console.error(`Failed to send email to ${email}`, e);
                    failedInvites.push(email);
                }
            }

            if (successfulInvites.length) {
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: `Sent invite to ${successfulInvites.join(', ')}.`,
                });
            }

            if (failedInvites.length) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to send invite to ${failedInvites.join(', ')}.`,
                });
            }
        })
    )
    .subscribe();
