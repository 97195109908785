import { BehaviorSubject, map, Subject } from 'rxjs';
import { selectedWorkspaceEnvironments$, selectedWorkspaceResources$, selectedWorkspaceUid$ } from '.';
import { OnDeployEvent } from '../../components/workspace/NewDeploymentDialog';
import { createDeployment, getPreDeploymentData, WorkspaceDeployments } from '../../data/deployment';
import { saveBundledScript, saveBundledScriptSourceMap } from '../../data/script';
import { getBundledOutputsFromSelectedWorkspace } from '../../utils/bundler';
import { BundlingError, CompilationError, InformativeError } from '../../utils/repository';
import { promptQuestion } from '../confirm';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import {
    createReadmeFile,
    readmeFileHasUnsavedChanges$,
    savedReadmeFileDetails$,
    saveReadmeFile,
    unsavedReadmeFileDetails$,
} from './readme';
import { savedScriptDetails$, saveScript, scriptHasUnsavedChanges$, unsavedScriptDetails$ } from './script';
import {
    loadWorkspaceDeploymentsWhenEventIsEmitted,
    loadWorkspaceEnvironmentsWhenEventIsEmitted,
    loadWorkspaceResourcesWhenEventIsEmitted,
} from './utils';

export const openNewDeploymentDialogAction$ = monitor('openNewDeploymentDialogAction$', new Subject<void>());
export const newDeploymentInitiatedAction$ = monitor('newDeploymentInitiatedAction$', new Subject<OnDeployEvent>());
export const newDeploymentCreatedAction$ = monitor('newDeploymentCreatedAction$', new Subject<string>());

export const newDeploymentDialogOpen$ = monitor('newDeploymentDialogOpen$', new BehaviorSubject(false));
export const loadingNewDeploymentDialog$ = monitor('loadingNewDeploymentDialog$', new BehaviorSubject(false));
export const newDeploymentErrors$ = monitor('newDeploymentErrors$', new BehaviorSubject<string | undefined>(undefined));
export const creatingNewDeployment$ = monitor('creatingNewDeployment$', new BehaviorSubject(false));
export const newDeploymentCurrentVersion$ = monitor(
    'newDeploymentCurrentVersion$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const newDeploymentSuggestedVersion$ = monitor(
    'newDeploymentSuggestedVersion$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const selectedWorkspaceDeployments$ = monitor(
    'selectedWorkspaceDeployments$',
    new BehaviorSubject<WorkspaceDeployments>([])
);

openNewDeploymentDialogAction$
    .pipe(
        map(async () => {
            newDeploymentDialogOpen$.next(true);
            newDeploymentErrors$.next(undefined);
            creatingNewDeployment$.next(false);

            try {
                loadingNewDeploymentDialog$.next(true);
                newDeploymentCurrentVersion$.next(undefined);
                newDeploymentSuggestedVersion$.next(undefined);

                const { currentVersion, nextVersion } = await getPreDeploymentData(selectedWorkspaceUid$.value ?? '');

                newDeploymentCurrentVersion$.next(currentVersion);
                newDeploymentSuggestedVersion$.next(nextVersion);
            } catch (e) {
                console.error('Error while getting pre-deployment data', e);
                newDeploymentErrors$.next(
                    'Unknown error occurred while preparing for deployment. Please try again, if the issue persists please contact support.'
                );
            }

            loadingNewDeploymentDialog$.next(false);
        })
    )
    .subscribe();

newDeploymentInitiatedAction$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async ({ newVersion, selectedEnvironmentUids, label }) => {
            const unsavedScripts = selectedWorkspaceResources$.value.scripts.filter(
                (s) => !!scriptHasUnsavedChanges$.value[s.uid]
            );

            const existingReadmeFileUid = selectedWorkspaceResources$.value.readmeFile?.uid;
            const hasUnsavedReadmeFile = readmeFileHasUnsavedChanges$.value[existingReadmeFileUid ?? ''];
            const unsavedReadmeFile = hasUnsavedReadmeFile && existingReadmeFileUid ? [existingReadmeFileUid] : [];
            const uncreatedReadmeFile = hasUnsavedReadmeFile && !existingReadmeFileUid;

            const initiateNewDeployment = async (): Promise<void> => {
                creatingNewDeployment$.next(true);
                newDeploymentErrors$.next(undefined);
                try {
                    const bundledOutput = unsavedScripts.length ? await getBundledOutputsFromSelectedWorkspace({}) : [];

                    await Promise.all([
                        ...unsavedScripts.map((script) => saveScript(script.uid)),
                        ...(bundledOutput
                            ? bundledOutput.map((output) => {
                                  if (output.path.endsWith('.js')) {
                                      return saveBundledScript(output.uid, output.content);
                                  } else if (output.path.endsWith('.js.map')) {
                                      return saveBundledScriptSourceMap(output.uid, output.content);
                                  }
                              })
                            : []),
                        ...unsavedReadmeFile.map((readmeFile) => saveReadmeFile(readmeFile)),
                        uncreatedReadmeFile ? createReadmeFile() : Promise.resolve(),
                    ]);

                    const { uid } = await createDeployment(
                        selectedWorkspaceUid$.value ?? '',
                        selectedEnvironmentUids,
                        newVersion,
                        label
                    );

                    let currentSavedScriptDetails = Object.entries(savedScriptDetails$.value ?? {});
                    let currentUnsavedScriptDetails = Object.entries(unsavedScriptDetails$.value ?? {});
                    let currentSavedReadmeFileDetails = Object.entries(savedReadmeFileDetails$.value ?? {});
                    let currentUnsavedReadmeFileDetails = Object.entries(unsavedReadmeFileDetails$.value ?? {});

                    for (const environmentUid of selectedEnvironmentUids) {
                        currentSavedScriptDetails = currentSavedScriptDetails.filter(
                            ([scriptKey]) => !scriptKey.endsWith(`_${environmentUid}`)
                        );
                        currentUnsavedScriptDetails = currentUnsavedScriptDetails.filter(
                            ([scriptKey]) => !scriptKey.endsWith(`_${environmentUid}`)
                        );
                        currentSavedReadmeFileDetails = currentSavedReadmeFileDetails.filter(
                            ([readmeKey]) => !readmeKey.endsWith(`_${environmentUid}`)
                        );
                        currentUnsavedReadmeFileDetails = currentUnsavedReadmeFileDetails.filter(
                            ([readmeKey]) => !readmeKey.endsWith(`_${environmentUid}`)
                        );
                    }

                    savedScriptDetails$.next(Object.fromEntries(currentSavedScriptDetails));
                    unsavedScriptDetails$.next(Object.fromEntries(currentUnsavedScriptDetails));
                    savedReadmeFileDetails$.next(Object.fromEntries(currentSavedReadmeFileDetails));
                    unsavedReadmeFileDetails$.next(Object.fromEntries(currentUnsavedReadmeFileDetails));

                    newDeploymentDialogOpen$.next(false);
                    const toastEnvironments =
                        selectedEnvironmentUids.length > 0
                            ? `, and deployed to following environments: ${selectedEnvironmentUids
                                  .map(
                                      (uid) =>
                                          selectedWorkspaceEnvironments$.value.find((e) => e.uid === uid)?.name ??
                                          'Unknown'
                                  )
                                  .join(', ')}`
                            : undefined;

                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: `Release with version ${newVersion} created${toastEnvironments ?? ''}.`,
                    });
                    newDeploymentCreatedAction$.next(uid);
                } catch (e) {
                    if (e instanceof CompilationError) {
                        newDeploymentErrors$.next(
                            'Some of your scripts failed to be compiled, please check console for more errors.'
                        );
                    } else if (e instanceof BundlingError) {
                        newDeploymentErrors$.next(
                            'Some of your scripts failed to be bundled, please check console for more errors.'
                        );
                    } else if (e instanceof InformativeError) {
                        newDeploymentErrors$.next(e.message);
                    } else {
                        console.error('Error while creating new deployment', e);
                        newDeploymentErrors$.next(
                            'Unknown error occurred while creating new deployment. Please try again, if the issue persists please contact support.'
                        );
                    }
                }
                creatingNewDeployment$.next(false);
            };

            const checkForUnsavedFiles = async (): Promise<void> => {
                if (unsavedScripts.length === 0) {
                    await initiateNewDeployment();
                } else {
                    promptQuestion({
                        title: 'Some of your scripts have not been saved',
                        message:
                            'Proceeding with deployment will save all your unsaved scripts. Do you want to proceed?',
                        onProceed: async () => await initiateNewDeployment(),
                    });
                }
            };

            await checkForUnsavedFiles();
        })
    )
    .subscribe();

loadWorkspaceEnvironmentsWhenEventIsEmitted(newDeploymentCreatedAction$);
loadWorkspaceDeploymentsWhenEventIsEmitted(newDeploymentCreatedAction$);
loadWorkspaceResourcesWhenEventIsEmitted(newDeploymentCreatedAction$);
