import { saveUserFeedback as saveUserFeedbackRpc } from '@avst-stitch/repository-lib/lib';
import { Response as UserFeedback } from '@avst-stitch/repository-lib/lib/rpcs/saveUserFeedback';
import { UserFeedbackAttachment } from '../store/userFeedback';
import { repositoryInvoker } from '../utils/repository';

export const saveUserFeedback = async (
    email: string,
    message: string,
    attachments: UserFeedbackAttachment[],
    canContact: boolean
): Promise<UserFeedback> => {
    await saveUserFeedbackRpc(repositoryInvoker, {
        email,
        message,
        attachments,
        canContact,
    });
};
