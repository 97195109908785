import { useObservableState } from 'observable-hooks';
import { StartWizardDialog } from '../../components/wizard/modals/StartWizardDialog';
import {
    updateWizardStateAction$,
    updateWizardStepAction$,
    wizardState$,
    wizardStep$,
    WizardState,
    WizardStep,
} from '../../store/wizard';

export const StartWizardDialogContainer: React.FC = () => {
    const wizardState = useObservableState(wizardState$);
    const wizardStep = useObservableState(wizardStep$);

    const open = wizardState === WizardState.ACTIVE && wizardStep === WizardStep.START;
    return (
        <StartWizardDialog
            open={open}
            onClose={() => updateWizardStateAction$.next(WizardState.CONFIRM)}
            onStart={() => updateWizardStepAction$.next(WizardStep.CREATE_WORKSPACE)}
        />
    );
};
