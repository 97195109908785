import { useObservableState } from 'observable-hooks';
import { SalesforceConnectionAppConfigureDialog } from '../../../components/connections/salesforce/SalesforceAppConfigureDialog';
import { configTopic$ } from '../../../store/config';
import {
    salesforceConnectionSaving$,
    salesforceManageConnectionDetails$,
    salesforceAppSetupDialogErrors$,
    salesforceAppSetupDialogOpen$,
    salesforceAppSetupCloseDialogAction$,
    salesforceAppSetupAuthorizeAction$,
    salesforceAppSetupDialogStage$,
} from '../../../store/connection/salesforce/setup-connection';

export const SalesforceConfigureDialog: React.FC = () => {
    const open = useObservableState(salesforceAppSetupDialogOpen$);
    const saving = useObservableState(salesforceConnectionSaving$);
    const details = useObservableState(salesforceManageConnectionDetails$);
    const errors = useObservableState(salesforceAppSetupDialogErrors$);
    const currentStage = useObservableState(salesforceAppSetupDialogStage$);
    const baseUrl = configTopic$.value.connection?.salesforce?.baseUrl;

    return (
        <SalesforceConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                salesforceAppSetupCloseDialogAction$.next();
                salesforceAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => salesforceAppSetupDialogStage$.next(stage)}
            clientInfo={{
                instanceUrl: details?.instanceUrl ?? '',
                clientId: details?.clientId ?? '',
                clientSecret: details?.clientSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => salesforceAppSetupDialogErrors$.next(error)}
            clearErrors={() => salesforceAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => salesforceAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${baseUrl}/callback`}
        />
    );
};
