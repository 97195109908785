import { useEffect, useRef, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '../../../buttons/Button';
import { DialogAlert } from '../../../dialog/DialogComponents';
import { Dropdown } from '../../../dropdown/Dropdown';
import {
    emptyWarning,
    DragButton,
    StyledDropArea,
    StyledEditModeActions,
    StyledEditModeContent,
    StyledReadOnlyExpandButton,
    StyledHeaderActions,
    StyledEditModeRequiredButton,
    StyledVariable,
    StyledReadOnlyVariableHeader,
    StyledVariableWrapper,
    StyledReadOnlyRow,
    StyledReadOnlyKeyField,
    StyledReadOnlyValueField,
    StyledReadOnlyMultipleValuesField,
    StyledReadOnlyListItem,
    StyledReadOnlyRequiredChip,
    StyledReadOnlyOptionalChip,
    StyledReadOnlyMissingInformationChip,
    StyledEditModeVariableHeader,
    StyledEditModeDivider,
} from '../EnvironmentVariableComponents';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
} from '../types';
import { EnvironmentVariableType } from '@avst-stitch/repository-lib/lib/types';
import { autoFocus, isFocused } from '../../../../utils/autoFocus';
import {
    DESCRIPTION_MAX_LENGTH,
    duplicateKeyNameHelperText,
    environmentVariableTypes,
    getVariableHeight,
    invalidKeyNameHelperText,
    isDescriptionTooLong,
} from '../utils';
import { handleKeyUp } from '../../../../utils/handleKeyUp';
import { isScriptOrEnvVariableNameValid } from '../../../../utils/validation';
import { styled } from '@mui/material';

interface UpdateListVariableEvent {
    defaultValue?: string[];
    description?: string;
    id: string;
    keyName: string;
    parentId?: string;
    required?: boolean;
    type: EnvironmentVariableType;
    value?: string[];
}

interface EnvironmentVariableListVariantProps {
    defaultValue?: string[];
    description?: string;
    dragOverlay?: boolean;
    editMode?: boolean;
    expanded?: boolean;
    hasBeenEdited?: boolean;
    id: string;
    keyName?: string;
    parentId?: string;
    required?: boolean;
    sameLevelKeyNames?: string[];
    templateMode?: boolean;
    value?: string[];
    workspaceLocked?: boolean;
    onChangeType?(event: ChangeVariableTypeEvent): void;
    onDelete?(event: DeleteVariableEvent): void;
    onToggleEditMode?(event: ToggleVariableEditModeEvent): void;
    onToggleExpand?(event: ToggleVariableExpandEvent): void;
    onUpdate?(event: UpdateListVariableEvent): void;
}

const StyledEditModeListItemsContainer = styled('div')(({ theme }) => ({
    flexDirection: 'column',
    display: 'flex',
    gap: theme.spacing(1),
}));

const StyledEditModeListItem = styled('div')(({ theme }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    gap: theme.spacing(1.5),
    width: '100%',
    '& .MuiIconButton-root': {
        marginTop: theme.spacing(1),
    },
}));

export const EnvironmentVariableListVariant: React.FC<EnvironmentVariableListVariantProps> = ({
    defaultValue = [],
    description = '',
    dragOverlay = false,
    editMode = false,
    expanded = false,
    hasBeenEdited = false,
    id,
    keyName = '',
    parentId,
    required = false,
    sameLevelKeyNames = [],
    templateMode = false,
    value = [],
    workspaceLocked = false,
    onChangeType,
    onDelete,
    onToggleEditMode,
    onToggleExpand,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentKeyName, setCurrentKeyName] = useState(keyName);
    const [currentValue, setCurrentValue] = useState(value);
    const [currentDefaultValue, setCurrentDefaultValue] = useState(defaultValue);
    const [currentDescription, setCurrentDescription] = useState(description);
    const [currentRequired, setCurrentRequired] = useState(required);

    const variableRef = useRef<HTMLDivElement>(null);
    const typeInputRef = useRef<HTMLInputElement>(null);
    const listItemInputRef = useRef<HTMLInputElement>(null);
    const defaultListItemInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    const draggable = useDraggable({
        id,
        data: { height: getVariableHeight(variableRef), keyName, parentId, type: 'variable' },
    });

    const droppable = useDroppable({ id });

    useEffect(() => {
        if (editMode) {
            autoFocus(typeInputRef);
        }
    }, [editMode]);

    const handleCancel = (): void => {
        setCurrentKeyName(keyName);
        setCurrentValue(value);
        setCurrentDefaultValue(defaultValue);
        setCurrentDescription(description);
        setCurrentRequired(required);
        onToggleEditMode?.({ editMode: false, id, parentId });
    };

    const handleUpdate = (): void => {
        onUpdate?.({
            defaultValue: currentDefaultValue,
            description: currentDescription,
            id,
            keyName: currentKeyName,
            parentId,
            required: currentRequired,
            type: 'LIST',
            value: currentValue,
        });
    };

    const handleAddListItem = (): void => {
        setCurrentValue([...currentValue, '']);
        autoFocus(listItemInputRef);
    };

    const handleAddDefaultListItem = (): void => {
        setCurrentDefaultValue([...currentDefaultValue, '']);
        autoFocus(defaultListItemInputRef);
    };

    const hasChanged =
        JSON.stringify(currentValue) !== JSON.stringify(value) ||
        JSON.stringify(currentDefaultValue) !== JSON.stringify(defaultValue) ||
        currentDescription !== description ||
        currentKeyName !== keyName ||
        currentRequired !== required;

    const isKeyNameDuplicate =
        !!currentKeyName && sameLevelKeyNames.filter((kn) => kn !== keyName).includes(currentKeyName);

    const isCurrentRequiredValueMissing = currentRequired && !currentValue.length;

    const canUpdate =
        !isKeyNameDuplicate &&
        isScriptOrEnvVariableNameValid(currentKeyName) &&
        !isDescriptionTooLong(currentDescription) &&
        (!hasBeenEdited || hasChanged);

    const isSavedRequiredValueMissing = required && !value.length;
    const hasEmptyListItems = value.some((v) => !v) || defaultValue.some((dv) => !dv);
    const missingInformation = !keyName || isSavedRequiredValueMissing || hasEmptyListItems;

    const displayPasteValueButton = !!currentValue.length && !currentDefaultValue.length;

    const draggedVariable = droppable.active?.data.current;
    const dropAreaHeight = (draggedVariable?.height ?? 0) as number;

    const isDraggedVariableDuplicate =
        !!draggedVariable?.keyName &&
        draggedVariable.parentId !== parentId &&
        sameLevelKeyNames.includes(draggedVariable.keyName);

    const forbiddenFolderSort = parentId ? draggedVariable && draggedVariable.type === 'folder' : false;
    const sortForbidden = draggable.isDragging || isDraggedVariableDuplicate || forbiddenFolderSort;

    const readOnlyListItems = value?.length
        ? value.map((v) => <StyledReadOnlyListItem label={v || emptyWarning(true)} />)
        : emptyWarning(required);

    const readOnlyDefaultListItems = defaultValue?.length
        ? defaultValue.map((dv) => <StyledReadOnlyListItem label={dv || emptyWarning(true)} />)
        : emptyWarning();

    const editModeListItems = (
        <StyledEditModeListItemsContainer>
            {isCurrentRequiredValueMissing && (
                <DialogAlert severity="warning" text="Please add at least one list item" />
            )}
            {currentValue.map((li, i) => (
                <StyledEditModeListItem key={'list-item' + i}>
                    <TextField
                        inputRef={listItemInputRef}
                        label="Value"
                        required
                        size="small"
                        placeholder="Enter value"
                        helperText={!li && 'Please enter a value'}
                        value={li}
                        onChange={(e) => setCurrentValue(currentValue.map((v, ix) => (ix === i ? e.target.value : v)))}
                    />
                    <IconButton onClick={() => setCurrentValue(currentValue.filter((_, ix) => ix !== i))}>
                        <DeleteOutlineIcon />
                    </IconButton>
                </StyledEditModeListItem>
            ))}
            <StyledEditModeListItem>
                <Button startIcon={<AddIcon />} variant="text" onClick={handleAddListItem}>
                    Add list item
                </Button>
            </StyledEditModeListItem>
        </StyledEditModeListItemsContainer>
    );

    const editModeDefaultListItems = (
        <StyledEditModeListItemsContainer>
            {currentDefaultValue.map((li, i) => (
                <StyledEditModeListItem key={'default-list-item' + i}>
                    <TextField
                        inputRef={defaultListItemInputRef}
                        label="Default value"
                        required
                        size="small"
                        placeholder="Enter default value"
                        value={li}
                        onChange={(e) =>
                            setCurrentDefaultValue(currentDefaultValue.map((v, ix) => (ix === i ? e.target.value : v)))
                        }
                    />
                    <IconButton onClick={() => setCurrentDefaultValue(currentDefaultValue.filter((_, ix) => ix !== i))}>
                        <DeleteOutlineIcon />
                    </IconButton>
                </StyledEditModeListItem>
            ))}
            <StyledEditModeListItem>
                <Button startIcon={<AddIcon />} variant="text" onClick={handleAddDefaultListItem}>
                    Add default list item
                </Button>
                {displayPasteValueButton && (
                    <Button
                        startIcon={<ContentPasteIcon />}
                        variant="text"
                        onClick={() => setCurrentDefaultValue(currentValue)}
                    >
                        Paste the value as default value
                    </Button>
                )}
            </StyledEditModeListItem>
        </StyledEditModeListItemsContainer>
    );

    return (
        <StyledVariableWrapper
            ref={(node) => {
                draggable.setNodeRef(node);
                droppable.setNodeRef(sortForbidden ? null : node);
            }}
            isDragging={draggable.isDragging}
            nested={!!parentId}
        >
            {!sortForbidden && (
                <StyledDropArea height={dropAreaHeight} visible={droppable.isOver} nested={!!parentId} />
            )}
            <StyledVariable
                className={dragOverlay ? 'dragOverlay' : ''}
                ref={variableRef}
                onKeyUp={(event) =>
                    handleKeyUp({
                        event,
                        enterCondition: canUpdate && editMode && !isFocused(descriptionInputRef),
                        enterFn: handleUpdate,
                        escFn: handleCancel,
                    })
                }
            >
                {editMode ? (
                    <>
                        <StyledEditModeVariableHeader>
                            <Typography>{hasBeenEdited ? 'Edit variable' : 'New variable'}</Typography>
                            <StyledHeaderActions>
                                <ToggleButtonGroup
                                    exclusive
                                    value={currentRequired}
                                    onChange={() => setCurrentRequired(!currentRequired)}
                                    aria-label={required ? 'Required' : 'Optional'}
                                >
                                    <StyledEditModeRequiredButton selected={!currentRequired} value={!currentRequired}>
                                        Optional
                                    </StyledEditModeRequiredButton>
                                    <StyledEditModeRequiredButton selected={currentRequired} value={currentRequired}>
                                        Required
                                    </StyledEditModeRequiredButton>
                                </ToggleButtonGroup>
                                <IconButton onClick={() => handleCancel()}>
                                    <CloseIcon />
                                </IconButton>
                            </StyledHeaderActions>
                        </StyledEditModeVariableHeader>
                        <StyledEditModeContent>
                            <Dropdown
                                disabled={hasBeenEdited}
                                inputRef={typeInputRef}
                                label="Type"
                                selectedItem={'LIST'}
                                required
                                size="small"
                                items={environmentVariableTypes}
                                onSelect={(v) => onChangeType?.({ id, parentId, type: v as EnvironmentVariableType })}
                            />
                            <TextField
                                label="Key"
                                required
                                size="small"
                                error={!isScriptOrEnvVariableNameValid(currentKeyName) || isKeyNameDuplicate}
                                helperText={
                                    !isScriptOrEnvVariableNameValid(currentKeyName)
                                        ? invalidKeyNameHelperText
                                        : isKeyNameDuplicate
                                        ? duplicateKeyNameHelperText(!!parentId)
                                        : !currentKeyName
                                        ? 'Please enter key name'
                                        : undefined
                                }
                                placeholder="Enter key name"
                                value={currentKeyName}
                                onChange={(e) => setCurrentKeyName(e.target.value)}
                            />
                            <TextField
                                label="Notes"
                                inputRef={descriptionInputRef}
                                size="small"
                                error={isDescriptionTooLong(currentDescription)}
                                helperText={
                                    isDescriptionTooLong(currentDescription)
                                        ? `Notes length exceeds ${DESCRIPTION_MAX_LENGTH} characters`
                                        : `${DESCRIPTION_MAX_LENGTH - currentDescription.length} characters remaining`
                                }
                                multiline
                                rows={2}
                                placeholder="Enter notes"
                                value={currentDescription}
                                onChange={(e) => setCurrentDescription(e.target.value)}
                            />
                            {editModeListItems}
                            <StyledEditModeDivider>
                                <Typography>Developer Options</Typography>
                            </StyledEditModeDivider>
                            {editModeDefaultListItems}
                        </StyledEditModeContent>
                        <StyledEditModeActions>
                            <Button size="small" variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button size="small" disabled={!canUpdate} onClick={handleUpdate}>
                                {hasBeenEdited ? 'Update' : 'Create'}
                            </Button>
                        </StyledEditModeActions>
                    </>
                ) : (
                    <>
                        <StyledReadOnlyVariableHeader>
                            <StyledHeaderActions>
                                <DragButton
                                    disabled={workspaceLocked || templateMode}
                                    {...draggable.attributes}
                                    {...draggable.listeners}
                                />
                                <IconButton
                                    disabled={workspaceLocked || templateMode}
                                    onClick={() => {
                                        onToggleEditMode?.({ editMode: true, id, parentId });
                                    }}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                                <IconButton
                                    disabled={workspaceLocked || templateMode}
                                    onClick={() => onDelete?.({ id, parentId })}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </StyledHeaderActions>
                            <StyledHeaderActions>
                                {missingInformation && (
                                    <StyledReadOnlyMissingInformationChip label="Missing information" />
                                )}
                                {!templateMode ? (
                                    required ? (
                                        <StyledReadOnlyRequiredChip label="Required" />
                                    ) : (
                                        <StyledReadOnlyOptionalChip label="Optional" />
                                    )
                                ) : null}
                                <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
                                    <StyledReadOnlyExpandButton
                                        onClick={() => onToggleExpand?.({ expanded: !expanded, id, parentId })}
                                    >
                                        <Typography>Show all</Typography>
                                        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </StyledReadOnlyExpandButton>
                                </Tooltip>
                            </StyledHeaderActions>
                        </StyledReadOnlyVariableHeader>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Key *</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {keyName ? <Typography>{keyName}</Typography> : emptyWarning(true)}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>{'Value' + (required ? ' *' : '')}</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyMultipleValuesField>
                                {templateMode ? readOnlyDefaultListItems : readOnlyListItems}
                            </StyledReadOnlyMultipleValuesField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Notes</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {description ? <Typography>{description}</Typography> : emptyWarning()}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        {expanded && (
                            <>
                                <StyledReadOnlyRow>
                                    <StyledReadOnlyKeyField>
                                        <Typography>Type</Typography>
                                    </StyledReadOnlyKeyField>
                                    <StyledReadOnlyValueField>
                                        <Typography>List</Typography>
                                    </StyledReadOnlyValueField>
                                </StyledReadOnlyRow>
                                {!templateMode && (
                                    <StyledReadOnlyRow>
                                        <StyledReadOnlyKeyField>
                                            <Typography>Default value</Typography>
                                        </StyledReadOnlyKeyField>
                                        <StyledReadOnlyMultipleValuesField>
                                            {readOnlyDefaultListItems}
                                        </StyledReadOnlyMultipleValuesField>
                                    </StyledReadOnlyRow>
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledVariable>
        </StyledVariableWrapper>
    );
};
