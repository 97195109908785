import { Abort, refetch, Retry } from '../utils/refetch';
import { hasError } from './guards';
import {
    PackageError,
    PackageJson,
    PackageSelector,
    PackageVersionMap,
    FetchFromSkypackProps,
    FetchFromSkypackViaUrlProps,
    File,
} from './types';

export const SKYPACK_URL = 'https://cdn.skypack.dev';
export const DEFAULT_VERSION = 'latest';

// TODO: Validate response data

// eslint-disable-next-line max-len
const getSkypackUrl = (
    name: string,
    version: string = DEFAULT_VERSION,
    path = '',
    query = '',
    bypassCache = false
): string =>
    bypassCache || !skypackPinnedUrlCache.has(`${name}@${version}`)
        ? `${SKYPACK_URL}/${name}@${version}${path}${query ? '?' : ''}${query}`
        : // eslint-disable-next-line sonarjs/no-nested-template-literals
          `${skypackPinnedUrlCache.get(`${name}@${version}`)}${path}${query ? '?' : ''}${query}`;

/**
 * Resolve a module id to an skypack URL, using the versions map to determine the package version
 */
export const resolveModuleToSkypackUrl =
    (versions: PackageVersionMap = {}) =>
    (moduleId: string): string => {
        const scoped = moduleId[0] === '@';
        const packageName = moduleId.split('/', scoped ? 2 : 1).join('/');
        const modulePath = moduleId.substr(packageName.length).replace(/^\//, '');
        return getSkypackUrl(packageName, versions[packageName], modulePath ? '/' + modulePath : '', undefined, true);
    };

// eslint-disable-next-line max-len
const isSkypackPinnedUrl = (url: string): boolean =>
    url.startsWith(`${SKYPACK_URL}/-/`) || url.startsWith(`${SKYPACK_URL}/pin/`);

const packageCache = new Map<string, Promise<PackageJson | PackageError>>();
export const skypackPinnedUrlCache = new Map<string, string>();
export const importSuggestionsCache = new Map<string, File[]>();

/**
 * Fetch the package.json of an NPM package
 *
 * @param name regular or scoped package name
 * @param version exact or fuzzy version (1.0.0, ^1.0.0, latest, etc.) - defaults to latest
 */
// eslint-disable-next-line max-len
export const getPackageJson = async ({
    name,
    version = DEFAULT_VERSION,
    path = '',
}: PackageSelector): Promise<PackageJson | PackageError> => {
    const key = `${name}@${version}${path}`;
    let promise = packageCache.get(key);
    if (!promise) {
        promise = fetchPackageJson(name, version, path);
        packageCache.set(key, promise);
    }
    const result = await promise;
    if (hasError(result)) {
        // Remove from cache to allow it to be retried later
        packageCache.delete(key);
    }
    return result;
};

const fetchPackageJson = async (name: string, version: string, path: string): Promise<PackageJson | PackageError> => {
    try {
        // Uncomment to simulate randomized errors, consider putting it behind a feature flag
        // if (Math.random() > 0.7) {
        //     throw Error('Randomized error')
        // }

        const response = await fetchFromSkypack({
            name,
            version,
            path: `${path}/package.json`,
        });
        const packageJson = (await response.json()) as PackageJson;

        // TODO: fix this properly, add proper typesVersions support,
        // preferably after Skypack has added support for it: https://github.com/skypackjs/skypack-cdn/issues/183
        if (packageJson.name === 'jose-browser-runtime') {
            packageJson.types = 'dist/types/';
        }

        return packageJson;
    } catch (e) {
        return {
            name,
            version,
            path,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (e as any).responseStatus ?? String(e),
        };
    }
};

export const fetchFromSkypack = async ({
    name,
    version = DEFAULT_VERSION,
    path = '',
    query = '',
    bypassCache = false,
    ...rest
}: FetchFromSkypackProps): Promise<Response> => {
    const url = getSkypackUrl(name, version, path, query, bypassCache);
    const response = await fetchFromSkypackViaUrl({
        url,
        cache: isFuzzyVersion(version) ? 'no-cache' : undefined,
        ...rest,
    });

    if (isSkypackPinnedUrl(response.url) && response.url.endsWith('/package.json')) {
        // eslint-disable-next-line max-len
        const pinnedVersion = response.url
            .substring(0, response.url.lastIndexOf('-'))
            .substring(response.url.lastIndexOf('@v') + 2);

        if (!isFuzzyVersion(pinnedVersion)) {
            const pinnedPackage = `${name}@${pinnedVersion}`;
            const pinnedUrl = response.url.substring(0, response.url.length - 13);
            skypackPinnedUrlCache.set(pinnedPackage, pinnedUrl);
            console.info(`Cached pinned URL - Package: ${pinnedPackage} - URL: ${pinnedUrl}`);
        }
    }

    return response;
};

export const fetchFromSkypackViaUrl = async ({
    url,
    method,
    pinnedHeader = 'x-import-url',
    cache,
}: FetchFromSkypackViaUrlProps): Promise<Response> => {
    return await refetch(url, {
        method,
        cache,
        afterFetch: async (_time, response) => {
            if (response?.ok) {
                if (response.headers.has(pinnedHeader)) {
                    const pinnedUrl = response.headers.get(pinnedHeader);
                    if (pinnedUrl?.startsWith('/error/')) {
                        return new Abort('Skypack package parsing error');
                    } else {
                        return new Retry(`${SKYPACK_URL}${pinnedUrl}`);
                    }
                } else if (
                    (await response.clone().text()).includes(
                        'If you believe this to be an error in Skypack, file an issue here'
                    )
                ) {
                    return new Abort('Skypack package parsing error');
                }
            }
            return undefined;
        },
    });
};

// eslint-disable-next-line max-len
const isFuzzyVersion = (version: string): boolean =>
    version.toLowerCase() === 'latest' ||
    version.toLowerCase() === '*' ||
    version.toLowerCase() === 'x' ||
    version.startsWith('^') ||
    version.startsWith('~');
