import { useObservableState } from 'observable-hooks';
import { AtlassianOnPremiseManageConnectionDialog } from '../../../components/connections/atlassian-on-premise/ManageConnectionDialog';
import {
    atlassianOnPremiseConnectionSaving$,
    atlassianOnPremiseManageConnectionAuthorizeLoading$,
    atlassianOnPremiseManageConnectionCloseDialogAction$,
    atlassianOnPremiseManageConnectionDetails$,
    atlassianOnPremiseManageConnectionDialogErrors$,
    atlassianOnPremiseManageConnectionDialogOpen$,
    atlassianOnPremiseManageConnectionInitiateSetupAction$,
    atlassianOnPremiseSaveConnectionAction$,
} from '../../../store/connection/atlassian-on-premise/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const AtlassianOnPremiseManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(atlassianOnPremiseManageConnectionDialogOpen$);
    const saving = useObservableState(atlassianOnPremiseConnectionSaving$);
    const authorizeLoading = useObservableState(atlassianOnPremiseManageConnectionAuthorizeLoading$);
    const details = useObservableState(atlassianOnPremiseManageConnectionDetails$);
    const errors = useObservableState(atlassianOnPremiseManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <AtlassianOnPremiseManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => atlassianOnPremiseManageConnectionCloseDialogAction$.next()}
            onSave={(name) => atlassianOnPremiseSaveConnectionAction$.next(name)}
            onAuthorize={(name) => atlassianOnPremiseManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
