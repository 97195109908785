import React from 'react';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Button } from '../../buttons/Button';
import { stepByStepSetupDialogStyles } from '../../dialog/dialogStyles';
import { handleKeyUp } from '../../../utils/handleKeyUp';

export interface ConfirmMinimizeWizardProps {
    open?: boolean;
    onCancel(): void;
    onProceed(): void;
}

const StyledCircle = styled('div')(({ theme }) => ({
    ...theme.typography.radiusCircle,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.constants.boxShadow,
    height: 68,
    marginRight: theme.spacing(1),
    position: 'relative',
    width: 68,
    '& img': {
        height: 60,
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    ...stepByStepSetupDialogStyles(theme),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    margin: theme.spacing(-1, 0, 3, 9.75) + ' !important',
}));

const StyledTitleContainer = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
}));

export const ConfirmMinimizeWizardDialog: React.FC<ConfirmMinimizeWizardProps> = ({
    open = false,
    onCancel,
    onProceed,
}) => {
    return (
        <StyledDialog open={open} onKeyUp={(event) => handleKeyUp({ event, enterFn: onProceed, escFn: onCancel })}>
            <StyledTitleContainer>
                <StyledCircle>
                    <img src={process.env.PUBLIC_URL + '/wizard-wave-slow.gif'} alt="Wizard waving" />
                </StyledCircle>
                <DialogTitle variant="h6">Exit the Step-by-step setup</DialogTitle>
            </StyledTitleContainer>
            <StyledDialogContent>
                <Typography>
                    Are you sure you want to exit the <strong>Step-by-step</strong> setup?
                </Typography>
            </StyledDialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onProceed}>
                    Yes
                </Button>
                <Button onClick={onCancel}>No</Button>
            </DialogActions>
        </StyledDialog>
    );
};
