import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    MenuItem,
    TextField,
    Divider,
    SelectChangeEvent,
    Select,
    InputLabel,
    ListItemText,
    OutlinedInput,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { useState, ChangeEvent, useEffect } from 'react';
import { InvocationsRequest, InvocationWorkspace } from '../../data/reporting';
import { styled } from '@mui/material/styles';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Alert } from '../alert/Alert';
import { Button } from '../buttons/Button';
import { ColumnFilters } from './ColumnFilters';
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel';

export type ReportingFilters = Omit<InvocationsRequest, 'nextToken'>;
type ExecutionStatus = Exclude<InvocationsRequest['executionStatuses'], undefined>[number];
type TriggerType = Exclude<InvocationsRequest['triggerTypes'], undefined>[number];

interface ReportingPageFiltersProps {
    invocationsLength: number;
    filters: ReportingFilters;
    onSearchInvocations(request: InvocationsRequest): void;
    onSearch(request: ReportingFilters): void;
    onUpdate(event: SelectChangeEvent<unknown>): void;
    tableFilters: string[];
    selectedFilters: string[];
    workspaces?: Record<string, InvocationWorkspace>;
}

interface ReportingPageTextFieldFilterProps {
    name: string;
    value: string | number | string[];
    filterType?: 'number';
    comparatorValue?: string;
    field:
        | 'invocationId'
        | 'organizations'
        | 'workspaces'
        | 'environment'
        | 'script'
        | 'duration'
        | 'logCount'
        | 'httpLogCount';
    comparator?: string | number;
    comparatorName?:
        | 'invocationIdComparator'
        | 'environmentComparator'
        | 'scriptComparator'
        | 'durationComparator'
        | 'logCountComparator'
        | 'httpLogCountComparator';
}

interface MenuItemProps {
    value: string;
    text: string;
}

interface StyledFormLabelProps extends FormLabelProps {
    component?: 'legend';
}

const StyledFormContainer = styled('div')(({ theme }) => ({
    '& .MuiInputBase-root .MuiSvgIcon-root': {
        fontSize: 21,
        marginRight: theme.spacing(1),
    },
    '& .MuiFormControlLabel-label': {
        marginRight: theme.spacing(3.5),
    },
    '@media (max-width: 1350px)': {
        '& .MuiInputBase-root ': {
            width: 200,
        },
    },
}));

const StyledFormLabel = styled(FormLabel)<StyledFormLabelProps>(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(2.5, 0, 0.5, 0),
    '&:not(:focus)': {
        color: theme.palette.text.primary,
    },
}));

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
    alignItems: 'center',
    margin: theme.spacing(1.5, 0),
    '& > .MuiFormControl-root': {
        margin: theme.spacing(0, 2, 0, 0),
    },
}));

const StyledButton = styled(Button)(() => ({
    height: 26,
    padding: 0,
    width: 26,
    '& .MuiSvgIcon-root': {
        fontSize: 18,
    },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledFormActions = styled(Box)(({ theme }) => ({
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(2, 1, 3),
}));

export const ReportingPageFilters: React.FC<ReportingPageFiltersProps> = ({
    invocationsLength,
    filters,
    onSearchInvocations,
    onSearch,
    onUpdate,
    tableFilters,
    selectedFilters,
    workspaces = {},
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [searchFilters, setSearchFilters] = useState(filters);
    const [hasDuplicateField, setHasDuplicateField] = useState(false);

    const invocationId = 'Invocation ID';
    const notAssociatedId = 'NA';
    const formFieldOptions: ReportingPageTextFieldFilterProps[] = [
        {
            name: 'Team',
            value: [],
            field: 'organizations',
        },
        {
            name: 'Workspace',
            value: [],
            field: 'workspaces',
        },
        {
            name: 'Environment',
            comparator: 'equals',
            comparatorName: 'environmentComparator',
            value: '',
            field: 'environment',
        },
        {
            name: 'Script',
            comparator: 'equals',
            comparatorName: 'scriptComparator',
            value: '',
            field: 'script',
        },
        {
            name: 'Duration',
            filterType: 'number',
            comparator: 'gt',
            comparatorName: 'durationComparator',
            value: '',
            field: 'duration',
        },
        {
            name: 'Logs',
            filterType: 'number',
            comparator: 'gt',
            comparatorName: 'logCountComparator',
            value: '',
            field: 'logCount',
        },
        {
            name: 'HTTP Logs',
            filterType: 'number',
            comparator: 'gt',
            comparatorName: 'httpLogCountComparator',
            value: '',
            field: 'httpLogCount',
        },
    ];

    const [formFields, setFormFields] = useState<ReportingPageTextFieldFilterProps[]>([
        {
            name: invocationId,
            comparator: 'equals',
            comparatorName: 'invocationIdComparator',
            value: '',
            field: 'invocationId',
        },
    ]);
    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(() => {
        const updatedFields = formFields.map((fields) => {
            switch (fields.field) {
                case 'invocationId':
                    return {
                        ...fields,
                        comparator: searchFilters.invocationIdComparator ?? 'equals',
                        value: searchFilters.invocationId ?? '',
                    };
                case 'organizations':
                    return {
                        ...fields,
                        value: searchFilters.organizations ?? [],
                    };
                case 'workspaces':
                    return {
                        ...fields,
                        value: searchFilters.workspaces ?? [],
                    };
                case 'environment':
                    return {
                        ...fields,
                        comparator: searchFilters.environmentComparator ?? 'equals',
                        value: searchFilters.environment ?? '',
                    };
                case 'script':
                    return {
                        ...fields,
                        comparator: searchFilters.scriptComparator ?? 'equals',
                        value: searchFilters.script ?? '',
                    };
                case 'duration':
                    return {
                        ...fields,
                        comparator: searchFilters.durationComparator ?? 'gt',
                        value: searchFilters.duration ?? '',
                    };
                case 'logCount':
                    return {
                        ...fields,
                        comparator: searchFilters.logCountComparator ?? 'gt',
                        value: searchFilters.logCount ?? '',
                    };
                case 'httpLogCount':
                    return {
                        ...fields,
                        comparator: searchFilters.httpLogCountComparator ?? 'gt',
                        value: searchFilters.httpLogCount ?? '',
                    };
                default:
                    return fields;
            }
        });
        setFormFields(updatedFields);
    }, [searchFilters]);

    useEffect(() => {
        const hasDuplicates = formFields.length !== new Set(formFields.map((item) => item.name)).size;
        setHasDuplicateField(hasDuplicates);
    }, [formFields]);

    const handleSetSearchFilter = (value: string | number | undefined, filter?: keyof ReportingFilters): void => {
        if (filter) {
            setSearchFilters((prev) => ({
                ...prev,
                [filter]: value === undefined || value === '' ? undefined : value,
            }));
        }
    };

    const handleSetField = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void => {
        const newField = formFieldOptions.find((field) => field.name === event.target.value);
        if (newField) {
            const newArray = [...formFields.slice(0, index), newField, ...formFields.slice(index + 1)];
            setFormFields(newArray);
        }
    };

    const handleAddField = (): void => {
        for (const field of formFieldOptions) {
            const fieldAlreadyExists = formFields.find((existingField) => existingField.name === field.name);
            if (!fieldAlreadyExists) {
                setFormFields((prev) => [...prev, field]);
                break;
            }
        }
    };

    const handleRemoveField = (
        index: number,
        field: keyof ReportingFilters,
        comparatorName?: keyof ReportingFilters
    ): void => {
        setFormFields((prev) => prev.filter((_, i) => i !== index));
        setSearchFilters((prev) => ({
            ...prev,
            ...(comparatorName ? { [comparatorName]: undefined } : {}),
            [field]: undefined,
        }));
    };

    const handleFilterStatusChange = (
        status: ExecutionStatus | TriggerType,
        checked: boolean,
        statusType: 'executionStatuses' | 'triggerTypes'
    ): void => {
        if (checked) {
            setSearchFilters((prev) => ({
                ...prev,
                [statusType]: [...(prev[statusType] ?? []), status],
            }));
        } else {
            setSearchFilters((prev) => ({
                ...prev,
                [statusType]: ((prev[statusType] ?? []) as (ExecutionStatus | TriggerType)[]).filter(
                    (sts) => status !== sts
                ),
            }));
        }
    };

    const handleWorkspaceOrOrganizationSelectChange = (
        event: SelectChangeEvent<string[]>,
        field: 'organizations' | 'workspaces'
    ): void => {
        const value = event.target.value;
        if (Array.isArray(value)) {
            setSearchFilters((prev) => ({
                ...prev,
                [field]: value.length > 0 ? value : undefined,
            }));
        }
    };

    const handleSearch = (): void => {
        onSearchInvocations(searchFilters);
        onSearch(searchFilters);
    };

    const textMenuItems = [
        {
            value: 'equals',
            text: 'Equals',
        },
        {
            value: 'contains',
            text: 'Contains',
        },
    ];
    const numberMenuItems = [
        {
            value: 'gt',
            text: 'Greater Than',
        },
        {
            value: 'lt',
            text: 'Less Than',
        },
    ];

    const numberFields = ['duration', 'logCount', 'httpLogCount'];

    const getMenuItems = (items: MenuItemProps[]): JSX.Element[] =>
        items.map((item, i) => (
            <MenuItem key={i} value={item.value}>
                {item.text}
            </MenuItem>
        ));

    const organizations = Object.values(workspaces).reduce<Record<string, { name: string; uid: string }>>((acc, ws) => {
        if (ws.organization?.uid && !acc[ws.organization.uid]) {
            acc[ws.organization.uid] = ws.organization;
        }
        return acc;
    }, {});

    const sortedWorkspaces = Object.values(workspaces).sort((ws1, ws2) => {
        if (ws1.organization && ws2.organization) {
            return ws1.organization.name.localeCompare(ws2.organization.name);
        } else if (ws1.organization) {
            return 1;
        } else if (ws2.organization) {
            return -1;
        } else {
            return ws1.name.localeCompare(ws2.name);
        }
    });

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const getOrganizationsOrWorkspacesMultiSelectField = (type: 'workspaces' | 'organizations'): JSX.Element => {
        switch (type) {
            case 'organizations':
                return (
                    <FormControl>
                        <InputLabel>Team</InputLabel>
                        <Select
                            multiple
                            value={searchFilters.organizations ?? []}
                            onChange={(event) => handleWorkspaceOrOrganizationSelectChange(event, 'organizations')}
                            input={<OutlinedInput label="Team" />}
                            renderValue={(selected) =>
                                selected
                                    .map((uid) => {
                                        const orgName = organizations[uid]?.name;
                                        const defaultName = uid === notAssociatedId ? 'Not Associated' : uid;
                                        return orgName ?? defaultName;
                                    })
                                    .join(', ')
                            }
                        >
                            <MenuItem value={notAssociatedId}>
                                <Checkbox checked={(searchFilters.organizations ?? []).includes(notAssociatedId)} />
                                <ListItemText primary={'Not Associated'} />
                            </MenuItem>
                            {Object.values(organizations).map((org) => (
                                <MenuItem key={org.uid} value={org.uid}>
                                    <Checkbox checked={(searchFilters.organizations ?? []).includes(org.uid)} />
                                    <ListItemText primary={org.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'workspaces':
            default:
                return (
                    <FormControl>
                        <InputLabel>Workspace</InputLabel>
                        <Select
                            multiple
                            value={searchFilters.workspaces ?? []}
                            onChange={(event) => handleWorkspaceOrOrganizationSelectChange(event, 'workspaces')}
                            input={<OutlinedInput label="Workspace" />}
                            renderValue={(selected) => selected.map((uid) => workspaces[uid]?.name ?? uid).join(', ')}
                        >
                            {sortedWorkspaces.map((ws) => {
                                const orgName = ws.organization ? ` (${ws.organization.name})` : '';
                                const isDeleted = ws.deleted ? ' (deleted)' : '';
                                return (
                                    <MenuItem key={ws.uid} value={ws.uid}>
                                        <Checkbox checked={(searchFilters.workspaces ?? []).includes(ws.uid)} />
                                        <ListItemText primary={`${ws.name}${orgName}${isDeleted}`} />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                );
        }
    };

    return (
        <StyledFormContainer className="formcontainer">
            {hasDuplicateField && (
                // TODO - change to DialogAlert from DialogComponents.tsx once that becomes available
                <StyledAlert
                    severity="error"
                    alertTitle="Error"
                    text="Cannot include a filter condition with a repeated column"
                />
            )}
            <StyledFormLabel component="legend">
                Report Criteria. You can refine the results by adding more fields +
            </StyledFormLabel>
            {/* eslint-disable-next-line sonarjs/cognitive-complexity */}
            {formFields.map((field, i) => (
                <StyledFormGroup row className="formrow" key={i}>
                    {field.name === invocationId ? (
                        <FormControl size="small">
                            <TextField
                                label="Column"
                                variant="outlined"
                                type={field.filterType}
                                value={field.name}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                    ) : (
                        <FormControl>
                            <TextField
                                select
                                variant="outlined"
                                label="Column"
                                value={field.name}
                                onChange={(event) => handleSetField(event, i)}
                            >
                                {formFieldOptions.map((item, i) => (
                                    <MenuItem key={i} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    )}
                    <FormControl>
                        {field.field === 'organizations' || field.field === 'workspaces' ? (
                            getOrganizationsOrWorkspacesMultiSelectField(field.field)
                        ) : (
                            <TextField
                                margin="normal"
                                select
                                variant="outlined"
                                label="Comparator"
                                value={field.comparator}
                                onChange={(event) => handleSetSearchFilter(event.target.value, field.comparatorName)}
                            >
                                {field.filterType === 'number'
                                    ? getMenuItems(numberMenuItems)
                                    : getMenuItems(textMenuItems)}
                            </TextField>
                        )}
                    </FormControl>
                    {!(field.field === 'organizations' || field.field === 'workspaces') && (
                        <FormControl>
                            <TextField
                                variant="outlined"
                                label="Value"
                                type={field.filterType}
                                value={field.value}
                                onChange={(event) =>
                                    handleSetSearchFilter(
                                        numberFields.includes(field.field)
                                            ? event.target.value === ''
                                                ? undefined
                                                : +event.target.value
                                            : event.target.value,
                                        field.field
                                    )
                                }
                            />
                        </FormControl>
                    )}
                    {field.name === invocationId ? (
                        <StyledButton
                            color="secondary"
                            variant="contained"
                            onClick={handleAddField}
                            disabled={formFields.length > formFieldOptions.length}
                            tooltip="Add a new field"
                        >
                            <AddRoundedIcon />
                        </StyledButton>
                    ) : (
                        <StyledButton
                            color="secondary"
                            onClick={() => handleRemoveField(i, field.field, field.comparatorName)}
                            tooltip="Remove this field"
                            variant="outlined"
                        >
                            <CloseRoundedIcon />
                        </StyledButton>
                    )}
                </StyledFormGroup>
            ))}
            <StyledFormLabel component="legend">Time Scale</StyledFormLabel>
            <StyledFormGroup row>
                <FormControl size="small">
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="From"
                        value={searchFilters.from ? dayjs(searchFilters.from) : null}
                        onChange={(value) =>
                            handleSetSearchFilter(value?.isValid() ? value.toISOString() : undefined, 'from')
                        }
                    />
                </FormControl>
                <FormControl>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="To"
                        value={searchFilters.to ? dayjs(searchFilters.to) : null}
                        onChange={(value) =>
                            handleSetSearchFilter(value?.isValid() ? value.toISOString() : undefined, 'to')
                        }
                    />
                </FormControl>
            </StyledFormGroup>
            <StyledFormLabel component="legend">Results Order</StyledFormLabel>
            <StyledFormGroup row>
                <FormControl>
                    <TextField
                        select
                        variant="outlined"
                        label="Order By"
                        value={searchFilters.orderByField ?? 'starttime'}
                        onChange={(event) => handleSetSearchFilter(event.target.value, 'orderByField')}
                    >
                        <MenuItem value="starttime">Invocation Start Time</MenuItem>
                        <MenuItem value="workspace">Workspace</MenuItem>
                        <MenuItem value="workspaceOwner">Workspace Owner</MenuItem>
                        <MenuItem value="environment">Environment</MenuItem>
                        <MenuItem value="duration">Duration</MenuItem>
                        <MenuItem value="logs">Logs</MenuItem>
                        <MenuItem value="httpLogs">HTTP Logs</MenuItem>
                    </TextField>
                </FormControl>
                <FormControl>
                    <TextField
                        select
                        variant="outlined"
                        label="Order By Direction"
                        value={searchFilters.orderByDirection ?? 'desc'}
                        onChange={(event) => handleSetSearchFilter(event.target.value, 'orderByDirection')}
                    >
                        <MenuItem value="asc">Ascending</MenuItem>
                        <MenuItem value="desc">Descending</MenuItem>
                    </TextField>
                </FormControl>
            </StyledFormGroup>
            <FormControl>
                <StyledFormLabel component="legend">Execution Status</StyledFormLabel>
                <StyledFormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.executionStatuses ?? []).includes('FINISHED')}
                                onChange={(_, checked) =>
                                    handleFilterStatusChange('FINISHED', checked, 'executionStatuses')
                                }
                                name="finished"
                            />
                        }
                        label="Finished"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.executionStatuses ?? []).includes('RUNNING')}
                                onChange={(_, checked) =>
                                    handleFilterStatusChange('RUNNING', checked, 'executionStatuses')
                                }
                                name="running"
                            />
                        }
                        label="Running"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.executionStatuses ?? []).includes('DENIED')}
                                onChange={(_, checked) =>
                                    handleFilterStatusChange('DENIED', checked, 'executionStatuses')
                                }
                                name="denied"
                            />
                        }
                        label="Denied"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.executionStatuses ?? []).includes('TIMED_OUT')}
                                onChange={(_, checked) =>
                                    handleFilterStatusChange('TIMED_OUT', checked, 'executionStatuses')
                                }
                                name="timedOut"
                            />
                        }
                        label="Timed out"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.executionStatuses ?? []).includes('ABORTED')}
                                onChange={(_, checked) =>
                                    handleFilterStatusChange('ABORTED', checked, 'executionStatuses')
                                }
                                name="aborted"
                            />
                        }
                        label="Aborted"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.executionStatuses ?? []).includes('FUNCTION_ERROR')}
                                onChange={(_, checked) =>
                                    handleFilterStatusChange('FUNCTION_ERROR', checked, 'executionStatuses')
                                }
                                name="functionError"
                            />
                        }
                        label="Function Error"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.executionStatuses ?? []).includes('MALFORMED_PAYLOAD_ERROR')}
                                onChange={(_, checked) =>
                                    handleFilterStatusChange('MALFORMED_PAYLOAD_ERROR', checked, 'executionStatuses')
                                }
                                name="malformedPayloadError"
                            />
                        }
                        label="Malformed Payload Error"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.executionStatuses ?? []).includes('RUNTIME_ERROR')}
                                onChange={(_, checked) =>
                                    handleFilterStatusChange('RUNTIME_ERROR', checked, 'executionStatuses')
                                }
                                name="runtimeError"
                            />
                        }
                        label="Runtime Error"
                    />
                </StyledFormGroup>
            </FormControl>
            <br />
            <FormControl>
                <StyledFormLabel component="legend">Trigger Type</StyledFormLabel>
                <StyledFormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.triggerTypes ?? []).includes('MANUAL')}
                                onChange={(_, checked) => handleFilterStatusChange('MANUAL', checked, 'triggerTypes')}
                                name="manual"
                            />
                        }
                        label="Manual"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.triggerTypes ?? []).includes('MANUAL_EVENT_LISTENER')}
                                onChange={(_, checked) =>
                                    handleFilterStatusChange('MANUAL_EVENT_LISTENER', checked, 'triggerTypes')
                                }
                                name="manualEventListener"
                            />
                        }
                        label="Manual Event Listener"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.triggerTypes ?? []).includes('EXTERNAL')}
                                onChange={(_, checked) => handleFilterStatusChange('EXTERNAL', checked, 'triggerTypes')}
                                name="external"
                            />
                        }
                        label="External"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.triggerTypes ?? []).includes('CHAINED')}
                                onChange={(_, checked) => handleFilterStatusChange('CHAINED', checked, 'triggerTypes')}
                                name="chained"
                            />
                        }
                        label="Chained"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(searchFilters.triggerTypes ?? []).includes('SCHEDULED')}
                                onChange={(_, checked) =>
                                    handleFilterStatusChange('SCHEDULED', checked, 'triggerTypes')
                                }
                                name="scheduled"
                            />
                        }
                        label="Scheduled"
                    />
                </StyledFormGroup>
            </FormControl>
            <StyledFormActions>
                <div className="submit-search">
                    <Button variant="contained" onClick={handleSearch} disabled={hasDuplicateField}>
                        Search
                    </Button>
                </div>
                <div className="div">Invocations Displayed: {invocationsLength}</div>
                <ColumnFilters filters={tableFilters} onUpdate={onUpdate} selectedFilters={selectedFilters} />
            </StyledFormActions>
            <StyledDivider />
        </StyledFormContainer>
    );
};
