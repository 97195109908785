import virtual from './rollup-plugin-virtual';
import { urlBuilder } from './rollup-plugin-url-builder';
import { urlResolve } from './rollup-plugin-url-resolve';
import { Plugin, rollup, RollupOutput, LoadResult } from 'rollup';
import { BundleOptions, BundledFile } from './types';
import { CompiledOutput } from '../monaco/ts-service/types';

/**
 * Create a bundle per trigger handler
 */
export const bundle = async ({
    target = '',
    compiledOutput,
    dependencies,
    bundleWithUnoptimizedSkypackImports = false,
}: BundleOptions): Promise<BundledFile[]> => {
    const files = compiledOutput.reduce((acc, compiledScript) => {
        const codeFile = compiledScript.compiled.files.find((f) => f.name.endsWith('.js'));
        const mapFile = compiledScript.compiled.files.find((f) => f.name.endsWith('.js.map'));

        if (!codeFile) {
            throw Error(`Code file was not found: ${compiledScript.compiled.files.map((f) => f.name).join(', ')}`);
        }

        const name = codeFile.name.replace(/^file:\/\/\//, '').replace(/\.js$/, '');

        acc[name] = {
            code: codeFile.text,
            map: mapFile?.text,
        };

        return acc;
    }, {} as Record<string, LoadResult>);

    const bundleModel = prepRollup([
        // Attempt to resolve from the compiled modules
        virtual(files) as Plugin,

        // Attempt to resolve canonical URL from skypack.dev
        urlBuilder(dependencies, bundleWithUnoptimizedSkypackImports) as Plugin,

        // Load URL imports
        urlResolve() as Plugin,
    ]);

    const bundleCompiledOutput = async (compiledScript: CompiledOutput): Promise<BundledFile[] | null> => {
        const inputPath = `${compiledScript.name}`;
        const outputPath = `${target}${compiledScript.name}.js`;

        if (compiledScript.uid) {
            const output = await bundleModel(inputPath);

            if (output) {
                const [{ code, map }] = output.output;
                const bundledFiles: BundledFile[] = [];

                if (code) {
                    bundledFiles.push({
                        uid: compiledScript.uid,
                        path: outputPath,
                        content: code,
                    });
                }

                if (map) {
                    bundledFiles.push({
                        uid: compiledScript.uid,
                        path: `${outputPath}.map`,
                        content: JSON.stringify(map),
                    });
                }
                return bundledFiles;
            }
        }
        return null;
    };

    return (await Promise.all(compiledOutput.map(bundleCompiledOutput)))
        .filter((bundledFiles): bundledFiles is BundledFile[] => !!bundledFiles)
        .flatMap((bundledFiles) => bundledFiles);
};

const prepRollup =
    (plugins: Plugin[]) =>
    async (input: string): Promise<RollupOutput> => {
        const rollupBuild = await rollup({
            input,
            treeshake: true,
            output: {
                exports: 'named',
            },
            onwarn: (warning) => {
                switch (warning.code) {
                    case 'THIS_IS_UNDEFINED':
                    case 'MIXED_EXPORTS':
                        break;
                    default:
                        // TODO: Emit this to a diagnostics observer
                        console.warn('ROLLUP', warning.message);
                        break;
                }
            },
            plugins,
        });

        return rollupBuild.generate({ format: 'commonjs', sourcemap: true });
    };
