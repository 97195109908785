import { fetchFromSkypackViaUrl } from '../skypack/skypack';
import { Plugin } from 'rollup';

export const urlResolve = (): Plugin => ({
    name: 'urlResolve',

    resolveId(source, importer) {
        return resolveURL(parseURL(source, importer));
    },

    async load(id) {
        const url = resolveURL(parseURL(id));
        if (url) {
            const response = await fetchFromSkypackViaUrl({
                url: url,
            });

            return response.text();
        }
        return null;
    },
});

const parseURL = (source: string, importer?: string): URL | null => {
    try {
        if (importer && (source[0] === '.' || source[0] === '/')) {
            return new URL(source, importer);
        } else {
            return new URL(source);
        }
    } catch {
        return null;
    }
};

const resolveURL = (url: URL | null): string | null => {
    switch (url?.protocol) {
        case 'http:':
        case 'https:':
            return url.href;
    }
    return null;
};
