import { BehaviorSubject, Subject, map, filter } from 'rxjs';
import { getInvocationsForReport, InvocationsReportRequest } from '../../data/report/invocations';
import { getInvocations, InvocationsResponse } from '../../data/reporting';
import { InformativeError } from '../../utils/repository';
import { monitor } from '../monitor';

export const invocations$ = monitor(
    'invocations$',
    new BehaviorSubject<InvocationsResponse>({ invocations: [], workspaces: {} })
);

export const invocationsReportLoading$ = monitor('invocationsReportLoading$', new BehaviorSubject(false));

export const invocationsReportErrors$ = monitor(
    'invocationsReportErrors',
    new BehaviorSubject<string | undefined>(undefined)
);

export const queryInvocationsAction$ = monitor(
    'queryInvocationsAction$',
    new Subject<InvocationsReportRequest & { isReport?: boolean }>()
);
export const searchInvocationsAction$ = monitor(
    'searchInvocationsAction$',
    new Subject<InvocationsReportRequest & { isReport?: boolean }>()
);
export const addInvocationsAction$ = monitor('addInvocationsAction$', new Subject<InvocationsResponse>());

queryInvocationsAction$
    .pipe(
        filter(({ nextToken }) => !!nextToken),
        map(async ({ isReport, ...request }) => {
            invocationsReportErrors$.next(undefined);

            try {
                const invocations = await (isReport ? getInvocationsForReport(request) : getInvocations(request));
                addInvocationsAction$.next({
                    workspaces: {},
                    ...invocations,
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    invocationsReportErrors$.next(e.message);
                } else {
                    invocationsReportErrors$.next(
                        'Failed to query for Invocations, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while querying invocations', e);
                }
            }
            invocationsReportLoading$.next(false);
        })
    )
    .subscribe();

searchInvocationsAction$
    .pipe(
        filter(({ nextToken }) => !nextToken),
        map(async ({ isReport, ...request }) => {
            invocationsReportLoading$.next(true);
            invocationsReportErrors$.next(undefined);

            try {
                const invocations = await (isReport ? getInvocationsForReport(request) : getInvocations(request));
                invocations$.next({
                    workspaces: {},
                    ...invocations,
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    invocationsReportErrors$.next(e.message);
                } else {
                    invocationsReportErrors$.next(
                        'Failed to search for Invocations, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while querying invocations', e);
                }
            }
            invocationsReportLoading$.next(false);
        })
    )
    .subscribe();

addInvocationsAction$
    .pipe(
        filter(({ invocations }) => invocations.length > 0),
        map(({ invocations, nextToken, workspaces }) => {
            const current = invocations$.value;
            return {
                invocations: [...current.invocations, ...invocations],
                nextToken,
                workspaces,
            };
        })
    )
    .subscribe((invocations) => invocations$.next(invocations));
