import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { APP } from '@avst-stitch/repository-lib/constants';
import { Button } from '../../buttons/Button';
import { DialogAlert } from '../../dialog/DialogComponents';
import { ConnectionModalSecretTextField } from '../../textfield/ConnectionModalTextField';
import { OpenInNewLink } from '../../link/OpenInNewLink';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { ZoomEventListenerWizardStage, ZoomEventListenerWizardSteps } from './ZoomEventListenerWizardSteps';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface ZoomEventListenerSetupDialogProps {
    errors?: string;
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    saving?: boolean;
    stage: ZoomEventListenerWizardStage;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
    onSave: (token: string) => void;
    onSetStage: (stage: ZoomEventListenerWizardStage) => void;
}

export const ZoomEventListenerSetupDialog: React.FC<ZoomEventListenerSetupDialogProps> = ({
    errors,
    eventTypeName,
    loading = false,
    open = false,
    saving = false,
    stage,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
    onSave,
    onSetStage,
}) => {
    const appName = APP.ZOOM.NAME;

    const [token, setToken] = useState('');

    useEffect(() => {
        setToken('');
    }, [open]);

    const canSaveToken = !!token && !loading && !saving;

    switch (stage) {
        case ZoomEventListenerWizardStage.SAVE_TOKEN:
            return (
                <Dialog
                    maxWidth="md"
                    open={open}
                    onKeyUp={(event) =>
                        handleKeyUp({
                            event,
                            enterCondition: canSaveToken,
                            enterFn: () => onSave(token),
                            escFn: onClose,
                        })
                    }
                >
                    <ZoomEventListenerWizardSteps stage={stage} />
                    <DialogTitle sx={{ mb: 2 }} variant="h6">
                        Event Listener setup instructions for Zoom
                    </DialogTitle>
                    {loading ? (
                        <AbsoluteCenteredLoadingSpinner />
                    ) : (
                        <>
                            {errors && <DialogAlert alertTitle="Error" severity="error" text={errors} />}
                            <DialogContent>
                                <DialogContentText variant="subtitle2">
                                    You need to setup a webhook in your Zoom instance in order to listen to events in
                                    ScriptRunner Connect.
                                </DialogContentText>
                                <DialogContentText>
                                    You also need to save your app's secret token to ScriptRunner Connect to receive
                                    events from your {appName} app.
                                </DialogContentText>
                            </DialogContent>
                            <DialogContentText component="ol">
                                <li>
                                    Visit the{' '}
                                    <OpenInNewLink url={'https://marketplace.zoom.us/user/build'}>
                                        Zoom Apps Marketplace
                                    </OpenInNewLink>{' '}
                                    in your browser. If you are not signed in, please sign in first.
                                </li>
                                <li>Select the {appName} app you want to setup the event listener for.</li>
                                <li>
                                    Click on <strong>Feature</strong>.
                                </li>
                                <li>
                                    Copy the <strong>Secret Token</strong> token and paste it in the{' '}
                                    <strong>Secret Token</strong> field below.
                                </li>
                                <li>
                                    Click <strong>Save</strong> below.
                                </li>
                            </DialogContentText>
                            <ConnectionModalSecretTextField
                                label="Secret Token"
                                value={token}
                                onUpdate={(e) => setToken(e.target.value.trim())}
                            />
                            <DialogActions>
                                <Button variant="outlined" onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button busy={saving} disabled={saving || !token} onClick={() => onSave(token)}>
                                    Save
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            );

        case ZoomEventListenerWizardStage.ADD_EVENT:
            return (
                <Dialog
                    maxWidth="md"
                    open={open}
                    onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}
                >
                    <ZoomEventListenerWizardSteps stage={stage} />
                    <DialogTitle sx={{ mb: 2 }} variant="h6">
                        Event Listener setup instructions for Zoom
                    </DialogTitle>
                    {loading ? (
                        <AbsoluteCenteredLoadingSpinner />
                    ) : (
                        <>
                            <DialogContent>
                                <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                            </DialogContent>
                            <DialogContentText component="ol" start={6}>
                                <li>Copy the URL above.</li>
                                <li>
                                    Scroll down to <strong>General Features</strong> and enable{' '}
                                    <strong>Event Subscriptions</strong>.
                                </li>
                                <li>
                                    Click on <strong>Add Event Subscription</strong>.
                                </li>
                                <li>
                                    Enter a meaningful name into the <strong>Subscription name</strong> field.
                                </li>
                                <li>
                                    Paste the copied URL into the <strong>Event notification endpoint URL</strong>{' '}
                                    field.
                                </li>
                                <li>
                                    Click on <strong>Validate</strong> so {appName} can validate the URL.{' '}
                                    <em>Note that you must have saved the token in the previous step</em>.
                                </li>
                                <li>
                                    Click on <strong>Add Events</strong>.
                                </li>
                                <li>
                                    Select ONLY the following event to listen: <strong>{eventTypeName}</strong>.
                                </li>
                                <li>
                                    Click <strong>Save</strong>.
                                </li>
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={() => onSetStage(ZoomEventListenerWizardStage.SAVE_TOKEN)}
                                >
                                    Back
                                </Button>
                                <Button onClick={onClose}>Done</Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            );
    }
};
