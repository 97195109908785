import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../buttons/Button';
import {
    ServiceNowEventListenerWizardStage,
    ServiceNowEventListenerWizardSteps,
} from './ServiceNowEventListenerWizardSteps';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface ServiceNowEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const ServiceNowEventListenerSetupDialog: React.FC<ServiceNowEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const [stage, setStage] = useState(ServiceNowEventListenerWizardStage.CREATE_RECORD);

    useEffect(() => {
        setStage(ServiceNowEventListenerWizardStage.CREATE_RECORD);
    }, [open]);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const getStageContent = (): JSX.Element => {
        switch (stage) {
            case ServiceNowEventListenerWizardStage.CREATE_RECORD:
                return (
                    <Dialog
                        maxWidth="md"
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterFn: () => setStage(ServiceNowEventListenerWizardStage.CREATE_HTTP_METHOD),
                                escFn: onClose,
                            })
                        }
                    >
                        <ServiceNowEventListenerWizardSteps stage={stage} />
                        <DialogTitle sx={{ mb: 2 }} variant="h6">
                            Event Listener setup instructions for ServiceNow
                        </DialogTitle>
                        {loading ? (
                            <AbsoluteCenteredLoadingSpinner />
                        ) : (
                            <>
                                <DialogContentText variant="subtitle2">
                                    You need to setup a webhook in your ServiceNow instance in order to listen to events
                                    in ScriptRunner Connect.
                                </DialogContentText>
                                <DialogContent>
                                    <ReadOnlyTextField
                                        label="Webhook URL"
                                        value={`${webhookBaseUrl}/${webhookUrlId}`}
                                    />
                                    <DialogContentText component="ol">
                                        <li>Copy the URL above.</li>
                                        {setupBaseUrl ? (
                                            <li>
                                                Visit your{' '}
                                                <Link target="_blank" href={`${setupBaseUrl}/sys_rest_message_list.do`}>
                                                    REST messages
                                                </Link>{' '}
                                                page.
                                            </li>
                                        ) : (
                                            <li>
                                                Open https://[YOUR_SERVICENOW_INSTANCE]/sys_rest_message_list.do in your
                                                browser
                                            </li>
                                        )}
                                        <li>
                                            Click <strong>New</strong>.
                                        </li>
                                        <li>
                                            Enter a meaningful name into the <strong>Name</strong> field.
                                        </li>
                                        <li>
                                            Paste the copied URL into the <strong>Endpoint</strong> field.
                                        </li>
                                        <li>
                                            Click <strong>Submit</strong>.
                                        </li>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="outlined" onClick={() => onClose()}>
                                        Cancel
                                    </Button>

                                    <Button
                                        onClick={() => setStage(ServiceNowEventListenerWizardStage.CREATE_HTTP_METHOD)}
                                    >
                                        Next
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                );

            case ServiceNowEventListenerWizardStage.CREATE_HTTP_METHOD:
                return (
                    <Dialog
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterFn: () => setStage(ServiceNowEventListenerWizardStage.CONFIGURE_HTTP_METHOD),
                                escFn: onClose,
                            })
                        }
                    >
                        <ServiceNowEventListenerWizardSteps stage={stage} />
                        <DialogTitle sx={{ mb: 2 }} variant="h6">
                            Event Listener setup instructions for ServiceNow
                        </DialogTitle>
                        <DialogContent>
                            <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                            <DialogContentText component="ol" start={7}>
                                <li>Copy the URL above.</li>
                                <li>
                                    Select the newly created REST message from the list to continue the configuration.
                                </li>
                                <li>
                                    Scroll down to <strong>HTTP Methods</strong> and click <strong>New</strong> to
                                    create a new HTTP method.
                                </li>
                                <li>
                                    Enter a meaningful name into the <strong>Name</strong> field.
                                </li>
                                <li>
                                    Select <strong>POST</strong> from the <strong>HTTP method</strong> dropdown.
                                </li>
                                <li>
                                    Paste the copied URL into the <strong>Endpoint</strong> field.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => setStage(ServiceNowEventListenerWizardStage.CREATE_RECORD)}
                            >
                                Back
                            </Button>

                            <Button onClick={() => setStage(ServiceNowEventListenerWizardStage.CONFIGURE_HTTP_METHOD)}>
                                Next
                            </Button>
                        </DialogActions>
                    </Dialog>
                );

            case ServiceNowEventListenerWizardStage.CONFIGURE_HTTP_METHOD:
                return (
                    <Dialog
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterFn: () => setStage(ServiceNowEventListenerWizardStage.GENERATE_VARIABLES),
                                escFn: onClose,
                            })
                        }
                    >
                        <ServiceNowEventListenerWizardSteps stage={stage} />
                        <DialogTitle sx={{ mb: 2 }} variant="h6">
                            Event Listener setup instructions for ServiceNow
                        </DialogTitle>
                        <DialogContent>
                            <ReadOnlyTextField label="HTTP Header Name" value="Content-type" />
                            <ReadOnlyTextField label="HTTP Header Value" value="application/json; charset=UTF-8" />
                            <ReadOnlyTextField
                                label="Content"
                                value={
                                    '{"title": "${title}", "eventType": "generic_triggered", "user": "${user}", "trigger": "${trigger}"}'
                                }
                            />
                            <DialogContentText component="ol" start={13}>
                                <li>
                                    Click on the <strong>HTTP Request</strong> tab.
                                </li>
                                <li>
                                    In the <strong>HTTP Headers</strong> section, click on{' '}
                                    <strong>Insert a new row...</strong> and copy the above values to the{' '}
                                    <strong>Name</strong> and <strong>Value</strong> inputs respectively
                                </li>
                                <li>
                                    Copy the JSON above and paste it to the <strong>Content</strong> field under the{' '}
                                    <strong>HTTP Query Parameters</strong> section. This will be the event payload that
                                    is sent to ScriptRunner Connect.
                                </li>
                                <li>
                                    Optionally you can add more variables to your event payload, by editing the provided
                                    JSON. Note that the values for these variables will need to be specified later under{' '}
                                    <strong>Business rules</strong>.
                                </li>
                                <li>
                                    Click on <strong>Submit</strong>.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => setStage(ServiceNowEventListenerWizardStage.CREATE_HTTP_METHOD)}
                            >
                                Back
                            </Button>

                            <Button onClick={() => setStage(ServiceNowEventListenerWizardStage.GENERATE_VARIABLES)}>
                                Next
                            </Button>
                        </DialogActions>
                    </Dialog>
                );

            case ServiceNowEventListenerWizardStage.GENERATE_VARIABLES:
                return (
                    <Dialog
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterFn: () => setStage(ServiceNowEventListenerWizardStage.CREATE_BUSINESS_RULE),
                                escFn: onClose,
                            })
                        }
                    >
                        <ServiceNowEventListenerWizardSteps stage={stage} />
                        <DialogTitle sx={{ mb: 2 }} variant="h6">
                            Event Listener setup instructions for ServiceNow
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText component="ol" start={18}>
                                <li>Click on the newly created HTTP method.</li>
                                <li>
                                    Scroll down to <strong>Related Links</strong>.
                                </li>
                                <li>
                                    Click on the <strong>Auto-generate variables</strong> link.
                                </li>
                                <li>
                                    Click on the <strong>Preview Script Usage</strong> to generate the code required to
                                    send a request to the webhook.
                                </li>
                                <li>Copy the generated code.</li>
                                <li>
                                    Close the modal and click on <strong>Update</strong>.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => setStage(ServiceNowEventListenerWizardStage.CONFIGURE_HTTP_METHOD)}
                            >
                                Back
                            </Button>

                            <Button onClick={() => setStage(ServiceNowEventListenerWizardStage.CREATE_BUSINESS_RULE)}>
                                Next
                            </Button>
                        </DialogActions>
                    </Dialog>
                );

            case ServiceNowEventListenerWizardStage.CREATE_BUSINESS_RULE:
                return (
                    <Dialog
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterFn: () => setStage(ServiceNowEventListenerWizardStage.CONFIGURE_SCRIPT),
                                escFn: onClose,
                            })
                        }
                    >
                        <ServiceNowEventListenerWizardSteps stage={stage} />
                        <DialogTitle sx={{ mb: 2 }} variant="h6">
                            Event Listener setup instructions for ServiceNow
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText component="ol" start={24}>
                                {setupBaseUrl ? (
                                    <li>
                                        Navigate to{' '}
                                        <Link target="_blank" href={`${setupBaseUrl}/sys_script_list.do`}>
                                            Business Rules
                                        </Link>
                                    </li>
                                ) : (
                                    <li>Open https://[YOUR_SERVICENOW_INSTANCE]/sys_script_list.do in your browser</li>
                                )}
                                <li>
                                    Click on <strong>New</strong>.
                                </li>
                                <li>
                                    Enter a meaningful name into the <strong>Name</strong> field.
                                </li>
                                <li>
                                    Select the table for which you wish to set up the event listener, for example{' '}
                                    <strong>{'Incident[incident]'}</strong>.
                                </li>
                                <li>
                                    Check the <strong>Advanced</strong> checkbox.
                                </li>
                                <li>
                                    Check one or more appropriate checkboxes on the right depending on which events you
                                    wish to listen to.
                                </li>
                                <li>
                                    Optionally, you can change the timing of when exactly the rule will be run by
                                    selecting a preferred option from the <strong>When</strong> dropdown.
                                </li>
                                <li>
                                    If you wish to add advanced conditions to when your event will fire, you can do so
                                    under <strong>Filter Conditions</strong> and <strong>Role Conditions</strong>.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => setStage(ServiceNowEventListenerWizardStage.GENERATE_VARIABLES)}
                            >
                                Back
                            </Button>

                            <Button onClick={() => setStage(ServiceNowEventListenerWizardStage.CONFIGURE_SCRIPT)}>
                                Next
                            </Button>
                        </DialogActions>
                    </Dialog>
                );

            case ServiceNowEventListenerWizardStage.CONFIGURE_SCRIPT:
                return (
                    <Dialog open={open} onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}>
                        <ServiceNowEventListenerWizardSteps stage={stage} />
                        <DialogTitle sx={{ mb: 2 }} variant="h6">
                            Event Listener setup instructions for ServiceNow
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText component="ol" start={32}>
                                <li>
                                    Click on the <strong>Advanced</strong> tab and paste the code you had copied in the
                                    preview step below the <strong>{'//Add your code here'}</strong> line.
                                </li>
                                <li>
                                    In the code, update the <strong>r.execute()</strong> function call to{' '}
                                    <strong>r.executeAsync()</strong>.
                                </li>
                                <li>
                                    Replace <strong>r.setStringParameterNoEscape('user', '');</strong> with{' '}
                                    <strong>r.setStringParameterNoEscape('user', gs.getUser().getID());</strong>
                                </li>
                                <li>
                                    Replace <strong>r.setStringParameterNoEscape('trigger', '');</strong> with{' '}
                                    <strong>r.setStringParameterNoEscape('trigger', current.getDisplayValue())</strong>
                                </li>
                                <li>
                                    Optionally replace the empty string in{' '}
                                    <strong>r.setStringParameterNoEscape('title', '');</strong> with an appropriate
                                    title in order to give the event object a name.
                                </li>
                                <li>
                                    Optionally if you defined more variables in step 16, they will show up here as well,
                                    but you will need to provide the data yourself. Referencing the{' '}
                                    <strong>current</strong> object might be helpful.
                                </li>
                                <li>
                                    For advanced use cases you can optionally specify your filter conditions using
                                    JavaScript. In order to do that, write your condition into the{' '}
                                    <strong>Condition</strong> field, by referencing the <strong>current</strong>{' '}
                                    object.
                                </li>
                                <li>
                                    Click <strong>Submit</strong>.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => setStage(ServiceNowEventListenerWizardStage.CREATE_BUSINESS_RULE)}
                            >
                                Back
                            </Button>

                            <Button onClick={() => onClose()}>Done</Button>
                        </DialogActions>
                    </Dialog>
                );
        }
    };

    return getStageContent();
};
