import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Dropdown } from '../../dropdown/Dropdown';
import { gt } from 'semver';

export interface PackageProperties {
    name: string;
    selectedVersion: string;
    latestVersion?: string;
    description?: string;
    link?: string;
    versions: string[];
    selected?: boolean;
    locked?: boolean;
    saved?: boolean;
    thirdParty?: boolean;
    verified?: boolean;
    childPackage?: Pick<
        PackageProperties,
        'name' | 'selectedVersion' | 'description' | 'link' | 'versions' | 'selected'
    >;
    onSelect: (selected: boolean, version: string, name: string) => void;
}

const StyledBadge = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: '2px',
    fontSize: theme.typography.body2.fontSize,
    height: '21px',
    marginLeft: theme.spacing(2),
    padding: 0,
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    padding: 0,
}));

const StyledLink = styled(Link)(() => ({
    display: 'inline-block',
}));

const StyledPackage = styled(ListItemButton)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.constants.boxShadow,
    display: 'flex',
    height: 80,
    width: '100%',
    '&.locked': {
        cursor: 'default',
        backgroundColor: theme.palette.action.disabledBackground,
        '&:hover': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
    },
}));

const StyledChildPackage = styled(StyledPackage)(() => ({
    flexGrow: 0,
    height: 70,
    width: '95%',
}));

const StyledChildPackageContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
}));

const StyledSelectContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    '& .MuiInputBase-root': {
        width: '98px',
    },
}));

const StyledTextContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    ...theme.typography.overflowBlock,
}));

export const Package: React.FC<PackageProperties> = ({
    name,
    selectedVersion,
    versions,
    description,
    link,
    childPackage,
    locked = false,
    selected = false,
    thirdParty = false,
    verified = false,
    onSelect,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [parentChecked, setParentChecked] = useState(selected);
    const [currentlySelectedVersion, setCurrentlySelectedVersion] = useState(selectedVersion);
    const [childChecked, setChildChecked] = useState(!!childPackage?.selected);
    const [currentlySelectedChildVersion, setCurrentlySelectedChildVersion] = useState(
        childPackage?.selectedVersion ?? ''
    );

    const handleParentCheckChange = (checked: boolean): void => {
        setParentChecked(checked);
        setChildChecked(checked);
    };

    useEffect(() => {
        onSelect(parentChecked, currentlySelectedVersion, name);
    }, [parentChecked, currentlySelectedVersion]);

    useEffect(() => {
        if (childPackage) {
            onSelect(childChecked, currentlySelectedChildVersion, childPackage.name);
        }
    }, [childChecked, currentlySelectedChildVersion]);

    useEffect(() => {
        if (childPackage) {
            setChildChecked(!!childPackage.selected);
            setCurrentlySelectedChildVersion(childPackage.selectedVersion);
        }
    }, [childPackage]);

    const dropdownItems = [
        ...versions
            .map((version) => ({ name: version, value: version }))
            .sort((v1, v2) => (gt(v1.value, v2.value) ? -1 : 1)),
    ];

    const childDropdownItems = (childPackage?.versions ?? [])
        .map((version) => ({ name: version, value: version }))
        .sort((v1, v2) => (gt(v1.value, v2.value) ? -1 : 1));

    const packageState = locked ? 'locked' : 'not-locked';

    return (
        <>
            <StyledPackage
                className={packageState}
                selected={parentChecked}
                onClick={() => {
                    if (!locked) {
                        handleParentCheckChange(!parentChecked);
                    }
                }}
            >
                <StyledCheckbox
                    disabled={locked}
                    checked={parentChecked}
                    onChange={(_e, checked) => handleParentCheckChange(checked)}
                />
                <StyledTextContainer sx={!description ? { justifyContent: 'center' } : null}>
                    {link ? (
                        <StyledLink onClick={(e) => e.stopPropagation()} href={link} target="_blank">
                            <Typography variant="subtitle2">{name}</Typography>
                        </StyledLink>
                    ) : (
                        <Typography variant="subtitle2">{name}</Typography>
                    )}

                    {description && (
                        <Tooltip title={description}>
                            <Typography noWrap>{description}</Typography>
                        </Tooltip>
                    )}
                </StyledTextContainer>
                <StyledSelectContainer>
                    {parentChecked && (
                        <Dropdown
                            onSelect={(version) => setCurrentlySelectedVersion(version)}
                            selectedItem={currentlySelectedVersion}
                            label="Version"
                            items={dropdownItems}
                            size="small"
                        ></Dropdown>
                    )}
                    {thirdParty && (
                        <StyledBadge
                            sx={{
                                backgroundColor: verified ? 'success.main' : 'action.disabledBackground',
                                color: verified ? 'primary.contrastText' : 'text.primary',
                            }}
                            label={verified ? 'Verified' : 'Unverified'}
                        ></StyledBadge>
                    )}
                </StyledSelectContainer>
            </StyledPackage>
            {childPackage && (
                <StyledChildPackageContainer>
                    <StyledChildPackage
                        className={packageState}
                        selected={childChecked}
                        onClick={() => {
                            if (!locked) {
                                setChildChecked(!childChecked);
                            }
                        }}
                    >
                        <StyledCheckbox
                            disabled={locked}
                            checked={childChecked}
                            onChange={(_event, checked) => setChildChecked(checked)}
                        />
                        <StyledTextContainer sx={!childPackage.description ? { justifyContent: 'center' } : null}>
                            {childPackage.link ? (
                                <StyledLink href={childPackage.link} target="_blank">
                                    <Typography variant="subtitle2">{childPackage.name}</Typography>
                                </StyledLink>
                            ) : (
                                <Typography variant="subtitle2">{childPackage.name}</Typography>
                            )}

                            {childPackage.description && (
                                <Tooltip title={childPackage.description}>
                                    <Typography noWrap>{childPackage.description}</Typography>
                                </Tooltip>
                            )}
                        </StyledTextContainer>
                        <StyledSelectContainer>
                            {childChecked && (
                                <Dropdown
                                    onSelect={(version) => setCurrentlySelectedChildVersion(version)}
                                    selectedItem={currentlySelectedChildVersion}
                                    label="Version"
                                    items={childDropdownItems}
                                    size="small"
                                ></Dropdown>
                            )}
                            {thirdParty && (
                                <StyledBadge
                                    sx={{
                                        backgroundColor: verified ? 'success.main' : 'action.disabledBackground',
                                        color: verified ? 'primary.contrastText' : 'text.primary',
                                    }}
                                    label={verified ? 'Verified' : 'Unverified'}
                                ></StyledBadge>
                            )}
                        </StyledSelectContainer>
                    </StyledChildPackage>
                </StyledChildPackageContainer>
            )}
        </>
    );
};
