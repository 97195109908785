import { BehaviorSubject, Subject } from 'rxjs';
import { hotjarEvent } from '../data/hotjar-events';
import { segmentAnalyticsTrack } from '../data/segment-analytics';
import { monitor } from './monitor';
import { selectedWorkspaceUid$ } from './workspace';
import { openNewBlankWorkspaceAction$ } from './workspaces';
import { loggedInUserDetails$ } from './user';

export enum WizardStep {
    NONE,
    START,
    CREATE_WORKSPACE,
    ADD_API_CONNECTION,
    SAVE_API_CONNECTION,
    ADD_EVENT_LISTENER,
    SAVE_EVENT_LISTENER,
    CREATE_SCRIPT,
    SUCCESS,
}

export enum WizardState {
    CLOSED,
    CONFIRM,
    MINIMIZED,
    ACTIVE,
}

export interface WizardWorkspaceDetails {
    workspaceUid?: string;
    environmentUid?: string;
}

export interface Wizard {
    state: WizardState;
    step: WizardStep;
    createdUnsavedResource?: string;
    workspaceDetails?: WizardWorkspaceDetails;
}

export interface ResourceManagerOffsets {
    container: number;
    scripts: number;
    apiConnections: number;
    eventListeners: number;
}

export const wizardState$ = monitor('wizardState$', new BehaviorSubject<WizardState>(WizardState.CLOSED));
export const wizardStep$ = monitor('wizardStep$', new BehaviorSubject<WizardStep>(WizardStep.NONE));
export const wizardWorkspaceDetails$ = monitor(
    'wizardDetail$',
    new BehaviorSubject<WizardWorkspaceDetails | undefined>(undefined)
);
export const wizardCreatedUnsavedWorkspaceResourceUid$ = monitor(
    'wizardCreatedUnsavedWorkspaceResourceUid$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const resourceManagerOffsets$ = monitor(
    'resourceManagerOffsets$',
    new BehaviorSubject<ResourceManagerOffsets | undefined>(undefined)
);

export const updateWizardStateAction$ = monitor('updateWizardStateAction$', new Subject<WizardState>());
export const updateWizardStepAction$ = monitor('updateWizardStepAction$', new Subject<WizardStep>());
export const navigateToWizardWorkspaceAction$ = monitor(
    'navigateToWizardWorkspaceAction$',
    new Subject<WizardWorkspaceDetails>()
);
export const saveWizardCreatedWorkspaceResourceAction$ = monitor(
    'saveWizardCreatedWorkspaceResourceAction$',
    new Subject<string | undefined>()
);
export const setResourceManagerOffsetsAction$ = monitor(
    'setResourceManagerOffsetsAction$',
    new Subject<ResourceManagerOffsets | undefined>()
);

updateWizardStateAction$.subscribe((state) => {
    if (state === WizardState.CLOSED) {
        updateWizardStepAction$.next(WizardStep.NONE);
        wizardWorkspaceDetails$.next(undefined);
        saveWizardCreatedWorkspaceResourceAction$.next(undefined);
    } else if (
        state === WizardState.ACTIVE &&
        selectedWorkspaceUid$.value !== wizardWorkspaceDetails$.value?.workspaceUid
    ) {
        const { workspaceUid, environmentUid } = wizardWorkspaceDetails$?.value || {};
        navigateToWizardWorkspaceAction$.next({ workspaceUid, environmentUid });
    }
    wizardState$.next(state);
});

updateWizardStepAction$.subscribe((step) => {
    if (step !== WizardStep.SAVE_API_CONNECTION && step !== WizardStep.SAVE_EVENT_LISTENER) {
        saveWizardCreatedWorkspaceResourceAction$.next(undefined);
    }
    switch (step) {
        case WizardStep.START:
            updateWizardStateAction$.next(WizardState.ACTIVE);
            wizardWorkspaceDetails$.next(undefined);
            break;
        case WizardStep.CREATE_WORKSPACE:
            openNewBlankWorkspaceAction$.next('guided');
            break;
    }
    wizardStep$.next(step);
});

saveWizardCreatedWorkspaceResourceAction$.subscribe((uid) => {
    wizardCreatedUnsavedWorkspaceResourceUid$.next(uid);
});

setResourceManagerOffsetsAction$.subscribe((offsets) => {
    resourceManagerOffsets$.next(offsets);
});

export const guidedSetupFinishedAction$ = monitor('guidedSetupFinishedAction$', new Subject<void>());

guidedSetupFinishedAction$.subscribe(() => {
    const event = 'guided-setup-finished';
    try {
        hotjarEvent(event);
    } catch (e) {
        console.error(`Error while triggering Hotjar event ${event}, error: ${(e as Error).message}`);
    }
    segmentAnalyticsTrack('Setup Finished', { type: 'guided', userOrigin: loggedInUserDetails$.value?.userOrigin });
});
