import { BehaviorSubject, map, Subject } from 'rxjs';
import { InformativeError } from '../utils/repository';
import { saveUserFeedback } from '../data/userFeedback';
import { monitor } from './monitor';
import { encode } from 'base64-arraybuffer';
import { publishLocalFeedbackEventAction$ } from './feedback';

interface UserFeedback {
    email: string;
    message: string;
    attachments: File[];
    canContact: boolean;
}

export interface UserFeedbackAttachment {
    fileName: string;
    content: string;
}

export const openUserFeedbackDialogAction$ = monitor('openUserFeedbackDialogAction$', new Subject<void>());
export const closeUserFeedbackDialogAction$ = monitor('closeUserFeedbackDialogAction$', new Subject<void>());
export const saveUserFeedbackAction$ = monitor('saveUserFeedbackAction$', new Subject<UserFeedback>());
export const savingNewUserFeedback$ = monitor('savingNewUserFeedback$', new BehaviorSubject(false));
export const userFeedbackDialogOpen$ = monitor('userFeedbackDialogOpen$', new BehaviorSubject(false));
export const userFeedbackDialogErrors$ = monitor(
    'userFeedbackDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

openUserFeedbackDialogAction$.subscribe(() => userFeedbackDialogOpen$.next(true));
closeUserFeedbackDialogAction$.subscribe(() => {
    userFeedbackDialogOpen$.next(false);
    savingNewUserFeedback$.next(false);
    userFeedbackDialogErrors$.next(undefined);
});

saveUserFeedbackAction$
    .pipe(
        map(async ({ email, message, attachments, canContact }) => {
            if (email.trim().length === 0 || message.trim().length === 0) {
                userFeedbackDialogErrors$.next('User email or message is empty.');
                return;
            }

            savingNewUserFeedback$.next(true);
            userFeedbackDialogErrors$.next(undefined);
            const files: UserFeedbackAttachment[] = [];

            for (const attachment of attachments) {
                const content = await readFile(attachment);
                if (content instanceof ArrayBuffer) {
                    const base64String = encode(content);
                    files.push({
                        fileName: attachment.name,
                        content: base64String,
                    });
                }
            }

            try {
                await saveUserFeedback(email, message, files, canContact);
                userFeedbackDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Feedback sent.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    userFeedbackDialogErrors$.next(e.message);
                } else {
                    userFeedbackDialogErrors$.next(
                        'Unknown error occurred while saving a user feedback. Please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving user feedback', e);
                }
            }

            savingNewUserFeedback$.next(false);
        })
    )
    .subscribe();

const readFile = (file: Blob): Promise<string | ArrayBuffer | null> => {
    const reader = new FileReader();
    return new Promise((resolve) => {
        reader.addEventListener('load', () => resolve(reader.result));
        reader.readAsArrayBuffer(file);
    });
};
