import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '../../buttons/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export interface InvocationsLimitAlertProps {
    remainingInvocations?: number;
    onClose(): void;
    onUpgradePlan(): void;
}

const StyledBoxContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.action.selected}`,
    borderRadius: theme.constants.borderRadius,
    backgroundColor: theme.palette.background.paper,
}));

const StyledCircleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
    borderRadius: theme.constants.radiusCircle,
    backgroundColor: theme.palette.action.selected,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        height: 20,
        width: 20,
    },
}));

export const InvocationsLimitAlert: React.FC<InvocationsLimitAlertProps> = ({
    remainingInvocations = 0,
    onClose,
    onUpgradePlan,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useResizeObserver({
        ref: containerRef,
        onResize: () => {
            if (containerRef.current) {
                const clientRect = containerRef?.current?.getBoundingClientRect();
                setContainerWidth(clientRect?.width);
            }
        },
    });

    const text =
        remainingInvocations > 0 ? 'Approaching script invocation limit ' : 'You have run out of script invocations ';
    const fullWidth = containerWidth >= 500;
    return (
        <StyledBoxContainer ref={containerRef}>
            <StyledCircleContainer>
                {remainingInvocations > 0 ? (
                    <WarningAmberIcon
                        sx={(theme) => ({
                            color: theme.palette.warning.main,
                            fontSize: 20,
                            paddingBottom: theme.spacing(0.2),
                        })}
                    />
                ) : (
                    <ErrorOutlineIcon
                        sx={(theme) => ({
                            color: theme.palette.error.main,
                            fontSize: 20,
                        })}
                    />
                )}
            </StyledCircleContainer>
            <Box display="flex" width={fullWidth ? 'auto' : '80%'}>
                <Typography variant="body1">
                    {text}(<b>{remainingInvocations}</b> remaining)
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                sx={(theme) => ({
                    marginLeft: fullWidth ? 'auto' : 0,
                    paddingTop: theme.spacing(fullWidth ? 0 : 1),
                })}
            >
                <Button size="small" onClick={onUpgradePlan}>
                    Upgrade
                </Button>
                {remainingInvocations > 0 && (
                    <StyledIconButton onClick={onClose}>
                        <CloseIcon />
                    </StyledIconButton>
                )}
            </Box>
        </StyledBoxContainer>
    );
};
