import { useObservableState } from 'observable-hooks';
import { MicrosoftAppConfigureDialog } from '../../../components/connections/microsoft/MicrosoftAppConfigureDialog';
import { configTopic$ } from '../../../store/config';
import {
    microsoftConnectionSaving$,
    microsoftManageConnectionDetails$,
    microsoftAppSetupDialogErrors$,
    microsoftAppSetupDialogStage$,
    microsoftAppSetupDialogOpen$,
    microsoftAppSetupCloseDialogAction$,
    microsoftAppSetupAuthorizeAction$,
} from '../../../store/connection/microsoft/setup-connection';

export const MicrosoftConfigureDialog: React.FC = () => {
    const open = useObservableState(microsoftAppSetupDialogOpen$);
    const saving = useObservableState(microsoftConnectionSaving$);
    const details = useObservableState(microsoftManageConnectionDetails$);
    const errors = useObservableState(microsoftAppSetupDialogErrors$);
    const currentStage = useObservableState(microsoftAppSetupDialogStage$);
    const callbackUrl = configTopic$.value.connection?.microsoft?.restBaseUrl;
    const adminConsentUrl = configTopic$.value.connection?.microsoft?.adminConsentUrl;

    return (
        <MicrosoftAppConfigureDialog
            name={details?.name ?? ''}
            open={open}
            onClose={() => {
                microsoftAppSetupCloseDialogAction$.next();
                microsoftAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => microsoftAppSetupDialogStage$.next(stage)}
            clientInfo={{
                tenantId: details?.tenantId ?? '',
                clientId: details?.clientId ?? '',
                clientSecret: details?.clientSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => microsoftAppSetupDialogErrors$.next(error)}
            clearErrors={() => microsoftAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => microsoftAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${callbackUrl}`}
            adminConsentUrl={`${adminConsentUrl}`}
        />
    );
};
