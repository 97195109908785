import React, { ReactNode } from 'react';
import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { ProductIcon } from '../../icons/ProductIcon';
import { ThreeDotsButton } from '../../buttons/ThreeDotsButton';
import {
    SelectorItemDescription,
    selectorCardIconWrapStyle,
    selectorCardStyle,
    SelectorItemTitle,
    selectorChipStyle,
    TemplateStyledChip,
} from '../../selector/SelectorComponents';
import Tooltip from '@mui/material/Tooltip';

interface TemplateSelectorCardProps {
    description?: string;
    draft?: boolean;
    isNew?: boolean;
    incoming?: string[];
    onSelect(uid: string): void;
    onEdit(uid: string): void;
    outgoing?: string[];
    title?: string;
    uid: string;
    userCanWorkWithTemplates?: boolean;
}

const StyledCard = styled(Card)(({ theme }) => ({
    ...selectorCardStyle(theme),
    padding: theme.spacing(3, 2, 1),
    width: 420,
}));

const StyledMoreItems = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(1),
    '& > :not(:first-of-type)': {
        marginLeft: theme.spacing(1),
    },
}));

const StyledInfoItem = styled(Chip)(({ theme }) => ({
    ...selectorChipStyle(theme),
}));

const StyledUpperCard = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    height: 110,
    margin: theme.spacing(1, 0),
    p: {
        ...theme.typography.overflowBlock,
        WebkitLineClamp: '4',
    },
}));

const StyledTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
}));

const StyledMediaContainer = styled('div')(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: theme.spacing(3, 0, 1, 0),
}));

const StyledRow = styled('span')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
    },
}));

const StyledIconContainer = styled('div')(({ theme }) => ({
    ...selectorCardIconWrapStyle(theme),
    boxShadow: theme.constants.boxShadow,
    height: theme.spacing(4),
    width: theme.spacing(4),
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

export const TemplateSelectorCard: React.FC<TemplateSelectorCardProps> = ({
    description,
    draft = false,
    isNew = false,
    incoming = [],
    onSelect,
    outgoing = [],
    title,
    uid,
    onEdit,
    userCanWorkWithTemplates,
}) => {
    const iconList = (connections: string[]): ReactNode[] => {
        return connections.map((connection, index) => {
            return (
                <Tooltip title={connection} key={index} describeChild>
                    <StyledIconContainer>
                        <ProductIcon name={connection} />
                    </StyledIconContainer>
                </Tooltip>
            );
        });
    };
    const MediaContainer: React.FC = () => {
        return (
            <>
                <StyledMediaContainer>
                    <StyledRow>
                        <TemplateStyledChip
                            label="Incoming"
                            sx={{ backgroundColor: 'connections.main' }}
                            icon={<DownloadOutlinedIcon />}
                        />
                        {iconList(incoming)}
                    </StyledRow>
                    <StyledRow>
                        <TemplateStyledChip
                            label="Outgoing"
                            sx={{ backgroundColor: 'connections.light' }}
                            icon={<UploadOutlinedIcon />}
                        />
                        {iconList(outgoing)}
                    </StyledRow>
                </StyledMediaContainer>
            </>
        );
    };

    return (
        <StyledCard
            elevation={1}
            onClick={!draft ? () => onSelect(uid) : undefined}
            sx={draft ? { cursor: 'default' } : {}}
        >
            <StyledMoreItems>
                {draft && (
                    <StyledInfoItem
                        sx={{ backgroundColor: 'action.disabled', color: 'text.secondary' }}
                        label="Draft"
                    />
                )}
                {isNew && (
                    <StyledInfoItem sx={{ backgroundColor: 'setup.main', color: 'setup.contrastText' }} label="New" />
                )}
            </StyledMoreItems>
            {userCanWorkWithTemplates && (
                <ThreeDotsButton
                    uid={uid}
                    actions={[{ name: 'Edit', onSelect: onEdit, icon: <ModeEditOutlineOutlinedIcon /> }]}
                />
            )}
            <StyledUpperCard>
                <StyledCardContent>
                    <StyledTitleContainer>
                        <SelectorItemTitle block={true} title={title} />
                    </StyledTitleContainer>
                    <SelectorItemDescription description={description} />
                </StyledCardContent>
                <CardMedia component={MediaContainer} />
            </StyledUpperCard>
        </StyledCard>
    );
};
