import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../buttons/Button';
import { DialogAlert, DialogTitleMain } from '../../dialog/DialogComponents';
import { StyledChip } from '../../layout';
import { IconCircle } from '../../icon-circle';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface ManageEnvironmentsProps {
    environments: {
        uid: string;
        name: string;
        default: boolean;
    }[];
    errors?: string;
    loading?: boolean;
    open?: boolean;
    onClose(): void;
    onDelete(uid: string): void;
    onRename(uid: string, newName: string): void;
}

const StyledDiv = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.constants.boxShadow,
    borderRadius: theme.constants.borderRadius,
    flexDirection: 'row',
    height: 48,
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 2),
    width: '100%',
    '& .MuiFormControl-root': {
        margin: 0 + ' !important',
    },
    '&:first-of-type': {
        marginTop: theme.spacing(0),
    },
}));

const StyledIcons = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    '& .MuiSvgIcon-root': {
        height: 22,
        width: 22,
        cursor: 'pointer',
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.secondary.main,
        },
        '&:last-child': {
            marginLeft: theme.spacing(1),
        },
    },
}));

const StyledTextField = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        width: 'fit-content',
    },
    '& .MuiInputBase-input': {
        maxWidth: 280,
    },
}));

const StyledUpdateButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

const StyledResults = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

export const ManageEnvironments: React.FC<ManageEnvironmentsProps> = ({
    environments,
    errors,
    loading = false,
    open = false,
    onClose,
    onDelete,
    onRename,
}) => {
    const NUMBER = 7;
    const DEFAULT_INPUT_WIDTH = 180;

    const [listEnvironments, setListEnvironments] = useState(environments);
    const [newName, setNewName] = useState('');
    const [selectedUid, setSelectedUid] = useState('');
    const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

    useEffect(() => {
        if (newName.length * NUMBER > DEFAULT_INPUT_WIDTH) {
            setInputWidth(newName.length * NUMBER);
        } else {
            setInputWidth(DEFAULT_INPUT_WIDTH);
        }
    }, [newName]);

    useEffect(() => {
        setListEnvironments(environments);
    }, [environments]);

    const createLabel = (): string => {
        const currentResultsLength = environments.length ?? 0;

        return currentResultsLength === 1 ? currentResultsLength + ' Result' : currentResultsLength + ' Results';
    };

    const handleUpdate = (uid: string): void => {
        const updateList = listEnvironments.map((env) => {
            if (uid === env.uid) {
                return { ...env, name: newName };
            }
            return env;
        });

        setListEnvironments(updateList);
        onRename(uid, newName);
        setSelectedUid('');
    };

    const list = listEnvironments.map((env) => {
        const canUpdate = !!newName && newName !== env.name && !loading;
        return (
            <StyledDiv key={env.uid}>
                {selectedUid === env.uid ? (
                    <>
                        <StyledTextField
                            size="small"
                            autoFocus
                            value={newName}
                            placeholder="Enter new name"
                            onChange={(e) => setNewName(e.target.value)}
                            onKeyUp={(event) =>
                                handleKeyUp({
                                    event,
                                    enterCondition: canUpdate,
                                    enterFn: () => handleUpdate(env.uid),
                                })
                            }
                            sx={{
                                '& .MuiInputBase-input': {
                                    width: inputWidth,
                                },
                            }}
                        />
                        <StyledUpdateButton
                            variant="outlined"
                            size="small"
                            onClick={() => handleUpdate(env.uid)}
                            disabled={!canUpdate}
                        >
                            Update
                        </StyledUpdateButton>
                    </>
                ) : (
                    <Typography noWrap variant="subtitle2" sx={{ maxWidth: 400 }}>
                        {env.name}
                    </Typography>
                )}
                <StyledIcons>
                    <Tooltip title="Rename" placement="top">
                        <EditOutlinedIcon
                            onClick={() => {
                                selectedUid !== env.uid ? setSelectedUid(env.uid) : setSelectedUid('');
                                setNewName(env.name);
                            }}
                        />
                    </Tooltip>
                    {!env.default && (
                        <Tooltip title="Delete" placement="top">
                            <DeleteOutlinedIcon onClick={() => onDelete(env.uid)} />
                        </Tooltip>
                    )}
                </StyledIcons>
            </StyledDiv>
        );
    });

    return (
        <Dialog open={open} onKeyUp={(event) => handleKeyUp({ event, escFn: onClose })}>
            <DialogTitleMain title="Manage Environments" icon={<IconCircle icon={<DisplaySettingsRoundedIcon />} />} />
            {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
            {loading ? (
                <AbsoluteCenteredLoadingSpinner />
            ) : (
                <>
                    <StyledResults>
                        <StyledChip label={createLabel()} />
                    </StyledResults>
                    <DialogContent sx={{ padding: 0.25, mb: 1 }}>{environments ? list : undefined}</DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={onClose}>
                            Close
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
