import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { UsersWithDeployedWorkspaces } from '../../data/report/usersWithDeployedWorkspaces';
import { PageContainer } from '../layout/PageComponents';

export interface UsersWithDeployedWorkspacesReportPageProps {
    users?: UsersWithDeployedWorkspaces;
}

export const UsersWithDeployedWorkspacesReportPage: React.FC<UsersWithDeployedWorkspacesReportPageProps> = ({
    users,
}) => {
    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">Users with Deployed Workspaces</Typography>
            </Box>
            <TableContainer>
                <Table sx={{ width: '450px' }} component={Paper}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Total Users</TableCell>
                            <TableCell>Users with Deployed Workspaces</TableCell>
                            <TableCell>Users with Deployed Workspaces (non-default environments)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{users?.total}</TableCell>
                            <TableCell>{users?.usersWithDeployedWorkspaces}</TableCell>
                            <TableCell>{users?.usersWithNonDefaultDeployedWorkspaces}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </PageContainer>
    );
};
