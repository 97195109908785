import { fetchFromSkypackViaUrl, getPackageJson, resolveModuleToSkypackUrl, SKYPACK_URL } from '../skypack/skypack';
import { Plugin } from 'rollup';
import { PackageName, PackageVersion } from './types';
import { hasError } from '../skypack/guards';

/**
 * Rollup plugin to resolve modules from skypack.dev
 */
export const urlBuilder = (
    versions: Record<PackageName, PackageVersion> = {},
    bundleWithUnoptimizedSkypackImports: boolean
): Plugin => ({
    name: 'urlBuilder',

    async resolveId(source) {
        if (source[0] === '/' || source[0] === '.' || source.includes('://')) {
            if (
                bundleWithUnoptimizedSkypackImports &&
                source.startsWith('/-/') &&
                source.includes('mode=imports/optimized/')
            ) {
                return getUrlForPackageJsonMain(source.slice(3), versions);
            }

            return null;
        }
        return getCanonicalUrl(source, versions);
    },
});

const canonicalUrlCache = new Map<string, Promise<string>>();

const getCanonicalUrl = async (
    source: string,
    versions: Record<PackageName, PackageVersion> = {}
): Promise<string | null> => {
    const pkg = `${source}@${versions[source] ?? 'latest'}`;
    try {
        let promise = canonicalUrlCache.get(pkg);
        if (!promise) {
            promise = fetchCanonicalUrl(source, versions);
            canonicalUrlCache.set(pkg, promise);
        }
        return promise;
    } catch (e) {
        console.error('Failed to resolve bundler canonical URL', e);
        canonicalUrlCache.delete(pkg);
        return null;
    }
};

const fetchCanonicalUrl = async (
    source: string,
    versions: Record<PackageName, PackageVersion> = {}
): Promise<string> => {
    const url = resolveModuleToSkypackUrl(versions)(source);

    const resolvedUrl = (
        await fetchFromSkypackViaUrl({
            url,
            method: 'HEAD',
        })
    ).url;

    if (!url) {
        throw Error('Response URL missing');
    }

    return resolvedUrl;
};

const getUrlForPackageJsonMain = async (
    source: string,
    versions: Record<PackageName, PackageVersion> = {}
): Promise<string | null> => {
    const scoped = source[0] === '@';
    const packageName = source.split('@', scoped ? 2 : 1).join('@');
    const packageVersion = versions[packageName];
    const packageJson = await getPackageJson({ version: packageVersion, name: packageName });

    if (hasError(packageJson)) {
        console.error('Failed to get url for Package.json main module');
        return null;
    }

    const start = source.indexOf('mode=imports/optimized/');
    return `${SKYPACK_URL}/-/${source.substring(0, start)}mode=imports/unoptimized/${packageJson.main ?? 'index.js'}`;
};
