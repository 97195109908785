import { useObservableState } from 'observable-hooks';
import { OnboardingTemplateSelector } from '../components/onboarding/OnboardingTemplateSelector';
import {
    onboardingTemplateSelectorOpen$,
    onboardingTemplateSelectorTemplates$,
    closeOnboardingTemplateSelectorAction$,
} from '../store/onboarding';
import { navigateAction$ } from '../store/navigation';
import { segmentAnalyticsTrack } from '../data/segment-analytics';
import { loggedInUserDetails$ } from '../store/user';

export const OnboardingTemplateSelectorContainer: React.FC = () => {
    const onboardingTemplateSelectorOpen = useObservableState(onboardingTemplateSelectorOpen$);
    const templates = useObservableState(onboardingTemplateSelectorTemplates$);
    const userDetails = useObservableState(loggedInUserDetails$);

    return (
        <OnboardingTemplateSelector
            open={onboardingTemplateSelectorOpen && !!templates?.length}
            templates={(templates ?? []).map((tmp) => ({
                name: tmp.template.name,
                appsUsed: tmp.apps,
                onSelect: () => {
                    closeOnboardingTemplateSelectorAction$.next();
                    segmentAnalyticsTrack('OnboardingTemplateSuggestion Selected', {
                        priorityApp: tmp.priorityApp,
                        template: {
                            uid: tmp.template.uid,
                            name: tmp.template.name,
                        },
                        userId: userDetails?.uid,
                        stitchTeamMember: userDetails?.stitchTeamMember,
                        userOrigin: userDetails?.userOrigin,
                    });
                    navigateAction$.next(`template/${tmp.template.uid}`);
                },
            }))}
            onBrowse={() => {
                closeOnboardingTemplateSelectorAction$.next();
                segmentAnalyticsTrack('BrowseMoreTemplates selected', {
                    userId: userDetails?.uid,
                    stitchTeamMember: userDetails?.stitchTeamMember,
                    userOrigin: userDetails?.userOrigin,
                });
                navigateAction$.next('templates');
            }}
            onClose={() => {
                closeOnboardingTemplateSelectorAction$.next();
                segmentAnalyticsTrack('TemplateSelector closed', {
                    userId: userDetails?.uid,
                    stitchTeamMember: userDetails?.stitchTeamMember,
                    userOrigin: userDetails?.userOrigin,
                });
                navigateAction$.next('dashboard');
            }}
        />
    );
};
