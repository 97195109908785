import { Box, Divider, styled, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DotNavigation } from '../dot-navigation';
import { ProductIcon } from '../icons/ProductIcon';
import { IconCircle } from '../icon-circle';
import { DialogTitleMain } from '../dialog/DialogComponents';

export enum OnboardingWizardStage {
    SELECT_PROFESSION,
    SELECT_CODING_LEVEL,
    SELECT_APPS,
    SELECT_GOALS,
}

export interface OnboardingWizardStepsProps {
    readonly stage: OnboardingWizardStage;
    onSetStage: (stage: OnboardingWizardStage) => void;
}

interface OnboardingAppItemProps {
    name: string;
    selected?: boolean;
    uid: string;
    onSelect(uid: string): void;
}

interface OnboardingGoalCardProps {
    icon: JSX.Element;
    selected?: boolean;
    title: string;
    onSelect(title: string): void;
}

interface ProfileUpdateTitleProps {
    icon: JSX.Element;
    title: string;
    subtitle: string;
    color?: string;
}

interface ProfileUpdateContentProps {
    title: string;
    items: {
        icon: JSX.Element;
        title: string;
        description?: string;
    }[];
}

const StyledAppItem = styled('div')(() => ({
    textAlign: 'center',
    width: 126,
}));

const StyledAppCardContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
}));

const StyledAppCard = styled('div')<{ selected?: boolean }>(({ theme, selected }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: selected ? theme.palette.action.selected : theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    cursor: 'pointer',
    justifyContent: 'center',
    padding: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        height: 48,
        width: 48,
    },
    '&:hover': {
        backgroundColor: selected ? theme.palette.action.focus : theme.palette.action.hover,
    },
}));

const StyledGoalCard = styled('div')<{ selected: boolean }>(({ selected, theme }) => ({
    backgroundColor: selected ? theme.palette.action.selected : theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    cursor: 'pointer',
    height: 120,
    textAlign: 'center',
    width: 180,
    '&:hover': {
        backgroundColor: selected ? theme.palette.action.focus : theme.palette.action.hover,
    },
}));

const StyledIconContainer = styled('div')(({ theme }) => ({
    alignItems: 'flex-end',
    display: 'flex',
    height: '50%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        height: 28,
        width: 28,
    },
}));

const StyledTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(2),
    '& .MuiDialogTitle-root': {
        margin: 0,
    },
    '& .MuiBox-root > div:first-of-type': {
        marginBottom: 0,
    },
    '& > div:first-of-type': {
        height: 52,
        width: 52,
        '& .MuiSvgIcon-root': {
            height: 32,
            width: 32,
        },
    },
}));

export const FullLengthDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(0, -3),
}));

const StyledBodyContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledListItems = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingTop: theme.spacing(2),
    '& > div:first-of-type': {
        height: 40,
        width: 40,
        marginRight: theme.spacing(1.5),
        '& .MuiSvgIcon-root': {
            height: 24,
            width: 24,
        },
    },
}));

export const OnboardingWizardSteps: React.FC<OnboardingWizardStepsProps> = ({ stage, onSetStage }) => {
    return (
        <DotNavigation
            activeStep={stage}
            onSetActiveStep={onSetStage}
            stageEnum={OnboardingWizardStage}
        ></DotNavigation>
    );
};

export const OnboardingAppItem: React.FC<OnboardingAppItemProps> = ({ selected = false, name, uid, onSelect }) => {
    return (
        <StyledAppItem>
            <StyledAppCardContainer>
                <StyledAppCard selected={selected} onClick={() => onSelect(uid)}>
                    <Tooltip title={name} describeChild>
                        <ProductIcon name={name} />
                    </Tooltip>
                </StyledAppCard>
            </StyledAppCardContainer>
            <Typography color="text.secondary">{name}</Typography>
        </StyledAppItem>
    );
};

export const OnboardingGoalCard: React.FC<OnboardingGoalCardProps> = ({ icon, title, selected = false, onSelect }) => {
    return (
        <StyledGoalCard selected={selected} onClick={() => onSelect(title)}>
            <StyledIconContainer>{icon}</StyledIconContainer>
            <Typography color="text.secondary">{title}</Typography>
        </StyledGoalCard>
    );
};

export const ProfileUpdateTitle: React.FC<ProfileUpdateTitleProps> = ({ icon, title, subtitle, color }) => {
    return (
        <>
            <StyledTitleContainer>
                <IconCircle icon={icon} color={color} />
                <Box ml={0.5}>
                    <DialogTitleMain title={title} />
                    <Typography variant="subtitle2" fontWeight="normal">
                        {subtitle}
                    </Typography>
                </Box>
            </StyledTitleContainer>
            <FullLengthDivider />
        </>
    );
};

export const ProfileUpdateContent: React.FC<ProfileUpdateContentProps> = ({ items, title }) => {
    const theme = useTheme();
    return (
        <StyledBodyContainer>
            <Typography variant="subtitle1">{title}</Typography>
            {items.map((item, i) => (
                <StyledListItems key={i}>
                    <IconCircle icon={item.icon} color={theme.palette.text.primary} />
                    <div>
                        <Typography variant="subtitle2">{item.title}</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {item.description}
                        </Typography>
                    </div>
                </StyledListItems>
            ))}
        </StyledBodyContainer>
    );
};
