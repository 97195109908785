import { useObservableState } from 'observable-hooks';
import { useNavigate, useSearch } from 'react-location';
import { InvocationsReportPage, ReportingFilters } from '../../components/report/InvocationsReportPage';
import {
    invocations$,
    invocationsReportLoading$,
    invocationsReportErrors$,
    queryInvocationsAction$,
    searchInvocationsAction$,
} from '../../store/report/invocations';

export const InvocationsReportPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const search = useSearch();
    const invocations = useObservableState(invocations$);
    const isLoading = useObservableState(invocationsReportLoading$);
    const errors = useObservableState(invocationsReportErrors$);

    return (
        <InvocationsReportPage
            invocations={invocations.invocations}
            nextToken={invocations.nextToken}
            isLoading={isLoading}
            errors={errors}
            filters={
                {
                    invocationId: search.invocationId,
                    invocationIdComparator: search.invocationIdComparator,
                    workspace: search.workspace,
                    workspaceComparator: search.workspaceComparator,
                    workspaceOwner: search.workspaceOwner,
                    workspaceOwnerComparator: search.workspaceOwnerComparator,
                    environment: search.environment,
                    environmentComparator: search.environmentComparator,
                    script: search.script,
                    scriptComparator: search.scriptComparator,
                    executionStatuses: search.executionStatuses,
                    triggerTypes: search.triggerTypes,
                    duration: search.duration,
                    durationComparator: search.durationComparator,
                    logCount: search.logCount,
                    logCountComparator: search.logCountComparator,
                    httpLogCount: search.httpLogCount,
                    httpLogCountComparator: search.httpLogCountComparator,
                    from: search.from,
                    to: search.to,
                    orderByField: search.orderByField,
                    orderByDirection: search.orderByDirection,
                } as ReportingFilters
            }
            onQueryInvocations={(request) => queryInvocationsAction$.next({ ...request, isReport: true })}
            onSearchInvocations={(request) => searchInvocationsAction$.next({ ...request, isReport: true })}
            onSearch={(request) => {
                navigate({
                    search: {
                        ...request,
                    },
                });
            }}
        />
    );
};
