import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { ConfirmMinimizeWizardDialog } from '../../components/wizard/modals/ConfirmMinimizeWizardDialog';
import {
    updateWizardStateAction$,
    wizardState$,
    WizardState,
    wizardStep$,
    updateWizardStepAction$,
    navigateToWizardWorkspaceAction$,
} from '../../store/wizard';
import { getBasePath } from '../../utils/path';

export const ConfirmMinimizeWizardContainer: React.FC = () => {
    const navigate = useNavigate();

    const navigateToWorkspace = (workspaceUid: string, environmentUid: string): void =>
        navigate({ to: `${getBasePath()}workspace/${workspaceUid}/environment/${environmentUid}` });

    const wizardState = useObservableState(wizardState$);
    const wizardStep = useObservableState(wizardStep$);
    const open = wizardState === WizardState.CONFIRM;

    const handleCancel = (): void => {
        updateWizardStateAction$.next(WizardState.ACTIVE);
        updateWizardStepAction$.next(wizardStep);
    };

    useSubscription(navigateToWizardWorkspaceAction$, ({ workspaceUid, environmentUid }) => {
        if (workspaceUid && environmentUid) {
            navigateToWorkspace(workspaceUid, environmentUid);
        }
    });

    return (
        <ConfirmMinimizeWizardDialog
            open={open}
            onCancel={handleCancel}
            onProceed={() => updateWizardStateAction$.next(WizardState.MINIMIZED)}
        />
    );
};
