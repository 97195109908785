/* eslint-disable sonarjs/no-duplicate-string */
import { Theme } from '@mui/material';

export const stepByStepSetupDialogStyles = (theme: Theme) =>
    ({
        '& .MuiDialog-paper': {
            backgroundColor: theme.palette.background.default,
            minHeight: 202,
            '& .MuiDialogContent-root': {
                margin: theme.spacing(1, 5.75, 3),
            },
        },
    } as const);

export const tinyDialogStyles = {
    '& .MuiDialog-paper': {
        minHeight: 125,
        width: 400,
    },
} as const;
