import { useObservableState } from 'observable-hooks';
import { AtlassianCloudManageConnectionDialog } from '../../../components/connections/atlassian-cloud/ManageConnectionDialog';
import {
    atlassianCloudConnectionSaving$,
    atlassianCloudManageConnectionAuthorizeLoading$,
    atlassianCloudManageConnectionCloseDialogAction$,
    atlassianCloudManageConnectionDetails$,
    atlassianCloudManageConnectionDialogErrors$,
    atlassianCloudManageConnectionDialogOpen$,
    atlassianCloudManageConnectionInitiateSetupAction$,
    atlassianCloudSaveConnectionAction$,
} from '../../../store/connection/atlassian-cloud/manage-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const AtlassianCloudManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(atlassianCloudManageConnectionDialogOpen$);
    const saving = useObservableState(atlassianCloudConnectionSaving$);
    const authorizeLoading = useObservableState(atlassianCloudManageConnectionAuthorizeLoading$);
    const details = useObservableState(atlassianCloudManageConnectionDetails$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );
    const errors = useObservableState(atlassianCloudManageConnectionDialogErrors$);

    return (
        <AtlassianCloudManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => atlassianCloudManageConnectionCloseDialogAction$.next()}
            onSave={(name) => atlassianCloudSaveConnectionAction$.next(name)}
            onAuthorize={(name) => atlassianCloudManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
