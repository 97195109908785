import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ReadOnlyEventListenerDetails } from '../../components/workspace/event-listener-details/ReadOnlyEventListenerDetails';
import { useObservableState } from 'observable-hooks';
import { apps$ } from '../../store/apps';
import { selectedEventListenerDetails$ } from '../../store/workspace/event-listener';
import { selectedWorkspaceResources$ } from '../../store/workspace';

const StyledContainer = styled('div')(({ theme }) => ({
    height: '100%',
    padding: theme.spacing(2, 4),
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
    height: '26px',
    minHeight: '26px',
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.text.secondary,
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.text.secondary,
    height: '24px',
    minHeight: 'unset',
    padding: 0,
    '&.Mui-selected': {
        color: theme.palette.text.primary,
    },
    '&:not(:first-of-type)': {
        marginLeft: theme.spacing(2.5),
    },
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
    height: `calc(100% - ${theme.spacing(4.25)})`,
    padding: theme.spacing(3, 0, 0),
}));

export const ReadOnlyEventListenerDetailsContainer: React.FC = () => {
    const details = useObservableState(selectedEventListenerDetails$);
    const apps = useObservableState(apps$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);

    const selectedAppName = apps.find((a) => a.uid === details?.appUid)?.name ?? '';
    const filteredEventTypes =
        apps
            .find((a) => a.uid === details?.appUid)
            ?.connectionType.eventListenerTypes.find((elt) => elt.uid === details?.eventListenerTypeUid)?.eventTypes ??
        [];

    return (
        <StyledContainer>
            <TabContext value="details">
                <Box>
                    <StyledTabList>
                        <StyledTab label="Details" value="details" />
                    </StyledTabList>
                </Box>
                <StyledTabPanel value="details">
                    <ReadOnlyEventListenerDetails
                        connectionRequired={details?.connectionRequired}
                        selectedAppName={selectedAppName}
                        scripts={workspaceResources.scripts}
                        selectedEventTypeUid={details?.eventTypeUid}
                        selectedScriptUid={details?.scriptUid}
                        eventTypes={filteredEventTypes}
                    />
                </StyledTabPanel>
            </TabContext>
        </StyledContainer>
    );
};
