/* eslint-disable sonarjs/cognitive-complexity */
import { PropsWithChildren, useEffect, useState } from 'react';
import { setupFeedbackConnection } from '../store/feedback';
import { useAuth } from '../hooks/auth';
import { useObservableState } from 'observable-hooks';
import {
    configTopic$,
    loadTokens$,
    sessionExpired$,
    showSessionExpiredWarning$,
    startSessionAction$,
    stitchSession$,
} from '../store/config';
import { CenteredLoadingSpinner } from '../components/loading/CenteredLoadingSpinner';
import { publicIpv4 } from 'public-ip';
import {
    loggedInUserDetails$,
    registerAnonUserAction$,
    unregisterAnonUserAction$,
    userAuthenticatedAction$,
} from '../store/user';
import { readLocalStorage, saveLocalStorage } from '../utils/localStorage';
import { ulid } from 'ulid';
import { loadErrorPage } from '../store/error';
import { ErrorPageContainer } from '../containers/ErrorPageContainer';

const isUserOnline = async (): Promise<boolean> => {
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => {
        abortController.abort();
    }, 5000);

    try {
        await fetch('https://google.com', {
            mode: 'no-cors',
            signal: abortController.signal,
        });
        clearTimeout(timeoutId);
        return true;
    } catch (error) {
        console.error('User is not online or has network issues: ', error);
        clearTimeout(timeoutId);
        return false;
    }
};

const Authenticator: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { isLoading, isAuthenticated, error, operations, userDetails } = useAuth();

    const session = useObservableState(stitchSession$);
    const sessionExpired = useObservableState(sessionExpired$);
    const loadTokens = useObservableState(loadTokens$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);

    const [showErrorPage, setShowErrorPage] = useState<boolean>(window.location.pathname === '/error');

    useEffect(() => {
        const setupAuthenticatedUser = async (): Promise<void> => {
            const initialLoad = sessionExpired === undefined;

            const isOnline = initialLoad ? true : await isUserOnline();

            loadTokens$.next(undefined);

            if (isOnline) {
                const tokens = await operations.getTokens();
                const accessToken = tokens.access_token;

                stitchSession$.next({ jwt: accessToken });
                startSessionAction$.next((tokens.expires_in - 5) * 1000);
                sessionExpired$.next(false);
                showSessionExpiredWarning$.next(false);

                if (initialLoad) {
                    setupFeedbackConnection({ stitchSession$, configTopic$ });
                    if (userDetails.sub) {
                        userAuthenticatedAction$.next(userDetails.sub);
                    }
                }

                const stitchInitialUid: string = readLocalStorage('stitchInitialUid', '');
                if (stitchInitialUid) {
                    unregisterAnonUserAction$.next(stitchInitialUid);
                }
            } else {
                //user offline and session has expired so show the warning
                if (sessionExpired) {
                    showSessionExpiredWarning$.next(true);
                }
            }
        };

        if (isAuthenticated && sessionExpired !== false && loadTokens !== false) {
            setupAuthenticatedUser();
        }
    }, [isAuthenticated, sessionExpired, loadTokens]);

    useEffect(() => {
        const registerAnonUser = async (): Promise<void> => {
            let ip = '';
            const searchParams = new URLSearchParams(window.location.search);
            try {
                ip = await publicIpv4();
            } catch (err) {
                console.error('Error: ', err);
            }
            const record = {
                uid: ulid(),
                ip,
                originUid: searchParams.get('originUid') ?? undefined,
                utmMedium: searchParams.get('utm_medium') ?? undefined,
                utmSource: searchParams.get('utm_source') ?? undefined,
                utmCampaign: searchParams.get('utm_campaign') ?? undefined,
                referrerSite: document.referrer,
            };
            saveLocalStorage('stitchInitialUid', record.uid);
            registerAnonUserAction$.next(record);
            operations.login();
        };
        if (!isAuthenticated && !isLoading && !error) {
            const stitchInitialUid: string = readLocalStorage('stitchInitialUid', '');
            if (stitchInitialUid === '') {
                registerAnonUser();
            } else {
                operations.login();
            }
        }
    });

    useEffect(() => {
        if (error) {
            setShowErrorPage(true);
            loadErrorPage({
                error: error.message,
                genericMessage: 'Error encountered whilst logging you in',
                skipRefreshMessageOverride: 'Try logging out and logging back in.',
                showDashboardLink: false,
            });
        } else {
            setShowErrorPage(false);
        }
    }, [error]);

    if (showErrorPage) {
        return <ErrorPageContainer />;
    }
    if (isLoading) {
        return <CenteredLoadingSpinner />;
    }

    if (isAuthenticated && loggedInUserDetails) {
        return (
            <>
                {session && children}
                {!session && <CenteredLoadingSpinner />}
            </>
        );
    } else {
        return <CenteredLoadingSpinner />;
    }
};

export default Authenticator;
