import React from 'react';
import { styled, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Button } from '../buttons/Button';
import { dashboardCardStyles } from './DashboardComponents';

interface DashboardInstructionsCardProps {
    color: 'dark' | 'light';
    icon?: JSX.Element;
    image?: string;
    instructions?: string;
    onContinue: () => void;
    title?: string;
}

const StyledInstructionCard = styled('div')(({ theme }) => ({
    // do not put any width constraints on the card containers, let the parent Grid xs do the sizing
    ...dashboardCardStyles,
    borderRadius: theme.constants.borderRadius,
    color: theme.palette.setup.contrastText,
    padding: theme.spacing(2, 3),
}));

const StyledIconBox = styled('div')(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    height: 44,
    position: 'relative',
    width: 44,
    '&:before': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.constants.borderRadius,
        content: '""',
        height: '100%',
        opacity: 0.08,
        position: 'absolute',
        width: '100%',
    },
    '& .MuiSvgIcon-root': {
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

const StyledImage = styled('img')(({ theme }) => ({
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 150,
    [theme.breakpoints.down('lg')]: {
        top: '25%',
        width: 100,
    },
}));

const StyledInstruction = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
}));

const StyledInstructionText = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: 82,
    marginBottom: theme.spacing(2),
}));

export const DashboardInstructionsCard: React.FC<DashboardInstructionsCardProps> = ({
    color,
    icon,
    image,
    instructions,
    onContinue,
    title,
}) => {
    const theme = useTheme();
    const buttonStyle = {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        backgroundColor: color === 'light' ? 'setup.main' : 'setup.light',
        color: 'setup.contrastText',
        '&:hover': {
            backgroundColor: 'setup.dark',
            boxShadow: theme.constants.boxShadow,
        },
    };
    return (
        <StyledInstructionCard sx={{ backgroundColor: color === 'light' ? 'setup.light' : 'setup.main' }}>
            <StyledIconBox>{icon}</StyledIconBox>
            <StyledInstructionText>
                <StyledInstruction>{instructions}</StyledInstruction>
                <Typography component="h2" variant="h6">
                    {title}
                </Typography>
            </StyledInstructionText>
            {image && <StyledImage className="chromatic-ignore" src={image} alt={title} />}
            <Button sx={buttonStyle} onClick={onContinue}>
                Continue
            </Button>
        </StyledInstructionCard>
    );
};
