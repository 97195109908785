import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export interface BannerProps extends MuiAlertProps {
    severity: 'error' | 'warning' | 'info' | 'success';
    text?: string | JSX.Element;
    alertTitle?: string;
    action?: React.ReactNode;
}

const StyledBanner = styled(MuiAlert, {
    shouldForwardProp: (prop) => prop !== 'maxSize' && prop !== 'minSize',
})(({ theme }) => ({
    minWidth: '100%',
    '& .MuiAlert-message': {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    '& .MuiAlert-action': {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}));

const StyledBannerTitle = styled(AlertTitle)(() => ({
    fontWeight: 700,
}));

export const Banner: React.FC<BannerProps> = ({ severity, text, alertTitle, action, ...props }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
        }
    }, []);
    return (
        <StyledBanner
            ref={ref}
            {...props}
            severity={severity}
            action={action}
            iconMapping={{
                success: <CheckCircleOutlineIcon />,
            }}
        >
            {alertTitle && <StyledBannerTitle>{alertTitle}</StyledBannerTitle>}
            {text}
        </StyledBanner>
    );
};
