import { Subject, map, BehaviorSubject } from 'rxjs';
import { selectedEnvironmentUid$ } from '.';
import { SaveEventListenerPayloadEvent } from '../../components/workspace/event-listener-payloads';
import {
    EventListenerTestPayloads,
    createEventListenerTestPayload,
    getEventListenerTestPayload,
    saveEventListenerTestPayload,
} from '../../data/event-listener';
import { InformativeError } from '../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';

interface CreateEventListenerPayload {
    eventListenerUid: string;
    environmentUid: string;
    name: string;
}

export const saveEventListenerPayloadAction$ = monitor(
    'saveEventListenerPayloadAction$',
    new Subject<SaveEventListenerPayloadEvent>()
);

export const openCreateEventListenerPayloadAction$ = monitor(
    'openCreateEventListenerPayloadAction$',
    new Subject<void>()
);
export const cancelCreateEventListenerPayloadAction$ = monitor(
    'cancelCreateEventListenerPayloadAction$',
    new Subject<void>()
);

export const saveNewBlankEventListenerPayloadAction$ = monitor(
    'saveNewBlankEventListenerPayloadAction$',
    new Subject<CreateEventListenerPayload>()
);

export const loadEventListenerPayloadContentAction$ = monitor(
    'loadEventListenerPayloadContentAction$',
    new Subject<string>()
);

export const changeEventListenerPayloadContentAction$ = monitor(
    'changeEventListenerPayloadContentAction$',
    new Subject<{ uid: string; content: string }>()
);

export const createEventListenerPayloadDialogOpen$ = monitor(
    'createEventListenerPayloadDialogOpen$',
    new BehaviorSubject(false)
);

export const savingNewBlankEventListenerPayload$ = monitor(
    'savingNewBlankEventListenerPayload$',
    new BehaviorSubject(false)
);

export const newBlankEventListenerPayloadErrors$ = monitor(
    'newBlankEventListenerPayloadErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const selectedEventListenerSelectedPayloadErrors$ = monitor(
    'selectedEventListenerSelectedPayloadErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const selectedEventListenerPayloads$ = monitor(
    'selectedEventListenerPayloads$',
    new BehaviorSubject<EventListenerTestPayloads | undefined>(undefined)
);

export const selectedEventListenerSelectedPayloadUid$ = monitor(
    'selectedEventListenerSelectedPayload$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const loadingEventListenerPayloadContent$ = monitor(
    'loadingEventListenerPayloadContent$',
    new BehaviorSubject(false)
);

export const loadingEventListenerPayloads$ = monitor('loadingEventListenerPayloads$', new BehaviorSubject(false));

export const savingSelectedEventListenerSelectedPayload$ = monitor(
    'savingSelectedEventListenerSelectedPayload$',
    new BehaviorSubject(false)
);

export const storedEventListenerPayloadContent$ = monitor(
    'storedEventListenerPayloadContent$',
    new BehaviorSubject<Record<string, string>>({})
);

export const ignoreNextPayloadContentChange$ = monitor('ignoreNextPayloadContentChange$', new BehaviorSubject(false));

export const savedEventListenerPayloadContent$ = monitor(
    'savedEventListenerPayloadContent$',
    new BehaviorSubject<Record<string, string>>({})
);

saveEventListenerPayloadAction$
    .pipe(
        map(async ({ content, uid, feedbackMessage }) => {
            savingSelectedEventListenerSelectedPayload$.next(true);
            selectedEventListenerSelectedPayloadErrors$.next(undefined);

            try {
                await saveEventListenerTestPayload(uid, selectedEnvironmentUid$.value ?? '', content);

                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Event Listener Test Payload ' + feedbackMessage,
                });

                selectedEventListenerPayloads$.next({
                    testPayloads: selectedEventListenerPayloads$.value?.testPayloads ?? [],
                    selectedTestPayloadUid: uid,
                });

                storedEventListenerPayloadContent$.next({
                    ...storedEventListenerPayloadContent$.value,
                    [uid]: content,
                });
                savedEventListenerPayloadContent$.next({ ...savedEventListenerPayloadContent$.value, [uid]: content });
            } catch (e) {
                if (e instanceof InformativeError) {
                    selectedEventListenerSelectedPayloadErrors$.next(e.message);
                } else {
                    selectedEventListenerSelectedPayloadErrors$.next(
                        'Failed to save or switch Event Listener Test Payload, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Event Listener Test Payload', e);
                }
            }

            savingSelectedEventListenerSelectedPayload$.next(false);
        })
    )
    .subscribe();

openCreateEventListenerPayloadAction$.subscribe(() => {
    createEventListenerPayloadDialogOpen$.next(true);
    savingNewBlankEventListenerPayload$.next(false);
    newBlankEventListenerPayloadErrors$.next(undefined);
});

cancelCreateEventListenerPayloadAction$.subscribe(() => createEventListenerPayloadDialogOpen$.next(false));

saveNewBlankEventListenerPayloadAction$
    .pipe(
        map(async ({ name, environmentUid, eventListenerUid }) => {
            try {
                savingNewBlankEventListenerPayload$.next(true);
                newBlankEventListenerPayloadErrors$.next(undefined);

                const createdPayload = await createEventListenerTestPayload(eventListenerUid, environmentUid, name);
                createEventListenerPayloadDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Event Listener Payload created and selected.',
                });

                selectedEventListenerPayloads$.next({
                    testPayloads: [
                        ...(selectedEventListenerPayloads$.value?.testPayloads ?? []),
                        { uid: createdPayload.uid, name },
                    ],
                    selectedTestPayloadUid: createdPayload.uid,
                });
                loadEventListenerPayloadContentAction$.next(createdPayload.uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    newBlankEventListenerPayloadErrors$.next(e.message);
                } else {
                    newBlankEventListenerPayloadErrors$.next(
                        'Failed to create Event Listener Test Payload, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while creating Event Listener Test Payload', e);
                }
            }

            savingNewBlankEventListenerPayload$.next(false);
        })
    )
    .subscribe();

loadEventListenerPayloadContentAction$
    .pipe(
        map(async (uid) => {
            loadingEventListenerPayloadContent$.next(true);
            selectedEventListenerSelectedPayloadUid$.next(uid);
            const storedContent = storedEventListenerPayloadContent$.value;

            if (storedContent[uid] === undefined) {
                try {
                    const { content } = await getEventListenerTestPayload(uid);

                    storedEventListenerPayloadContent$.next({ ...storedContent, [uid]: content });
                    savedEventListenerPayloadContent$.next({
                        ...savedEventListenerPayloadContent$.value,
                        [uid]: content,
                    });
                } catch (e) {
                    if (e instanceof InformativeError) {
                        selectedEventListenerSelectedPayloadErrors$.next(e.message);
                    } else {
                        selectedEventListenerSelectedPayloadErrors$.next(
                            'Failed to load Event Listener Test Payload, please try again, if the issue persists please contact support.'
                        );
                        console.error('Error while loading Event Listener Test Payload', e);
                    }
                }
            }
            loadingEventListenerPayloadContent$.next(false);
        })
    )
    .subscribe();

changeEventListenerPayloadContentAction$.subscribe(({ uid, content }) => {
    storedEventListenerPayloadContent$.next({ ...storedEventListenerPayloadContent$.value, [uid]: content });
});
