// disconnecting from design tokens package while we migrate to Figma designs
import * as tkn from './theme-constants'; // tkn = design tokens
import { createTheme } from '@mui/material/styles';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';

/* eslint-disable sonarjs/no-duplicate-string */

const spacing = function (value: number): number {
    return 8 * value;
};

// use 600 for medium on windows, otherwise its 500 - do not remove without consulting Sharon
const boldWeight: number = navigator.userAgent.includes('Win') ? 600 : 500;

const breakpoints = createBreakpoints({});

// let's try rem font sizing
const fontSizeBase = 16; // 16px - this one has to be in pixels
const fontSizeBody = '0.875rem'; // 14px
const fontSizeSmall = '0.75rem'; // 12px
const fontSizeH1 = '3rem';
const fontSizeH2 = '2.625rem';
const fontSizeH3 = '2.25rem';
const fontSizeH4 = '1.875rem';
const fontSizeH5 = '1.5rem';
const fontSizeH6 = '1.25rem';

const light = 300;
const regular = 400;
const bold = boldWeight;
const extraBold = 700;

const borderRadius = 4;
const boxShadow = '0 0.5px 2px rgba(0, 0, 0, 0.16)';
const buttonHeight = '40px';
const buttonPadding = '0 16px';
const smallButtonHeight = '32px';
const smallButtonPadding = '0 8px';

export const horizonLight = createTheme({
    constants: {
        borderHidden: '1px solid transparent',
        borderRadius: borderRadius,
        borderDashedHover: 'dashed 1px ' + tkn.LtPrimaryMain,
        borderSelected: '1px solid ' + tkn.LtPrimaryMain,
        boxShadow: boxShadow,
        radiusCircle: '50%',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            // db is for dashboard resizing
            db: 1268,
        },
    },
    typography: {
        htmlFontSize: fontSizeBase,
        fontFamily: tkn.FontFamilyBase,
        fontWeightLight: light,
        fontWeightRegular: regular,
        fontWeightBold: bold,
        /*  relative font sizing
            using breakpoints.down so the first value is for desktop
            breakpoint value is for narrow screens */
        body1: {
            fontSize: fontSizeBody,
        },
        body2: {
            fontSize: fontSizeSmall,
        },
        subtitle1: {
            fontWeight: bold,
            lineHeight: 1.2,
        },
        subtitle2: {
            fontWeight: bold,
        },
        button: {
            fontWeight: bold,
        },
        h1: {
            fontSize: fontSizeH1,
            fontWeight: extraBold,
            [breakpoints.down('md')]: {
                fontSize: fontSizeH2,
            },
        },
        h2: {
            fontSize: fontSizeH2,
            fontWeight: bold,
            [breakpoints.down('md')]: {
                fontSize: fontSizeH3,
            },
        },
        h3: {
            fontSize: fontSizeH3,
            fontWeight: bold,
            [breakpoints.down('md')]: {
                fontSize: fontSizeH4,
            },
        },
        h4: {
            fontSize: fontSizeH4,
            fontWeight: bold,
            [breakpoints.down('md')]: {
                fontSize: fontSizeH5,
            },
        },
        h5: {
            fontSize: fontSizeH5,
            fontWeight: bold,
            [breakpoints.down('md')]: {
                fontSize: fontSizeH6,
            },
        },
        h6: {
            fontSize: fontSizeH6,
            fontWeight: bold,
            [breakpoints.down('md')]: {
                fontSize: fontSizeBody,
            },
        },
        /* custom typography */
        overflowLine: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        overflowBlock: {
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
            whiteSpace: 'initial',
        },
        flexAlignCenter: {
            alignItems: 'center',
            display: 'flex',
        },
        radiusCircle: {
            backgroundClip: 'padding-box',
            borderRadius: '50%',
            overflow: 'hidden',
        },
    },
    palette: {
        // contrastThreshold for accessibility https://mui.com/material-ui/customization/palette/#accessibility
        contrastThreshold: 4.5,
        // tonalOffset allows MUI to define the dark and light variants of a main colour by steps - https://mui.com/material-ui/customization/palette/#providing-the-colors-directly
        tonalOffset: 0.2,
        mode: 'light',
        // action and text use MUI default
        background: {
            paper: tkn.LtBackgroundPaper,
            default: tkn.LtBackgroundDefault,
        },
        primary: {
            /* blues */
            main: tkn.LtPrimaryMain,
        },
        secondary: {
            /* greys/purples */
            main: tkn.LtSecondaryMain,
        },
        // brand colours
        brand: {
            main: tkn.SRBrand,
            contrastText: tkn.SRBrandContrast,
        },
        // Stitch custom colours - for dashboard and template badges only //
        // we set all values here to have more control //
        setup: {
            main: tkn.LtSetupMain,
            light: tkn.LtSetupLight,
            dark: tkn.LtSetupDark,
            contrastText: tkn.LtSetupContrastText,
        },
        knowledge: {
            main: tkn.LtKnowledgeMain,
            light: tkn.LtKnowledgeLight,
            dark: tkn.LtKnowledgeDark,
            contrastText: tkn.LtKnowledgeContrastText,
        },
        connections: {
            main: tkn.LtConnectionsMain,
            light: tkn.LtConnectionsLight,
            dark: tkn.LtConnectionsDark,
            contrastText: tkn.LtConnectionsContrastText,
        },
        // MUI status - using tonalOffset for light and dark
        error: {
            /* reds */
            main: tkn.LtErrorMain,
        },
        warning: {
            /* oranges */
            main: tkn.LtWarningMain,
            contrastText: tkn.LtWarningContrastText,
        },
        info: {
            /* blues */
            main: tkn.LtInfoMain,
            contrastText: tkn.LtInfoContrastText,
        },
        success: {
            /* greens */
            main: tkn.LtSuccessMain,
            contrastText: tkn.LtSuccessContrastText,
        },
        action: {
            active: tkn.LtActionActive,
            hover: tkn.LtActionHover,
            selected: tkn.LtActionSelected,
            focus: tkn.LtActionFocus,
        },
    },
    components: {
        // ## global theme overrides
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    lineHeight: 1.43,
                },
            },
        },
        // ## layout and common elements
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: borderRadius,
                },
                elevation1: {
                    boxShadow: boxShadow,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.extra-bold': {
                        fontWeight: extraBold,
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderBottom: 'thin solid rgba(0,0,0,0.12)',
                    boxShadow: 'none',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                icon: ({ theme }) => ({
                    '& .MuiSvgIcon-fontSizeMedium': {
                        fontSize: theme.typography.h6.fontSize,
                    },
                }),
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow,
                    border: 'none',
                    h3: {
                        fontWeight: bold,
                    },
                    '& .MuiCardContent-root': {
                        padding: 0,
                    },
                    '& .MuiCardActions-root': {
                        padding: 0,
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    '&:hover.no-underline': {
                        textDecoration: 'none',
                    },
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    '& li': {
                        fontSize: fontSizeBody,
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    fontSize: fontSizeSmall,
                    height: 18,
                    minWidth: 18,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: ({ theme }) => ({
                    backgroundColor: theme.palette.text.primary,
                    color: theme.palette.primary.contrastText,
                    fontSize: fontSizeSmall,
                    maxWidth: 350,
                }),
            },
        },
        // ## dropdown fields
        MuiMenuItem: {
            styleOverrides: {
                // maybe needed
            },
        },
        // ## buttons
        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    boxShadow,
                    borderRadius: borderRadius,
                    fontSize: fontSizeBody,
                    height: buttonHeight,
                    minWidth: '2em',
                    padding: buttonPadding,
                    '&:disabled': {
                        color: theme.palette.text.disabled,
                    },
                }),
                sizeSmall: {
                    height: smallButtonHeight,
                    padding: smallButtonPadding,
                },
                text: {
                    boxShadow: 'none',
                    '&:disabled': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.MuiIconButton-root': {
                        color: theme.palette.primary.light,
                        height: 24,
                        width: 24,
                    },
                    '&.MuiRadio-root': {
                        '& .MuiSvgIcon-root': {
                            fontSize: fontSizeH5,
                        },
                    },
                }),
            },
            defaultProps: {
                // disable ripple on all buttons
                disableRipple: true,
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 3,
                    padding: spacing(1),
                },
            },
        },
        // tabs list uses buttons
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        // ## forms
        MuiFormControl: {
            styleOverrides: {
                marginNormal: {
                    marginTop: spacing(1),
                },
                root: {
                    margin: spacing(1) + 'px 0',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: fontSizeBody,
                    height: 56,
                    width: 300,
                },
                multiline: {
                    minHeight: 82,
                },
                sizeSmall: {
                    height: 40,
                    width: 180,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    margin: spacing(1) + 'px 0',
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    marginLeft: spacing(1),
                    '& .MuiSvgIcon-root': {
                        height: 24,
                        width: 24,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    marginLeft: spacing(1),
                },
            },
        },
        // ## modal dialogs
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minHeight: 240,
                    paddingTop: spacing(2),
                    paddingRight: spacing(2),
                    paddingBottom: spacing(1),
                    paddingLeft: spacing(3),
                    overflow: 'hidden',
                    minWidth: 580,
                    '& ol': {
                        paddingInlineStart: spacing(2.5),
                    },
                    '& li': {
                        marginBottom: spacing(0.5),
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: 0,
                    margin: 0,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    overflow: 'auto',
                    padding: spacing(0.5),
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    marginBottom: spacing(2),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    marginBottom: spacing(1),
                    marginTop: spacing(2),
                    padding: 0,
                    '&>:not(:first-of-type)': {
                        marginLeft: spacing(2),
                    },
                },
            },
        },
        // added to get around the external modal overlay issue
        MuiModal: {
            styleOverrides: {
                root: {
                    marginRight: 'inherit',
                },
            },
        },
        // ## stepper
        MuiStepper: {
            styleOverrides: {
                horizontal: {
                    justifyContent: 'center',
                    marginBottom: spacing(3),
                    padding: spacing(0),
                },
            },
        },
        MuiStep: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.Mui-completed + .MuiStep-root .MuiStepConnector-line': {
                        borderColor: theme.palette.success.light,
                    },
                }),
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                horizontal: {
                    left: `calc(-50% + ${spacing(1)}px)`,
                    right: `calc(50% + ${spacing(1)}px)`,
                },
                lineHorizontal: ({ theme }) => ({
                    borderColor: theme.palette.success.light,
                    borderTopWidth: '2px',
                }),
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ...theme.typography.radiusCircle,
                    height: 14,
                    width: 14,
                    border: `2px solid ${theme.palette.success.light}`,
                    color: theme.palette.background.paper,
                    '&.Mui-active': {
                        border: `2px solid ${theme.palette.success.light}`,
                        '&.MuiSvgIcon-root': {
                            color: theme.palette.background.paper,
                        },
                    },
                    '&.Mui-completed': {
                        border: 'none',
                        color: theme.palette.success.light,
                        height: 20,
                        width: 20,
                    },
                }),
                text: {
                    display: 'none',
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                iconContainer: {
                    transform: `translateY(${spacing(0.75)}px)`,
                    zIndex: 100,
                    '&.Mui-completed': {
                        transform: `translateY(${spacing(0.5)}px)`,
                    },
                },
            },
        },
    },
});

// WIP on dark theme so colors may be hard coded for now
export const horizonDark = createTheme({
    constants: {
        ...horizonLight.constants,
        borderDashedHover: 'dashed 1px ' + tkn.DtPrimaryMain,
        borderSelected: '1px solid ' + tkn.DtPrimaryMain,
    },
    breakpoints,
    palette: {
        //...horizonLight.palette,
        mode: 'dark',
        background: {
            default: tkn.DtBackgroundDefault,
            paper: tkn.DtBackgroundPaper,
        },
        // brand colours
        brand: {
            ...horizonLight.palette.brand,
        },
        // Stitch custom colours - for dashboard and template badges only //
        // we set all values here to have more control //
        setup: {
            main: tkn.LtSetupMain,
            light: tkn.LtSetupLight,
            dark: tkn.LtSetupDark,
            contrastText: tkn.LtSetupContrastText,
        },
        knowledge: {
            main: tkn.LtKnowledgeMain,
            light: tkn.LtKnowledgeLight,
            dark: tkn.LtKnowledgeDark,
            contrastText: tkn.LtKnowledgeContrastText,
        },
        connections: {
            main: tkn.LtConnectionsMain,
            light: tkn.LtConnectionsLight,
            dark: tkn.LtConnectionsDark,
            contrastText: tkn.LtConnectionsContrastText,
        },
    },
    typography: {
        ...horizonLight.typography,
    },
    components: {
        ...horizonLight.components,
    },
});
