import { Link } from 'react-location';

export const Reports: React.FC = () => {
    return (
        <ul>
            <li>
                <Link to="./users">Users</Link>
            </li>
            <li>
                <Link to="./teams">Teams</Link>
            </li>
            <li>
                <Link to="./invocations">Invocations</Link>
            </li>
            <li>
                <Link to="./anonymous">Anonymous Users</Link>
            </li>
            <li>
                <Link to="./connectionTypes">Connection Types</Link>
            </li>
            <li>
                <Link to="./usersWithDeployedWorkspaces">Users With Deployed Workspaces</Link>
            </li>
            {/* <li>
                <Link to="./userFeedback">User Feedback</Link>
            </li> */}
            <li>
                <Link to="./registeredVsActiveUsers">Registered vs Active Users</Link>
            </li>
            <li>
                <Link to="./usersUtilization">Users Utilization</Link>
            </li>
            <li>
                <Link to="./teamsUtilization">Teams Utilization</Link>
            </li>
            <li>
                <Link to="./templatesUsed">Templates Used</Link>
            </li>
            <li>
                <Link to="./userActivation">User Activation</Link>
            </li>
            <li>
                <Link to="./userOnboardingData">User Onboarding Data</Link>
            </li>
            <li>
                <Link to="./discountCodes">Discount Codes</Link>
            </li>
        </ul>
    );
};
