import React from 'react';
import { JiraOnPremiseIcon } from '../../icons/JiraOnPremiseIcon';
import { ConnectionDialog } from '../ConnectionDialog';
import { ConfluenceIcon } from '../../icons/ConfluenceIcon';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

export interface ManageConnectionDialog {
    readonly instanceUrl?: string;
    readonly onSave: (name: string) => void;
    readonly saving: boolean;
    readonly onAuthorize: (name: string) => void;
    readonly authorizeLoading: boolean;
    readonly open: boolean;
    readonly onCancel: () => void;
    readonly name: string;
    readonly authorized: boolean;
    readonly errors?: string;
    readonly connectionType?: string;
}

export const AtlassianOnPremiseManageConnectionDialog: React.FC<ManageConnectionDialog> = ({
    name,
    saving,
    onSave,
    onAuthorize,
    authorizeLoading,
    instanceUrl,
    open,
    onCancel,
    authorized,
    errors,
    connectionType,
}) => {
    const getIcon = (type?: string): JSX.Element => {
        switch (type) {
            case 'Jira On-Premise':
                return <JiraOnPremiseIcon fontSize="inherit" />;
            case 'Confluence On-Premise':
                return <ConfluenceIcon fontSize="inherit" />;
            default:
                return <HelpOutlinedIcon fontSize="inherit" />;
        }
    };

    return (
        <ConnectionDialog
            authorized={authorized}
            name={name}
            onSave={onSave}
            onAuthorize={onAuthorize}
            authorizeLoading={!!authorizeLoading}
            instanceUrl={instanceUrl}
            saving={saving}
            icon={getIcon(connectionType)}
            open={open}
            onCancel={onCancel}
            errors={errors}
            connectionType={connectionType}
        />
    );
};
