import { useObservableState } from 'observable-hooks';
import { organizationInvoicePlanInformationDialogOpen$ } from '../../store/organization';
import { OrganizationInvoicePlanInformationDialog } from '../../components/organization/OrganizationInvoicePlanInformationDialog';

export const OrganizationInvoicePlanInformationDialogContainer: React.FC = () => {
    const open = useObservableState(organizationInvoicePlanInformationDialogOpen$);

    return (
        <OrganizationInvoicePlanInformationDialog
            open={open}
            onCancel={() => organizationInvoicePlanInformationDialogOpen$.next(false)}
        />
    );
};
