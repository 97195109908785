import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../buttons/Button';
import { SlackIcon } from '../../icons/SlackIcon';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { ConnectionModalTextField, ConnectionModalSecretTextField } from '../../textfield/ConnectionModalTextField';
import { OpenInNewLink } from '../../link/OpenInNewLink';
import { SlackWizardStage, SlackWizardSteps, StageType } from './SlackWizardSteps';
import Link from '@mui/material/Link';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { DialogAlert, DialogTitleMain } from '../../dialog';

export interface SlackAppInfo {
    appId: string;
    signingSecret: string;
    botUserOAuthAccessToken: string;
}

export interface SlackConnection {
    appInfo: SlackAppInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    startStage?: SlackWizardStage;
    currentStage: number;
    setStage: (stage: SlackWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    clearErrors: () => void;
}

export interface onSaveProps {
    appId: string;
    signingSecret: string;
    botUserOAuthAccessToken: string;
}

export const StageContent: StageType = (props) => {
    const [appId, setAppId] = useState(props.appInfo.appId);
    const [signingSecret, setSigningSecret] = useState(props.appInfo.signingSecret);
    const [accessTokenValue, setAccessTokenValue] = useState(props.appInfo.botUserOAuthAccessToken);

    const scopes = ['team:read', 'chat:write', 'commands'];

    switch (props.stage) {
        case SlackWizardStage.INPUT_APP_DETAILS:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text=" If you already have an application, skip the steps below and paste the App ID and Signing Secret
                            which can be found in the App Credentials section"
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the&nbsp;
                                <Link href="https://api.slack.com/apps" target="_blank">
                                    Slack API site&#8239;
                                    <OpenInNew fontSize="inherit" />
                                </Link>
                                &nbsp;and click <strong>Create an App</strong>. Select the <strong>From scratch</strong>{' '}
                                option to configure your app.
                            </li>
                            <li>
                                Choose a name for your app and the Workspace that the app belongs to, then click{' '}
                                <strong>Create App</strong>.
                            </li>
                            <li>
                                You should be redirected to the <strong>Basic Information</strong> page for your app,
                                scroll down to the <strong>App Credentials</strong> section.
                            </li>
                            <li>Copy the corresponding information into the form below.</li>
                        </DialogContentText>
                        <DialogContentText>
                            <strong>NOTE:</strong> These credentials will be stored securely in our platform.
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="App ID"
                            value={appId}
                            onUpdate={(e) => setAppId(e.target.value)}
                        />
                        <ConnectionModalSecretTextField
                            label="Signing Secret"
                            value={signingSecret}
                            onUpdate={(e) => setSigningSecret(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose && props.onClose()}>
                            Close
                        </Button>
                        <Button
                            onClick={() => props.setStage(SlackWizardStage.ADD_SCOPES)}
                            disabled={!(appId && signingSecret)}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case SlackWizardStage.ADD_SCOPES:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text=" If you already have an application, skip the steps below and click next"
                    />
                    <DialogContent>
                        <DialogContentText>
                            You now need to add some permission scopes so your Slack App can work properly.
                        </DialogContentText>
                        <DialogContentText component="ol">
                            <li>
                                Visit the Slack API&nbsp;
                                <OpenInNewLink url={`https://api.slack.com/apps/${appId}/oauth`}>
                                    OAuth &amp; Permissions
                                </OpenInNewLink>
                                &nbsp;page.
                            </li>

                            <li>
                                Scroll down to <strong>Scopes</strong> then <strong>Bot Token Scopes</strong>
                            </li>
                            <li>
                                Click <strong>Add an OAuth Scope</strong>
                            </li>
                            <li>Find and add the following scopes</li>

                            {scopes.map((value, i) => (
                                <ReadOnlyTextField key={i} label="Bot token scopes" value={value} />
                            ))}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(SlackWizardStage.INPUT_APP_DETAILS)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(SlackWizardStage.INSTALL)}>Next</Button>
                    </DialogActions>
                </>
            );
        case SlackWizardStage.INSTALL:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text=" If you already have an application, skip the steps below and click next"
                    />
                    <DialogContent>
                        <DialogContentText>
                            You now need to install the Slack app in the Workspace you created it in:
                        </DialogContentText>
                        <DialogContentText component="ol">
                            <li>
                                Visit the Slack API&nbsp;
                                <OpenInNewLink url={`https://api.slack.com/apps/${appId}/install-on-team`}>
                                    Install App
                                </OpenInNewLink>
                                &nbsp;page.
                            </li>
                            <li>
                                Click on <strong>Install to Workspace</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(SlackWizardStage.ADD_SCOPES)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(SlackWizardStage.INPUT_BOT_TOKEN)}>Next</Button>
                    </DialogActions>
                </>
            );
        case SlackWizardStage.INPUT_BOT_TOKEN:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            Now copy and paste the <strong>Bot User OAuth Access Token</strong> in to the box below.
                        </DialogContentText>
                        <ConnectionModalSecretTextField
                            label="Bot User OAuth Access Token"
                            value={accessTokenValue}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setAccessTokenValue(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(SlackWizardStage.INSTALL);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!accessTokenValue}
                            onClick={() =>
                                props.onSave({
                                    appId,
                                    signingSecret,
                                    botUserOAuthAccessToken: accessTokenValue,
                                })
                            }
                        >
                            Done
                        </Button>
                    </DialogActions>
                </>
            );
    }
};

export const SlackConfigureDialog: React.FC<SlackConnection> = (props) => {
    const error = <DialogAlert severity="error" alertTitle="Error" text={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<SlackIcon />} />
            {props.errors && error}
            <>
                <SlackWizardSteps stage={props.currentStage} />
                <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
            </>
        </Dialog>
    );
};
