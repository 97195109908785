import { useObservableState } from 'observable-hooks';
import { BitbucketCloudManageConnectionDialog } from '../../../components/connections/bitbucket-cloud/ManageConnectionDialog';
import {
    bitbucketCloudConnectionSaving$,
    bitbucketCloudManageConnectionAuthorizeLoading$,
    bitbucketCloudManageConnectionCloseDialogAction$,
    bitbucketCloudManageConnectionDetails$,
    bitbucketCloudManageConnectionDialogErrors$,
    bitbucketCloudManageConnectionDialogOpen$,
    bitbucketCloudManageConnectionInitiateSetupAction$,
    bitbucketCloudSaveConnectionAction$,
} from '../../../store/connection/bitbucket-cloud/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const BitbucketCloudManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(bitbucketCloudManageConnectionDialogOpen$);
    const saving = useObservableState(bitbucketCloudConnectionSaving$);
    const authorizeLoading = useObservableState(bitbucketCloudManageConnectionAuthorizeLoading$);
    const details = useObservableState(bitbucketCloudManageConnectionDetails$);
    const errors = useObservableState(bitbucketCloudManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <BitbucketCloudManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => bitbucketCloudManageConnectionCloseDialogAction$.next()}
            onSave={(name) => bitbucketCloudSaveConnectionAction$.next(name)}
            onAuthorize={(name) => bitbucketCloudManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
