import React from 'react';
import { ConnectionDialog } from '../ConnectionDialog';
import { ProductIcon } from '../../icons/ProductIcon';
import { useState } from 'react';
import { githubManageConnectionInitiateAuthorizeAction$ } from '../../../store/connection/github/authorize-connection';
import { ConfirmReAuthDialog } from '../ConfirmReAuthDialog';

export interface GithubManageConnectionDialogProps {
    readonly onSave: (name: string) => void;
    readonly saving: boolean;
    readonly isFirstConnection: boolean;
    readonly authorizeLoading: boolean;
    readonly open: boolean;
    readonly onCancel: () => void;
    readonly name: string;
    readonly authorized: boolean;
    readonly errors?: string;
    readonly connectionType?: string;
}

export const GithubManageConnectionDialog: React.FC<GithubManageConnectionDialogProps> = ({
    isFirstConnection,
    name,
    saving,
    onSave,
    authorizeLoading,
    open,
    onCancel,
    authorized,
    errors,
    connectionType,
}) => {
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const [newConnectionName, setNewConnectionName] = useState('');

    const onAuthorizeHandler = (newConnectionName: string): void => {
        setNewConnectionName(newConnectionName);
        if (name || !isFirstConnection) {
            setConfirmModalOpen(true);
        } else {
            githubManageConnectionInitiateAuthorizeAction$.next(newConnectionName);
        }
    };

    const onModalConfirmHandler = (): void => {
        setConfirmModalOpen(false);
        githubManageConnectionInitiateAuthorizeAction$.next(newConnectionName);
    };

    const onConfirmCancelHandler = (): void => {
        setConfirmModalOpen(false);
    };

    const reauthorizationConnectionTitle = `You are about to re-authorize your GitHub connector`;
    const reauthorizationConnectionMessage = `You will be automatically authorized with the
    account you are currently signed in on GitHub website. If you want to use a different account, before
    reauthorization please sign out on`;

    const anotherNewConnectionTitle = `You are about to authorize a new GitHub connector`;
    const anotherNewConnectionMessage = `Please keep in mind that you already have an existing GitHub connector and you will be automatically authorized with the
    account you are currently signed in on GitHub website. If you want to use a different account, before
    creating a new connector please sign out on`;

    return (
        <>
            <ConnectionDialog
                authorized={authorized}
                name={name}
                onSave={onSave}
                onAuthorize={onAuthorizeHandler}
                saving={!!saving}
                authorizeLoading={!!authorizeLoading}
                icon={<ProductIcon name={connectionType ?? ''} />}
                open={open}
                onCancel={onCancel}
                errors={errors}
                connectionType={connectionType}
            />
            <ConfirmReAuthDialog
                isReauthorization={!!name}
                link="https://github.com"
                open={isConfirmModalOpen}
                title={authorized ? reauthorizationConnectionTitle : anotherNewConnectionTitle}
                message={authorized ? reauthorizationConnectionMessage : anotherNewConnectionMessage}
                onCancel={onConfirmCancelHandler}
                onConfirm={onModalConfirmHandler}
            />
        </>
    );
};
