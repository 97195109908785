import { useObservableState } from 'observable-hooks';
import { OpsgenieEventListenerSetupDialog } from '../../../components/connections/opsgenie/EventListenerSetupDialog';
import { configTopic$ } from '../../../store/config';
import {
    opsgenieEventListenerSetupDialogCloseAction$,
    opsgenieEventListenerSetupDialogDetails$,
    opsgenieEventListenerSetupDialogLoading$,
    opsgenieEventListenerSetupDialogOpen$,
} from '../../../store/connection/opsgenie/setup-event-listener';

export const OpsgenieEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(opsgenieEventListenerSetupDialogLoading$);
    const open = useObservableState(opsgenieEventListenerSetupDialogOpen$);
    const details = useObservableState(opsgenieEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <OpsgenieEventListenerSetupDialog
            loading={loading}
            open={open}
            eventTypeName={details?.eventTypeName ?? ''}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            setupBaseUrl={details?.setupBaseUrl}
            onClose={() => opsgenieEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
