import { useObservableState } from 'observable-hooks';
import { NetSuiteManageConnectionDialog } from '../../../components/connections/netsuite/ManageConnectionDialog';
import {
    netSuiteConnectionSaving$,
    netSuiteManageConnectionAuthorizeLoading$,
    netSuiteManageConnectionCloseDialogAction$,
    netSuiteManageConnectionDetails$,
    netSuiteManageConnectionDialogErrors$,
    netSuiteManageConnectionDialogOpen$,
    netSuiteManageConnectionInitiateSetupAction$,
    netSuiteSaveConnectionAction$,
} from '../../../store/connection/netsuite/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const NetSuiteManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(netSuiteManageConnectionDialogOpen$);
    const saving = useObservableState(netSuiteConnectionSaving$);
    const authorizeLoading = useObservableState(netSuiteManageConnectionAuthorizeLoading$);
    const details = useObservableState(netSuiteManageConnectionDetails$);
    const errors = useObservableState(netSuiteManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <NetSuiteManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            accountId={details?.netSuiteAccountId}
            onCancel={() => netSuiteManageConnectionCloseDialogAction$.next()}
            onSave={(name) => netSuiteSaveConnectionAction$.next(name)}
            onAuthorize={(name) => netSuiteManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
