import { useObservableState } from 'observable-hooks';
import { MondayEventListenerSetupDialog } from '../../../components/connections/monday/EventListenerSetupDialog';
import { configTopic$ } from '../../../store/config';
import {
    mondayEventListenerSetupDialogCloseAction$,
    mondayEventListenerSetupDialogDetails$,
    mondayEventListenerSetupDialogLoading$,
    mondayEventListenerSetupDialogOpen$,
} from '../../../store/connection/monday/setup-event-listener';

export const MondayEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(mondayEventListenerSetupDialogLoading$);
    const open = useObservableState(mondayEventListenerSetupDialogOpen$);
    const details = useObservableState(mondayEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <MondayEventListenerSetupDialog
            loading={loading}
            open={open}
            eventTypeName={details?.eventTypeName ?? ''}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            mondayUrl={details?.mondayUrl ?? ''}
            onClose={() => mondayEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
