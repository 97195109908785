import { useObservableState } from 'observable-hooks';
import { GithubEventListenerSetupDialog } from '../../../components/connections/github/EventListenerSetupDialog';
import { configTopic$ } from '../../../store/config';
import {
    githubEventListenerSetupDialogCloseAction$,
    githubEventListenerSetupDialogDetails$,
    githubEventListenerSetupDialogOpen$,
} from '../../../store/connection/github/setup-event-listener';

export const GithubEventListenerSetupDialogContainer: React.FC = () => {
    const open = useObservableState(githubEventListenerSetupDialogOpen$);
    const details = useObservableState(githubEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);
    return (
        <GithubEventListenerSetupDialog
            open={open}
            eventTypeName={details?.eventTypeName ?? ''}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            onClose={() => githubEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
