import { BehaviorSubject, Subject, map } from 'rxjs';
import { monitor } from './monitor';
import { deleteConnection, saveConnection } from '../data/connection';
import { MondayConnectionDetails } from './connection/monday/setup-connection';
import { configTopic$ } from './config';
import { initiate, openConsentWindow } from './connection/utils';
import { navigateAction$ } from './navigation';
import { getBasePath } from '../utils/path';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { segmentAnalyticsTrack } from '../data/segment-analytics';
import { MONDAY_ORIGIN } from '../utils/constants';

export const mondayUserAuthorizeConnectionAction$ = monitor(
    'mondayUserAuthorizeConnectionAction$',
    new Subject<string>()
);

export const mondayUserConnectionAuthorizedAction$ = monitor(
    'mondayUserConnectionAuthorizedAction$',
    new Subject<void>()
);

export const mondayUserOnboardingFailedAction$ = monitor(
    'mondayUserOnboardingFailedAction$',
    new Subject<string | undefined>()
);

export const mondayUserOnboardingDetails$ = monitor(
    'mondayUserOnboardingDetails$',
    new BehaviorSubject<MondayConnectionDetails | undefined>(undefined)
);

export const openMondayUserOnboardingDialogAction$ = monitor(
    'openMondayUserOnboardingDialogAction$',
    new Subject<{ userId?: string; stitchTeamMember?: boolean }>()
);

export const mondayUserOnboardingDialogOpen$ = monitor('mondayUserOnboardingDialogOpen$', new BehaviorSubject(false));

export const mondayUserOnboardingDialogSaving$ = monitor(
    'mondayUserOnboardingDialogSaving$',
    new BehaviorSubject(false)
);

export const mondayUserFailureDialogOpen$ = monitor('mondayUserFailureDialogOpen$', new BehaviorSubject(false));

export const mondayUserSuccessDialogOpen$ = monitor('mondayUserSuccessDialogOpen$', new BehaviorSubject(false));

openMondayUserOnboardingDialogAction$.subscribe(({ userId, stitchTeamMember }) => {
    mondayUserOnboardingDialogOpen$.next(true);
    segmentAnalyticsTrack('Advanced Automations Modal Opened', {
        userId,
        stitchTeamMember,
        userOrigin: MONDAY_ORIGIN,
    });
});

mondayUserAuthorizeConnectionAction$
    .pipe(
        map(async (connectorName) => {
            mondayUserOnboardingDialogSaving$.next(true);

            const { uid, connectionType, instanceUrl, name } = mondayUserOnboardingDetails$.value ?? {};
            try {
                if (!connectorName) {
                    throw Error('Connector name is required.');
                }
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('monday.com Connector type not defined.');
                }
                if (!uid) {
                    throw Error('monday.com Connector has not been created so cannot configure it.');
                }

                if (!instanceUrl) {
                    throw Error('monday.com Connector does not have an instance url specified.');
                }

                const baseUrl = configTopic$.value.connection?.monday?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No monday.com Connector url configured in meta');
                }

                if (connectorName !== name) {
                    await changeMondayConnectorName(uid, connectorName);
                }

                const response = await initiate(
                    {
                        url: instanceUrl,
                        connectionId: uid,
                        connectionType: 'monday',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, mondayUserOnboardingDialogSaving$, uid);
            } catch (e) {
                console.error('Error while authorizing monday.com connector', e);
                mondayUserOnboardingFailedAction$.next(uid);
            }
        })
    )
    .subscribe();

mondayUserConnectionAuthorizedAction$.subscribe(() => {
    mondayUserOnboardingDialogSaving$.next(false);
    mondayUserOnboardingDialogOpen$.next(false);
    mondayUserOnboardingDetails$.next(undefined);
    navigateAction$.next(`${getBasePath()}templates`);
    mondayUserSuccessDialogOpen$.next(true);
});

mondayUserOnboardingFailedAction$
    .pipe(
        map(async (uid) => {
            mondayUserOnboardingDialogSaving$.next(false);
            mondayUserOnboardingDialogOpen$.next(false);
            mondayUserOnboardingDetails$.next(undefined);
            try {
                if (!uid) {
                    throw new Error('monday.com Connector not found');
                }

                await deleteConnection(uid, true);
            } catch (e) {
                console.error('Failed to delete monday.com Connector', e);
            }

            mondayUserFailureDialogOpen$.next(true);
        })
    )
    .subscribe();

const changeMondayConnectorName = async (uid: string, name: string): Promise<void> => {
    try {
        await saveConnection(uid, name);
    } catch (e) {
        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message: 'Failed to rename connector.',
        });
        console.error(`Error while saving monday.com connector`, e);
    }
};
