import { BehaviorSubject, map, Subject } from 'rxjs';
import { selectedEnvironmentUid$, selectedWorkspaceUid$ } from '.';
import { SaveScheduledTriggerEvent } from '../../components/workspace/scheduled-trigger-details';
import {
    createScheduledTrigger,
    deleteScheduledTrigger,
    saveScheduledTrigger,
    ScheduledTrigger,
} from '../../data/scheduled-trigger';
import { InformativeError } from '../../utils/repository';
import { promptQuestion } from '../confirm';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { DeletionRequest } from './types';
import { loadWorkspaceResourcesWhenEventIsEmitted } from './utils';
import { createNewScriptAction$, scriptCreatedForScheduledTriggerAction$ } from './script';
import { bundleScriptWhenEventIsEmitted } from '../../utils/script';

export const createScheduledTriggerAction$ = monitor('createScheduledTriggerAction$', new Subject<void>());
export const scheduledTriggerCreatedAction$ = monitor('scheduledTriggerCreatedAction$', new Subject<string>());
export const navigateToScheduledTriggerAction$ = monitor('navigateToScheduledTriggerAction$', new Subject<string>());
export const saveScheduledTriggerAction$ = monitor(
    'saveScheduledTriggerAction$',
    new Subject<SaveScheduledTriggerEvent>()
);
export const scheduledTriggerSavedAction$ = monitor('scheduledTriggerSavedAction$', new Subject<void>());
export const deleteScheduledTriggerAction$ = monitor('deleteScheduledTriggerAction$', new Subject<DeletionRequest>());
export const scheduledTriggerDeletedAction$ = monitor('scheduledTriggerDeletedAction$', new Subject<string>());
export const createScriptForScheduledTriggerAction$ = monitor(
    'createScriptForScheduledTriggerAction$',
    new Subject<void>()
);
export const scriptForScheduledTriggerCreatedAction$ = monitor(
    'scriptForScheduledTriggerCreatedAction$',
    new Subject<void>()
);

export const creatingScheduledTrigger$ = monitor('creatingScheduledTrigger$', new BehaviorSubject(false));
export const selectedScheduledTriggerDetails$ = monitor(
    'selectedScheduledTriggerDetails$',
    new BehaviorSubject<ScheduledTrigger | undefined>(undefined)
);
export const selectedScheduledTriggerErrors$ = monitor(
    'selectedScheduledTriggerErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const savingSelectedScheduledTrigger$ = monitor('savingSelectedScheduledTrigger$', new BehaviorSubject(false));
export const scheduledTriggersBeingDeleted$ = monitor(
    'scheduledTriggersBeingDeleted$',
    new BehaviorSubject<Record<string, boolean>>({})
);
export const creatingScriptForScheduledTrigger$ = monitor(
    'creatingScriptForScheduledTrigger$',
    new BehaviorSubject(false)
);

createScheduledTriggerAction$
    .pipe(
        map(async () => {
            creatingScheduledTrigger$.next(true);

            try {
                const { uid } = await createScheduledTrigger(selectedWorkspaceUid$.value ?? '');

                scheduledTriggerCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    console.error('Error while creating Scheduled Trigger', e);

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to create Scheduled Trigger, please try again, if the issue persists please contact support.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }
            }

            creatingScheduledTrigger$.next(false);
        })
    )
    .subscribe();

saveScheduledTriggerAction$
    .pipe(
        map(async (event) => {
            try {
                savingSelectedScheduledTrigger$.next(true);
                selectedScheduledTriggerErrors$.next(undefined);

                await saveScheduledTrigger(
                    event.uid,
                    selectedEnvironmentUid$.value ?? '',
                    event.cronExpression,
                    event.scriptUid,
                    event.disabled
                );

                scheduledTriggerSavedAction$.next();
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Scheduled Trigger saved.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    selectedScheduledTriggerErrors$.next(e.message);
                } else {
                    selectedScheduledTriggerErrors$.next(
                        'Failed to save Scheduled Trigger, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Scheduled Trigger', e);
                }
            }

            savingSelectedScheduledTrigger$.next(false);
        })
    )
    .subscribe();

deleteScheduledTriggerAction$
    .pipe(
        map(async (event) => {
            const initiateScheduledTriggerDelete = async (): Promise<void> => {
                scheduledTriggersBeingDeleted$.next({
                    ...scheduledTriggersBeingDeleted$.value,
                    [event.uid]: true,
                });

                try {
                    const response = await deleteScheduledTrigger(event.uid, event.ignoreWarnings);

                    if (!event.ignoreWarnings && response.warning) {
                        promptQuestion({
                            message: response.warning,
                            onProceed: () =>
                                deleteScheduledTriggerAction$.next({
                                    ...event,
                                    ignoreWarnings: true,
                                }),
                        });
                    } else if (!response.warning) {
                        scheduledTriggerDeletedAction$.next(event.uid);
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Scheduled Trigger deleted.',
                        });
                    }
                } catch (e) {
                    console.error('Error while deleting Scheduled Trigger', e);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to delete Scheduled Trigger, please try again, if the issue persists please contact support.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }

                scheduledTriggersBeingDeleted$.next({
                    ...scheduledTriggersBeingDeleted$.value,
                    [event.uid]: false,
                });
            };

            if (!event.ignoreWarnings) {
                promptQuestion({
                    message: 'Are you sure you want to delete the Scheduled Trigger?',
                    onProceed: async () => await initiateScheduledTriggerDelete(),
                });
            } else {
                await initiateScheduledTriggerDelete();
            }
        })
    )
    .subscribe();

bundleScriptWhenEventIsEmitted(scriptCreatedForScheduledTriggerAction$);

createScriptForScheduledTriggerAction$.subscribe(() => {
    creatingScriptForScheduledTrigger$.next(true);
    createNewScriptAction$.next();
});
scheduledTriggerCreatedAction$.subscribe((uid) => navigateToScheduledTriggerAction$.next(uid));

loadWorkspaceResourcesWhenEventIsEmitted(scheduledTriggerCreatedAction$);
loadWorkspaceResourcesWhenEventIsEmitted(scheduledTriggerSavedAction$);
