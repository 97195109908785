import {
    Box,
    FormControl,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { ConnectionTypes } from '../../data/report/connectionTypes';
import { styled } from '@mui/material/styles';
import { Button } from '../buttons/Button';
import { useState } from 'react';
import { PageContainer } from '../layout/PageComponents';

export interface ConnectionTypesReportPageProps {
    connectionTypes?: ConnectionTypes;
    userType?: UserType;
    onSearch(userType: UserType): void;
}

type UserType = 'all' | 'non-adaptavist' | 'adaptavist' | 'non-stitch';

const StyledContainer = styled('div')(({ theme }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
}));

export const ConnectionTypesReportPage: React.FC<ConnectionTypesReportPageProps> = ({
    connectionTypes = [],
    userType = 'all',
    onSearch,
}) => {
    const tableRows =
        connectionTypes &&
        connectionTypes.map((ct) => {
            return (
                <TableRow>
                    <TableCell>{ct.name}</TableCell>
                    <TableCell>{ct.connectionCount}</TableCell>
                </TableRow>
            );
        });

    const [selectedItem, setSelectedItem] = useState<UserType>(userType);

    const handleSearch = (): void => {
        onSearch(selectedItem);
    };

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <StyledContainer>
                <Box pb={3}>
                    <Typography variant="h3">Connection Types</Typography>
                </Box>
                <FormControl>
                    <TextField
                        select
                        variant="outlined"
                        label="User Type"
                        value={selectedItem}
                        onChange={(event) => setSelectedItem(event.target.value as UserType)}
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="non-adaptavist">Non-Adaptavist</MenuItem>
                        <MenuItem value="adaptavist">Adaptavist</MenuItem>
                        <MenuItem value="non-stitch">Non-Stitch</MenuItem>
                    </TextField>
                </FormControl>
                <Button variant="contained" onClick={handleSearch} sx={{ marginTop: 2 }}>
                    Search
                </Button>
            </StyledContainer>

            <TableContainer>
                <Table component={Paper}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Connection Type</TableCell>

                            <TableCell>Connections</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>{tableRows}</TableBody>
                </Table>
            </TableContainer>
        </PageContainer>
    );
};
