import { useObservableState } from 'observable-hooks';
import { TrelloConnectionAppConfigureDialog } from '../../../components/connections/trello/TrelloAppConfigureDialog';
import {
    trelloConnectionSaving$,
    trelloManageConnectionDetails$,
    trelloSetupDialogErrors$,
    trelloSetupDialogOpen$,
    trelloSetupCloseDialogAction$,
    trelloSetupAuthorizeAction$,
    trelloSetupDialogStage$,
} from '../../../store/connection/trello/setup-connection';

export const TrelloConfigureDialog: React.FC = () => {
    const open = useObservableState(trelloSetupDialogOpen$);
    const saving = useObservableState(trelloConnectionSaving$);
    const details = useObservableState(trelloManageConnectionDetails$);
    const errors = useObservableState(trelloSetupDialogErrors$);
    const currentStage = useObservableState(trelloSetupDialogStage$);

    return (
        <TrelloConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                trelloSetupCloseDialogAction$.next();
                trelloSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => trelloSetupDialogStage$.next(stage)}
            clientInfo={{
                apiKey: details?.apiKey ?? '',
                token: details?.token ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => trelloSetupDialogErrors$.next(error)}
            clearErrors={() => trelloSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => trelloSetupAuthorizeAction$.next(appInfoProps)}
        />
    );
};
