/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type * as monacoApi from 'monaco-editor/esm/vs/editor/editor.api';
import loader from '@monaco-editor/loader';
import { fetchFromSkypackViaUrl } from '../../skypack/skypack';
import { Module } from '../../store/editor/types';
import { sleep } from '../../utils/sleep';

type Monaco = typeof monacoApi;
type TypeScriptWorker = monacoApi.languages.typescript.TypeScriptWorker;

const skypackFileCache = new Map<string, Promise<Response>>();

export const addTypeDefinitionsForImportedModules = async (modules: Module[]): Promise<void> => {
    const [monaco, worker] = await getMonacoAndWorker(modules);
    const importStatements: string[] = [];

    if (worker) {
        for (const module of modules) {
            const filename = module.uri.toString();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const imports = (await (worker as any).findImports?.(filename)) ?? [];
            importStatements.push(...imports);
        }

        await fetchTypeDefinitionForModuleFromSkypack(importStatements, monaco, worker);
    }
};

/**
 * Fetch d.ts from x-typescript-types header and contents for imports used recursively
 */
const fetchTypeDefinitionForModuleFromSkypack = async (
    importStatements: string[],
    monaco: Monaco,
    worker: TypeScriptWorker
): Promise<void> => {
    for (const importStatement of importStatements) {
        if (importStatement.startsWith('https://cdn.skypack.dev/')) {
            const url = `${importStatement}?dts`;

            if (!skypackFileCache.has(url)) {
                const res = fetchFromSkypackViaUrl({
                    url,
                    pinnedHeader: 'x-typescript-types',
                });
                skypackFileCache.set(url, res);
            }

            const response = await skypackFileCache.get(url)!;

            await addModuleToMonacoAndFetchNestedTypeDefinitionsFromSkypack(
                response,
                monaco,
                worker,
                importStatement,
                `${importStatement}/index.d.ts`
            );
        }
    }
};

/**
 * Add d.ts file to Monaco and fetch contents for imports used in that file recursively
 */
const addModuleToMonacoAndFetchNestedTypeDefinitionsFromSkypack = async (
    response: Response,
    monaco: Monaco,
    worker: TypeScriptWorker,
    importBase: string,
    importPath: string
): Promise<void> => {
    const content = await response.clone().text();
    const baseUrl = response.url;

    if (monaco.languages.typescript.typescriptDefaults.getExtraLibs()[importPath]) {
        return;
    }

    const extraLib = monaco.languages.typescript.typescriptDefaults.addExtraLib(content, importPath);

    // Timeout needed to for the worker to be able to find the file that was added
    await sleep(0);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const importStatements: string[] = (await (worker as any).findImports?.(importPath)) ?? [];
    let actualContent = content;
    let updateContent = false;

    for (const importStatement of importStatements) {
        // Remove .d.ts from the end of the skypack imports to work with Monaco
        if (!importStatement.startsWith('/-/')) {
            const newImport = importStatement.replace('.d.ts', '');
            actualContent = actualContent.replace(importStatement, newImport);
            updateContent = true;
        }

        const fullUrlFromImport = new URL(importStatement, baseUrl).href;

        if (!skypackFileCache.has(fullUrlFromImport)) {
            const res = fetchFromSkypackViaUrl({ url: fullUrlFromImport });
            skypackFileCache.set(fullUrlFromImport, res);
        }

        const responsePromise = skypackFileCache.get(fullUrlFromImport)!;
        const response = await responsePromise;
        // There are 2 types of import statements:
        // 1) From another package (import starts with /-/) -> add it to monaco as is + index.d.ts
        // 2) From inside the package (e.g. '../types') -> base import path
        // (e.g. https://cdn.skypack.dev/uuid) + imported file path from the skypack url (e.g. /definitions/user)
        const path = importStatement.startsWith('/-/')
            ? `${importStatement}/index.d.ts`
            : importBase + fullUrlFromImport.substring(fullUrlFromImport.indexOf(',mode=types') + 11);

        await addModuleToMonacoAndFetchNestedTypeDefinitionsFromSkypack(response, monaco, worker, importBase, path);
    }

    //Update the content when imports with .d.ts were present
    if (updateContent) {
        extraLib.dispose();
        monaco.languages.typescript.typescriptDefaults.addExtraLib(actualContent, importPath);
    }
};

const getMonacoAndWorker = async (modules: Module[]): Promise<[Monaco, TypeScriptWorker | null]> => {
    const monaco = await loader.init();
    const uris = modules.map(({ uri }) => uri);
    if (uris.length > 0) {
        try {
            const createWorker = await monaco.languages.typescript.getTypeScriptWorker();
            const worker = await createWorker(...uris);
            return [monaco, worker];
        } catch (e) {
            // May throw an 'TypeScript not registered!' error if TS support hasn't yet loaded due to
            // no TS models being registered with Monaco.
            // This is fine, we'll just fall through and return no worker.
            console.error(e);
        }
    }
    return [monaco, null];
};
