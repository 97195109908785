import React, { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import IconButton from '@mui/material/IconButton';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import Box from '@mui/material/Box';
import useResizeObserver from 'use-resize-observer';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';

export interface LogProps {
    severity: LogSeverity;
    text?: JSX.Element;
    columns?: Columns[];
    logTitle: LogTitleProps;
    plainText?: string;
}

export interface LogTitleProps {
    time: number;
    level: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'SUCCESS';
    source?: string;
    environmentName?: string;
    invocationId?: string;
}

interface Columns {
    name: string;
    checked: boolean;
}

export type LogSeverity = 'error' | 'warning' | 'info' | 'success' | 'debug';

const StyledWarningAmberOutlinedIcon = styled(WarningAmberOutlinedIcon)(({ theme }) => ({
    color: theme.palette.warning.main,
}));

const iconMapping = {
    success: <CheckCircleOutlineIcon />,
    error: <ReportOutlinedIcon />,
    warning: <StyledWarningAmberOutlinedIcon />,
    info: <InfoOutlinedIcon />,
    debug: <BugReportOutlinedIcon />,
};

const StyledLog = styled(MuiAlert)(({ theme }) => ({
    minWidth: '100%',
    overflow: 'hidden',
    padding: theme.spacing(0.5),
    '&.MuiAlert-severityDebug': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.disabledBackground,
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
        },
    },
    '& .MuiAlert-message, .MuiAlertTitle-root': {
        ...theme.typography.body1,
        '& .MuiSvgIcon-root': {
            height: 16,
            width: 16,
        },
    },
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
    '& .MuiAlert-message': {
        padding: theme.spacing(0.75, 0, 0),
    },
    '& .MuiAlert-icon': {
        padding: 0,
        marginRight: theme.spacing(1),
    },
    '& .MuiTypography-root': {
        marginBottom: theme.spacing(0),
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
        width: 30,
        height: 30,
    },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        margin: theme.spacing(0.625),
    },
}));

const StyledSpan = styled('span')(({ theme }) => ({
    margin: theme.spacing(0, 0.5),
}));

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Log: React.FC<LogProps> = ({ severity, text, columns = [], logTitle, plainText, ...props }) => {
    const messageRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [initialHeight, setInitialHeight] = useState(0);

    useResizeObserver({
        ref: messageRef,
        onResize: () => {
            if (messageRef.current && !expanded) {
                const hasOverflow = messageRef.current.scrollWidth > messageRef.current.clientWidth;
                setIsOverflowing(hasOverflow);
            }

            if (messageRef.current && expanded && messageRef.current.clientHeight === initialHeight) {
                const hasOverflow = messageRef.current.scrollWidth > messageRef.current.clientWidth;
                setExpanded(false);
                setIsOverflowing(hasOverflow);
            }
        },
    });

    const addLogLevel = (arr: Columns[]): Columns[] => {
        const index = arr.findIndex((column) => column.name === 'Environment Name');
        const logLevel = { name: 'Log Level', checked: true };

        return [...arr.slice(0, index + 1), logLevel, ...arr.slice(index + 1)];
    };

    const alertSeverity = severity === 'debug' ? undefined : severity;
    const customClass = severity === 'debug' ? 'MuiAlert-severityDebug' : '';
    const displayText = expanded || !plainText ? text : plainText;
    const updatedColumns = addLogLevel(columns);

    return (
        <StyledLog
            {...props}
            severity={alertSeverity}
            iconMapping={iconMapping}
            icon={
                <div style={{ display: 'flex' }}>
                    {((plainText && plainText?.includes('\n')) || (isOverflowing && plainText)) && (
                        <Tooltip title={expanded ? 'Collapse log' : 'Expand log'}>
                            <StyledIconButton
                                onClick={() => {
                                    setExpanded((prev) => !prev);
                                    setInitialHeight(messageRef.current?.clientHeight ?? 0);
                                }}
                            >
                                {expanded ? <ArrowDropDownOutlinedIcon /> : <ArrowRightOutlinedIcon />}
                            </StyledIconButton>
                        </Tooltip>
                    )}
                    <Box pt={0.5}>{iconMapping[severity]}</Box>
                </div>
            }
            className={customClass}
        >
            <AlertTitle>
                <FilteredColumns columnsChecked={updatedColumns.filter((c) => c.checked)} logTitle={logTitle} />
            </AlertTitle>
            <div
                ref={messageRef}
                style={{
                    whiteSpace: expanded ? 'normal' : 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {severity === 'error' ? <strong>{displayText}</strong> : displayText}
            </div>
        </StyledLog>
    );
};

const FilteredColumns: React.FC<{
    logTitle: LogTitleProps;
    columnsChecked: Columns[];
}> = ({ logTitle, columnsChecked }) => {
    const date = new Date(logTitle.time as number);

    const renderColumn = (name: string): JSX.Element | string | undefined => {
        switch (name) {
            case 'Date':
                return date.toLocaleDateString();
            case 'Time':
                return date.toLocaleTimeString();
            case 'Source':
                return (
                    <>
                        <SourceOutlinedIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: 4 }} />
                        {logTitle.source ?? 'Workspace'}
                    </>
                );
            case 'Log Level':
                return logTitle.level;
            case 'Environment Name':
                return logTitle.environmentName ? (
                    <>
                        <DisplaySettingsOutlinedIcon fontSize="small" style={{ marginRight: 4 }} />
                        {logTitle.environmentName}
                    </>
                ) : undefined;
            case 'Invocation ID':
                return logTitle.invocationId;
            default:
                return;
        }
    };

    const columnsToDisplay = columnsChecked
        .map((column) => renderColumn(column.name))
        .filter((content) => content !== undefined);

    return (
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
            {columnsToDisplay.map((content, i) => (
                <React.Fragment key={i}>
                    {i !== 0 && <StyledSpan>·</StyledSpan>}
                    {content}
                </React.Fragment>
            ))}
        </Box>
    );
};
