import React, { useState } from 'react';
import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Button } from './Button';

interface ThreeDotsButtonProps {
    uid: string;
    actions?: {
        name: string;
        onSelect?(uid: string): void;
        icon?: JSX.Element;
    }[];
    disabled?: boolean;
}

const StyledSelectionButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.secondary,
    display: 'flex',
    fontWeight: theme.typography.fontWeightRegular,
    justifyContent: 'flex-start',
    padding: theme.spacing(0.6, 2),
    textTransform: 'none',
    width: '100%',
    '& .MuiSvgIcon-root': {
        height: 20,
        marginRight: theme.spacing(1),
        width: 20,
    },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    maxWidth: 200,
    padding: theme.spacing(1, 0),
    pointerEvents: 'auto',
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    left: theme.spacing(-1),
    pointerEvents: 'none',
    top: 0,
    '& .MuiPaper-root': {
        boxShadow: theme.constants.boxShadow,
    },
    '& .MuiButton-root': {
        pointerEvents: 'auto',
    },
}));

const StyledThreeDotsButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 3,
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

export const ThreeDotsButton: React.FC<ThreeDotsButtonProps> = ({ uid, actions = [], disabled = false }) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    return (
        <>
            <StyledThreeDotsButton
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchor(event.currentTarget);
                }}
                aria-label="Options"
                title="Options"
                disabled={disabled}
            >
                <MoreHorizIcon />
            </StyledThreeDotsButton>
            <StyledPopover
                open={Boolean(anchor)}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => setAnchor(null)}
            >
                <ClickAwayListener onClickAway={() => setAnchor(null)}>
                    <StyledPaper>
                        {actions.map((action, index) => {
                            return (
                                <StyledSelectionButton
                                    key={index}
                                    variant="text"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        action.onSelect?.(uid);
                                        setAnchor(null);
                                    }}
                                >
                                    {action.icon}
                                    {action.name}
                                </StyledSelectionButton>
                            );
                        })}
                    </StyledPaper>
                </ClickAwayListener>
            </StyledPopover>
        </>
    );
};
