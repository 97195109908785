import { useObservableState } from 'observable-hooks';
import { getBasePath } from '../utils/path';
import { useNavigate } from 'react-location';
import {
    profileUpdateDialogOpen$,
    skipProfileUpdateDialogAction$,
    updateProfileUpdateDialogAction$,
} from '../store/onboarding';
import { ProfileUpdateDialog } from '../components/onboarding/ProfileUpdateDialog';
import { readLocalStorage } from '../utils/localStorage';

export const ProfileUpdateDialogContainer: React.FC = () => {
    const navigate = useNavigate();
    const open = useObservableState(profileUpdateDialogOpen$);
    const hasSkippedProfileUpdate = readLocalStorage('hasSkippedProfileUpdate', false);

    const onSkip = (): void => {
        skipProfileUpdateDialogAction$.next();
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}/dashboard` }), 100);
    };

    return (
        <ProfileUpdateDialog
            open={open}
            onSkip={onSkip}
            onUpdate={() => updateProfileUpdateDialogAction$.next()}
            hasSkipped={hasSkippedProfileUpdate}
        />
    );
};
