import React, { useEffect } from 'react';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {
    EmptyStateContainer,
    PageContainer,
    EmptyStateImg,
    EmptyStateText,
    PageSubTitle,
    PageTitle,
    StyledActionButton,
} from '../layout';
import { wrapAsync } from '../../utils/react';
import { useAuth } from '../../hooks/auth';
import Link from '@mui/material/Link';
import styled from '@mui/system/styled';
import { AppBanners } from '../layout/AppBanners';

interface ErrorPageProps {
    title?: string;
    background?: 'paper' | 'default';
    error?: string;
    skipRefreshMessage?: boolean;
    skipRefreshMessageOverride?: string;
    showDashboardLink?: boolean;
    showRefreshSessionWarning?: boolean;
    userLoggedOutFromApp?: boolean;
    supportPortalUrl: string;
    onSetLoadTokens(loadTokens: boolean): void;
    logoutFromApp(): void;
}

const StyledLinks = styled('div')(({ theme }) => ({
    '& a': {
        padding: theme.spacing(0, 1),
    },
    '& a:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.text.secondary}`,
    },
}));

export const ErrorPage: React.FC<ErrorPageProps> = ({
    error,
    title,
    background = 'default',
    skipRefreshMessage = false,
    skipRefreshMessageOverride,
    showDashboardLink = true,
    showRefreshSessionWarning = false,
    supportPortalUrl,
    onSetLoadTokens,
    logoutFromApp,
    userLoggedOutFromApp = false,
}) => {
    const { operations } = useAuth();
    const handleLogOut = async (): Promise<void> => {
        logoutFromApp();
    };
    useEffect(() => {
        if (userLoggedOutFromApp === true) {
            operations.logout({ redirectUrl: window.location.origin });
        }
    }, [userLoggedOutFromApp]);
    return userLoggedOutFromApp ? (
        <></>
    ) : (
        <>
            {showRefreshSessionWarning && (
                <AppBanners showRefreshSessionWarning={showRefreshSessionWarning} onSetLoadTokens={onSetLoadTokens} />
            )}
            <PageContainer
                sx={{
                    backgroundColor: background === 'default' ? 'background.default' : 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {title && <PageTitle title={title} />}
                <EmptyStateContainer>
                    <EmptyStateImg
                        src={process.env.PUBLIC_URL + '/wizard-think-slow-crop.gif'}
                        alt="Oops"
                        sx={{ mr: 7 }}
                    />
                    <PageSubTitle variant="h6">{error ?? 'Something went wrong here'}</PageSubTitle>
                    {!skipRefreshMessage && (
                        <EmptyStateText>
                            {skipRefreshMessageOverride ??
                                'Try refreshing your browser, this often resolves the error.'}
                            <br /> If the issue persists, please contact support
                        </EmptyStateText>
                    )}
                    <StyledActionButton
                        startIcon={<SupportAgentIcon />}
                        onClick={() => window.open(supportPortalUrl, '_blank')}
                    >
                        Contact support
                    </StyledActionButton>
                    <br />
                    <StyledLinks>
                        {showDashboardLink && <Link href={process.env.PUBLIC_URL + '/dashboard'}>Dashboard</Link>}
                        <Link onClick={wrapAsync(handleLogOut)}>Logout</Link>
                    </StyledLinks>
                </EmptyStateContainer>
            </PageContainer>
        </>
    );
};
