import { useObservableState } from 'observable-hooks';
import { OpsgenieConnectionAppConfigureDialog } from '../../../components/connections/opsgenie/OpsgenieAppConfigureDialog';
import {
    opsgenieConnectionSaving$,
    opsgenieManageConnectionDetails$,
    opsgenieSetupDialogErrors$,
    opsgenieSetupDialogOpen$,
    opsgenieSetupCloseDialogAction$,
    opsgenieSetupAuthorizeAction$,
    opsgenieSetupDialogStage$,
} from '../../../store/connection/opsgenie/setup-connection';

export const OpsgenieConfigureDialog: React.FC = () => {
    const open = useObservableState(opsgenieSetupDialogOpen$);
    const saving = useObservableState(opsgenieConnectionSaving$);
    const details = useObservableState(opsgenieManageConnectionDetails$);
    const errors = useObservableState(opsgenieSetupDialogErrors$);
    const currentStage = useObservableState(opsgenieSetupDialogStage$);

    return (
        <OpsgenieConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                opsgenieSetupCloseDialogAction$.next();
                opsgenieSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => opsgenieSetupDialogStage$.next(stage)}
            clientInfo={{
                apiKey: details?.apiKey ?? '',
                region: details?.region ?? 'US',
                instanceUrl: details?.instanceUrl ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => opsgenieSetupDialogErrors$.next(error)}
            clearErrors={() => opsgenieSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => opsgenieSetupAuthorizeAction$.next(appInfoProps)}
        />
    );
};
