import { useObservableState } from 'observable-hooks';
import { MicrosoftEventListenerSetupDialog } from '../../../components/connections/microsoft/EventListenerSetupDialog';
import { configTopic$ } from '../../../store/config';
import {
    microsoftEventListenerSetupDialogCloseAction$,
    microsoftEventListenerSetupDialogDetails$,
    microsoftEventListenerSetupDialogErrors$,
    microsoftEventListenerSetupDialogLoading$,
    microsoftEventListenerSetupDialogOpen$,
    microsoftEventListenerSetupDialogSaveTokenAction$,
    microsoftEventListenerSetupDialogSaving$,
} from '../../../store/connection/microsoft/setup-event-listener';
import { selectedWorkspace$ } from '../../../store/workspace';

export const MicrosoftEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(microsoftEventListenerSetupDialogLoading$);
    const saving = useObservableState(microsoftEventListenerSetupDialogSaving$);
    const open = useObservableState(microsoftEventListenerSetupDialogOpen$);
    const details = useObservableState(microsoftEventListenerSetupDialogDetails$);
    const errors = useObservableState(microsoftEventListenerSetupDialogErrors$);
    const config = useObservableState(configTopic$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);

    return (
        <MicrosoftEventListenerSetupDialog
            loading={loading}
            open={open}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            workspaceLocked={!!selectedWorkspace?.locked}
            errors={errors}
            onSave={(token) =>
                microsoftEventListenerSetupDialogSaveTokenAction$.next({
                    token,
                    uid: details?.uid ?? '',
                    environmentUid: details?.environmentUid ?? '',
                })
            }
            saving={saving}
            onClose={() => microsoftEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
