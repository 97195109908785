import { useObservableState } from 'observable-hooks';
import { StatuspageAppConfigureDialog } from '../../../components/connections/statuspage/StatuspageAppConfigureDialog';
import {
    statuspageConnectionSaving$,
    statuspageSetupDialogErrors$,
    statuspageSetupDialogOpen$,
    statuspageSetupCloseDialogAction$,
    statuspageSetupAuthorizeAction$,
} from '../../../store/connection/statuspage/setup-connection';

export const StatuspageConfigureDialog: React.FC = () => {
    const open = useObservableState(statuspageSetupDialogOpen$);
    const saving = useObservableState(statuspageConnectionSaving$);
    const errors = useObservableState(statuspageSetupDialogErrors$);

    return (
        <StatuspageAppConfigureDialog
            open={open}
            onCancel={() => statuspageSetupCloseDialogAction$.next()}
            saving={saving}
            errors={errors}
            onSave={(apiKey) => statuspageSetupAuthorizeAction$.next({ apiKey })}
        />
    );
};
