import { useObservableState } from 'observable-hooks';
import { OpsgenieManageConnectionDialog } from '../../../components/connections/opsgenie/ManageConnectionDialog';
import {
    opsgenieConnectionSaving$,
    opsgenieManageConnectionAuthorizeLoading$,
    opsgenieManageConnectionCloseDialogAction$,
    opsgenieManageConnectionDetails$,
    opsgenieManageConnectionDialogErrors$,
    opsgenieManageConnectionDialogOpen$,
    opsgenieManageConnectionInitiateSetupAction$,
    opsgenieSaveConnectionAction$,
} from '../../../store/connection/opsgenie/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const OpsgenieManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(opsgenieManageConnectionDialogOpen$);
    const saving = useObservableState(opsgenieConnectionSaving$);
    const authorizeLoading = useObservableState(opsgenieManageConnectionAuthorizeLoading$);
    const details = useObservableState(opsgenieManageConnectionDetails$);
    const errors = useObservableState(opsgenieManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <OpsgenieManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => opsgenieManageConnectionCloseDialogAction$.next()}
            onSave={(name) => opsgenieSaveConnectionAction$.next(name)}
            onAuthorize={(name) => opsgenieManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
