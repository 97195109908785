import { useObservableState } from 'observable-hooks';
import { InvocationLogs } from '../components/invocation-logs';
import { selectedInvocationId$, selectedInvocationLogs$ } from '../store/invocationlogs';

export const InvocationLogsContainer: React.FC = () => {
    const invocationId = useObservableState(selectedInvocationId$);
    const invocationLogs = useObservableState(selectedInvocationLogs$);

    return <InvocationLogs invocationId={invocationId ?? ''} logs={invocationLogs} />;
};
