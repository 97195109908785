/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Cable from '@mui/icons-material/Cable';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useNavigate } from 'react-location';
import { getBasePath, getPathName } from '../../utils/path';
import { SRConnectIcon } from '../icons/SRConnectIcon';
import { Button } from '../buttons/Button';
import { readLocalStorage, saveLocalStorage } from '../../utils/localStorage';

export interface SideBarMenuProps {
    useRouter?: boolean;
    showDashboard?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    open?: boolean;
    onOpenConnectAndSupportModal?: () => void;
    onRequestScriptingHelp?: () => void;
}

const StyledDrawerHeader = styled('div')(({ theme }) => ({
    height: 44,
    padding: theme.spacing(1.5, 0, 0, 1),
}));

const StyledLogoContainer = styled('div')(({ theme }) => ({
    '& a ': {
        alignItems: 'center',
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'start',
    },
    '& .MuiSvgIcon-root': {
        height: 42,
        width: 42,
    },
}));

const StyledApp = styled('span')(({ theme }) => ({
    ...theme.typography.subtitle1,
    flexGrow: 2,
    marginLeft: theme.spacing(1),
}));

const StyledClose = styled('div')(({ theme }) => ({
    display: 'flex',
    height: 36,
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 0.5),
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        height: 22,
        width: 22,
    },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
    position: 'relative',
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        height: 24,
        width: 24,
    },
    '&.MuiListItemButton-root.Mui-selected': {
        '& .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        paddingRight: theme.spacing(2),
        width: '100%',
    },
}));

const StyledLine = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1.5),
    right: 0,
    height: 20,
    borderLeft: '3px solid ' + theme.palette.primary.dark,
    borderBottomLeftRadius: 2,
    borderTopLeftRadius: 2,
}));

const StyledListModalButton = styled(StyledListItemButton)(({ theme }) => ({
    '& .MuiSvgIcon-root, & .MuiTypography-root': {
        color: theme.palette.text.secondary,
    },
}));

const RequestScriptingHelp = styled('div')(({ theme }) => ({
    display: 'flex',
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1),
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    minWidth: 193, // for smooth expand transition
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.action.selected}`,
}));

const RequestScriptingHelpMinimised = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 1),
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.action.selected}`,
    '& .MuiButtonBase-root': {
        color: theme.palette.text.primary,
        width: '100%',
        height: '100%',
    },
}));

const RequestScriptingHelpBody = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const RequestScriptingHelpHeader = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const RequestScriptingHelpHeaderIcons = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
}));

const RequestScriptingHelpText = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    whiteSpace: 'pre-wrap',
}));

enum PathNameEnum {
    dashboard,
    workspaces,
    connectors,
    teams,
    templates,
    reporting,
    help,
}

const LinkToHome: React.FC<{ isMondayUser: boolean }> = ({ isMondayUser, children }) => {
    const path = isMondayUser ? '/templates' : '/dashboard';
    return (
        <Tooltip title="ScriptRunner Connect Home" role="link">
            <Link className="no-underline" href={process.env.PUBLIC_URL + path} aria-label="Return to app home">
                {children}
            </Link>
        </Tooltip>
    );
};

// menu state styling is in theme > horizonLight.ts > MuiButtonBase > styleOverrides
export const SideBarMenu: React.FC<SideBarMenuProps> = ({
    useRouter,
    showDashboard = true,
    onClose,
    onOpen,
    open = true,
    onOpenConnectAndSupportModal,
    onRequestScriptingHelp,
}) => {
    const navigate = useRouter ? useNavigate() : undefined;

    const navigateTo = (path: string): void => navigate?.({ to: path });

    const pathName = getPathName();
    const pathNameFound = Object.entries(PathNameEnum).find((entry) => entry[0] === pathName);

    const pathNameIndex = pathNameFound && pathNameFound[1] !== undefined ? pathNameFound[1] : -1;

    const [selectedIndex, setSelectedIndex] = useState(pathNameIndex);
    const openRequestScriptingHelpModal: boolean = readLocalStorage('openRequestScriptingHelp', true);
    const [openRequestScriptingHelp, setOpenRequestScriptingHelp] = useState(openRequestScriptingHelpModal);

    useEffect(() => {
        if (pathNameIndex !== selectedIndex) setSelectedIndex(pathNameIndex);
    }, [pathNameIndex]);

    const theme = useTheme();

    const handleListItemClick = (index: number, target: string): void => {
        setSelectedIndex(index);
        navigateTo(getBasePath() + target);
    };

    const handleOpenRequestScriptingHelp = (event: boolean): void => {
        saveLocalStorage('openRequestScriptingHelp', event);
        setOpenRequestScriptingHelp(event);
    };

    return (
        <>
            <StyledDrawerHeader>
                <StyledLogoContainer>
                    <LinkToHome isMondayUser={!showDashboard}>
                        <SRConnectIcon />
                        {open ? (
                            <StyledApp>
                                ScriptRunner
                                <br />
                                Connect
                            </StyledApp>
                        ) : null}
                    </LinkToHome>
                </StyledLogoContainer>
            </StyledDrawerHeader>
            <List sx={{ paddingTop: theme.spacing(1), height: '100%' }} component="nav" aria-label="main">
                <StyledClose sx={!open ? { justifyContent: 'center' } : null}>
                    {open ? (
                        <IconButton onClick={onClose} aria-label="close drawer">
                            {theme.direction === 'ltr' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </IconButton>
                    ) : (
                        <IconButton aria-label="open drawer" onClick={onOpen}>
                            <KeyboardArrowRight />
                        </IconButton>
                    )}
                </StyledClose>
                {showDashboard && (
                    <StyledListItemButton
                        key="Dashboard"
                        selected={selectedIndex === PathNameEnum.dashboard}
                        onClick={() => handleListItemClick(PathNameEnum.dashboard, 'dashboard')}
                        aria-label="Dashboard"
                    >
                        <Tooltip title="Dashboard" describeChild>
                            <ListItemIcon>
                                <DashboardOutlinedIcon />
                            </ListItemIcon>
                        </Tooltip>
                        <StyledListItemText primary="Dashboard" />
                        {selectedIndex === PathNameEnum.dashboard ? <StyledLine /> : <></>}
                    </StyledListItemButton>
                )}
                <StyledListItemButton
                    key="Workspaces"
                    selected={selectedIndex === PathNameEnum.workspaces}
                    onClick={() => handleListItemClick(PathNameEnum.workspaces, 'workspaces')}
                    aria-label="Workspaces"
                >
                    <Tooltip title="Workspaces" describeChild>
                        <ListItemIcon>
                            <WorkspacesOutlinedIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <StyledListItemText primary="Workspaces" />
                    {selectedIndex === PathNameEnum.workspaces ? <StyledLine /> : <></>}
                </StyledListItemButton>
                <StyledListItemButton
                    key="Connectors"
                    selected={selectedIndex === PathNameEnum.connectors}
                    onClick={() => handleListItemClick(PathNameEnum.connectors, 'connectors')}
                    aria-label="Connectors"
                >
                    <Tooltip title="Connectors" describeChild>
                        <ListItemIcon>
                            <Cable />
                        </ListItemIcon>
                    </Tooltip>
                    <StyledListItemText primary="Connectors" />
                    {selectedIndex === PathNameEnum.connectors ? <StyledLine /> : <></>}
                </StyledListItemButton>
                <StyledListItemButton
                    key="Teams"
                    selected={selectedIndex === PathNameEnum.teams}
                    onClick={() => handleListItemClick(PathNameEnum.teams, 'teams')}
                    aria-label="Teams"
                >
                    <Tooltip title="Teams" describeChild>
                        <ListItemIcon>
                            <HubOutlinedIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <StyledListItemText primary="Teams" />
                    {selectedIndex === PathNameEnum.teams ? <StyledLine /> : <></>}
                </StyledListItemButton>
                <StyledListItemButton
                    key="Templates"
                    selected={selectedIndex === PathNameEnum.templates}
                    onClick={() => handleListItemClick(PathNameEnum.templates, 'templates')}
                    aria-label="Templates"
                >
                    <Tooltip title="Templates" describeChild>
                        <ListItemIcon>
                            <ArticleOutlinedIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <StyledListItemText primary="Templates" />
                    {selectedIndex === PathNameEnum.templates ? <StyledLine /> : <></>}
                </StyledListItemButton>
                <StyledListItemButton
                    key="Reporting"
                    selected={selectedIndex === PathNameEnum.reporting}
                    onClick={() => handleListItemClick(PathNameEnum.reporting, 'reporting')}
                    aria-label="Reporting"
                >
                    <Tooltip title="Reporting" describeChild>
                        <ListItemIcon>
                            <AnalyticsOutlinedIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <StyledListItemText primary="Reporting" />
                    {selectedIndex === PathNameEnum.reporting ? <StyledLine /> : <></>}
                </StyledListItemButton>
            </List>
            <List component="div" aria-label="secondary">
                {open && openRequestScriptingHelp && (
                    <RequestScriptingHelp>
                        <RequestScriptingHelpBody>
                            <RequestScriptingHelpHeader>
                                <RequestScriptingHelpHeaderIcons>
                                    <AutoAwesomeOutlinedIcon sx={{ color: theme.palette.primary.light }} />
                                    <TerminalOutlinedIcon />
                                </RequestScriptingHelpHeaderIcons>
                                <IconButton onClick={() => handleOpenRequestScriptingHelp(false)}>
                                    <HorizontalRuleOutlinedIcon sx={{ color: theme.palette.text.primary }} />
                                </IconButton>
                            </RequestScriptingHelpHeader>
                            <RequestScriptingHelpText>
                                <Typography variant="subtitle1">
                                    Having trouble writing scripts? We can help!
                                </Typography>
                                <Typography color="text.secondary" variant="body1">
                                    Our team of experts are here to assist you with all your scripting needs
                                </Typography>
                            </RequestScriptingHelpText>
                        </RequestScriptingHelpBody>
                        <Button fullWidth onClick={onRequestScriptingHelp}>
                            Contact Us
                        </Button>
                    </RequestScriptingHelp>
                )}
                {open && !openRequestScriptingHelp && (
                    <RequestScriptingHelp>
                        <RequestScriptingHelpHeader>
                            <RequestScriptingHelpHeaderIcons>
                                <AutoAwesomeOutlinedIcon sx={{ color: theme.palette.primary.light }} />
                                <TerminalOutlinedIcon />
                            </RequestScriptingHelpHeaderIcons>
                            <Typography variant="subtitle2">Need help?</Typography>
                            <IconButton onClick={() => handleOpenRequestScriptingHelp(true)}>
                                <AddOutlinedIcon sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </RequestScriptingHelpHeader>
                    </RequestScriptingHelp>
                )}
                {!open && (
                    <RequestScriptingHelpMinimised>
                        <Tooltip title="Request Scripting Support" describeChild>
                            <IconButton onClick={onRequestScriptingHelp}>
                                <TerminalOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </RequestScriptingHelpMinimised>
                )}
            </List>
            <List sx={{ bottom: 24, marginTop: theme.spacing(2) }} component="div" aria-label="secondary">
                <StyledListModalButton
                    key="Connect & Support"
                    onClick={onOpenConnectAndSupportModal}
                    aria-label="Connect & Support"
                >
                    <Tooltip title="Connect & Support" describeChild>
                        <ListItemIcon>
                            <ContactSupportOutlinedIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <StyledListItemText primary="Connect & Support" />
                </StyledListModalButton>
            </List>
        </>
    );
};
