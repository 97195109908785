import { useObservableState } from 'observable-hooks';
import { JsmCloudAssetsManageConnectionDialog } from '../../../components/connections/jsmCloudAssets/ManageConnectionDialog';
import {
    jsmCloudAssetsConnectionSaving$,
    jsmCloudAssetsManageConnectionAuthorizeLoading$,
    jsmCloudAssetsManageConnectionCloseDialogAction$,
    jsmCloudAssetsManageConnectionDetails$,
    jsmCloudAssetsManageConnectionDialogErrors$,
    jsmCloudAssetsManageConnectionDialogOpen$,
    jsmCloudAssetsManageConnectionInitiateSetupAction$,
    jsmCloudAssetsSaveConnectionAction$,
} from '../../../store/connection/jsmCloudAssets/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const JsmCloudAssetsManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(jsmCloudAssetsManageConnectionDialogOpen$);
    const saving = useObservableState(jsmCloudAssetsConnectionSaving$);
    const authorizeLoading = useObservableState(jsmCloudAssetsManageConnectionAuthorizeLoading$);
    const details = useObservableState(jsmCloudAssetsManageConnectionDetails$);
    const errors = useObservableState(jsmCloudAssetsManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <JsmCloudAssetsManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            onCancel={() => jsmCloudAssetsManageConnectionCloseDialogAction$.next()}
            onSave={(name) => jsmCloudAssetsSaveConnectionAction$.next(name)}
            onAuthorize={(name) => jsmCloudAssetsManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
