import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { ConnectionSelector } from '../components/workspace/connection-selector';
import { appSelectorOpen$, openAppSelectorAction$ } from '../store/apps';
import { ConnectionDetails, openConnectionDetailsDialogAction$ } from '../store/connection';
import { connectionDeletedAction$, deleteConnectionAction$, loggedInUserConnections$ } from '../store/connections';
import { getBasePath } from '../utils/path';
import { loggedInUserDetails$ } from '../store/user';
import { APP } from '@avst-stitch/repository-lib/constants';
import { connectorDocumentationUrl, mondayConnectorDocumentationUrl } from '../utils/documentation';
import { MONDAY_ORIGIN } from '../utils/constants';

export const ConnectorsContainer: React.FC = () => {
    const connections = useObservableState(loggedInUserConnections$);
    const { userOrigin } = useObservableState(loggedInUserDetails$) ?? {};
    const navigate = useNavigate();

    useSubscription(connectionDeletedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}/connectors` }), 100);
    });

    const isMondayUser = userOrigin === MONDAY_ORIGIN;

    const mappedConnections = connections
        .map(({ uid, connectionType, name, authorized }) => ({
            uid,
            title: name,
            type: connectionType.name,
            authorized,
        }))
        .filter((app) => (isMondayUser ? app.type === APP.MONDAY.NAME : true));

    return (
        <ConnectionSelector
            onCreateNew={() => {
                openAppSelectorAction$.next({
                    nextAction: (app) => {
                        openConnectionDetailsDialogAction$.next({
                            connectionType: app.connectionType,
                        });
                        appSelectorOpen$.next(false);
                    },
                    mode: 'CONNECTOR',
                });
            }}
            onSelect={(uid) => {
                const conn = connections.find((conn) => conn.uid === uid);
                if (conn) {
                    openConnectionDetailsDialogAction$.next(conn as unknown as ConnectionDetails);
                }
            }}
            onDelete={(uid) => deleteConnectionAction$.next({ uid, ignoreWarnings: false })}
            connections={mappedConnections}
            docsUrl={isMondayUser ? mondayConnectorDocumentationUrl : connectorDocumentationUrl}
        />
    );
};
