import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../router';
import { loadWorkspaces } from '../store/workspace/utils';
import { loadLoggedInUserOrganizations } from '../store/organizations';
import { loadErrorPage } from '../store/error';

export const workspacesLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        await Promise.all([loadWorkspaces(true, 'workspaces'), loadLoggedInUserOrganizations()]);
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Workspaces',
            genericMessage: 'Failed to load your workspaces.',
        });

        throw e;
    }

    return {};
};
