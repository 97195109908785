import {
    BehaviorSubject,
    combineLatest,
    concat,
    defer,
    EMPTY,
    interval,
    Observable,
    Subject,
    Subscription,
    Unsubscribable,
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    ignoreElements,
    map,
    repeat,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Config, StitchCoreTopics, StitchSession } from './config/types';
import { FeedbackQuery } from '../feedback/types';
import { toast, ToastOptions, TypeOptions } from 'react-toastify';
import { FeedbackEvent } from '@avst-stitch/feedback-lib';
import { getFeedbackInterceptor, getFeedbackRenderer } from '../feedback';
import { monitor } from './monitor';
import { ToastMessage } from '../components/toast';
import { SESSION_ID } from '..';

export const feedbackQueryAction$ = monitor('feedbackQueryAction$', new Subject<FeedbackQuery>());
export const feedbackConnectionStatus$ = monitor('feedbackConnectionStatus$', new Subject<boolean>());
export const notificationBannerDetails$ = monitor(
    'notificationBannerDetails$',
    new BehaviorSubject<NotificationBannerDetails | undefined>(undefined)
);

export const feedbackIncomingEvent$ = monitor(
    'feedbackIncomingEvent$',
    new BehaviorSubject<(FeedbackEvent & WithToastOptions) | null>(null)
);

export const publishLocalFeedbackEventAction$ = monitor(
    'publishLocalFeedbackEventAction$',
    new Subject<LocalFeedbackEvent & WithToastOptions>()
);

export type LocalFeedbackEvent = {
    readonly type?: string;
    readonly message: string;
    readonly level: FeedbackEvent['level'];
};

export type WithToastOptions = {
    readonly toastOptions?: Partial<ToastOptions>;
    readonly noToast?: boolean;
};

export type NotificationBannerDetails = {
    readonly message: string;
    readonly level: 'info' | 'warning' | 'error';
};

const MIN_DELAY = 100;
const MAX_DELAY = 30000;

type UrlAndJwtAndSession = [string | undefined, string | undefined, string];
type CreateSubscriptions<D = void> = (deps: D) => Unsubscribable[];

interface AuthMessage {
    messageType: 'AUTH';
    authToken: string;
    sessionId: string;
}

//todo: fix action type
const subscribeToEvents =
    (feedbackQueryAction$: Subject<FeedbackQuery>) =>
    ([url, jwt, sessionId]: UrlAndJwtAndSession): Observable<unknown> => {
        //TODO: only run when the jwt has changed or url has distinctUntilKeyChanged
        let sub: Subscription | undefined = undefined;

        let reconnectDelay = MIN_DELAY;

        const subject: WebSocketSubject<unknown> = webSocket({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            url: url!,
            openObserver: {
                next: () => {
                    console.debug('WebSocket open');
                    if (url && jwt) {
                        console.debug('WebSocket sending auth');
                        subject.next({
                            messageType: 'AUTH',
                            authToken: jwt,
                            sessionId,
                        } as AuthMessage);

                        sub = feedbackQueryAction$.subscribe((feedbackQuery) => {
                            console.debug(`Sending Query: ${feedbackQuery}`);
                            subject.next(feedbackQuery);
                        });
                    }
                },
            },
            closeObserver: {
                next: () => {
                    console.debug('WebSocket closed');
                    sub?.unsubscribe();
                    sub = undefined;
                },
            },
        });

        return concat(
            subject.pipe(
                // Reset the reconnection delay when we receive an event
                tap(() => {
                    reconnectDelay = MIN_DELAY;
                }),

                // Report errors
                catchError((errors) => {
                    console.error('WebSocket error:', errors);
                    return EMPTY;
                })
            ),

            // Inject a delay into the pipeline when the websocket closes before attempting to reconnect
            defer(() =>
                interval(reconnectDelay).pipe(
                    tap(() => {
                        console.log(`WebSocket reconnection delayed for ${reconnectDelay / 1000}s`);
                        reconnectDelay = Math.min(reconnectDelay * 2, MAX_DELAY);
                    }),
                    take(1),
                    ignoreElements()
                )
            )
        ).pipe(
            // Attempt to reconnect after the websocket closes
            repeat()
        );
    };

const extractUrlAndJwt = ([meta, session]: [Config | null, StitchSession | null]): UrlAndJwtAndSession => [
    meta?.FeedbackWebSocketUrl,
    session?.jwt,
    SESSION_ID,
];

export const setupFeedbackConnection: CreateSubscriptions<StitchCoreTopics> = ({ configTopic$, stitchSession$ }) => [
    combineLatest(configTopic$, stitchSession$)
        .pipe(
            map(extractUrlAndJwt),
            distinctUntilChanged(
                ([url1, jwt1, sessionId1], [url2, jwt2, sessionId2]) =>
                    url1 === url2 && jwt1 === jwt2 && sessionId1 === sessionId2
            ),
            filter(([url, jwt]) => !!jwt && !!url),
            switchMap(subscribeToEvents(feedbackQueryAction$))
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .subscribe((e: any) => {
            /*
                    TODO: need a way to distinguish between genuine error and
                    error as a string in an event
                */
            if (e?.message?.includes('error') && !e?.source) {
                feedbackConnectionStatus$.next(false);
            } else {
                feedbackIncomingEvent$.next(e);
            }
        }),
];
feedbackIncomingEvent$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async (event) => {
            try {
                if (event && !event.invocationId) {
                    const interceptor = getFeedbackInterceptor(event);

                    if (interceptor && (await interceptor.processEvent(event)) === true) {
                        return;
                    }

                    const renderer = getFeedbackRenderer(event);

                    if (renderer) {
                        const message =
                            renderer.getPlainText?.(event) ??
                            event.message ??
                            `${event.type}: ${JSON.stringify(event.payload ?? {})}`;

                        if (!event.noToast) {
                            toast(ToastMessage({ type: mapToToastType(event.level), message }), {
                                ...event.toastOptions,
                                type: mapToToastType(event.level),
                                icon: false,
                                hideProgressBar: true,
                                position: 'bottom-left',
                            });
                        }
                    } else if (!event.noToast) {
                        const message = event.message ?? `${event.type}: ${JSON.stringify(event.payload ?? {})}`;

                        toast(ToastMessage({ type: mapToToastType(event.level), message }), {
                            ...event.toastOptions,
                            type: mapToToastType(event.level),
                            icon: false,
                            hideProgressBar: true,
                            position: 'bottom-left',
                        });
                    }
                }
            } catch (e) {
                console.error('Error while rendering feedback', e, event);
            }
        })
    )
    .subscribe();

const mapToToastType = (logLevel: FeedbackEvent['level']): TypeOptions => {
    switch (logLevel) {
        case 'ERROR':
            return 'error';
        case 'WARN':
            return 'warning';
        case 'INFO':
            return 'info';
        case 'SUCCESS':
            return 'success';
        default:
            return 'default';
    }
};
