import { useObservableState } from 'observable-hooks';
import { GitlabAppConfigureDialog } from '../../../components/connections/gitlab/GitlabAppConfigureDialog';
import { configTopic$ } from '../../../store/config';
// import { configTopic$ } from '../../../store/config';
import {
    gitlabConnectionSaving$,
    gitlabManageConnectionDetails$,
    gitlabAppSetupDialogErrors$,
    gitlabAppSetupDialogStage$,
    gitlabAppSetupDialogOpen$,
    gitlabAppSetupCloseDialogAction$,
    gitlabAppSetupAuthorizeAction$,
} from '../../../store/connection/gitlab/setup-connection';
import { loggedInUserConnections$ } from '../../../store/connections';

export const GitlabConfigureDialog: React.FC = () => {
    const open = useObservableState(gitlabAppSetupDialogOpen$);
    const saving = useObservableState(gitlabConnectionSaving$);
    const details = useObservableState(gitlabManageConnectionDetails$);
    const errors = useObservableState(gitlabAppSetupDialogErrors$);
    const currentStage = useObservableState(gitlabAppSetupDialogStage$);
    const baseUrl = configTopic$.value.connection?.gitlab?.baseUrl;
    const gitlabAuthorizedSaasConnections = useObservableState(loggedInUserConnections$).filter(
        (x) => x.connectionType.name.toLowerCase() === 'gitlab' && x.authorized && !x.instanceUrl
    );
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <GitlabAppConfigureDialog
            name={details?.name ?? ''}
            authorized={connection?.authorized}
            hasAuthorizedSaasConnections={!!gitlabAuthorizedSaasConnections.length}
            open={open}
            onClose={() => {
                gitlabAppSetupCloseDialogAction$.next();
                gitlabAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => gitlabAppSetupDialogStage$.next(stage)}
            clientInfo={{
                instanceUrl: details?.instanceUrl ?? '',
                clientId: details?.clientId ?? '',
                clientSecret: details?.clientSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => gitlabAppSetupDialogErrors$.next(error)}
            clearErrors={() => gitlabAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => gitlabAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${baseUrl}/callback`}
        />
    );
};
