import { BehaviorSubject, map, Subject } from 'rxjs';
import { CreateBillingDetailsEvent } from '../components/organization/billing-details/CreateBillingEntityModal';
import { UpdateBillingEntityEvent } from '../components/organization/billing-details/SavedBillingDetailsList';
import { BillingEntities, createBillingEntity, getBillingEntities, updateBillingEntity } from '../data/billing-details';
import { InformativeError } from '../utils/repository';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';
import { changeOrganizationPlan, changeOrganizationPlanV2 } from '../data/organization';
import { Plan } from '../components/organization/create-organization-wizard/CreateOrganizationWizardDialog';
import { SelectBillingDetailsEvent } from '../components/organization/SelectBillingDetailsDialog';
import { organizationPlanChangedAction$, selectedOrganizationUid$ } from './organization';
import { openSalableCheckoutWindow } from './utils';

export interface BillingEntity {
    uid: string;
    contactName: string;
    contactEmail: string;
    associatedOrganizations: {
        uid: string;
        name: string;
    }[];
}

export const billingEntities$ = monitor('billingEntities$', new BehaviorSubject<BillingEntities>([]));
export const refreshBillingEntitiesAction$ = monitor('refreshBillingEntitiesAction$', new Subject<void>());
export const updateBillingEntityAction$ = monitor(
    'updateBillingEntityAction$',
    new Subject<UpdateBillingEntityEvent>()
);
export const createBillingEntityAction$ = monitor(
    'createBillingEntityAction$',
    new Subject<CreateBillingDetailsEvent>()
);
export const openSelectBillingDetailsDialogAction$ = monitor(
    'openSelectBillingDetailsDialogAction$',
    new Subject<Plan>()
);
export const closeSelectBillingDetailsDialogAction$ = monitor(
    'closeSelectBillingDetailsDialogAction$',
    new Subject<void>()
);
export const billingDetailsSelectedAction$ = monitor('billingDetailsSelectedAction$', new Subject<void>());

export const createNewBillingEntityValidationError$ = monitor(
    'createNewBillingEntityValidationError$',
    new BehaviorSubject<string>('')
);
export const updateBillingEntityValidationError$ = monitor(
    'updateBillingEntityValidationError$',
    new BehaviorSubject<string>('')
);
export const billingEntityUpdating$ = monitor('billingEntityUpdating$', new BehaviorSubject<boolean>(false));
export const billingEntityCreating$ = monitor('billingEntityCreating$', new BehaviorSubject<boolean>(false));
export const createBillingEntityDialogOpen$ = monitor(
    'createBillingEntityDialogOpen$',
    new BehaviorSubject<boolean>(false)
);
export const selectedBillingDetails$ = monitor(
    'selectedBillingDetails$',
    new BehaviorSubject<BillingEntity | undefined>(undefined)
);
export const billingEntityCreatedAction$ = monitor('billingEntityCreatedAction$', new Subject<string>());
export const billingEntityUpdatedAction$ = monitor('billingEntityUpdatedAction$', new Subject<void>());
export const addBillingDetailsToOrganizationAction$ = monitor(
    'addBillingDetailsToOrganizationAction$',
    new Subject<SelectBillingDetailsEvent>()
);
export const addBillingDetailsToOrganizationActionV2$ = monitor(
    'addBillingDetailsToOrganizationActionV2$',
    new Subject<SelectBillingDetailsEvent>()
);

export const selectBillingDetailsDialogOpen$ = monitor('selectBillingDetailsDialogOpen$', new BehaviorSubject(false));
export const selectBillingDetailsDialogLoading$ = monitor(
    'selectBillingDetailsDialogLoading$',
    new BehaviorSubject(false)
);
export const selectBillingDetailsDialogSaving$ = monitor(
    'selectBillingDetailsDialogSaving$',
    new BehaviorSubject(false)
);
export const selectBillingDetailsError$ = monitor(
    'selectBillingDetailsError$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const selectedOrganizationPlan$ = monitor(
    'selectedOrganizationPlan$',
    new BehaviorSubject<Plan | undefined>(undefined)
);

closeSelectBillingDetailsDialogAction$.subscribe(() => {
    selectBillingDetailsDialogOpen$.next(false);
    selectedOrganizationPlan$.next(undefined);
    selectBillingDetailsError$.next(undefined);
    selectBillingDetailsDialogSaving$.next(false);
    selectBillingDetailsDialogLoading$.next(false);
});

updateBillingEntityAction$
    .pipe(
        map(async ({ uid, name, email }) => {
            billingEntityUpdating$.next(true);
            try {
                await updateBillingEntity(uid, name, email);
                updateBillingEntityValidationError$.next('');
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Billing entity updated.',
                });
                billingEntityUpdatedAction$.next();
            } catch (e) {
                if (e instanceof InformativeError) {
                    updateBillingEntityValidationError$.next(e.message);
                } else {
                    updateBillingEntityValidationError$.next('Error occurred while updating billing entity.');
                    throw new Error((e as Error).message);
                }
            }
            billingEntityUpdating$.next(false);
        })
    )
    .subscribe();

createBillingEntityAction$
    .pipe(
        map(async ({ contactName, contactEmail }) => {
            billingEntityCreating$.next(true);
            try {
                const response = await createBillingEntity(contactName, contactEmail);
                createNewBillingEntityValidationError$.next('');
                createBillingEntityDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Billing entity created.',
                });
                billingEntityCreatedAction$.next(response.uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    createNewBillingEntityValidationError$.next(e.message);
                } else {
                    createNewBillingEntityValidationError$.next('Error occurred while creating billing entity.');
                    throw new Error((e as Error).message);
                }
            }
            billingEntityCreating$.next(false);
        })
    )
    .subscribe();

openSelectBillingDetailsDialogAction$
    .pipe(
        map(async (plan) => {
            selectBillingDetailsError$.next(undefined);
            selectBillingDetailsDialogLoading$.next(true);
            selectedOrganizationPlan$.next(plan);
            selectBillingDetailsDialogOpen$.next(true);
            try {
                const billingEntities = await getBillingEntities();
                billingEntities$.next(billingEntities);
                selectBillingDetailsDialogLoading$.next(false);
            } catch (e) {
                let errorMessage: string;
                if (e instanceof InformativeError) {
                    errorMessage = e.message;
                } else {
                    console.error('Failed to load billing entities', e);
                    errorMessage =
                        'Failed to load billing entities, try refreshing the browser, if the issue persists please contact support.';
                }
                selectBillingDetailsDialogOpen$.next(false);
                selectBillingDetailsDialogLoading$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: errorMessage,
                });
            }
        })
    )
    .subscribe();

addBillingDetailsToOrganizationAction$
    .pipe(
        map(async (event) => {
            selectBillingDetailsError$.next(undefined);
            selectBillingDetailsDialogSaving$.next(true);
            try {
                await changeOrganizationPlan({
                    ...event,
                    organizationUid: selectedOrganizationUid$.value ?? '',
                    plan: selectedOrganizationPlan$.value,
                });
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Plan changed',
                });
                closeSelectBillingDetailsDialogAction$.next();
                organizationPlanChangedAction$.next();
            } catch (e) {
                if (e instanceof InformativeError) {
                    selectBillingDetailsError$.next(e.message);
                } else {
                    console.error('Failed to upgrade plan', e);
                    selectBillingDetailsError$.next(
                        'Error occurred while changing plan. Please try again, if the issue persists please contact support.'
                    );
                }
            }
            selectBillingDetailsDialogSaving$.next(false);
        })
    )
    .subscribe();

addBillingDetailsToOrganizationActionV2$
    .pipe(
        map(async (event) => {
            selectBillingDetailsError$.next(undefined);
            selectBillingDetailsDialogSaving$.next(true);
            try {
                const checkout = await changeOrganizationPlanV2({
                    ...event,
                    organizationUid: selectedOrganizationUid$.value ?? '',
                    plan: selectedOrganizationPlan$.value,
                    originalReferrer: window.location.origin,
                });

                if (checkout?.checkoutUrl) {
                    openSalableCheckoutWindow(
                        checkout.checkoutUrl,
                        [selectBillingDetailsDialogSaving$],
                        [selectBillingDetailsError$]
                    );
                } else {
                    selectBillingDetailsDialogSaving$.next(false);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Plan changed',
                    });
                    closeSelectBillingDetailsDialogAction$.next();
                    organizationPlanChangedAction$.next();
                }
            } catch (e) {
                selectBillingDetailsDialogSaving$.next(false);
                if (e instanceof InformativeError) {
                    selectBillingDetailsError$.next(e.message);
                } else {
                    console.error('Failed to upgrade plan', e);
                    selectBillingDetailsError$.next(
                        'Error occurred while changing plan. Please try again, if the issue persists please contact support.'
                    );
                }
            }
        })
    )
    .subscribe();

refreshBillingEntitiesAction$
    .pipe(
        map(async () => {
            try {
                const entities = await getBillingEntities();
                billingEntities$.next(entities);
            } catch (e) {
                console.error('Failed to get billing entites', e);
            }
        })
    )
    .subscribe();
