import { useObservableState } from 'observable-hooks';
import { LargeInvocationLogPayload } from '../components/invocation-logs/LargeInvocationLogPayload';
import { selectedInvocationLogId$, selectedInvocationLogContent$ } from '../store/invocationlogs';

export const LargeInvocationLogPayloadContainer: React.FC = () => {
    const logId = useObservableState(selectedInvocationLogId$);
    const content = useObservableState(selectedInvocationLogContent$);

    return <LargeInvocationLogPayload logId={logId ?? ''} content={content ?? ''} />;
};
