import { useObservableState } from 'observable-hooks';
import { NetSuiteEventListenerSetupDialog } from '../../../components/connections/netsuite/EventListenerSetupDialog';
import { configTopic$ } from '../../../store/config';
import {
    netSuiteEventListenerSetupDialogCloseAction$,
    netSuiteEventListenerSetupDialogDetails$,
    netSuiteEventListenerSetupDialogLoading$,
    netSuiteEventListenerSetupDialogOpen$,
} from '../../../store/connection/netsuite/setup-event-listener';

export const NetSuiteEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(netSuiteEventListenerSetupDialogLoading$);
    const open = useObservableState(netSuiteEventListenerSetupDialogOpen$);
    const details = useObservableState(netSuiteEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <NetSuiteEventListenerSetupDialog
            loading={loading}
            open={open}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            accountId={details?.accountId}
            onClose={() => netSuiteEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
