import { useObservableState } from 'observable-hooks';
import { billingEntities$ } from '../../store/billing-details';
import { selectedOrganizationPlan$, organizationBillingDetails$ } from '../../store/organization';
import {
    closeChangeOrganizationPlanWizardDialogAction$,
    changeOrganizationPlanWizardDialogOpen$,
    changeOrganizationPlanWizardDialogSaving$,
    changeOrganizationPlanWizardDialogLoading$,
    changeOrganizationPlanWizardDialogError$,
    changeOrganizationPlanWizardSelectedPlan$,
    addChangePlanDetailsToOrganizationActionV2$,
} from '../../store/organization/changeOrganizationPlan';
import { ChangeOrganizationPlanWizardDialog } from '../../components/organization/change-organization-plan-wizard/ChangeOrganizationPlanWizardDialog';

export const ChangeOrganizationPlanWizardContainer: React.FC = () => {
    const open = useObservableState(changeOrganizationPlanWizardDialogOpen$);
    const saving = useObservableState(changeOrganizationPlanWizardDialogSaving$);
    const loading = useObservableState(changeOrganizationPlanWizardDialogLoading$);
    const errors = useObservableState(changeOrganizationPlanWizardDialogError$);
    const plan = useObservableState(changeOrganizationPlanWizardSelectedPlan$);
    const currentPlan = useObservableState(selectedOrganizationPlan$);
    const billingEntities = useObservableState(billingEntities$);
    const organizationBillingDetails = useObservableState(organizationBillingDetails$);

    return (
        <ChangeOrganizationPlanWizardDialog
            billingEntities={billingEntities}
            currentBillingEntity={organizationBillingDetails?.selectedBillingEntityUid}
            plan={plan}
            currentPlan={currentPlan}
            errors={errors}
            open={open}
            onCancel={() => closeChangeOrganizationPlanWizardDialogAction$.next()}
            onCreate={(event) => addChangePlanDetailsToOrganizationActionV2$.next(event)}
            saving={saving}
            loading={loading}
        />
    );
};
