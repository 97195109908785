import { useObservableState } from 'observable-hooks';
import { BitbucketOnPremiseConnectionAppConfigureDialog } from '../../../components/connections/bitbucket-on-premise/BitbucketOnPremiseAppConfigureDialog';
import { configTopic$ } from '../../../store/config';
import {
    bitbucketOnPremiseConnectionSaving$,
    bitbucketOnPremiseManageConnectionDetails$,
    bitbucketOnPremiseAppSetupDialogErrors$,
    bitbucketOnPremiseAppSetupDialogOpen$,
    bitbucketOnPremiseAppSetupCloseDialogAction$,
    bitbucketOnPremiseAppSetupAuthorizeAction$,
    bitbucketOnPremiseAppSetupDialogStage$,
} from '../../../store/connection/bitbucket-on-premise/setup-connection';

export const BitbucketOnPremiseConfigureDialog: React.FC = () => {
    const open = useObservableState(bitbucketOnPremiseAppSetupDialogOpen$);
    const saving = useObservableState(bitbucketOnPremiseConnectionSaving$);
    const details = useObservableState(bitbucketOnPremiseManageConnectionDetails$);
    const errors = useObservableState(bitbucketOnPremiseAppSetupDialogErrors$);
    const currentStage = useObservableState(bitbucketOnPremiseAppSetupDialogStage$);
    const baseUrl = configTopic$.value.connection?.bitbucketOnPremise?.baseUrl;
    return (
        <BitbucketOnPremiseConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                bitbucketOnPremiseAppSetupCloseDialogAction$.next();
                bitbucketOnPremiseAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => bitbucketOnPremiseAppSetupDialogStage$.next(stage)}
            clientInfo={{
                instanceUrl: details?.instanceUrl ?? '',
                clientId: details?.clientId ?? '',
                clientSecret: details?.clientSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => bitbucketOnPremiseAppSetupDialogErrors$.next(error)}
            clearErrors={() => bitbucketOnPremiseAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => bitbucketOnPremiseAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${baseUrl}/callback`}
        />
    );
};
