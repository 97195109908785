import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Button } from '../buttons/Button';
import { StyledCard } from './DashboardComponents';

interface Article {
    description: string;
    index: number;
    title: string;
    url: string;
}

interface KnowledgeBaseCardProps {
    articles: Article[];
    onLearnMore?: (url?: string) => void;
}

const StyledKBCard = styled(StyledCard)(({ theme }) => ({
    // do not put any width constraints on the card containers, let the parent Grid xs do the sizing
    backgroundColor: theme.palette.knowledge.light,
    color: theme.palette.knowledge.contrastText,
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
        outline: theme.constants.borderHidden,
    },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginBottom: theme.spacing(2),
}));

const StyledCardUpper = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
}));

const StyledCardTitle = styled('div')(() => ({
    flex: '0 0 70%',
}));

const StyledIconCircle = styled('div')(({ theme }) => ({
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.knowledge.dark,
    borderRadius: theme.constants.radiusCircle,
    height: 40,
    position: 'relative',
    width: 40,
}));

const StyledIconContainer = styled('div')(({ theme }) => ({
    borderRadius: theme.constants.radiusCircle,
    height: 22,
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 16,
    '& .MuiSvgIcon-root': {
        color: theme.palette.knowledge.light,
        stroke: theme.palette.knowledge.light,
        height: 22,
        width: 22,
    },
}));

const StyledDescriptionContainer = styled('div')(() => ({
    height: '100%',
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flex: '0 0 34px',
    justifyContent: 'space-between',
}));

const StyledNavigation = styled('div')(() => ({}));

const StyledNavigationButton = styled(IconButton)(({ theme }) => ({
    border: `1px solid ${theme.palette.knowledge.contrastText}`,
    borderRadius: theme.constants.radiusCircle,
    '&.MuiIconButton-root': {
        height: 34,
        width: 34,
    },
    '&:hover': {
        backgroundColor: theme.palette.knowledge.main,
    },
    '&:last-child': {
        marginLeft: theme.spacing(1),
        '& .MuiSvgIcon-root': {
            transform: 'rotate(180deg)',
        },
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.knowledge.contrastText,
        height: 22,
        opacity: 0.5,
        width: 22,
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.info.dark,
    '& > span': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
    },
}));

const disabledMixin = { opacity: '38%' };

export const KnowledgeBaseCard: React.FC<KnowledgeBaseCardProps> = ({ articles, onLearnMore }) => {
    const randomIndex = Math.floor(Math.random() * articles.length);
    const getRandomArticle = (): Article | undefined => {
        return articles[randomIndex];
    };
    useEffect(() => {
        setSelectedArticle(getRandomArticle());
    }, []);

    const [selectedArticle, setSelectedArticle] = useState<Article | undefined>(undefined);

    const handleSelectPrevious = (): void => {
        setSelectedArticle(selectedArticle && articles[selectedArticle.index - 1]);
    };

    const handleSelectNext = (): void => {
        setSelectedArticle(selectedArticle && articles[selectedArticle.index + 1]);
    };

    const isDisabled = (dir: 'NEXT' | 'PREVIOUS'): boolean => {
        if (selectedArticle) {
            return dir === 'NEXT' ? selectedArticle.index >= articles.length - 1 : selectedArticle.index < 1;
        } else {
            return true;
        }
    };

    return (
        <>
            <StyledKBCard elevation={1}>
                <StyledCardContent>
                    {/* there is heavy use of chromatic-ignore for the card title which means any changes here may not be picked up as expected in the UI reviews
                      - recommend you remove chromatic-ignore when working in this area and then put back when you're sure it's all working as expected */}
                    <StyledCardUpper className="chromatic-ignore">
                        <StyledCardTitle className="chromatic-ignore">
                            <Typography
                                className="chromatic-ignore"
                                component="h4"
                                variant="subtitle1"
                                sx={{ lineHeight: 1.25 }}
                            >
                                <span className="chromatic-ignore">{selectedArticle ? selectedArticle.title : ''}</span>
                            </Typography>
                        </StyledCardTitle>
                        <StyledIconCircle>
                            <StyledIconContainer>
                                <TipsAndUpdatesIcon />
                            </StyledIconContainer>
                        </StyledIconCircle>
                    </StyledCardUpper>
                    <StyledDescriptionContainer>
                        <Typography>{selectedArticle ? selectedArticle.description : ''}</Typography>
                    </StyledDescriptionContainer>
                </StyledCardContent>
                <StyledCardActions>
                    <StyledNavigation className="chromatic-ignore">
                        <StyledNavigationButton
                            disabled={isDisabled('PREVIOUS')}
                            onClick={handleSelectPrevious}
                            sx={isDisabled('PREVIOUS') ? disabledMixin : null}
                            title="Previous"
                        >
                            <KeyboardBackspaceIcon />
                        </StyledNavigationButton>
                        <StyledNavigationButton
                            disabled={isDisabled('NEXT')}
                            onClick={handleSelectNext}
                            sx={isDisabled('NEXT') ? disabledMixin : null}
                            title="Next"
                        >
                            <KeyboardBackspaceIcon />
                        </StyledNavigationButton>
                    </StyledNavigation>
                    <Link
                        target="_blank"
                        href={selectedArticle && selectedArticle.url}
                        title="ScriptRunner Connect documentation"
                        onClick={() => onLearnMore?.(selectedArticle?.url)}
                    >
                        <StyledButton endIcon={<OpenInNewIcon />} variant="text">
                            Learn More
                        </StyledButton>
                    </Link>
                </StyledCardActions>
            </StyledKBCard>
        </>
    );
};
