import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    OrganizationDetails,
    OrganizationPlan,
    OrganizationUsage,
    OrganizationViewDetails,
    saveOrganizationDetails,
    SaveOrganizationDetailsRequest,
    changeOrganizationBillingEntity,
    OrganizationBillingDetails,
    UpdateOrganizationBillingDetails,
    OrganizationMembers,
    updateOrganizationMemberPermissions,
    OrganizationMemberPermissionsUpdateRequest,
    updateOrganizationInvitedUserPermissions,
    OrganizationInvitedUserPermissionsUpdateRequest,
    removeOrganizationMember,
    OrganizationMemberRemovalRequest,
    removeOrganizationInvite,
    OrganizationInviteRemovalRequest,
    MemberInvitationRequest,
    sendOrganizationMemberInvite,
    resendOrganizationMemberInvite,
    getOrganizationWorkspacesWithUserConnections,
} from '../data/organization';
import { InformativeError, PermissionError } from '../utils/repository';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';
import { promptQuestion } from './confirm';
import { AYSYWTRTU } from '../i18n';

export const selectOrganizationAction$ = monitor('selectOrganizationAction$', new Subject<{ orgUid: string }>());

export const saveOrganizationDetailsAction$ = monitor(
    'saveOrganizationDetailsAction$',
    new Subject<SaveOrganizationDetailsRequest>()
);

export const organizationDetailsUpdatedAction$ = monitor('organizationDetailsUpdatedAction$', new Subject<string>());

export const organizationPlanChangedAction$ = monitor('organizationPlanChangedAction$', new Subject<void>());

export const selectedOrganizationUid$ = monitor(
    'selectedOrganizationUid$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const selectedOrganizationViewDetails$ = monitor(
    'selectedOrganizationViewDetails$',
    new BehaviorSubject<OrganizationViewDetails | undefined>(undefined)
);
export const organizationMembers$ = monitor(
    'organizationMembers$',
    new BehaviorSubject<OrganizationMembers | undefined>(undefined)
);
export const organizationBillingEntityPermissionError$ = monitor(
    'organizationBillingEntityPermissionError$',
    new BehaviorSubject<string>('')
);
export const organizationBillingDetails$ = monitor(
    'organizationBillingDetails$',
    new BehaviorSubject<OrganizationBillingDetails | undefined>(undefined)
);
export const organizationBillingDetailsCustomerPortal$ = monitor(
    'organizationBillingDetailsCustomerPortal$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const changeOrganizationBillingDetailsAction$ = monitor(
    'changeOrganizationBillingDetailsAction$',
    new Subject<UpdateOrganizationBillingDetails>()
);
export const organizationBillingDetailsUpdatedAction$ = monitor(
    'organizationBillingDetailsUpdatedAction$',
    new Subject<void>()
);
export const organizationBillingDetailsUpdateCancelledAction$ = monitor(
    'organizationBillingDetailsCancelledAction$',
    new Subject<void>()
);
export const savingOrganizationBillingDetails$ = monitor(
    'savingOrganizationBillingDetails$',
    new BehaviorSubject(false)
);

changeOrganizationBillingDetailsAction$
    .pipe(
        map(async (updateData) => {
            savingOrganizationBillingDetails$.next(true);
            try {
                await changeOrganizationBillingEntity(updateData);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Billing details updated.',
                });
                organizationBillingEntityPermissionError$.next('');
                organizationBillingDetailsUpdatedAction$.next();
            } catch (e) {
                if (e instanceof PermissionError) {
                    organizationBillingEntityPermissionError$.next(e.message);
                } else {
                    organizationBillingEntityPermissionError$.next(
                        'Error occurred while updating team billing details.'
                    );
                    throw new Error((e as Error).message);
                }
            }
            savingOrganizationBillingDetails$.next(false);
        })
    )
    .subscribe();

export const selectedOrganizationUsage$ = monitor(
    'selectedOrganizationUsage$',
    new BehaviorSubject<OrganizationUsage | undefined>(undefined)
);

export const updateOrganizationMemberPermissionsAction$ = monitor(
    'updateOrganizatioMemberPermissionsAction$',
    new Subject<OrganizationMemberPermissionsUpdateRequest>()
);
export const organizationMemberPermissionsUpdatedAction$ = monitor(
    'organizationMemberPermissionsUpdated$Action',
    new Subject<string>()
);

export const updateOrganizationInvitedUserPermissionsAction$ = monitor(
    'updateOrganizatioInvitedUserPermissionsAction$',
    new Subject<OrganizationInvitedUserPermissionsUpdateRequest>()
);
export const organizationInvitedUserPermissionsUpdatedAction$ = monitor(
    'organizationInvitedUserPermissionsUpdatedAction$',
    new Subject<string>()
);

export const removeOrganizationMemberAction$ = monitor(
    'removeOrganizatioMemberAction$',
    new Subject<OrganizationMemberRemovalRequest>()
);
export const organizationMemberRemovedAction$ = monitor('organizationMemberRemovedAction$', new Subject<string>());

export const removeOrganizationInviteAction$ = monitor(
    'removeOrganizatioInviteAction$',
    new Subject<OrganizationInviteRemovalRequest>()
);
export const organizationInviteRemovedAction$ = monitor('organizationInviteRemovedAction$', new Subject<string>());

export const memberManagementError$ = monitor(
    'memberManagementError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const memberManagementSaving$ = monitor('memberManagementSaving$', new BehaviorSubject(false));

updateOrganizationMemberPermissionsAction$
    .pipe(
        map(async (update) => {
            memberManagementError$.next(undefined);
            memberManagementSaving$.next(true);
            try {
                await updateOrganizationMemberPermissions(update);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Member updated.',
                });
                organizationInvitedUserPermissionsUpdatedAction$.next(update.memberUid);
            } catch (e) {
                if (e instanceof PermissionError || e instanceof InformativeError) {
                    memberManagementError$.next(e.message);
                } else {
                    memberManagementError$.next(
                        'Error occurred while updating team member. Please try again, if the issue persists please contact support.'
                    );
                }
            }
            memberManagementSaving$.next(false);
        })
    )
    .subscribe();

updateOrganizationInvitedUserPermissionsAction$
    .pipe(
        map(async (update) => {
            {
                memberManagementError$.next(undefined);
                memberManagementSaving$.next(true);
                try {
                    await updateOrganizationInvitedUserPermissions(update);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Invited user updated.',
                    });
                    organizationInvitedUserPermissionsUpdatedAction$.next(update.inviteUid);
                } catch (e) {
                    if (e instanceof PermissionError || e instanceof InformativeError) {
                        memberManagementError$.next(e.message);
                    } else {
                        memberManagementError$.next(
                            'Error occurred while updating invited user. Please try again, if the issue persists please contact support.'
                        );
                    }
                }
                memberManagementSaving$.next(false);
            }
        })
    )
    .subscribe();

removeOrganizationMemberAction$
    .pipe(
        map(async (event) => {
            {
                const callRemoveMember = async (): Promise<void> => {
                    await removeOrganizationMember(event);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Member removed.',
                    });
                    organizationMemberRemovedAction$.next(event.removedUser);
                };

                memberManagementError$.next(undefined);
                memberManagementSaving$.next(true);

                try {
                    const workspacesWithUserConnections = await getOrganizationWorkspacesWithUserConnections({
                        memberUid: event.removedUser,
                        organizationUid: event.organizationUid,
                    });

                    const workspaceNames = workspacesWithUserConnections.map((w) => w.name);
                    const message = workspacesWithUserConnections.length
                        ? `This will also remove all Connectors in the team owned by this user, affecting the following workspaces: ${workspaceNames.join(
                              ', '
                          )}.`
                        : '';
                    promptQuestion({
                        title: AYSYWTRTU,
                        message,
                        onProceed: async () => await callRemoveMember(),
                    });
                } catch (e) {
                    if (e instanceof PermissionError || e instanceof InformativeError) {
                        memberManagementError$.next(e.message);
                    } else {
                        memberManagementError$.next(
                            'Error occurred while removing team member. Please try again, if the issue persists please contact support.'
                        );
                    }
                }

                memberManagementSaving$.next(false);
            }
        })
    )
    .subscribe();

removeOrganizationInviteAction$
    .pipe(
        map(async (event) => {
            {
                memberManagementError$.next(undefined);
                memberManagementSaving$.next(true);
                try {
                    await removeOrganizationInvite(event);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Invite removed.',
                    });
                    organizationInviteRemovedAction$.next(event.removedInvite);
                } catch (e) {
                    if (e instanceof PermissionError || e instanceof InformativeError) {
                        memberManagementError$.next(e.message);
                    } else {
                        memberManagementError$.next(
                            'Error occurred while removing team invite. Please try again, if the issue persists please contact support.'
                        );
                    }
                }
                memberManagementSaving$.next(false);
            }
        })
    )
    .subscribe();

export const addNewOrganizationMemberAction$ = monitor(
    'addNewOrganizationMemberAction$',
    new Subject<MemberInvitationRequest>()
);
export const addNewOrganizationMemberDialogOpen$ = monitor(
    'addNewOrganizationMemberDialogOpen$',
    new BehaviorSubject(false)
);
export const organizationMemberInviteSent$ = monitor('organizationMemberInviteSent$', new Subject<void>());
export const sendingOrganizationMemberInvite$ = monitor('sendingOrganizationMemberInvite$', new BehaviorSubject(false));
export const sendOrganizationMemberInviteError$ = monitor(
    'sendOrganizationMemberInviteError$',
    new BehaviorSubject<string | undefined>(undefined)
);
addNewOrganizationMemberAction$
    .pipe(
        map(async (invite) => {
            sendOrganizationMemberInviteError$.next(undefined);
            sendingOrganizationMemberInvite$.next(true);

            try {
                await sendOrganizationMemberInvite(invite);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Invite sent.',
                });
                addNewOrganizationMemberDialogOpen$.next(false);
                organizationMemberInviteSent$.next();
            } catch (e) {
                sendingOrganizationMemberInvite$.next(false);
                if (e instanceof PermissionError || e instanceof InformativeError) {
                    sendOrganizationMemberInviteError$.next(e.message);
                } else {
                    console.error('Failed to send invitation', e);
                    sendOrganizationMemberInviteError$.next(
                        'Error occurred while sending invitation. Please try again, if the issue persists please contact support.'
                    );
                }
            }

            sendingOrganizationMemberInvite$.next(false);
        })
    )
    .subscribe();

export const resendOrganizationMemberInviteAction$ = monitor(
    'resendOrganizationMemberInviteAction$',
    new Subject<string>()
);

resendOrganizationMemberInviteAction$
    .pipe(
        map(async (uid) => {
            try {
                await resendOrganizationMemberInvite(uid);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Invite resent.',
                });
            } catch (e) {
                if (e instanceof PermissionError || e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message:
                            'Error occurred while resending invite. Please try again, if the issue persists please contact support.',
                    });
                }
            }
        })
    )
    .subscribe();

export const selectedOrganizationDetails$ = monitor(
    'selectedOrganizationDetails$',
    new BehaviorSubject<OrganizationDetails | undefined>(undefined)
);

export const saveOrganizationDetailsValidationError$ = monitor(
    'saveOrganizationDetailsValidationError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const organizationDetailsUpdating$ = monitor(
    'organizationDetailsUpdating$',
    new BehaviorSubject<boolean>(false)
);

export const selectedOrganizationPlan$ = monitor(
    'selectedOrganizationPlan$',
    new BehaviorSubject<OrganizationPlan | undefined>(undefined)
);

export const organizationInvoicePlanInformationDialogOpen$ = monitor(
    'organizationInvoicePlanInformationDialogOpen$',
    new BehaviorSubject<boolean>(false)
);

saveOrganizationDetailsAction$
    .pipe(
        map(async ({ name, organizationUid, description }) => {
            try {
                organizationDetailsUpdating$.next(true);
                saveOrganizationDetailsValidationError$.next(undefined);
                await saveOrganizationDetails(name, organizationUid, description);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Team details updated.',
                });
                organizationDetailsUpdating$.next(false);
                organizationDetailsUpdatedAction$.next(name);
            } catch (e) {
                organizationDetailsUpdating$.next(false);
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    saveOrganizationDetailsValidationError$.next(e.message);
                } else {
                    saveOrganizationDetailsValidationError$.next(
                        'Error occurred while updating team details. Please try again, if the issue persists please contact support.'
                    );
                }
            }
        })
    )
    .subscribe();
