import { useObservableState } from 'observable-hooks';
import { GitlabEventListenerSetupDialog } from '../../../components/connections/gitlab/EventListenerSetupDialog';
import { configTopic$ } from '../../../store/config';
import {
    gitlabEventListenerSetupDialogCloseAction$,
    gitlabEventListenerSetupDialogDetails$,
    gitlabEventListenerSetupDialogLoading$,
    gitlabEventListenerSetupDialogOpen$,
} from '../../../store/connection/gitlab/setup-event-listener';

export const GitlabEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(gitlabEventListenerSetupDialogLoading$);
    const open = useObservableState(gitlabEventListenerSetupDialogOpen$);
    const details = useObservableState(gitlabEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <GitlabEventListenerSetupDialog
            loading={loading}
            open={open}
            eventTypeName={details?.eventTypeName ?? ''}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            gitlabUrl={details?.gitlabUrl ?? ''}
            onClose={() => gitlabEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
